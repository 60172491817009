// app/javascript/controllers/global_quotes_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tableBody", "quoteCheckbox", "selectAll", "bulkAction",
    "searchInput", "priorityFilter", "sortBy", "sortIcon",
    "emptyState"
  ]

  connect() {
    this.selectedQuotes = new Set()
    this.currentSort = { key: "created_at", direction: "desc" }

    // Set default sort icon
    this.updateSortIcons()
  }

  // Filtering by tab
  filterByTab(event) {
    const status = event.currentTarget.dataset.status
    this.filterQuotes(status)
  }

  // Search functionality
  search() {
    const query = this.searchInputTarget.value.toLowerCase()
    this.filterQuotes()
  }

  // Filter by priority
  filterByPriority() {
    this.filterQuotes()
  }

  // Reset all filters
  resetFilters() {
    this.searchInputTarget.value = ""
    this.priorityFilterTarget.value = ""
    this.sortByTarget.value = "created_at-desc"
    this.currentSort = { key: "created_at", direction: "desc" }
    this.updateSortIcons()

    // Find active tab and click it to reset to that view
    const activeTab = document.querySelector('[data-tabs-target="tab"].tab-active')
    if (activeTab) {
      activeTab.click()
    } else {
      this.filterQuotes()
    }
  }

  // Combine all filters
  filterQuotes(status = null) {
    const query = this.searchInputTarget.value.toLowerCase()
    const priority = this.priorityFilterTarget.value

    // If status is not provided, get it from the active tab
    if (!status) {
      const activeTab = document.querySelector('[data-tabs-target="tab"].tab-active')
      status = activeTab ? activeTab.dataset.status : 'all'
    }

    let visibleCount = 0

    // Filter the rows
    const rows = this.tableBodyTarget.querySelectorAll('tr')
    rows.forEach(row => {
      const publicId = row.dataset.publicId?.toLowerCase() || ""
      const customer = (row.dataset.customer || "").toLowerCase()
      const rowStatus = row.dataset.status
      const rowPriority = row.dataset.priority

      // Apply all filters
      const matchesSearch = !query ||
        publicId.includes(query) ||
        customer.includes(query)

      const matchesStatus = status === 'all' || rowStatus === status
      const matchesPriority = !priority || rowPriority === priority

      // Show or hide the row
      if (matchesSearch && matchesStatus && matchesPriority) {
        row.classList.remove('hidden')
        visibleCount++
      } else {
        row.classList.add('hidden')
      }
    })

    // Show empty state if no results
    if (visibleCount === 0) {
      this.emptyStateTarget.classList.remove('hidden')
    } else {
      this.emptyStateTarget.classList.add('hidden')
    }

    // Apply current sort
    this.applySort(this.currentSort.key, this.currentSort.direction)

    // Reset checkboxes when filtering
    this.selectAllTarget.checked = false
    this.selectedQuotes.clear()
    this.updateBulkActions()
  }

  // Sorting
  sort(event) {
    let key, direction

    // Handle sort from dropdown
    if (event.type === 'change') {
      const [sortKey, sortDirection] = this.sortByTarget.value.split('-')
      key = sortKey
      direction = sortDirection
    }
    // Handle sort from column header
    else if (event.currentTarget.dataset.sortKey) {
      key = event.currentTarget.dataset.sortKey

      // Toggle direction if clicking the same column
      if (this.currentSort.key === key) {
        direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc'
      } else {
        direction = 'asc'
      }

      // Update dropdown to match
      this.sortByTarget.value = `${key}-${direction}`
    }

    this.currentSort = { key, direction }
    this.applySort(key, direction)
    this.updateSortIcons()
  }

  applySort(key, direction) {
    const rows = Array.from(this.tableBodyTarget.querySelectorAll('tr:not(.hidden)'))

    rows.sort((a, b) => {
      let valueA = a.dataset[this.camelCase(key)]
      let valueB = b.dataset[this.camelCase(key)]

      // Convert to numbers if possible (for total, created-at, etc.)
      if (!isNaN(valueA) && !isNaN(valueB)) {
        valueA = parseFloat(valueA)
        valueB = parseFloat(valueB)
      }

      if (direction === 'asc') {
        return valueA > valueB ? 1 : -1
      } else {
        return valueA < valueB ? 1 : -1
      }
    })

    // Re-append rows in sorted order
    rows.forEach(row => {
      this.tableBodyTarget.appendChild(row)
    })
  }

  updateSortIcons() {
    // Clear all icons first
    this.sortIconTargets.forEach(icon => {
      icon.innerHTML = ''
    })

    // Find the active sort icon
    const activeIcon = this.sortIconTargets.find(icon =>
      icon.dataset.sortKey === this.currentSort.key
    )

    if (activeIcon) {
      activeIcon.innerHTML = this.currentSort.direction === 'asc'
        ? '<i class="fas fa-arrow-up text-xs ml-1"></i>'
        : '<i class="fas fa-arrow-down text-xs ml-1"></i>'
    }
  }

  // Convert dash-case to camelCase for dataset properties
  camelCase(str) {
    return str.replace(/-([a-z])/g, g => g[1].toUpperCase())
  }

  // Select all quotes
  toggleSelectAll() {
    const isChecked = this.selectAllTarget.checked

    this.quoteCheckboxTargets.forEach(checkbox => {
      // Only affect visible rows
      if (!checkbox.closest('tr').classList.contains('hidden')) {
        checkbox.checked = isChecked

        const quoteId = checkbox.dataset.quoteId
        if (isChecked) {
          this.selectedQuotes.add(quoteId)
        } else {
          this.selectedQuotes.delete(quoteId)
        }
      }
    })

    this.updateBulkActions()
  }

  // Update selected quotes when individual checkbox changes
  updateSelectedQuotes(event) {
    const checkbox = event.target
    const quoteId = checkbox.dataset.quoteId

    if (checkbox.checked) {
      this.selectedQuotes.add(quoteId)
    } else {
      this.selectedQuotes.delete(quoteId)

      // Uncheck "select all" if any individual checkbox is unchecked
      this.selectAllTarget.checked = false
    }

    this.updateBulkActions()
  }

  // Enable/disable bulk actions based on selection
  updateBulkActions() {
    const hasSelection = this.selectedQuotes.size > 0

    this.bulkActionTargets.forEach(button => {
      button.disabled = !hasSelection
    })
  }

  // Bulk action methods
  bulkExport() {
    if (this.selectedQuotes.size === 0) return

    alert(`Exporting ${this.selectedQuotes.size} quotes (Implementation pending)`)
    // TODO: Implementation for exporting selected quotes
  }

  bulkEmail() {
    if (this.selectedQuotes.size === 0) return

    alert(`Emailing ${this.selectedQuotes.size} quotes (Implementation pending)`)
    // TODO: Implementation for emailing selected quotes
  }

  bulkCancel() {
    if (this.selectedQuotes.size === 0) return

    if (confirm(`Are you sure you want to cancel ${this.selectedQuotes.size} selected quotes?`)) {
      const quoteIds = Array.from(this.selectedQuotes)
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content

      fetch('/manage/quotes/bulk_cancel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ quote_ids: quoteIds })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Refresh the page or update the UI
            window.location.reload()
          } else {
            alert(`Error: ${data.message || 'Could not cancel quotes'}`)
          }
        })
        .catch(error => {
          console.error('Error cancelling quotes:', error)
          alert('An error occurred while cancelling quotes. Please try again.')
        })
    }
  }
}