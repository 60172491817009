import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["otherReason"];

  toggleOther(event) {
    const select = event.currentTarget;
    const rmaItemContainer = select.closest(".rma-item");
    const otherField = rmaItemContainer.querySelector(
      '[data-rma-target="otherReason"]',
    );
    if (select.value === "other") {
      otherField.style.display = "block";
    } else {
      otherField.style.display = "none";
      // Optionally clear the text input if not needed
      const input = otherField.querySelector("input[type='text']");
      if (input) {
        input.value = "";
      }
    }
  }
}
