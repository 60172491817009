// app/javascript/controllers/wizard_payments_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "paymentMethodRadio",
    "methodFields",
    "referenceField",
    "amountField",
    "cardElement",
    "paymentLinkAmount",
    "paymentLinkNotes",
    "paymentLinkForm",
    "paymentLinkResult",
    "fileInput",
    "fileList",
    "fileListContainer"
  ]

  debug(message, ...args) {
    console.log(`[WizardPayments] ${message}`, ...args)
  }

  connect() {
    this.debug("Controller connected")

    // Format the initial amount value properly
    this.formatAmount()

    // Set up Stripe elements
    this.setupStripe()

    // Initialize view based on current payment method
    this.updatePaymentFields()

    // Add transaction filter listeners
    this.initTransactionFilters()

    // Add debugging info
    this.debug("Payment method targets:", this.paymentMethodRadioTargets.length)
    this.debug("Method field targets:", this.methodFieldsTargets.length)
    this.debug("Card element target exists:", !!this.cardElementTarget)
  }

  setupStripe() {
    // We need to delay this slightly to ensure the DOM is fully loaded
    setTimeout(() => {
      if (typeof Stripe === 'undefined') {
        console.error('Stripe.js is not loaded')
        // Show a warning in the card element container
        const errorDiv = document.getElementById('card-errors')
        if (errorDiv) {
          errorDiv.textContent = 'Error: Stripe.js failed to load. Please refresh the page.'
        }
        return
      }

      // Get the Stripe key from a data attribute or environment
      const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content')

      if (!stripeKey) {
        console.error('Stripe publishable key is missing')
        return
      }

      try {
        this.stripe = Stripe(stripeKey)
        this.elements = this.stripe.elements()
        this.cardElement = this.elements.create('card', {
          style: {
            base: {
              color: document.documentElement.classList.contains('dark') ? '#e2e8f0' : '#4a5568',
              '::placeholder': {
                color: document.documentElement.classList.contains('dark') ? '#64748b' : '#a0aec0',
              },
              iconColor: document.documentElement.classList.contains('dark') ? '#e2e8f0' : '#4a5568',
            },
            invalid: {
              color: '#ef4444',
              iconColor: '#ef4444'
            }
          }
        })

        if (this.cardElementTarget) {
          this.cardElement.mount(this.cardElementTarget)
          this.debug('Stripe card element mounted successfully')
        } else {
          console.error('Card element target not found')
        }
      } catch (error) {
        console.error('Error initializing Stripe:', error)
      }
    }, 100) // Small delay to ensure DOM is ready
  }

  formatAmount() {
    // Get the current value and format it properly
    const currentValue = this.amountFieldTarget.value

    // If value exists and is a valid number
    if (currentValue && !isNaN(parseFloat(currentValue))) {
      // Parse to float, round to 2 decimal places, and format back
      const formattedValue = parseFloat(currentValue).toFixed(2)
      this.amountFieldTarget.value = formattedValue
    }
  }

  initTransactionFilters() {
    const filterLinks = document.querySelectorAll('.transaction-filter')
    const transactionItems = document.querySelectorAll('.transaction-item')

    filterLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault()

        // Remove active class from all filters
        filterLinks.forEach(f => {
          f.classList.remove('active-filter', 'bg-blue-50', 'dark:bg-blue-900', 'text-blue-700', 'dark:text-blue-300')
        })

        // Add active class to clicked filter
        link.classList.add('active-filter', 'bg-blue-50', 'dark:bg-blue-900', 'text-blue-700', 'dark:text-blue-300')

        const filterType = link.dataset.filter

        // Filter transactions
        transactionItems.forEach(item => {
          if (filterType === 'all' || item.dataset.type.includes(filterType)) {
            item.style.display = ''
          } else {
            item.style.display = 'none'
          }
        })
      })
    })
  }

  changePaymentMethod(event) {
    this.updatePaymentFields()
  }

  updatePaymentFields() {
    const selectedMethod = this.getSelectedPaymentMethod()
    this.debug('Selected payment method:', selectedMethod)

    // Hide all method fields
    this.methodFieldsTargets.forEach(field => {
      field.classList.add('hidden')
    })

    // Show the selected method fields
    const selectedFields = this.methodFieldsTargets.find(field =>
      field.dataset.method === selectedMethod
    )

    if (selectedFields) {
      selectedFields.classList.remove('hidden')
      this.debug('Showing fields for method:', selectedMethod)
    } else {
      console.warn('No fields found for method:', selectedMethod)
    }

    // Handle reference field visibility
    if (selectedMethod === 'credit_card') {
      if (this.referenceFieldTarget) {
        this.referenceFieldTarget.classList.add('hidden')
      }

      // Re-initialize Stripe if it's a credit card payment
      if (this.cardElementTarget && this.cardElement) {
        // If we're switching back to credit card, make sure the element is mounted
        setTimeout(() => {
          try {
            // Check if the element is already mounted
            if (!this.cardElementTarget.querySelector('iframe')) {
              this.cardElement.mount(this.cardElementTarget)
              this.debug('Remounted Stripe card element')
            }
          } catch (error) {
            console.error('Error remounting Stripe element:', error)
          }
        }, 50)
      }
    } else {
      if (this.referenceFieldTarget) {
        this.referenceFieldTarget.classList.remove('hidden')
      }
    }
  }

  getSelectedPaymentMethod() {
    const selectedRadio = this.paymentMethodRadioTargets.find(radio => radio.checked)
    return selectedRadio ? selectedRadio.value : 'credit_card'
  }

  updateAmountByPercentage(event) {
    const percentage = parseFloat(event.currentTarget.dataset.value || event.target.value)
    if (!isNaN(percentage)) {
      // Get the original balance due from the data attribute
      const balanceDue = parseFloat(this.amountFieldTarget.dataset.balanceDue)
      if (!isNaN(balanceDue)) {
        const newAmount = (balanceDue * percentage / 100).toFixed(2)
        this.amountFieldTarget.value = newAmount
      }
    }
  }

  updateAmountByFixedValue(event) {
    const fixedValue = parseFloat(event.target.value)
    if (!isNaN(fixedValue)) {
      this.amountFieldTarget.value = fixedValue.toFixed(2)
    }
  }

  setFullAmount() {
    const balanceDue = parseFloat(this.amountFieldTarget.dataset.balanceDue)
    if (!isNaN(balanceDue)) {
      this.amountFieldTarget.value = balanceDue.toFixed(2)
    }
  }

  updateFileList() {
    if (!this.hasFileInputTarget) return

    const files = this.fileInputTarget.files
    if (files.length > 0) {
      // Show the file list container
      this.fileListContainerTarget.classList.remove('hidden')

      // Clear the list first
      this.fileListTarget.innerHTML = ''

      // Add each file to the list
      Array.from(files).forEach(file => {
        const li = document.createElement('li')
        li.textContent = file.name
        li.className = 'text-xs text-gray-600 dark:text-space-400'
        this.fileListTarget.appendChild(li)
      })
    } else {
      // Hide the list if no files
      this.fileListContainerTarget.classList.add('hidden')
    }
  }

  generatePaymentLink(event) {
    event.preventDefault()

    // Get values from form
    const amount = this.amountFieldTarget.value
    const notes = document.querySelector('textarea[name="transaction[notes]"]').value

    // Set values in the payment link form
    this.paymentLinkAmountTarget.value = amount
    this.paymentLinkNotesTarget.value = notes

    // Submit the form via fetch instead of traditional submit
    const formData = new FormData(this.paymentLinkFormTarget)
    const url = this.paymentLinkFormTarget.action

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.url) {
          this.showPaymentLinkModal(data.url)
        } else {
          alert(data.error || 'Failed to generate payment link')
        }
      })
      .catch(error => {
        console.error('Error generating payment link:', error)
        alert('An error occurred while generating the payment link')
      })
  }

  showPaymentLinkModal(url) {
    // Create modal HTML
    const modal = document.createElement('div')
    modal.className = 'fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 z-50 flex items-center justify-center'
    modal.innerHTML = `
      <div class="bg-white dark:bg-space-800 rounded-lg shadow-lg max-w-md w-full p-6">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-medium text-gray-900 dark:text-space-100">Payment Link Generated</h3>
          <button type="button" class="text-gray-400 hover:text-gray-500 dark:text-space-400 dark:hover:text-space-300">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="mt-2">
          <p class="text-sm text-gray-500 dark:text-space-400 mb-2">Share this link with your customer to collect payment:</p>
          <div class="flex">
            <input type="text" value="${url}" class="flex-1 p-2 border border-gray-300 dark:border-space-600 rounded-l-md bg-gray-50 dark:bg-space-700 text-gray-800 dark:text-space-200" readonly />
            <button type="button" class="bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 text-white px-4 rounded-r-md copy-link-button">
              Copy
            </button>
          </div>
        </div>
        <div class="mt-4 flex justify-end">
          <button type="button" class="bg-gray-100 hover:bg-gray-200 dark:bg-space-700 dark:hover:bg-space-600 text-gray-800 dark:text-space-200 px-4 py-2 rounded-md close-modal-button">
            Close
          </button>
        </div>
      </div>
    `

    document.body.appendChild(modal)

    // Add event listeners
    modal.querySelector('.close-modal-button').addEventListener('click', () => {
      document.body.removeChild(modal)
    })

    modal.querySelector('.copy-link-button').addEventListener('click', () => {
      const input = modal.querySelector('input')
      input.select()
      document.execCommand('copy')

      const copyButton = modal.querySelector('.copy-link-button')
      copyButton.textContent = 'Copied!'
      setTimeout(() => {
        copyButton.textContent = 'Copy'
      }, 2000)
    })

    modal.querySelector('button[type="button"]').addEventListener('click', () => {
      document.body.removeChild(modal)
    })
  }

  processPayment(event) {
    const paymentMethod = this.getSelectedPaymentMethod()

    // Only intercept for credit card payments
    if (paymentMethod === 'credit_card') {
      event.preventDefault()

      // Check if Stripe is properly initialized
      if (!this.stripe || !this.cardElement) {
        const errorElement = document.getElementById('card-errors')
        errorElement.textContent = 'Stripe is not initialized. Please refresh the page and try again.'
        return
      }

      // Disable submit button
      const submitButton = event.target.querySelector('[type="submit"]')
      submitButton.disabled = true
      submitButton.textContent = 'Processing...'

      // Use createToken for simple charge processing
      this.stripe.createToken(this.cardElement).then(result => {
        if (result.error) {
          // Show error
          const errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message

          // Re-enable button
          submitButton.disabled = false
          submitButton.textContent = 'Process Payment'
        } else {
          // Add token to form and submit
          const hiddenInput = document.createElement('input')
          hiddenInput.setAttribute('type', 'hidden')
          hiddenInput.setAttribute('name', 'stripe_payment_method_id')
          hiddenInput.setAttribute('value', result.token.id)
          event.target.appendChild(hiddenInput)

          // Submit the form
          event.target.submit()
        }
      }).catch(error => {
        console.error('Stripe error:', error)
        const errorElement = document.getElementById('card-errors')
        errorElement.textContent = 'An error occurred while processing your card. Please try again.'

        // Re-enable button
        submitButton.disabled = false
        submitButton.textContent = 'Process Payment'
      })
    }
  }
}