import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "collapsible", "collapsibleButton", "collapsibleIcon"]

  connect() {
    // Initialize collapsible states
    this.collapsibleTargets.forEach(collapsible => {
      const id = collapsible.dataset.id
      const isCollapsed = localStorage.getItem(`sidebar_${id}`) !== 'expanded'
      if (isCollapsed) {
        this.collapse(collapsible)
      } else {
        this.expand(collapsible)
      }
    })

    // Add click outside listener for mobile
    this.clickOutsideHandler = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.clickOutsideHandler)
  }

  disconnect() {
    // Remove click outside listener when controller is disconnected
    document.removeEventListener('click', this.clickOutsideHandler)
  }

  toggle(event) {
    // Prevent default behavior
    event?.preventDefault()
    event?.stopPropagation()

    // Toggle sidebar visibility
    const sidebar = document.querySelector('[data-sidebar-target="sidebar"]')
    sidebar.classList.toggle('-translate-x-full')

    // Add/remove backdrop
    const existingBackdrop = document.getElementById('sidebar-backdrop')
    if (existingBackdrop) {
      existingBackdrop.remove()
    } else {
      const backdrop = document.createElement('div')
      backdrop.id = 'sidebar-backdrop'
      backdrop.classList.add(
        'fixed', 'inset-0', 'bg-black', 'bg-opacity-50',
        'z-30', 'sm:hidden'
      )
      backdrop.addEventListener('click', (e) => this.toggle(e))
      document.body.appendChild(backdrop)
    }
  }

  handleClickOutside(event) {
    // Close sidebar when clicking outside on mobile
    const sidebar = document.querySelector('[data-sidebar-target="sidebar"]')
    if (!sidebar.classList.contains('-translate-x-full') &&
      window.innerWidth < 640 && // Only for mobile
      !sidebar.contains(event.target) &&
      !event.target.closest('[data-action="click->sidebar#toggle"]')) {
      this.toggle(event)
    }
  }

  toggleCollapsible(event) {
    const collapsible = event.currentTarget.nextElementSibling
    const id = event.currentTarget.dataset.id

    if (collapsible.classList.contains('hidden')) {
      this.expand(collapsible)
      localStorage.setItem(`sidebar_${id}`, 'expanded')
    } else {
      this.collapse(collapsible)
      localStorage.setItem(`sidebar_${id}`, 'collapsed')
    }

    // Close mobile sidebar after selecting an item
    if (window.innerWidth < 640) {
      this.toggle(event)
    }
  }

  expand(collapsible) {
    collapsible.classList.remove('hidden')
    collapsible.previousElementSibling.querySelector('.collapse-icon').classList.add('rotate-180')
  }

  collapse(collapsible) {
    collapsible.classList.add('hidden')
    collapsible.previousElementSibling.querySelector('.collapse-icon').classList.remove('rotate-180')
  }
}