// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static targets = ["tab", "panel"]

//   connect() {
//     // Show first panel by default
//     this.showFirstPanel()
//   }

//   change(event) {
//     event.preventDefault()
//     this.hideAllPanels()

//     // Find the clicked tab
//     const clickedTab = event.currentTarget
//     const allTabs = this.tabTargets
//     const tabIndex = allTabs.indexOf(clickedTab)

//     // Show corresponding panel
//     if (tabIndex !== -1) {
//       this.panelTargets[tabIndex].classList.remove('hidden')
//     }

//     // Update tab styles
//     this.tabTargets.forEach((tab, index) => {
//       if (index === tabIndex) {
//         tab.setAttribute('data-tab-active', '')
//       } else {
//         tab.removeAttribute('data-tab-active')
//       }
//     })
//   }

//   hideAllPanels() {
//     this.panelTargets.forEach(panel => {
//       panel.classList.add('hidden')
//     })
//   }

//   showFirstPanel() {
//     if (this.panelTargets.length > 0) {
//       this.hideAllPanels()
//       this.panelTargets[0].classList.remove('hidden')
//       this.tabTargets[0]?.setAttribute('data-tab-active', '')
//     }
//   }
// }

// app/javascript/controllers/form_tabs_controller.js
// app/javascript/controllers/form_tabs_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    // Set initial active tab
    this.activateTab(0)
  }

  change(event) {
    const clickedTab = event.currentTarget
    const tabIndex = Array.from(this.tabTargets).indexOf(clickedTab)

    if (tabIndex !== -1) {
      this.activateTab(tabIndex)
    }
  }

  activateTab(index) {
    // Update tab styles
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.add("text-space-100", "bg-blue-500/10", "border-blue-500")
        tab.classList.remove("text-space-400", "border-transparent")
      } else {
        tab.classList.remove("text-space-100", "bg-blue-500/10", "border-blue-500")
        tab.classList.add("text-space-400", "border-transparent")
      }
    })

    // Show selected panel, hide others
    this.panelTargets.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("hidden")
      } else {
        panel.classList.add("hidden")
      }
    })
  }
}