// app/javascript/controllers/order_wizard/order_wizard_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    // Customer and Address targets
    "customerSearch",
    "customerResults",
    "selectedCustomerId",
    "customerInfo",
    "shippingAddress1",
    "shippingAddress2",
    "shippingCity",
    "shippingState",
    "shippingZip",
    "shippingCountry",
    "shippingName",
    "shippingPhone",
    "shippingEmail",
    "billingAddress1",
    "billingAddress2",
    "billingCity",
    "billingState",
    "billingZip",
    "billingCountry",
    "billingName",
    "billingPhone",
    "billingEmail",
    "addressSuggestions",
    "newCustomerModal",
    "addressSelectionModal",
    "customerAddressList",
    "addressTypeLabel",
    "addressSelectionType",
  ];

  static values = {
    searchUrl: { type: String, default: "/api/v1/customers/search" },
    geocodeUrl: { type: String, default: "/api/v1/addresses/geocode" },
    customerUrl: { type: String, default: "/api/v1/customers" },
  };

  connect() {
    console.log("Order Wizard Connected!");

    this.setupModalListeners();
    this.setupAddressLookup();

    // Debounce variables
    this.searchDebounce = null;
    this.addressDebounce = null;
    this.searchDelay = 300;

    // Customer and address data
    this.customerAddresses = [];

    // Listen for customer events
    document.addEventListener(
      "product-details:add-to-quote",
      this.handleProductAdd.bind(this),
    );
    document.addEventListener(
      "related-products:add-to-quote",
      this.handleRelatedProductsAdd.bind(this),
    );
    document.addEventListener(
      "line-item-addons:updated",
      this.handleAddonsUpdated.bind(this),
    );
    document.addEventListener(
      "customer:created",
      this.handleNewCustomer.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener(
      "product-details:add-to-quote",
      this.handleProductAdd.bind(this),
    );
    document.removeEventListener(
      "related-products:add-to-quote",
      this.handleRelatedProductsAdd.bind(this),
    );
    document.removeEventListener(
      "line-item-addons:updated",
      this.handleAddonsUpdated.bind(this),
    );
    document.removeEventListener(
      "customer:created",
      this.handleNewCustomer.bind(this),
    );
  }
  // ================ CUSTOMER SEARCH FUNCTIONALITY ================

  searchCustomer(event) {
    const query = event.target.value.trim();

    // Clear previous timeout
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }

    // Don't search for empty queries or queries less than 2 characters
    if (query.length < 2) {
      this.customerResultsTarget.innerHTML = "";
      this.customerResultsTarget.classList.add("hidden");
      return;
    }

    // Show loading state
    this.customerResultsTarget.innerHTML = `
      <div class="p-2 text-gray-500 dark:text-space-500 flex items-center justify-center gap-2">
        <svg class="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Searching...
      </div>
    `;
    this.customerResultsTarget.classList.remove("hidden");

    // Add a slight delay to prevent too many requests while typing
    this.searchDebounce = setTimeout(() => {
      fetch(`${this.searchUrlValue}?query=${encodeURIComponent(query)}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.displayCustomerResults(data || []);
        })
        .catch((error) => {
          console.error("Error searching customers:", error);

          // Show error message
          this.customerResultsTarget.innerHTML = `
            <div class="p-2 text-red-500 dark:text-red-400">
              <p>Error searching customers. Please try again.</p>
            </div>
            <div class="p-2 border-t border-gray-200 dark:border-space-700">
              <button type="button" data-action="order-wizard#createNewCustomer"
                class="text-blue-500 dark:text-blue-400 hover:underline">
                Create new customer
              </button>
            </div>
          `;
        });
    }, this.searchDelay);
  }

  displayCustomerResults(customers) {
    // Clear previous results
    this.customerResultsTarget.innerHTML = "";

    if (customers.length === 0) {
      this.customerResultsTarget.innerHTML = `
        <div class="p-2 text-gray-500 dark:text-space-500">No customers found</div>
        <div class="p-2 border-t border-gray-200 dark:border-space-700">
          <button type="button" data-action="order-wizard#createNewCustomer"
            class="text-blue-500 dark:text-blue-400 hover:underline">
            Create new customer
          </button>
        </div>
      `;
      this.customerResultsTarget.classList.remove("hidden");
      return;
    }

    // Create results list
    const resultsList = document.createElement("div");
    resultsList.classList.add("max-h-60", "overflow-y-auto");

    customers.forEach((customer) => {
      const resultItem = document.createElement("div");
      resultItem.classList.add(
        "p-2",
        "hover:bg-gray-100",
        "dark:hover:bg-space-700",
        "cursor-pointer",
        "border-b",
        "border-gray-200",
        "dark:border-space-700",
      );
      resultItem.innerHTML = `
        <div class="font-medium">${customer.name}</div>
        <div class="text-sm text-gray-500 dark:text-space-500">${customer.email || "No email"}</div>
        ${customer.phone ? `<div class="text-sm text-gray-500 dark:text-space-500">${customer.phone}</div>` : ""}
      `;
      resultItem.dataset.customerId = customer.id;
      resultItem.dataset.action = "click->order-wizard#selectCustomer";
      resultsList.appendChild(resultItem);
    });

    this.customerResultsTarget.appendChild(resultsList);
    this.customerResultsTarget.classList.remove("hidden");
  }

  selectCustomer(event) {
    const customerId = event.currentTarget.dataset.customerId;

    // Fetch the full customer details
    fetch(`${this.customerUrlValue}/${customerId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((customer) => {
        this.loadCustomerData(customer);
        this.selectedCustomerIdTarget.value = customer.id;
        this.customerResultsTarget.classList.add("hidden");
        this.customerSearchTarget.value = customer.name;
        this.showCustomerInfo(customer);

        // Store customer addresses for later use
        this.customerAddresses = customer.addresses || [];

        // Setup address selectors
        this.setupAddressSelectors();

        // Dispatch event so other controllers can react to customer selection
        const customerSelectedEvent = new CustomEvent("customer:selected", {
          detail: { customer: customer },
        });
        document.dispatchEvent(customerSelectedEvent);
      })
      .catch((error) => {
        console.error("Error fetching customer details:", error);
        this.showToast("Error loading customer details", "error");
      });
  }

  loadCustomerData(customer) {
    // Extract billing and shipping addresses
    const billingAddress =
      customer.addresses.find((a) => a.isBilling && a.isDefault) ||
      customer.addresses.find((a) => a.isBilling) ||
      {};

    const shippingAddress =
      customer.addresses.find((a) => a.isShipping && a.isDefault) ||
      customer.addresses.find((a) => a.isShipping) ||
      {};

    // Fill billing info
    if (this.hasBillingNameTarget && billingAddress.name)
      this.billingNameTarget.value = billingAddress.name;
    if (this.hasBillingPhoneTarget && billingAddress.phone)
      this.billingPhoneTarget.value = billingAddress.phone;
    if (this.hasBillingEmailTarget && customer.email)
      this.billingEmailTarget.value = customer.email;
    if (this.hasBillingAddress1Target && billingAddress.address_1)
      this.billingAddress1Target.value = billingAddress.address_1;
    if (this.hasBillingAddress2Target && billingAddress.address_2)
      this.billingAddress2Target.value = billingAddress.address_2;
    if (this.hasBillingCityTarget && billingAddress.city)
      this.billingCityTarget.value = billingAddress.city;
    if (this.hasBillingStateTarget && billingAddress.state)
      this.billingStateTarget.value = billingAddress.state;
    if (this.hasBillingZipTarget && billingAddress.zip)
      this.billingZipTarget.value = billingAddress.zip;
    if (this.hasBillingCountryTarget && billingAddress.country)
      this.billingCountryTarget.value = billingAddress.country;

    // Fill shipping info
    if (this.hasShippingNameTarget && shippingAddress.name)
      this.shippingNameTarget.value = shippingAddress.name;
    if (this.hasShippingPhoneTarget && shippingAddress.phone)
      this.shippingPhoneTarget.value = shippingAddress.phone;
    if (this.hasShippingEmailTarget && customer.email)
      this.shippingEmailTarget.value = customer.email;
    if (this.hasShippingAddress1Target && shippingAddress.address_1)
      this.shippingAddress1Target.value = shippingAddress.address_1;
    if (this.hasShippingAddress2Target && shippingAddress.address_2)
      this.shippingAddress2Target.value = shippingAddress.address_2;
    if (this.hasShippingCityTarget && shippingAddress.city)
      this.shippingCityTarget.value = shippingAddress.city;
    if (this.hasShippingStateTarget && shippingAddress.state)
      this.shippingStateTarget.value = shippingAddress.state;
    if (this.hasShippingZipTarget && shippingAddress.zip)
      this.shippingZipTarget.value = shippingAddress.zip;
    if (this.hasShippingCountryTarget && shippingAddress.country)
      this.shippingCountryTarget.value = shippingAddress.country;
  }

  showCustomerInfo(customer) {
    // Check if we have the customer info target
    if (!this.hasCustomerInfoTarget) return;

    // Show additional customer info like credit limit, etc.
    const creditInfo = customer.credit_limit
      ? `Credit limit: ${this.formatCurrency(customer.credit_limit)} | Available: ${this.formatCurrency(customer.available_credit)}`
      : "";

    this.customerInfoTarget.innerHTML = `
      <div class="text-sm p-2 bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-900 rounded">
        <div class="font-semibold">${customer.name}</div>
        <div>${customer.email || ""}</div>
        <div>${customer.phone || ""}</div>
        ${creditInfo ? `<div class="mt-1 text-xs">${creditInfo}</div>` : ""}
      </div>
    `;
    this.customerInfoTarget.classList.remove("hidden");
  }

  createNewCustomer() {
    // Hide the results dropdown
    if (this.hasCustomerResultsTarget) {
      this.customerResultsTarget.classList.add("hidden");
    }

    // Open the new customer modal if it exists
    if (this.hasNewCustomerModalTarget) {
      this.newCustomerModalTarget.classList.remove("hidden");
      document.body.classList.add("overflow-hidden");
    } else {
      this.showToast("Customer creation modal not available", "error");
    }
  }

  handleNewCustomer(event) {
    if (!event.detail || !event.detail.customer) return;

    const customer = event.detail.customer;

    // Update the form with the new customer
    this.loadCustomerData(customer);

    if (this.hasSelectedCustomerIdTarget) {
      this.selectedCustomerIdTarget.value = customer.id;
    }

    if (this.hasCustomerSearchTarget) {
      this.customerSearchTarget.value = customer.name;
    }

    this.showCustomerInfo(customer);

    // Store customer addresses
    this.customerAddresses = customer.addresses || [];

    // Setup address selectors
    this.setupAddressSelectors();

    // Close the modal if it exists
    if (this.hasNewCustomerModalTarget) {
      this.newCustomerModalTarget.classList.add("hidden");
      document.body.classList.remove("overflow-hidden");
    }

    this.showToast(`Customer ${customer.name} created successfully`, "success");
  }
  // ================ ADDRESS FUNCTIONALITY ================

  setupAddressLookup() {
    document.addEventListener("click", (e) => {
      // Close address suggestions when clicking outside
      if (
        this.hasAddressSuggestionsTarget &&
        !e.target.closest("[data-address-lookup]") &&
        !e.target.closest('[data-order-wizard-target="addressSuggestions"]')
      ) {
        this.addressSuggestionsTarget.classList.add("hidden");
      }
    });
  }

  // Initialize address lookup on field focus
  // Initialize address lookup on field focus
  initializeAddressLookup(event) {
    if (!this.addressSuggestionsTarget) return;

    // Position the suggestions dropdown
    const rect = event.target.getBoundingClientRect();
    this.addressSuggestionsTarget.style.top = `${rect.bottom}px`;
    this.addressSuggestionsTarget.style.left = `${rect.left}px`;
    this.addressSuggestionsTarget.style.width = `${rect.width}px`;

    // Store the current focused input for later use
    this.currentAddressInput = event.target;
  }

  lookupAddress(event) {
    if (!this.addressSuggestionsTarget) return;

    const query = event.target.value.trim();

    // Clear previous timeout
    if (this.addressDebounce) {
      clearTimeout(this.addressDebounce);
    }

    // Don't search for empty queries or queries less than 3 characters
    if (query.length < 3) {
      this.addressSuggestionsTarget.innerHTML = "";
      this.addressSuggestionsTarget.classList.add("hidden");
      return;
    }

    // Show loading state
    this.addressSuggestionsTarget.innerHTML = `
    <div class="bg-white dark:bg-space-800 shadow-lg rounded-md border border-gray-200 dark:border-space-700 p-3">
      <div class="text-center text-gray-500 dark:text-space-400">
        <svg class="animate-spin h-5 w-5 mx-auto mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Searching addresses...
      </div>
    </div>
  `;
    this.addressSuggestionsTarget.classList.remove("hidden");

    // Add a delay to prevent too many requests while typing
    this.addressDebounce = setTimeout(() => {
      fetch(`${this.geocodeUrlValue}?query=${encodeURIComponent(query)}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.displayAddressSuggestions(data.suggestions || []);
        })
        .catch((error) => {
          console.error("Error looking up address:", error);
          // Show error message in the dropdown
          this.addressSuggestionsTarget.innerHTML = `
          <div class="bg-white dark:bg-space-800 shadow-lg rounded-md border border-gray-200 dark:border-space-700 p-3">
            <div class="text-center text-red-500 dark:text-red-400">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              Unable to look up address. Please type manually.
            </div>
          </div>
        `;

          // Hide after 3 seconds
          setTimeout(() => {
            this.addressSuggestionsTarget.classList.add("hidden");
          }, 3000);
        });
    }, this.searchDelay);
  }

  displayAddressSuggestions(suggestions) {
    if (!this.addressSuggestionsTarget) return;

    // Clear previous results
    this.addressSuggestionsTarget.innerHTML = "";

    if (!suggestions || suggestions.length === 0) {
      this.addressSuggestionsTarget.classList.add("hidden");
      return;
    }

    // Create suggestions list
    const suggestionsList = document.createElement("div");
    suggestionsList.classList.add(
      "bg-white",
      "dark:bg-space-800",
      "shadow-lg",
      "rounded-md",
      "border",
      "border-gray-200",
      "dark:border-space-700",
      "overflow-hidden",
    );

    // Bind the selection method to preserve 'this' context
    const boundSelectSuggestion = this.selectAddressSuggestion.bind(this);

    suggestions.forEach((suggestion) => {
      const item = document.createElement("div");
      item.classList.add(
        "p-2",
        "hover:bg-gray-100",
        "dark:hover:bg-space-700",
        "cursor-pointer",
        "border-b",
        "border-gray-200",
        "dark:border-space-700",
      );
      item.textContent = suggestion.text;
      item.dataset.suggestion = JSON.stringify(suggestion.address);

      // Add click event handler with bound context
      item.addEventListener("click", boundSelectSuggestion);

      suggestionsList.appendChild(item);
    });

    this.addressSuggestionsTarget.appendChild(suggestionsList);
    this.addressSuggestionsTarget.classList.remove("hidden");
  }

  selectAddressSuggestion(event) {
    try {
      const suggestion = JSON.parse(event.currentTarget.dataset.suggestion);
      if (!this.currentAddressInput) return;

      const inputId = this.currentAddressInput.id;
      const fieldPrefix = inputId.includes("shipping") ? "shipping" : "billing";

      // Fill the address form fields - direct access to target elements
      const address1Target = this[`${fieldPrefix}Address1Target`];
      const address2Target = this[`${fieldPrefix}Address2Target`];
      const cityTarget = this[`${fieldPrefix}CityTarget`];
      const stateTarget = this[`${fieldPrefix}StateTarget`];
      const zipTarget = this[`${fieldPrefix}ZipTarget`];
      const countryTarget = this[`${fieldPrefix}CountryTarget`];

      // Set values if targets exist
      if (address1Target) {
        address1Target.value = suggestion.street_address || "";
      }

      // Important: Always clear line 2 if it's empty in the results
      if (address2Target) {
        address2Target.value = suggestion.street_address_2 || "";
      }

      if (cityTarget) {
        cityTarget.value = suggestion.city || "";
      }

      if (stateTarget) {
        stateTarget.value = suggestion.state || "";
      }

      if (zipTarget) {
        zipTarget.value = suggestion.postal_code || "";
      }

      if (countryTarget) {
        countryTarget.value = suggestion.country || "United States";
      }

      // Close the suggestions dropdown
      if (this.addressSuggestionsTarget) {
        this.addressSuggestionsTarget.classList.add("hidden");
      }

      // Show success toast
      this.showToast("Address filled successfully", "success");
    } catch (e) {
      console.error("Error filling address fields:", e);
    }
  }

  // Copy address functionality
  copyBillingToShipping() {
    if (this.hasShippingNameTarget && this.hasBillingNameTarget) {
      this.shippingNameTarget.value = this.billingNameTarget.value;
    }

    if (this.hasShippingPhoneTarget && this.hasBillingPhoneTarget) {
      this.shippingPhoneTarget.value = this.billingPhoneTarget.value;
    }

    if (this.hasShippingEmailTarget && this.hasBillingEmailTarget) {
      this.shippingEmailTarget.value = this.billingEmailTarget.value;
    }

    if (this.hasShippingAddress1Target && this.hasBillingAddress1Target) {
      this.shippingAddress1Target.value = this.billingAddress1Target.value;
    }

    if (this.hasShippingAddress2Target && this.hasBillingAddress2Target) {
      this.shippingAddress2Target.value = this.billingAddress2Target.value;
    }

    if (this.hasShippingCityTarget && this.hasBillingCityTarget) {
      this.shippingCityTarget.value = this.billingCityTarget.value;
    }

    if (this.hasShippingStateTarget && this.hasBillingStateTarget) {
      this.shippingStateTarget.value = this.billingStateTarget.value;
    }

    if (this.hasShippingZipTarget && this.hasBillingZipTarget) {
      this.shippingZipTarget.value = this.billingZipTarget.value;
    }

    if (this.hasShippingCountryTarget && this.hasBillingCountryTarget) {
      this.shippingCountryTarget.value = this.billingCountryTarget.value;
    }

    this.showToast("Copied billing address to shipping", "success");
  }

  copyShippingToBilling() {
    if (this.hasBillingNameTarget && this.hasShippingNameTarget) {
      this.billingNameTarget.value = this.shippingNameTarget.value;
    }

    if (this.hasBillingPhoneTarget && this.hasShippingPhoneTarget) {
      this.billingPhoneTarget.value = this.shippingPhoneTarget.value;
    }

    if (this.hasBillingEmailTarget && this.hasShippingEmailTarget) {
      this.billingEmailTarget.value = this.shippingEmailTarget.value;
    }

    if (this.hasBillingAddress1Target && this.hasShippingAddress1Target) {
      this.billingAddress1Target.value = this.shippingAddress1Target.value;
    }

    if (this.hasBillingAddress2Target && this.hasShippingAddress2Target) {
      this.billingAddress2Target.value = this.shippingAddress2Target.value;
    }

    if (this.hasBillingCityTarget && this.hasShippingCityTarget) {
      this.billingCityTarget.value = this.shippingCityTarget.value;
    }

    if (this.hasBillingStateTarget && this.hasShippingStateTarget) {
      this.billingStateTarget.value = this.shippingStateTarget.value;
    }

    if (this.hasBillingZipTarget && this.hasShippingZipTarget) {
      this.billingZipTarget.value = this.shippingZipTarget.value;
    }

    if (this.hasBillingCountryTarget && this.hasShippingCountryTarget) {
      this.billingCountryTarget.value = this.shippingCountryTarget.value;
    }

    this.showToast("Copied shipping address to billing", "success");
  }
  // Customer address selection
  setupAddressSelectors() {
    if (!this.customerAddresses || this.customerAddresses.length === 0) return;

    // Add "select from addresses" buttons if they don't already exist
    this.addAddressSelector("billing");
    this.addAddressSelector("shipping");
  }

  addAddressSelector(addressType) {
    // Get the address field to attach the selector to
    const targetName = `${addressType}Address1Target`;

    // Check if we have this target defined
    if (!this.targets.has(`${addressType}Address1`)) return;

    // Get the actual element
    const addressField = this[targetName];
    if (!addressField) return;

    // Check if selector already exists
    const existingSelector = addressField.parentElement.querySelector(
      ".address-selector-button",
    );
    if (existingSelector) return;

    // Create the selector button
    const selectorButton = document.createElement("div");
    selectorButton.className = "flex items-center mt-2 address-selector-button";
    selectorButton.innerHTML = `
      <button type="button" class="text-sm text-blue-500 hover:underline flex items-center"
              data-address-type="${addressType}"
              data-action="order-wizard#openAddressSelector">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        Select from customer addresses
      </button>
    `;

    // Add it after the field
    addressField.parentElement.appendChild(selectorButton);
  }

  openAddressSelector(event) {
    // Get selected address type (billing or shipping)
    const addressType = event.currentTarget.dataset.addressType;

    // Set modal title based on address type
    if (this.hasAddressTypeLabelTarget) {
      this.addressTypeLabelTarget.textContent =
        addressType === "billing" ? "Billing Address" : "Shipping Address";
    }

    // Store current address type
    if (this.hasAddressSelectionTypeTarget) {
      this.addressSelectionTypeTarget.value = addressType;
    }

    // Populate address list
    this.populateAddressList(addressType);

    // Open modal
    if (this.hasAddressSelectionModalTarget) {
      this.addressSelectionModalTarget.classList.remove("hidden");
      document.body.classList.add("overflow-hidden");
    }
  }

  populateAddressList(addressType) {
    if (!this.customerAddressListTarget || !this.customerAddresses) return;

    // Clear current list
    this.customerAddressListTarget.innerHTML = "";

    // Filter addresses by type
    const isBilling = addressType === "billing";
    const addresses = this.customerAddresses.filter(
      (address) =>
        (isBilling && address.isBilling) || (!isBilling && address.isShipping),
    );

    if (addresses.length === 0) {
      this.customerAddressListTarget.innerHTML = `
        <div class="text-center p-4 text-gray-500 dark:text-space-400">
          No ${addressType} addresses found for this customer.
        </div>
      `;
      return;
    }

    // Create address cards for each address
    addresses.forEach((address) => {
      const addressCard = document.createElement("div");
      addressCard.classList.add(
        "border",
        "border-gray-200",
        "dark:border-space-700",
        "rounded-md",
        "p-3",
        "hover:bg-gray-50",
        "dark:hover:bg-space-750",
        "cursor-pointer",
      );

      if (address.isDefault) {
        addressCard.classList.add(
          "bg-blue-50",
          "dark:bg-blue-900/20",
          "border-blue-200",
          "dark:border-blue-800",
        );
      }

      // Format the address
      const formattedAddress = [
        address.address_1,
        address.address_2,
        [address.city, address.state, address.zip].filter(Boolean).join(", "),
        address.country,
      ]
        .filter(Boolean)
        .join("<br>");

      addressCard.innerHTML = `
        <div class="flex justify-between">
          <h4 class="font-medium">${address.name || "Address"}</h4>
          ${address.isDefault ? '<span class="text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded">Default</span>' : ""}
        </div>
        <div class="text-sm text-gray-600 dark:text-space-300 mt-1">
          ${formattedAddress}
        </div>
        <div class="text-sm text-gray-600 dark:text-space-300 mt-1">
          ${address.phone ? `Phone: ${address.phone}` : ""}
        </div>
      `;

      // Store address data for selection
      addressCard.dataset.address = JSON.stringify(address);

      // Use explicit function binding to ensure 'this' context is preserved
      const boundSelectAddress = this.selectAddress.bind(this);
      addressCard.addEventListener("click", boundSelectAddress);

      this.customerAddressListTarget.appendChild(addressCard);
    });
  }

  selectAddress(event) {
    // Get the selected address data
    const addressData = JSON.parse(event.currentTarget.dataset.address);

    // Get the current address type (billing or shipping)
    const addressSelectionTypeTarget = this.addressSelectionTypeTarget;
    const addressType = addressSelectionTypeTarget
      ? addressSelectionTypeTarget.value
      : null;

    if (!addressType) return;

    const isShipping = addressType === "shipping";
    const prefix = isShipping ? "shipping" : "billing";

    // Fill in the form fields - direct access to target elements
    const nameTarget = this[`${prefix}NameTarget`];
    const phoneTarget = this[`${prefix}PhoneTarget`];
    const address1Target = this[`${prefix}Address1Target`];
    const address2Target = this[`${prefix}Address2Target`];
    const cityTarget = this[`${prefix}CityTarget`];
    const stateTarget = this[`${prefix}StateTarget`];
    const zipTarget = this[`${prefix}ZipTarget`];
    const countryTarget = this[`${prefix}CountryTarget`];

    // Set values if targets exist
    if (nameTarget) nameTarget.value = addressData.name || "";
    if (phoneTarget) phoneTarget.value = addressData.phone || "";
    if (address1Target) address1Target.value = addressData.address_1 || "";
    if (address2Target) address2Target.value = addressData.address_2 || "";
    if (cityTarget) cityTarget.value = addressData.city || "";
    if (stateTarget) stateTarget.value = addressData.state || "";
    if (zipTarget) zipTarget.value = addressData.zip || "";
    if (countryTarget)
      countryTarget.value = addressData.country || "United States";

    // Close the modal
    const modalTarget = this.addressSelectionModalTarget;
    if (modalTarget) {
      modalTarget.classList.add("hidden");
      document.body.classList.remove("overflow-hidden");
    }

    this.showToast(`Selected ${addressType} address`, "success");
  }

  closeModal(event) {
    const modal =
      event.target.closest(".modal") || event.target.closest("[data-modal]");
    if (modal) {
      modal.classList.add("hidden");
      document.body.classList.remove("overflow-hidden");
    }
  }

  // Handle "Same as billing" checkbox in customer form
  toggleSameAsBilling(event) {
    const checked = event.target.checked;
    const shippingFields = document.getElementById("shipping_address_fields");

    if (!shippingFields) return;

    if (checked) {
      // Copy billing address to shipping
      const billingFields = [
        "street_address",
        "street_address_2",
        "city",
        "state",
        "postal_code",
        "country",
        "phone",
      ];

      billingFields.forEach((field) => {
        const billingField = document.getElementById(`billing_${field}`);
        const shippingField = document.getElementById(`shipping_${field}`);

        if (billingField && shippingField) {
          shippingField.value = billingField.value;
        }
      });

      // Disable shipping fields
      shippingFields.querySelectorAll("input").forEach((input) => {
        input.disabled = true;
      });
      shippingFields.classList.add("opacity-50");
    } else {
      // Enable shipping fields
      shippingFields.querySelectorAll("input").forEach((input) => {
        input.disabled = false;
      });
      shippingFields.classList.remove("opacity-50");
    }
  }

  // Submit new customer form
  submitNewCustomer(event) {
    event.preventDefault();

    // Show loading state
    const submitButton = event.submitter;
    const originalText = submitButton.textContent;
    submitButton.disabled = true;
    submitButton.textContent = "Creating...";

    // Get form data
    const formData = new FormData(event.target);

    // Send form data to create customer
    fetch(this.customerUrlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          // Show errors
          this.displayFormErrors(data.errors, event.target);
        } else {
          // Close modal
          this.newCustomerModalTarget.classList.add("hidden");
          document.body.classList.remove("overflow-hidden");

          // Reset form
          event.target.reset();

          // Dispatch event with customer data
          document.dispatchEvent(
            new CustomEvent("customer:created", {
              detail: { customer: data },
            }),
          );

          // Show success message
          this.showToast(
            `Customer ${data.name} created successfully`,
            "success",
          );
        }
      })
      .catch((error) => {
        console.error("Error creating customer:", error);
        this.showToast(
          "There was an error creating the customer. Please try again.",
          "error",
        );
      })
      .finally(() => {
        // Reset button state
        submitButton.disabled = false;
        submitButton.textContent = originalText;
      });
  }

  displayFormErrors(errors, form) {
    // Create or update error summary
    let errorContainer = form.querySelector(".error-summary");

    if (!errorContainer) {
      errorContainer = document.createElement("div");
      errorContainer.classList.add(
        "error-summary",
        "bg-red-50",
        "dark:bg-red-900/20",
        "text-red-700",
        "dark:text-red-400",
        "p-4",
        "mb-4",
        "rounded",
      );
      form.querySelector(".p-4").prepend(errorContainer);
    }

    // Build error list
    const errorList = document.createElement("ul");
    errorList.classList.add("list-disc", "pl-5");

    errors.forEach((error) => {
      const li = document.createElement("li");
      li.textContent = error;
      errorList.appendChild(li);
    });

    errorContainer.innerHTML =
      '<h4 class="font-bold">Please fix the following errors:</h4>';
    errorContainer.appendChild(errorList);
  }
  // ================ SETUP AND INITIALIZATION ================

  setupModalListeners() {
    document.addEventListener(
      "product-details:add-to-quote",
      this.handleProductAdd.bind(this),
    );
    document.addEventListener(
      "related-products:add-to-quote",
      this.handleRelatedProductsAdd.bind(this),
    );
    document.addEventListener(
      "line-item-addons:updated",
      this.handleAddonsUpdated.bind(this),
    );

    // Check if we already have a customer when the page loads
    this.initializeCustomerData();
  }

  initializeCustomerData() {
    // If we already have a customer ID in the form, load that customer's data
    if (
      this.hasSelectedCustomerIdTarget &&
      this.selectedCustomerIdTarget.value
    ) {
      const customerId = this.selectedCustomerIdTarget.value;

      // Skip if the customer ID is empty
      if (!customerId) return;

      // Fetch the customer data
      fetch(`${this.customerUrlValue}/${customerId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((customer) => {
          // Store customer addresses for later use
          this.customerAddresses = customer.addresses || [];

          // Make sure the customer info is displayed
          if (
            this.hasCustomerSearchTarget &&
            !this.customerSearchTarget.value
          ) {
            this.customerSearchTarget.value = customer.name;
          }

          // Setup address selectors
          this.setupAddressSelectors();
        })
        .catch((error) => {
          console.error("Error fetching initial customer details:", error);
        });
    }
  }
  // ================ TOAST AND UTILITY FUNCTIONS ================

  showToast(message, type = "success") {
    const toast = document.createElement("div");

    if (type === "success") {
      toast.className =
        "fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0";
    } else {
      toast.className =
        "fixed bottom-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0";
    }

    toast.style.opacity = "0";
    toast.textContent = message;

    document.body.appendChild(toast);

    setTimeout(() => {
      toast.style.opacity = "1";
    }, 10);

    setTimeout(() => {
      toast.style.opacity = "0";
      setTimeout(() => {
        toast.remove();
      }, 500);
    }, 3000);
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
  // ================ PRODUCT HANDLING - CUSTOM ITEM ================

  // Custom Item
  addCustomItem(event) {
    event.preventDefault();

    const template = document.querySelector(
      '[data-nested-form-target="template"]',
    );

    if (!template) {
      console.error("Line item template not found");
      return;
    }

    const content = template.content.cloneNode(true);
    const newIndex = new Date().getTime();
    const uniqueId = `new_${newIndex}`;

    // Update all input names with the new index
    const inputs = content.querySelectorAll("input, select, textarea");
    inputs.forEach((input) => {
      if (input.name) {
        input.name = input.name.replace("NEW_RECORD", newIndex);
      }
    });

    const wrapper = content.querySelector(".line-item-wrapper");
    if (!wrapper) {
      console.error("Line item wrapper not found in template");
      return;
    }

    // Set unique ID and explicitly mark as custom item
    wrapper.dataset.lineItemId = uniqueId;
    wrapper.dataset.isCustom = "true";
    wrapper.dataset.customItem = "true";
    wrapper.dataset.productSku = `CUSTOM-${newIndex}`;
    wrapper.dataset.productName = "Custom Item";

    // Set input fields
    const productIdInput = wrapper.querySelector(
      'input[name$="[product_id]"], [data-product-id-field]',
    );
    if (productIdInput) productIdInput.value = ""; // No product ID for custom item

    const skuInput = wrapper.querySelector(
      'input[name$="[sku]"], [data-product-sku-field]',
    );
    if (skuInput) skuInput.value = `CUSTOM-${newIndex}`;

    const nameInput = wrapper.querySelector(
      'input[name$="[name]"], [data-product-name-field]',
    );
    if (nameInput) nameInput.value = "Custom Item";

    // Set initial price and cost
    const priceInput = wrapper.querySelector(
      'input[data-line-item-target="price"]',
    );
    if (priceInput) priceInput.value = "0.00";

    const costInput = wrapper.querySelector(
      'input[data-line-item-target="costDisplay"]',
    );
    if (costInput) costInput.value = "0.00";

    const hiddenCostInput = wrapper.querySelector(
      'input[data-line-item-target="cost"]',
    );
    if (hiddenCostInput) hiddenCostInput.value = "0.00";

    // Update visible elements
    const titleElement = wrapper.querySelector(
      ".product-title, [data-product-title]",
    );
    if (titleElement) {
      // Ensure we have the pencil icon for custom items
      if (
        !titleElement.previousElementSibling ||
        !titleElement.previousElementSibling.classList.contains("fa-pencil")
      ) {
        const pencilIcon = document.createElement("i");
        pencilIcon.className = "fas fa-pencil text-space-500 text-xs";
        titleElement.parentNode.insertBefore(pencilIcon, titleElement);
      }

      // Clear the existing text but keep the details button
      const detailsButton = titleElement.querySelector("button");
      titleElement.innerHTML = "Custom Item";
      if (detailsButton) {
        titleElement.appendChild(detailsButton);
      }

      // Add editable styling
      titleElement.classList.add(
        "border-b",
        "border-space-200",
        "dark:border-space-500",
      );

      // Make the title editable
      titleElement.contentEditable = "true";
      titleElement.addEventListener("input", (e) => {
        // Update the name field and data attribute when title changes
        const newName = e.target.innerText.trim();
        if (nameInput) nameInput.value = newName;
        wrapper.dataset.productName = newName;

        // Also update button data attribute if it exists
        const addonButton = wrapper.querySelector("[data-addons-button]");
        if (addonButton) addonButton.dataset.productName = newName;
      });
      titleElement.addEventListener("blur", (e) => {
        if (e.target.innerText.trim() === "") {
          e.target.innerText = "Custom Item";
          if (nameInput) nameInput.value = "Custom Item";
        }
      });
    }

    // Make SKU editable
    const skuElement = wrapper.querySelector(
      ".font-mono[data-product-sku-display]",
    );
    if (skuElement) {
      skuElement.textContent = `CUSTOM-${newIndex}`;
      // Add editable styling
      skuElement.classList.add(
        "border-b",
        "border-space-200",
        "dark:border-space-500",
      );
      skuElement.contentEditable = "true";
      skuElement.addEventListener("input", (e) => {
        // Update the SKU field and data attribute when SKU changes
        const newSku = e.target.innerText.trim();
        if (skuInput) skuInput.value = newSku;
        wrapper.dataset.productSku = newSku;

        // Also update button data attribute if it exists
        const addonButton = wrapper.querySelector("[data-addons-button]");
        if (addonButton) addonButton.dataset.productSku = newSku;
      });
      skuElement.addEventListener("blur", (e) => {
        if (e.target.innerText.trim() === "") {
          e.target.innerText = `CUSTOM-${newIndex}`;
          if (skuInput) skuInput.value = `CUSTOM-${newIndex}`;
        }
      });
    }

    const skuDisplayElement = wrapper.querySelector(".product-sku");
    if (skuDisplayElement && !skuElement) {
      // Ensure we have the pencil icon for custom SKU
      if (!skuDisplayElement.querySelector(".fa-pencil")) {
        const pencilIcon = document.createElement("i");
        pencilIcon.className = "fas fa-pencil text-space-500 text-xs mr-1";
        skuDisplayElement
          .querySelector("span")
          .insertBefore(
            pencilIcon,
            skuDisplayElement.querySelector("span").firstChild,
          );
      }

      const skuSpan = skuDisplayElement.querySelector("span");
      if (skuSpan) {
        skuSpan.textContent = `CUSTOM-${newIndex}`;
        skuSpan.contentEditable = "true";
        skuSpan.addEventListener("input", (e) => {
          const newSku = e.target.innerText.trim();
          if (skuInput) skuInput.value = newSku;
          wrapper.dataset.productSku = newSku;
        });
      }
    }

    // Hide details button for custom items
    const detailsButton = wrapper.querySelector("[data-details-button]");
    if (detailsButton) {
      detailsButton.classList.add("hidden");
      detailsButton.disabled = true;
      detailsButton.dataset.lineItemId = uniqueId;
      detailsButton.dataset.productSku = `CUSTOM-${newIndex}`;
      detailsButton.dataset.productName = "Custom Item";
      detailsButton.dataset.customItem = "true";
    }

    const addonsButton = wrapper.querySelector("[data-addons-button]");
    if (addonsButton) {
      addonsButton.dataset.lineItemId = uniqueId;
      addonsButton.dataset.productSku = `CUSTOM-${newIndex}`;
      addonsButton.dataset.productName = "Custom Item";
      addonsButton.dataset.customItem = "true";
    }

    // Update warehouse section
    const warehouseSection = wrapper.querySelector(
      '[data-order-item-target="warehouseInfo"]',
    );
    if (warehouseSection) {
      warehouseSection.innerHTML =
        '<div class="text-xs text-space-500 dark:text-space-500 italic">Custom item - no warehouse data</div>';
    }

    // Add the line item to the container
    const container = document.querySelector(
      '[data-nested-form-target="target"]',
    );
    if (container) {
      container.appendChild(content);

      const emptyState = document.querySelector(
        '[data-nested-form-target="empty"]',
      );
      if (emptyState) {
        emptyState.remove();
      }

      this.updateTotals();
      this.lastAddedLineItem = wrapper;
      this.showToast("Custom item added");
    }
  }
  // ================ PRODUCT HANDLING - REGULAR PRODUCTS ================

  updateTotals() {
    // Perform live total calculations
    // This functionality would depend on your specific implementation
    console.log("Updating totals...");
  }

  // Migrated from Quote
  handleProductAdd(event) {
    const { product } = event.detail;

    if (!product) {
      console.error("No product data in the event");
      return;
    }

    this.addProductToOrder(product);
  }

  handleRelatedProductsAdd(event) {
    const { products } = event.detail;

    if (!products || products.length === 0) {
      console.error("No products in the event");
      return;
    }

    products.forEach((item) => {
      this.addProductToOrder(item.product, item.quantity);
    });
  }

  handleAddonsUpdated(event) {
    const { lineItemId, addons } = event.detail;

    if (!lineItemId) {
      console.error("No line item ID in the event");
      return;
    }

    const lineItem = document.querySelector(
      `[data-line-item-id="${lineItemId}"]`,
    );
    if (!lineItem) return;

    addons.forEach((addon) => {
      this.addAddonToLineItem(lineItem, addon);
    });

    this.updateTotals();
  }

  addAddonToLineItem(lineItem, addon) {
    const newIndex = new Date().getTime() + Math.floor(Math.random() * 1000);

    const lineItemIndex = this.getLineItemIndex(lineItem);

    if (!lineItemIndex) {
      console.error("Could not determine line item index");
      return;
    }

    const addonFields = document.createElement("div");
    addonFields.classList.add("line-item-addon-fields", "hidden");

    addonFields.innerHTML = `
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][name]" value="${addon.name || ""}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][sku]" value="${addon.sku || ""}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][quantity]" value="${addon.quantity || 1}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][price]" value="${addon.price || 0}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][cost]" value="${addon.cost || 0}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][discount_amount]" value="0">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][discount_type]" value="fixed">
      ${addon.product_id ? `<input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][product_id]" value="${addon.product_id}">` : ""}
      ${addon.id ? `<input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][id]" value="${addon.id}">` : ""}
    `;

    lineItem.appendChild(addonFields);

    this.updateAddonDisplay(lineItem, lineItemIndex);
  }

  getLineItemIndex(lineItem) {
    const inputField = lineItem.querySelector(
      'input[name*="[line_items_attributes]"]',
    );

    if (!inputField) return null;

    const match = inputField.name.match(
      /\[line_items_attributes\]\[([0-9]+)\]/,
    );
    return match ? match[1] : null;
  }

  updateLineItemButtons(wrapper, product) {
    const detailsButton = wrapper.querySelector("[data-details-button]");
    const addonsButton = wrapper.querySelector("[data-addons-button]");

    const lineItemId = wrapper.dataset.lineItemId;

    if (detailsButton) {
      detailsButton.dataset.lineItemId = lineItemId;
      detailsButton.dataset.productSku = product.sku;
      detailsButton.dataset.productName = product.title;
    }

    if (addonsButton) {
      addonsButton.dataset.lineItemId = lineItemId;
      addonsButton.dataset.productSku = product.sku;
      addonsButton.dataset.productName = product.title;
    }
  }

  updateAddonDisplay(lineItem, lineItemIndex) {
    let addonsDisplay = lineItem.querySelector(".line-item-addons-display");

    if (!addonsDisplay) {
      addonsDisplay = document.createElement("div");
      addonsDisplay.className =
        "line-item-addons-display mt-2 pt-2 border-t border-space-200 dark:border-space-700";

      const insertAfter = lineItem.querySelector(".mt-auto");
      if (insertAfter && insertAfter.parentNode) {
        insertAfter.parentNode.insertBefore(
          addonsDisplay,
          insertAfter.nextSibling,
        );
      } else {
        lineItem.appendChild(addonsDisplay);
      }
    }

    const addonInputs = lineItem.querySelectorAll(
      `input[name*="[line_items_attributes][${lineItemIndex}][line_item_addons_attributes]"]`,
    );

    const addonGroups = {};
    addonInputs.forEach((input) => {
      const match = input.name.match(
        /\[line_item_addons_attributes\]\[([0-9]+)\]\[([^\]]+)\]/,
      );
      if (match) {
        const addonIndex = match[1];
        const fieldName = match[2];

        if (!addonGroups[addonIndex]) {
          addonGroups[addonIndex] = {};
        }

        addonGroups[addonIndex][fieldName] = input.value;
      }
    });

    const addonData = [];
    Object.values(addonGroups).forEach((addon) => {
      if (addon.name && addon.sku) {
        addonData.push({
          name: addon.name,
          quantity: parseInt(addon.quantity) || 1,
          price: parseFloat(addon.price) || 0,
        });
      }
    });

    if (addonData.length === 0) {
      addonsDisplay.classList.add("hidden");
      return;
    }

    let addonsHtml =
      '<div class="text-xs font-medium text-space-700 dark:text-space-300 mb-1">Add-ons:</div>';

    addonData.forEach((addon) => {
      const total = addon.quantity * addon.price;

      addonsHtml += `
        <div class="flex items-center justify-between text-xs py-1">
          <div class="flex items-center gap-1">
            <span class="font-medium">${addon.name}</span>
            <span class="text-space-500 dark:text-space-400">x${addon.quantity}</span>
          </div>
          <div class="font-medium">${this.formatCurrency(total)}</div>
        </div>
      `;
    });

    addonsDisplay.innerHTML = addonsHtml;
    addonsDisplay.classList.remove("hidden");
  }
  addProductToOrder(product, quantity = 1) {
    const template = document.querySelector(
      '[data-nested-form-target="template"]',
    );

    if (!template) {
      console.error("Line item template not found");
      return;
    }

    try {
      const content = template.content.cloneNode(true);

      const newIndex = new Date().getTime();

      const inputs = content.querySelectorAll("input, select, textarea");
      inputs.forEach((input) => {
        if (input.name) {
          input.name = input.name.replace("NEW_RECORD", newIndex);
        }
      });

      const wrapper = content.querySelector(".line-item-wrapper");

      if (!wrapper) {
        console.error("Line item wrapper not found in template");
        return;
      }

      // Generate a unique ID for the new line item
      const uniqueId = `new_${newIndex}`;
      wrapper.dataset.lineItemId = uniqueId;

      // Set all the fields for the line item
      this.setLineItemFields(wrapper, product, quantity);

      const container = document.querySelector(
        '[data-nested-form-target="target"]',
      );
      if (container) {
        container.appendChild(content);

        const emptyState = document.querySelector(
          '[data-nested-form-target="empty"]',
        );
        if (emptyState) {
          emptyState.remove();
        }
      }

      this.updateTotals();

      // Store reference to the last added line item
      this.lastAddedLineItem = wrapper;

      this.showToast(`${product.title} added to quote`);
    } catch (error) {
      console.error("Error adding product to quote:", error);
      this.showToast("Failed to add product to quote", "error");
    }
  }

  setLineItemFields(wrapper, product, quantity = 1) {
    // Update data attributes directly on the wrapper
    wrapper.dataset.productSku = product.sku || "";
    wrapper.dataset.productName = product.title || "";

    // Set hidden input fields
    const productIdInput = wrapper.querySelector(
      'input[name$="[product_id]"], [data-product-id-field]',
    );
    if (productIdInput) productIdInput.value = product.id;

    const skuInput = wrapper.querySelector(
      'input[name$="[sku]"], [data-product-sku-field]',
    );
    if (skuInput) skuInput.value = product.sku;

    const nameInput = wrapper.querySelector(
      'input[name$="[name]"], [data-product-name-field]',
    );
    if (nameInput) nameInput.value = product.title;

    // Set cost fields
    const costInput = wrapper.querySelector('input[name$="[cost]"]');
    const costDisplayInput = wrapper.querySelector(
      '[data-line-item-target="costDisplay"]',
    );
    if (costInput) costInput.value = product.cost;
    if (costDisplayInput) costDisplayInput.value = product.cost;

    // Set builder cost fields, if applicable
    const builderCostInput = wrapper.querySelector(
      'input[name$="[builder_cost]"]',
    );
    const builderCostDisplayInput = wrapper.querySelector(
      '[data-line-item-target="builderCostDisplay"]',
    );
    if (builderCostInput && product.builder_cost) {
      builderCostInput.value = product.builder_cost;
    }
    if (builderCostDisplayInput && product.builder_cost) {
      builderCostDisplayInput.value = product.builder_cost;
    }

    // Set price and quantity
    const priceInput = wrapper.querySelector(
      'input[name$="[price]"], [data-line-item-target="price"]',
    );
    if (priceInput) priceInput.value = product.price;

    const quantityInput = wrapper.querySelector(
      'input[name$="[quantity]"], [data-line-item-target="quantity"]',
    );
    if (quantityInput) quantityInput.value = quantity;

    // Set discount fields
    const discountTypeInput = wrapper.querySelector(
      'select[name$="[discount_type]"], [data-line-item-target="discountType"]',
    );
    const discountAmountInput = wrapper.querySelector(
      'input[name$="[discount_amount]"], [data-line-item-target="discountAmount"]',
    );
    if (discountTypeInput) discountTypeInput.value = "fixed";
    if (discountAmountInput) discountAmountInput.value = "0";

    // Update visible elements
    const titleElement = wrapper.querySelector(
      ".product-title, [data-product-title]",
    );
    if (titleElement) titleElement.textContent = product.title;

    const skuElement = wrapper.querySelector(
      ".font-mono[data-product-sku-display]",
    );
    if (skuElement) skuElement.textContent = product.sku;

    const skuDisplayElement = wrapper.querySelector(".product-sku");
    if (skuDisplayElement && !skuElement) {
      const skuTextNode = skuDisplayElement.querySelector("span");
      if (skuTextNode) skuTextNode.textContent = product.sku;
    }

    // Update image
    const imageElement = wrapper.querySelector(
      ".product-image, [data-product-image]",
    );
    if (
      imageElement &&
      (product.image || (product.image_urls && product.image_urls.length > 0))
    ) {
      const imageUrl = product.image || product.image_urls[0];
      imageElement.innerHTML = `<img src="${imageUrl}" class="w-full h-full object-cover" alt="${product.title}" />`;
    }

    // Update buttons
    this.updateLineItemButtons(wrapper, product);

    // Set stock info
    this.setStockInfo(wrapper, product);

    // Force recalculation of totals
    const lineItemController =
      this.application.getControllerForElementAndIdentifier(
        wrapper,
        "line-item",
      );
    if (
      lineItemController &&
      typeof lineItemController.updateTotal === "function"
    ) {
      lineItemController.updateTotal();
    }
  }

  setStockInfo(wrapper, product) {
    if (!product.stock) return;

    const stockContainer = wrapper.querySelector(".warehouse-availability");
    if (!stockContainer) return;

    const warehousesWithStock = product.stock.filter(
      (item) => item.available > 0,
    );

    if (warehousesWithStock.length === 0) {
      stockContainer.innerHTML =
        '<div class="text-xs text-space-500 dark:text-space-500 italic">No inventory available at any warehouse</div>';
      return;
    }

    let stockHtml = "";
    warehousesWithStock.forEach((inventory) => {
      stockHtml += `
        <div class="flex items-center gap-2 py-1 px-2 rounded bg-green-100 dark:bg-green-900/30">
          <div class="w-2 h-2 rounded-full bg-green-500 dark:bg-green-500"></div>
          <span class="text-xs font-medium">${inventory.warehouse.name}</span>
          <span class="text-xs ml-auto">${inventory.available} avail</span>
        </div>
      `;
    });

    stockContainer.innerHTML = stockHtml;
  }

  getTotalStock(product) {
    if (!product.stock || product.stock.length === 0) return 0;

    return product.stock.reduce((sum, item) => sum + (item.available || 0), 0);
  }
}
