import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "statusCheckbox",
    "paymentStatusCheckbox",
    "hasTransactionsCheckbox"
  ]

  connect() {
    this.loadFiltersFromStorage()
    this.bindChangeListeners()
  }

  bindChangeListeners() {
    // Add event listeners to all form elements to save changes
    this.statusCheckboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', () => this.saveFiltersToStorage())
    })

    this.paymentStatusCheckboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', () => this.saveFiltersToStorage())
    })

    if (this.hasHasTransactionsCheckboxTarget) {
      this.hasTransactionsCheckboxTarget.addEventListener('change', () => this.saveFiltersToStorage())
    }
  }

  saveFiltersToStorage() {
    const filters = {
      statuses: this.statusCheckboxTargets
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value),

      paymentStatuses: this.paymentStatusCheckboxTargets
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value),

      hasTransactions: this.hasHasTransactionsCheckboxTarget
        ? this.hasTransactionsCheckboxTarget.checked
        : false
    }

    localStorage.setItem('salesReportFilters', JSON.stringify(filters))
    console.log('Saved filters to local storage:', filters)
  }

  loadFiltersFromStorage() {
    const savedFilters = localStorage.getItem('salesReportFilters')

    if (!savedFilters) return

    try {
      const filters = JSON.parse(savedFilters)
      console.log('Loaded filters from storage:', filters)

      // Apply stored statuses
      if (filters.statuses && filters.statuses.length > 0) {
        this.statusCheckboxTargets.forEach(checkbox => {
          checkbox.checked = filters.statuses.includes(checkbox.value)
        })
      }

      // Apply stored payment statuses
      if (filters.paymentStatuses && filters.paymentStatuses.length > 0) {
        this.paymentStatusCheckboxTargets.forEach(checkbox => {
          checkbox.checked = filters.paymentStatuses.includes(checkbox.value)
        })
      }

      // Apply hasTransactions checkbox
      if (this.hasHasTransactionsCheckboxTarget && filters.hasTransactions !== undefined) {
        this.hasTransactionsCheckboxTarget.checked = filters.hasTransactions
      }
    } catch (e) {
      console.error('Error loading filters from storage:', e)
    }
  }

  clearFilters(event) {
    event.preventDefault()

    // Reset all checkboxes
    this.statusCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true
    })

    this.paymentStatusCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true
    })

    if (this.hasHasTransactionsCheckboxTarget) {
      this.hasTransactionsCheckboxTarget.checked = false
    }

    // Clear local storage
    localStorage.removeItem('salesReportFilters')

    // Save the default state
    this.saveFiltersToStorage()
  }
}