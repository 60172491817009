import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "results", "selectedCount", "addSelected"];

  connect() {
    console.log("ProductSearch controller connected");
    this.selectedProducts = new Map();
    this.setupKeyboardListener();
    this.setupClickOutsideListener();
  }

  setupKeyboardListener() {
    // Handle regular search input
    this.inputTarget.addEventListener(
      "keydown",
      (this.handleKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          const input = this.inputTarget.value.trim();

          // Check if the input matches the SKU:quantity or SKU;quantity pattern
          const skuQtyPattern =
            /^([A-Za-z0-9-]+)[:;,](\d+)(?:\s*[|,\s]\s*([A-Za-z0-9-]+)[:;,](\d+))*$/;
          if (skuQtyPattern.test(input)) {
            this.handleBulkAdd(input);
          } else {
            this.search();
          }
        } else if (e.key === "Escape") {
          // Dismiss results when Escape is pressed
          this.dismissResults();
        }
      }),
    );

    // Add global escape key listener for when focus is in the results area
    document.addEventListener(
      "keydown",
      (this.handleGlobalKeyDown = (e) => {
        if (e.key === "Escape" && this.resultsTarget.innerHTML.trim() !== "") {
          this.dismissResults();
        }
      }),
    );
  }

  setupClickOutsideListener() {
    // Add click outside listener
    document.addEventListener(
      "click",
      (this.handleClickOutside = (e) => {
        if (this.resultsTarget.innerHTML.trim() === "") return; // No results showing

        const searchArea = this.element;
        if (!searchArea.contains(e.target)) {
          this.dismissResults();
        }
      }),
    );
  }

  dismissResults() {
    this.resultsTarget.innerHTML = "";
  }

  async handleBulkAdd(input) {
    // Show loading indicator
    this.resultsTarget.innerHTML = `
      <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-gray-200 dark:border-space-500 p-8 text-center text-gray-500 dark:text-space-400">
        <p>Loading products...</p>
      </div>`;

    // Split by commas to get each SKU:qty pair
    const pairs = input.split(",");
    const requests = [];

    pairs.forEach((pair) => {
      // Split by either colon or semicolon
      const parts = pair.split(/[:;]/);
      const sku = parts[0];
      const qty = parts[1];

      if (sku && qty) {
        requests.push(this.fetchProductBySku(sku.trim(), parseInt(qty.trim())));
      }
    });

    try {
      const results = await Promise.all(requests);
      // Filter out any failed requests (null results)
      const validResults = results.filter((r) => r !== null);

      if (validResults.length > 0) {
        // Display success message
        this.resultsTarget.innerHTML = `
          <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-green-500 p-8 text-center text-green-600 dark:text-green-400">
            <p>Added ${validResults.length} product(s) to order</p>
          </div>`;

        // Add the products to the order
        validResults.forEach((result) => {
          this.addProductToOrder(result.product, result.quantity);
        });

        // Clear the search input
        this.inputTarget.value = "";

        // Hide the success message after 3 seconds
        setTimeout(() => {
          this.dismissResults();
        }, 3000);
      } else {
        this.resultsTarget.innerHTML = `
          <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-red-500 p-8 text-center text-red-600 dark:text-red-500">
            <p>No valid products found with the provided SKUs</p>
          </div>`;
      }
    } catch (error) {
      console.error("Bulk add failed:", error);
      this.resultsTarget.innerHTML = `
        <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-red-500 p-8 text-center text-red-600 dark:text-red-500">
          <p>Error adding products: ${error.message}</p>
        </div>`;
    }
  }

  async fetchProductBySku(sku, quantity) {
    try {
      const response = await fetch(
        `/api/v1/products/by_sku?sku=${encodeURIComponent(sku)}`,
      );
      if (!response.ok) return null;

      const data = await response.json();
      if (!data || !data.product) return null;

      // Transform the data to match what the product display expects
      const transformedProduct = {
        productId: data.product.id,
        productSku: data.product.sku,
        productTitle: data.product.title,
        productPrice: parseFloat(data.product.price),
        productCost: parseFloat(data.product.cost),
        productBuilderCost: parseFloat(data.product.builder_cost || 0), // Add builder_cost
        productImage: data.product.image,
      };

      console.log("Transformed product:", transformedProduct);
      return { product: transformedProduct, quantity };
    } catch (error) {
      console.error(`Error fetching product with SKU ${sku}:`, error);
      return null;
    }
  }

  search() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      const query = this.inputTarget.value.trim();
      if (query.length < 2) {
        this.dismissResults();
        return;
      }

      try {
        const response = await fetch(
          `/api/v1/products/search?query=${encodeURIComponent(query)}`,
        );
        const data = await response.json();

        if (data.results?.length > 0) {
          const resultsHtml = this.buildResultsHtml(data.results);
          this.resultsTarget.innerHTML = resultsHtml;

          // Re-highlight previously selected items
          this.restoreSelectedState();
        } else {
          this.resultsTarget.innerHTML = `
            <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-gray-200 dark:border-space-500 p-8 text-center text-gray-500 dark:text-space-400">
              <p>No products found</p>
            </div>`;
        }
      } catch (error) {
        console.error("Search failed:", error);
        this.resultsTarget.innerHTML = `
          <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-gray-200 dark:border-space-500 p-8 text-center text-red-600 dark:text-red-500">
            <p>Error performing search</p>
          </div>`;
      }
    }, 300);
  }

  buildResultsHtml(products) {
    return `
      <div class="bg-white dark:bg-space-700 rounded-md shadow-lg border border-gray-200 dark:border-space-500 max-h-96 overflow-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-space-600">
        <div class="sticky top-0 bg-gray-50 dark:bg-space-800 p-3 border-b border-gray-200 dark:border-space-600 flex justify-between items-center">
          <span class="text-sm text-gray-600 dark:text-space-300">Select multiple products</span>
          <button
            class="px-3 py-1 bg-blue-600 text-white rounded-md text-sm flex items-center gap-2 opacity-50 pointer-events-none"
            data-product-search-target="addSelected"
            data-action="click->product-search#addSelectedProducts">
            Add <span data-product-search-target="selectedCount">0</span> Selected
          </button>
        </div>
        <ul class="divide-y divide-gray-100 dark:divide-space-800">
          ${products
            .map((product) => {
              if (!product.cost) {
                console.warn(
                  `Product ${product.sku} is missing cost information`,
                );
              }

              const escapedSku = (product.sku || "").replace(/"/g, "&quot;");
              const escapedTitle = (product.title || "").replace(
                /"/g,
                "&quot;",
              );
              const escapedBrand = (product.brand || "").replace(
                /"/g,
                "&quot;",
              );
              const escapedImage = (product.image || "").replace(
                /"/g,
                "&quot;",
              );

              const stockCount =
                product.stock?.reduce(
                  (sum, s) => sum + (s.available || 0),
                  0,
                ) || 0;

              return `
              <li class="p-4 hover:bg-gray-50 dark:hover:bg-space-800 cursor-pointer"
                  data-product-id="${product.id}"
                  data-action="click->product-search#toggleProductSelection"
                  data-product-sku="${escapedSku}"
                  data-product-title="${escapedTitle}"
                  data-product-brand="${escapedBrand}"
                  data-product-price="${product.price || 0}"
                  data-product-cost="${product.cost || 0}"
                  data-product-builder-cost="${product.builder_cost || 0}"
                  data-product-image="${escapedImage}">
                <div class="flex items-center gap-4">
                  <div class="flex-shrink-0">
                    <input type="checkbox" class="w-4 h-4 rounded accent-blue-500 cursor-pointer product-select-checkbox">
                  </div>
                  ${
                    product.image
                      ? `<img src="${product.image}" class="w-16 h-16 object-cover rounded bg-gray-100 dark:bg-space-800" alt="${escapedTitle}" />`
                      : `<div class="w-16 h-16 bg-gray-100 dark:bg-space-800 rounded flex-shrink-0"></div>`
                  }
                  <div class="flex-1 min-w-0">
                    <div class="font-bold truncate text-gray-900 dark:text-white">${escapedTitle}</div>
                    <div class="text-sm text-gray-500 dark:text-space-400">SKU: ${escapedSku}</div>
                    <div class="text-sm text-gray-500 dark:text-space-400">${escapedBrand}</div>
                  </div>
                  <div class="text-right">
                    <div class="font-bold text-gray-900 dark:text-white">${this.formatCurrency(product.price)}</div>
                    <div class="text-sm text-gray-500 dark:text-space-400">Stock: ${stockCount}</div>
                    <div class="hidden product-quantity-control mt-2">
                      <div class="flex items-center bg-gray-100 dark:bg-space-900 rounded">
                        <button type="button" class="px-2 py-1 text-gray-600 dark:text-space-300 hover:text-gray-900 dark:hover:text-white" data-action="click->product-search#decrementQuantity">-</button>
                        <input type="number" min="1" value="1" class="w-12 bg-transparent border-0 text-center text-sm product-quantity text-gray-900 dark:text-white">
                        <button type="button" class="px-2 py-1 text-gray-600 dark:text-space-300 hover:text-gray-900 dark:hover:text-white" data-action="click->product-search#incrementQuantity">+</button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            `;
            })
            .join("")}
        </ul>
        <div class="bg-gray-50 dark:bg-space-800 border-t border-gray-200 dark:border-space-700 p-3 text-sm text-gray-600 dark:text-space-300">
          <p>Quick add: Enter SKU:quantity or SKU;quantity (e.g. ABC123:2,XYZ456;3) and press Enter</p>
        </div>
      </div>
    `;
  }

  toggleProductSelection(e) {
    // Don't bubble if clicking on the quantity controls
    if (
      e.target.closest(".product-quantity-control") ||
      e.target.classList.contains("product-quantity") ||
      e.target.tagName === "BUTTON"
    ) {
      return;
    }

    const item = e.currentTarget;
    const checkbox = item.querySelector(".product-select-checkbox");
    const quantityControl = item.querySelector(".product-quantity-control");

    // Toggle checkbox state
    checkbox.checked = !checkbox.checked;

    // Show/hide quantity controls
    if (checkbox.checked) {
      quantityControl.classList.remove("hidden");
      // Updated to handle both light and dark mode
      if (document.documentElement.classList.contains("dark")) {
        item.classList.add("bg-space-800");
      } else {
        item.classList.add("bg-gray-100");
      }

      // Add to selected products
      this.selectedProducts.set(item.dataset.productId, {
        productId: item.dataset.productId,
        productSku: item.dataset.productSku,
        productTitle: item.dataset.productTitle,
        productPrice: parseFloat(item.dataset.productPrice),
        productCost: parseFloat(item.dataset.productCost),
        productBuilderCost: parseFloat(item.dataset.productBuilderCost || 0), // Add builder_cost
        productImage: item.dataset.productImage,
        quantity: parseInt(item.querySelector(".product-quantity").value),
      });
    } else {
      quantityControl.classList.add("hidden");
      // Updated to handle both light and dark mode
      item.classList.remove("bg-gray-100");
      item.classList.remove("bg-space-800");

      // Remove from selected products
      this.selectedProducts.delete(item.dataset.productId);
    }

    this.updateSelectedCount();
  }

  decrementQuantity(e) {
    e.stopPropagation();
    const item = e.target.closest("li");
    const quantityInput = item.querySelector(".product-quantity");
    let value = parseInt(quantityInput.value);

    if (value > 1) {
      quantityInput.value = value - 1;

      // Update the stored quantity
      if (this.selectedProducts.has(item.dataset.productId)) {
        const product = this.selectedProducts.get(item.dataset.productId);
        product.quantity = value - 1;
      }
    }
  }

  incrementQuantity(e) {
    e.stopPropagation();
    const item = e.target.closest("li");
    const quantityInput = item.querySelector(".product-quantity");
    let value = parseInt(quantityInput.value);

    quantityInput.value = value + 1;

    // Update the stored quantity
    if (this.selectedProducts.has(item.dataset.productId)) {
      const product = this.selectedProducts.get(item.dataset.productId);
      product.quantity = value + 1;
    }
  }

  updateSelectedCount() {
    const count = this.selectedProducts.size;
    if (this.hasSelectedCountTarget) {
      this.selectedCountTarget.textContent = count;
    }

    // Enable/disable the "Add Selected" button
    if (this.hasAddSelectedTarget) {
      if (count > 0) {
        this.addSelectedTarget.classList.remove(
          "opacity-50",
          "pointer-events-none",
        );
      } else {
        this.addSelectedTarget.classList.add(
          "opacity-50",
          "pointer-events-none",
        );
      }
    }
  }

  restoreSelectedState() {
    if (this.selectedProducts.size === 0) return;

    // Re-check any previously selected products that are still in the results
    this.selectedProducts.forEach((data, id) => {
      const item = this.resultsTarget.querySelector(
        `li[data-product-id="${id}"]`,
      );
      if (item) {
        const checkbox = item.querySelector(".product-select-checkbox");
        const quantityControl = item.querySelector(".product-quantity-control");
        const quantityInput = item.querySelector(".product-quantity");

        checkbox.checked = true;
        quantityControl.classList.remove("hidden");

        // Updated to handle both light and dark mode
        if (document.documentElement.classList.contains("dark")) {
          item.classList.add("bg-space-800");
        } else {
          item.classList.add("bg-gray-100");
        }

        // Restore quantity
        if (quantityInput) {
          quantityInput.value = data.quantity;
        }
      }
    });
  }

  addSelectedProducts() {
    this.selectedProducts.forEach((productData) => {
      this.addProductToOrder(productData, productData.quantity);
    });

    // Clear selection
    this.selectedProducts.clear();
    this.updateSelectedCount();

    // Clear search and dismiss results
    this.inputTarget.value = "";
    this.dismissResults();
  }

  selectProduct(e) {
    // For backward compatibility with single product selection
    const item = e.currentTarget;
    const productData = {
      productId: item.dataset.productId || item.dataset.id,
      productSku: item.dataset.productSku || item.dataset.sku,
      productTitle: item.dataset.productTitle || item.dataset.title,
      productPrice: parseFloat(item.dataset.productPrice || item.dataset.price),
      productCost: parseFloat(item.dataset.productCost || item.dataset.cost),
      //productBuilderCost: parseFloat(item.dataset.productBuilderCost || 0), // Add builder_cost
      productImage: item.dataset.productImage || item.dataset.image,
    };

    this.addProductToOrder(productData, 1);

    // Clear search and dismiss results
    this.inputTarget.value = "";
    this.dismissResults();
  }

  addProductToOrder(productData, quantity) {
    if (!productData.productCost) {
      console.warn(
        `Selected product ${productData.productSku} has no cost information`,
      );
    }

    console.log("Adding product:", productData, "Quantity:", quantity);

    const template = document.querySelector(
      '[data-nested-form-target="template"]',
    );
    if (!template) {
      console.error("Template not found");
      return;
    }

    const content = template.content.cloneNode(true);
    const wrapper = content.querySelector(".order-item-wrapper");
    const newIndex = new Date().getTime();

    if (wrapper) {
      // Update input names with new index
      wrapper.querySelectorAll("input, select, textarea").forEach((input) => {
        if (input.name) {
          input.name = input.name.replace("NEW_RECORD", newIndex);
        }
      });

      // Set all form fields
      const costField = wrapper.querySelector(
        '[data-order-item-target="cost"]',
      );
      const costDisplay = wrapper.querySelector(
        '[data-order-item-target="costDisplay"]',
      );
      const builderCostField = wrapper.querySelector(
        'input[name$="[builder_cost]"]',
      );
      const builderCostDisplay = wrapper.querySelector(
        '[data-order-item-target="builderCostDisplay"]',
      );
      const skuField = wrapper.querySelector('input[name$="[sku]"]');
      const quantityField = wrapper.querySelector('input[name$="[quantity]"]');
      const priceField = wrapper.querySelector('input[name$="[price]"]');
      const infoButton = wrapper.querySelector(
        '[data-action="order-item#showProductInfo"]',
      );

      if (costField) costField.value = productData.productCost;
      if (costDisplay) costDisplay.value = productData.productCost;
      if (skuField) skuField.value = productData.productSku;
      if (quantityField) quantityField.value = quantity;
      if (priceField) priceField.value = productData.productPrice;
      if (infoButton) infoButton.dataset.sku = productData.productSku;

      // Set builder cost if available
      if (builderCostField && productData.productBuilderCost !== undefined) {
        builderCostField.value = productData.productBuilderCost;
      }
      if (builderCostDisplay && productData.productBuilderCost !== undefined) {
        builderCostDisplay.value = productData.productBuilderCost;
      }

      wrapper.querySelector('input[name$="[product_variant_id]"]').value =
        productData.productId;

      // Set display elements
      const titleElement = wrapper.querySelector(".product-title");
      const skuElement = wrapper.querySelector(".product-sku");

      if (titleElement) titleElement.textContent = productData.productTitle;
      if (skuElement) skuElement.textContent = `SKU: ${productData.productSku}`;

      if (productData.productImage) {
        const imgContainer = wrapper.querySelector(".w-24.h-24");
        if (imgContainer) {
          imgContainer.innerHTML = `<img src="${productData.productImage}" class="w-full h-full object-cover rounded-lg" alt="${productData.productTitle}" />`;
        }
      }

      // Add to form
      const targetContainer = document.querySelector(
        '[data-nested-form-target="target"]',
      );
      if (targetContainer) {
        targetContainer.appendChild(wrapper);

        // Remove empty state if exists
        document.querySelector('[data-nested-form-target="empty"]')?.remove();

        // Trigger update
        wrapper.dispatchEvent(
          new CustomEvent("order-item:update", {
            bubbles: true,
            detail: {
              total: productData.productPrice * quantity,
              cost: productData.productCost * quantity,
              builder_cost: productData.productBuilderCost * quantity,
              quantity: quantity,
            },
          }),
        );
      }
    }
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  }

  disconnect() {
    clearTimeout(this.timeout);

    // Clean up event listeners
    this.inputTarget.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keydown", this.handleGlobalKeyDown);
    document.removeEventListener("click", this.handleClickOutside);
  }
}
