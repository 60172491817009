import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "fileModal"];

  openAddNoteModal() {
    this.modalTarget.classList.remove("hidden");
  }

  closeModal() {
    this.modalTarget.classList.add("hidden");
  }

  openUploadModal() {
    this.fileModalTarget.classList.remove("hidden");
  }

  closeFileModal() {
    this.fileModalTarget.classList.add("hidden");
  }
}
