import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "tableBody", "pasteArea", "validCount", "addButton"]

  connect() {
    this.validProducts = []
    this.highestRowId = 0
    this.pendingChecks = 0

    if (this.hasTableBodyTarget) {
      this.initializeRows()
    }

    // Listen for order type changes
    document.addEventListener('change', this.handleOrderTypeChange = (e) => {
      if (e.target.name && e.target.name.includes('[order_type]')) {
        this.updateBuilderFieldsVisibility()
      }
    })
  }

  disconnect() {
    document.removeEventListener('change', this.handleOrderTypeChange)
  }

  initializeRows() {
    this.tableBodyTarget.innerHTML = ''
    this.highestRowId = 0
    this.validProducts = []

    for (let i = 0; i < 3; i++) {
      this.addRow()
    }
  }

  openModal() {
    if (this.hasModalTarget) {
      this.modalTarget.classList.remove('hidden')
      document.body.classList.add('overflow-hidden')
      this.updateValidCount()

      // Update builder fields visibility when opening the modal
      this.updateBuilderFieldsVisibility()
    }
  }

  closeModal() {
    if (this.hasModalTarget) {
      this.modalTarget.classList.add('hidden')
      document.body.classList.remove('overflow-hidden')
      this.initializeRows()
      if (this.hasPasteAreaTarget) {
        this.pasteAreaTarget.value = ''
      }
    }
  }

  addRow() {
    // Increment highest row ID for new rows
    const rowId = ++this.highestRowId
    const orderType = this.getOrderType()
    const isBuilderOrder = orderType === 'builder_order'

    const rowTemplate = `
      <tr data-row-id="${rowId}" class="${rowId % 2 === 0 ? 'bg-gray-50 dark:bg-space-800' : ''}">
        <td class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400">${rowId}</td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="text" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="Enter SKU" 
                data-bulk-add-row-id="${rowId}"
                data-bulk-add-field="sku"
                data-action="input->bulk-add#validateRow" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                value="1" 
                min="1"
                data-bulk-add-row-id="${rowId}"
                data-bulk-add-field="quantity"
                data-action="input->bulk-add#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full ${isBuilderOrder ? 'bg-white dark:bg-space-900' : 'bg-gray-100 dark:bg-space-800'} border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00" 
                step="0.01"
                data-bulk-add-row-id="${rowId}"
                data-bulk-add-field="cost"
                ${!isBuilderOrder ? 'readonly' : ''}
                data-action="input->bulk-add#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap builder-field ${!isBuilderOrder ? 'hidden' : ''}">
          <input type="number" 
                class="w-full ${isBuilderOrder ? 'bg-white dark:bg-space-900' : 'bg-gray-100 dark:bg-space-800'} border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00"
                step="0.01"
                data-bulk-add-row-id="${rowId}"
                data-bulk-add-field="builder_cost"
                ${!isBuilderOrder ? 'readonly' : ''}
                data-action="input->bulk-add#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00"
                step="0.01"
                data-bulk-add-row-id="${rowId}"
                data-bulk-add-field="price"
                data-action="input->bulk-add#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <div class="flex items-center gap-1">
            <input type="number" 
                  class="w-20 bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm text-right"
                  placeholder="%" 
                  data-bulk-add-row-id="${rowId}"
                  data-bulk-add-field="margin_percent"
                  data-action="input->bulk-add#updatePriceFromMargin" />
            <span class="text-xs text-gray-500 dark:text-space-400">$</span>
            <input type="text" 
                  class="w-20 bg-gray-100 dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm text-right"
                  placeholder="0.00" 
                  data-bulk-add-row-id="${rowId}"
                  data-bulk-add-field="margin_amount"
                  readonly />
          </div>
        </td>
        <td class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400" data-bulk-add-row-id="${rowId}" data-bulk-add-field="status">
          Waiting for SKU
        </td>
        <td class="px-3 py-2 text-sm text-gray-500 dark:text-space-400" data-bulk-add-row-id="${rowId}" data-bulk-add-field="stock">
          
        </td>
        <td class="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
          <button type="button" 
                  class="text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
                  data-action="click->bulk-add#removeRow"
                  data-bulk-add-row-id="${rowId}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </td>
      </tr>
    `
    this.tableBodyTarget.insertAdjacentHTML('beforeend', rowTemplate)
    this.updateBuilderFieldsVisibility()
  }

  updateBuilderFieldsVisibility() {
    const isBuilderOrder = this.getOrderType() === 'builder_order'
    console.log(`Updating bulk add fields for order type: ${this.getOrderType()}, isBuilder: ${isBuilderOrder}`)

    // Show/hide builder fields
    this.element.querySelectorAll('.builder-field').forEach(field => {
      field.classList.toggle('hidden', !isBuilderOrder)
    })

    // Update cost field editability
    this.element.querySelectorAll('input[data-bulk-add-field="cost"]').forEach(field => {
      field.readOnly = !isBuilderOrder
      if (isBuilderOrder) {
        field.classList.remove('bg-gray-100', 'dark:bg-space-800')
        field.classList.add('bg-white', 'dark:bg-space-900')
      } else {
        field.classList.add('bg-gray-100', 'dark:bg-space-800')
        field.classList.remove('bg-white', 'dark:bg-space-900')
      }
    })

    // Update builder cost field editability
    this.element.querySelectorAll('input[data-bulk-add-field="builder_cost"]').forEach(field => {
      field.readOnly = !isBuilderOrder
      if (isBuilderOrder) {
        field.classList.remove('bg-gray-100', 'dark:bg-space-800')
        field.classList.add('bg-white', 'dark:bg-space-900')
      } else {
        field.classList.add('bg-gray-100', 'dark:bg-space-800')
        field.classList.remove('bg-white', 'dark:bg-space-900')
      }
    })
  }

  getOrderType() {
    // First check if the modal has the data attribute
    if (this.hasModalTarget && this.modalTarget.dataset.orderType) {
      return this.modalTarget.dataset.orderType
    }

    // Then check the order type select element
    const orderTypeSelect = document.querySelector('select[name$="[order_type]"]')
    return orderTypeSelect ? orderTypeSelect.value : ''
  }

  removeRow(e) {
    const rowId = e.currentTarget.dataset.bulkAddRowId
    const row = this.tableBodyTarget.querySelector(`tr[data-row-id="${rowId}"]`)

    const index = this.validProducts.findIndex(p => p.rowId == rowId)
    if (index !== -1) {
      this.validProducts.splice(index, 1)
      this.updateValidCount()
    }

    if (row) {
      row.remove()
    }
  }

  async validateRow(e) {
    const rowId = e.currentTarget.dataset.bulkAddRowId
    const skuInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="sku"]`)
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="quantity"]`)

    if (!skuInput) return

    const sku = skuInput.value.trim()
    const quantity = parseInt(quantityInput?.value) || 1

    this.validateRowDirectly(rowId, sku, quantity)
  }

  updateRowCalculations(e) {
    const rowId = e.currentTarget.dataset.bulkAddRowId;
    const field = e.currentTarget.dataset.bulkAddField;
    const isBuilderOrder = this.getOrderType() === 'builder_order';

    const priceInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="price"]`);
    const costInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="cost"]`);
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="builder_cost"]`);
    const marginPercentInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="margin_percent"]`);
    const marginAmountInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="margin_amount"]`);
    // Add this line to get the quantity input
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="quantity"]`);

    const price = parseFloat(priceInput?.value) || 0;

    // Use the appropriate cost based on order type
    let effectiveCost = 0;
    if (isBuilderOrder && builderCostInput) {
      const builderCost = parseFloat(builderCostInput.value) || 0;
      if (builderCost > 0) {
        effectiveCost = builderCost;
      } else {
        effectiveCost = parseFloat(costInput?.value) || 0;
      }
    } else {
      effectiveCost = parseFloat(costInput?.value) || 0;
    }

    if (price > 0 && effectiveCost > 0) {
      const marginAmount = price - effectiveCost;
      const marginPercent = (marginAmount / price) * 100;

      if (marginPercentInput && field !== "margin_percent") marginPercentInput.value = marginPercent.toFixed(2);
      if (marginAmountInput) marginAmountInput.value = marginAmount.toFixed(2);
    } else {
      if (marginPercentInput && field !== "margin_percent") marginPercentInput.value = '';
      if (marginAmountInput) marginAmountInput.value = '';
    }

    // Update product data if this is a valid product
    const index = this.validProducts.findIndex(p => p.rowId == rowId);
    if (index !== -1) {
      if (priceInput) this.validProducts[index].price = parseFloat(priceInput.value) || 0;
      if (costInput) this.validProducts[index].cost = parseFloat(costInput.value) || 0;
      if (builderCostInput) {
        this.validProducts[index].builder_cost = parseFloat(builderCostInput.value) || 0;
      }
      // Add this line to update the quantity in validProducts
      if (quantityInput) this.validProducts[index].quantity = parseInt(quantityInput.value) || 1;
    }
  }

  async validateRowDirectly(rowId, sku, quantity) {
    const statusCell = this.tableBodyTarget.querySelector(`td[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="status"]`)
    const stockCell = this.tableBodyTarget.querySelector(`td[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="stock"]`)
    const priceInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="price"]`)
    const costInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="cost"]`)
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="builder_cost"]`)
    const skuInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="sku"]`)

    if (!statusCell) return

    if (!sku) {
      statusCell.textContent = "Waiting for SKU"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400"

      if (stockCell) stockCell.textContent = ""
      if (priceInput) priceInput.value = ""
      if (costInput) costInput.value = ""
      if (builderCostInput) builderCostInput.value = ""

      const index = this.validProducts.findIndex(p => p.rowId == rowId)
      if (index !== -1) {
        this.validProducts.splice(index, 1)
        this.updateValidCount()
      }
      return
    }

    statusCell.textContent = "Validating..."
    statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-blue-500 dark:text-blue-400"

    this.pendingChecks++
    try {
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error("Product not found")
      }

      const data = await response.json()
      const product = data.results && data.results[0]

      if (!product) {
        throw new Error("Product not found")
      }

      statusCell.textContent = "✓ Can be added to order"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-green-600 dark:text-green-400 font-semibold"

      // Update the SKU input with the SKU from the API response
      if (skuInput && product.sku) {
        skuInput.value = product.sku
      }

      // Clean number display with smart tooltip
      if (stockCell && product.stock) {
        // Calculate total available stock
        const totalStock = product.stock.reduce((sum, item) => sum + (item.available || 0), 0);

        // Create tooltip content with detailed stock info
        let tooltipContent = '';
        product.stock.forEach(stockItem => {
          tooltipContent += `${stockItem.warehouse.name}: ${stockItem.available} available<br>`;
        });

        // Set text color based on stock level
        const textColorClass = totalStock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400';

        // Generate a unique ID for this tooltip
        const tooltipId = `stock-tooltip-${rowId}`;

        // Create the HTML with just the number and tooltip
        stockCell.innerHTML = `
          <div class="flex items-center justify-center">
            <span class="font-medium text-base ${textColorClass} cursor-help" id="${tooltipId}-trigger">
              ${totalStock}
            </span>
            <div id="${tooltipId}" class="hidden absolute z-50 p-2 bg-white dark:bg-gray-800 rounded shadow-lg text-sm text-gray-800 dark:text-white border border-gray-200 dark:border-gray-700" style="width: 200px;">
              ${tooltipContent}
            </div>
          </div>
        `;

        // Add script to handle tooltip positioning
        setTimeout(() => {
          const trigger = document.getElementById(`${tooltipId}-trigger`);
          const tooltip = document.getElementById(tooltipId);

          if (trigger && tooltip) {
            // Show tooltip on hover
            trigger.addEventListener('mouseenter', (e) => {
              // Show the tooltip
              tooltip.classList.remove('hidden');

              // Position the tooltip
              const rect = trigger.getBoundingClientRect();
              const tableRect = this.tableBodyTarget.closest('table').getBoundingClientRect();

              // Default position (below and to the right)
              let top = rect.bottom + 5;
              let left = rect.left;

              // Check if tooltip would go out of viewport
              const tooltipRect = tooltip.getBoundingClientRect();
              const tooltipWidth = tooltipRect.width || 200;
              const tooltipHeight = tooltipRect.height || 100;

              // Adjust horizontal position if needed
              if (left + tooltipWidth > window.innerWidth) {
                left = rect.right - tooltipWidth;
              }

              // Adjust vertical position if needed
              if (top + tooltipHeight > window.innerHeight) {
                top = rect.top - tooltipHeight - 5;
              }

              // Ensure tooltip stays within the table's parent container
              if (left < tableRect.left) {
                left = tableRect.left + 5;
              }

              // Set the position
              tooltip.style.position = 'fixed';
              tooltip.style.top = `${top}px`;
              tooltip.style.left = `${left}px`;
            });

            // Hide tooltip when mouse leaves
            trigger.addEventListener('mouseleave', (e) => {
              // Check if we're not moving to the tooltip itself
              const rect = tooltip.getBoundingClientRect();
              if (
                e.clientX < rect.left ||
                e.clientX > rect.right ||
                e.clientY < rect.top ||
                e.clientY > rect.bottom
              ) {
                tooltip.classList.add('hidden');
              }
            });

            // Also hide when mouse leaves the tooltip
            tooltip.addEventListener('mouseleave', () => {
              tooltip.classList.add('hidden');
            });
          }
        }, 100);
      }

      if (priceInput && !priceInput.value) {
        priceInput.value = parseFloat(product.price).toFixed(2)
      }

      if (costInput && !costInput.value) {
        costInput.value = parseFloat(product.cost).toFixed(2)
      }

      if (builderCostInput && !builderCostInput.value) {
        // Use builder_cost if available, otherwise default to 0
        builderCostInput.value = parseFloat(product.builder_cost || 0).toFixed(2)
      }

      this.updateRowCalculations({
        currentTarget: {
          dataset: { bulkAddRowId: rowId }
        }
      })

      const productData = {
        rowId,
        productId: product.id,
        sku: product.sku, // Use the SKU from the API response
        title: product.title,
        price: parseFloat(priceInput?.value || product.price),
        cost: parseFloat(costInput?.value || product.cost),
        builder_cost: parseFloat(builderCostInput?.value || product.builder_cost || 0),
        image: product.image,
        quantity: parseInt(quantity) || 1,
        stock: product.stock
      }

      const existingIndex = this.validProducts.findIndex(p => p.rowId == rowId)
      if (existingIndex !== -1) {
        this.validProducts[existingIndex] = productData
      } else {
        this.validProducts.push(productData)
      }

    } catch (error) {
      statusCell.textContent = "✗ Product not found"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-red-600 dark:text-red-400 font-semibold"

      if (stockCell) stockCell.textContent = ""

      const index = this.validProducts.findIndex(p => p.rowId == rowId)
      if (index !== -1) {
        this.validProducts.splice(index, 1)
      }
    } finally {
      this.pendingChecks--
      this.updateValidCount()
    }
  }

  updateValidCount() {
    if (this.hasValidCountTarget) {
      this.validCountTarget.textContent = this.validProducts.length
    }

    if (this.hasAddButtonTarget) {
      if (this.validProducts.length > 0 && this.pendingChecks === 0) {
        this.addButtonTarget.disabled = false
        this.addButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
      } else {
        this.addButtonTarget.disabled = true
        this.addButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      }
    }
  }

  handlePaste(e) {
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')
    this.processPasteData(pastedData)
    e.preventDefault()
  }

  processPasteInput() {
    if (!this.hasPasteAreaTarget) return

    const pastedData = this.pasteAreaTarget.value
    if (!pastedData || pastedData.trim() === '') return

    this.processPasteData(pastedData)
  }

  processPasteData(pastedData) {
    if (!pastedData || !this.hasTableBodyTarget) return

    this.tableBodyTarget.innerHTML = ''
    this.highestRowId = 0
    this.validProducts = []

    const rows = pastedData.split(/\r?\n/).filter(row => row.trim() !== '')

    rows.forEach((row) => {
      let columns = row.split(/\t|  +|:|;/)

      if (columns.length >= 1) {
        const sku = columns[0].trim()
        const quantity = columns.length >= 2 ? parseInt(columns[1]) || 1 : 1

        if (sku) {
          this.addRowWithData(sku, quantity)
        }
      }
    })

    const currentRows = this.tableBodyTarget.querySelectorAll('tr').length
    if (currentRows < 3) {
      for (let i = currentRows; i < 3; i++) {
        this.addRow()
      }
    }
  }

  addRowWithData(sku, quantity) {
    this.addRow()

    const rowId = this.highestRowId
    const skuInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="sku"]`)
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="quantity"]`)

    if (skuInput) skuInput.value = sku
    if (quantityInput) quantityInput.value = quantity

    this.validateRowDirectly(rowId, sku, quantity)
  }

  addToOrder() {
    if (this.validProducts.length === 0) return

    const template = document.querySelector('[data-nested-form-target="template"]')
    const targetContainer = document.querySelector('[data-nested-form-target="target"]')

    if (!template || !targetContainer) return

    this.validProducts.forEach(productData => {
      this.addProductToOrder(productData, template, targetContainer)
    })

    this.closeModal()

    const successCount = this.validProducts.length
    this.showMessage(`Successfully added ${successCount} product${successCount !== 1 ? 's' : ''} to the order`)
  }

  addProductToOrder(productData, template, targetContainer) {
    const content = template.content.cloneNode(true)
    const wrapper = content.querySelector('.order-item-wrapper')
    const newIndex = new Date().getTime() + Math.floor(Math.random() * 1000)

    if (wrapper) {
      wrapper.querySelectorAll('input, select, textarea').forEach(input => {
        if (input.name) {
          input.name = input.name.replace('NEW_RECORD', newIndex)
        }
      })

      // Set form fields
      const costField = wrapper.querySelector('[data-order-item-target="cost"]')
      const costDisplay = wrapper.querySelector('[data-order-item-target="costDisplay"]')
      const builderCostField = wrapper.querySelector('input[name$="[builder_cost]"]')
      const builderCostDisplay = wrapper.querySelector('[data-order-item-target="builderCostDisplay"]')
      const skuField = wrapper.querySelector('input[name$="[sku]"]')
      const quantityField = wrapper.querySelector('input[name$="[quantity]"]')
      const priceInputField = wrapper.querySelector('input[name$="[price]"]')
      const infoButton = wrapper.querySelector('[data-action="order-item#showProductInfo"]')

      if (costField) costField.value = productData.cost
      if (costDisplay) costDisplay.value = productData.cost

      // Set builder cost fields
      if (builderCostField) builderCostField.value = productData.builder_cost || 0
      if (builderCostDisplay) builderCostDisplay.value = productData.builder_cost || 0

      if (skuField) skuField.value = productData.sku
      if (quantityField) quantityField.value = productData.quantity
      if (priceInputField) priceInputField.value = productData.price
      if (infoButton) infoButton.dataset.sku = productData.sku

      wrapper.querySelector('input[name$="[product_variant_id]"]').value = productData.productId

      const titleElement = wrapper.querySelector('.product-title')
      const skuElement = wrapper.querySelector('.product-sku')

      if (titleElement) titleElement.textContent = productData.title
      if (skuElement) skuElement.textContent = `SKU: ${productData.sku}`

      const stockInfoContainer = wrapper.querySelector('.stock-info-container')
      if (stockInfoContainer && productData.stock) {
        let stockHtml = '';
        productData.stock.forEach(stockItem => {
          const available = stockItem.available || 0;
          const cssClass = available > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400';
          stockHtml += `<span class="${cssClass} mr-2">${stockItem.warehouse.name.split(' ')[0]}: ${available}</span>`;
        });
        stockInfoContainer.innerHTML = stockHtml;
      }

      if (productData.image) {
        const imgContainer = wrapper.querySelector('.w-24.h-24')
        if (imgContainer) {
          imgContainer.innerHTML = `<img src="${productData.image}" class="w-full h-full object-cover rounded-lg" alt="${productData.title}" />`
        }
      }

      targetContainer.appendChild(wrapper)

      document.querySelector('[data-nested-form-target="empty"]')?.remove()

      // Add builder_cost to the event data
      wrapper.dispatchEvent(new CustomEvent('order-item:update', {
        bubbles: true,
        detail: {
          total: productData.price * productData.quantity,
          cost: productData.cost * productData.quantity,
          builder_cost: productData.builder_cost * productData.quantity,
          quantity: productData.quantity
        }
      }))

      // Calculate margin
      const event = new Event('input')
      const priceField = wrapper.querySelector('input[name$="[price]"]')
      if (priceField) priceField.dispatchEvent(event)
    }
  }

  updatePriceFromMargin(e) {
    const rowId = e.currentTarget.dataset.bulkAddRowId;
    const isBuilderOrder = this.getOrderType() === 'builder_order';

    const priceInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="price"]`);
    const costInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="cost"]`);
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="builder_cost"]`);
    const marginPercentInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="margin_percent"]`);
    const marginAmountInput = this.tableBodyTarget.querySelector(`input[data-bulk-add-row-id="${rowId}"][data-bulk-add-field="margin_amount"]`);

    // Get the desired margin percentage
    const marginPercent = parseFloat(marginPercentInput?.value) || 0;

    // Use the appropriate cost based on order type
    let effectiveCost = 0;
    if (isBuilderOrder && builderCostInput) {
      const builderCost = parseFloat(builderCostInput.value) || 0;
      if (builderCost > 0) {
        effectiveCost = builderCost;
      } else {
        effectiveCost = parseFloat(costInput?.value) || 0;
      }
    } else {
      effectiveCost = parseFloat(costInput?.value) || 0;
    }

    // Skip if no effective cost
    if (effectiveCost <= 0) return;

    // Calculate new price based on desired margin percentage
    // Formula: price = cost / (1 - margin%)
    const newPrice = effectiveCost / (1 - (marginPercent / 100));

    // Update price input
    if (priceInput) {
      priceInput.value = newPrice.toFixed(2);
    }

    // Update margin amount
    const marginAmount = newPrice - effectiveCost;
    if (marginAmountInput) {
      marginAmountInput.value = marginAmount.toFixed(2);
    }

    // Update product data if this is a valid product
    const index = this.validProducts.findIndex(p => p.rowId == rowId);
    if (index !== -1) {
      if (priceInput) this.validProducts[index].price = parseFloat(priceInput.value) || 0;
    }
  }

  showMessage(message) {
    const toast = document.createElement('div')
    toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }
}