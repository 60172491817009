// app/javascript/controllers/customer_modal_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "modalFooter",
    "selectButton",
    "searchInput",
    "searchResults",
    "emptyDetails",
    "customerDetails",
    "customerName",
    "customerEmail",
    "customerPhone",
    "billingAddressList",
    "shippingAddressList",
    "newCustomerForm",
    "editCustomerForm",
    "addressForm",
    "customerForm",
    "customerEditForm",
    "addressFormContent",
    "addressId",
    "addressCustomerId",
    "addressType",
    "addressFirstName",
    "addressLastName",
    "addressBusiness",
    "addressPhone",
    "addressStreet",
    "addressStreet2",
    "addressCity",
    "addressState",
    "addressPostalCode",
    "addressCountry",
    "addressDefault",
    "geocodingStatus",
    "editCustomerId",
    "addressSuggestions",
  ];

  connect() {
    console.log("Customer Modal Controller Connected");
    this.searchTimer = null;
    this.addressSuggestionTimer = null;
    this.selectedCustomer = null;
    this.selectedBillingAddress = null;
    this.selectedShippingAddress = null;
    this.onCustomerSelected = null;
    this.isOpening = false;

    // Bind global events for closing modal
    this.boundClickOutside = this.clickOutside.bind(this);
    document.addEventListener("click", this.boundClickOutside);
    this.handleEscKeyPress = this.handleEscKeyPress.bind(this);
    document.addEventListener("keydown", this.handleEscKeyPress);

    // Ensure address suggestions container exists
    if (!this.hasAddressSuggestionsTarget) {
      const suggestionsDiv = document.createElement("div");
      suggestionsDiv.setAttribute(
        "data-customer-modal-target",
        "addressSuggestions",
      );
      suggestionsDiv.className =
        "absolute z-10 w-full mt-1 bg-white dark:bg-space-800 border border-space-300 dark:border-space-700 rounded-md shadow-lg max-h-60 overflow-auto hidden";
      this.addressSuggestionsElement = suggestionsDiv;
    }

    this.setupAddressFieldListeners();
  }

  disconnect() {
    document.removeEventListener("click", this.boundClickOutside);
    document.removeEventListener("keydown", this.handleEscKeyPress);
    this.cleanupAddressFieldListeners();
  }

  // ---------------------------
  // ADDRESS SUGGESTION METHODS
  // ---------------------------
  setupAddressFieldListeners() {
    this.boundAddressFieldInput = this.onAddressFieldInput.bind(this);
    if (this.hasAddressStreetTarget) {
      this.addressStreetTarget.addEventListener(
        "input",
        this.boundAddressFieldInput,
      );
    }
  }

  cleanupAddressFieldListeners() {
    if (this.hasAddressStreetTarget && this.boundAddressFieldInput) {
      this.addressStreetTarget.removeEventListener(
        "input",
        this.boundAddressFieldInput,
      );
    }
  }

  onAddressFieldInput(event) {
    const field = event.target;
    const query = field.value.trim();
    clearTimeout(this.addressSuggestionTimer);
    this.hideAddressSuggestions();
    if (query.length < 3) return;

    // Show a loading indicator
    const loadingDiv = document.createElement("div");
    loadingDiv.className =
      "p-2 text-space-500 dark:text-space-400 text-sm flex items-center";
    loadingDiv.innerHTML = `
      <div class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
      Looking up address...
    `;
    const suggestionsContainer = this.getAddressSuggestionsContainer();
    suggestionsContainer.innerHTML = "";
    suggestionsContainer.appendChild(loadingDiv);
    this.positionAddressSuggestions(field);
    suggestionsContainer.classList.remove("hidden");

    // Wait briefly before calling your API
    this.addressSuggestionTimer = setTimeout(() => {
      this.fetchAddressSuggestions(query);
    }, 500);
  }

  getAddressSuggestionsContainer() {
    if (this.hasAddressSuggestionsTarget) {
      return this.addressSuggestionsTarget;
    } else {
      const addressFieldContainer = this.addressStreetTarget.closest("div");
      addressFieldContainer.style.position = "relative";
      addressFieldContainer.appendChild(this.addressSuggestionsElement);
      return this.addressSuggestionsElement;
    }
  }

  positionAddressSuggestions(field) {
    const suggestionsContainer = this.getAddressSuggestionsContainer();
    const rect = field.getBoundingClientRect();
    suggestionsContainer.style.width = `${rect.width}px`;
    suggestionsContainer.style.left = "0";
    suggestionsContainer.style.top = `${field.offsetHeight + 4}px`;
    suggestionsContainer.style.zIndex = "9999";
  }

  hideAddressSuggestions() {
    const suggestionsContainer = this.getAddressSuggestionsContainer();
    suggestionsContainer.classList.add("hidden");
    suggestionsContainer.innerHTML = "";
  }

  fetchAddressSuggestions(query) {
    fetch(`/api/v1/addresses/geocode?query=${encodeURIComponent(query)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const suggestions = data.suggestions || [];
        this.renderAddressSuggestions(suggestions);
      })
      .catch((error) => {
        console.error("Address suggestion error:", error);
        this.hideAddressSuggestions();
      });
  }

  renderAddressSuggestions(suggestions) {
    const suggestionsContainer = this.getAddressSuggestionsContainer();
    suggestionsContainer.innerHTML = "";
    if (suggestions.length === 0) {
      suggestionsContainer.classList.add("hidden");
      return;
    }
    suggestions.forEach((suggestion) => {
      const item = document.createElement("div");
      item.className =
        "p-2 hover:bg-space-50 dark:hover:bg-space-800 cursor-pointer border-b border-space-200 dark:border-space-700 last:border-0 text-space-900 dark:text-space-100";
      item.textContent = suggestion.text;
      item.dataset.address = JSON.stringify(suggestion.address);
      item.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.selectAddressSuggestion(e);
      });
      suggestionsContainer.appendChild(item);
    });
    suggestionsContainer.classList.remove("hidden");
  }

  selectAddressSuggestion(event) {
    event.preventDefault();
    event.stopPropagation();
    const addressData = JSON.parse(event.currentTarget.dataset.address);
    if (this.hasAddressStreetTarget) {
      this.addressStreetTarget.value = addressData.street_address || "";
    }
    if (this.hasAddressCityTarget) {
      this.addressCityTarget.value = addressData.city || "";
    }
    if (this.hasAddressStateTarget) {
      this.addressStateTarget.value = addressData.state || "";
    }
    if (this.hasAddressPostalCodeTarget) {
      this.addressPostalCodeTarget.value = addressData.postal_code || "";
    }
    if (this.hasAddressCountryTarget) {
      this.addressCountryTarget.value = addressData.country || "United States";
    }
    const geoLatField = this.addressFormContentTarget.querySelector(
      'input[name="address[geo_lat]"]',
    );
    const geoLongField = this.addressFormContentTarget.querySelector(
      'input[name="address[geo_long]"]',
    );
    if (geoLatField && addressData.geo_lat) {
      geoLatField.value = addressData.geo_lat;
    }
    if (geoLongField && addressData.geo_long) {
      geoLongField.value = addressData.geo_long;
    }
    this.hideAddressSuggestions();
    if (this.hasAddressStreet2Target && !this.addressStreet2Target.value) {
      this.addressStreet2Target.focus();
    } else if (this.hasAddressCityTarget && !this.addressCityTarget.value) {
      this.addressCityTarget.focus();
    } else if (this.hasAddressStateTarget && !this.addressStateTarget.value) {
      this.addressStateTarget.focus();
    }
  }

  handleEscKeyPress(event) {
    if (event.key === "Escape") {
      const suggestionsContainer = this.getAddressSuggestionsContainer();
      if (!suggestionsContainer.classList.contains("hidden")) {
        this.hideAddressSuggestions();
        event.stopPropagation();
        return;
      }
      if (!this.element.classList.contains("hidden")) {
        this.close();
      }
    }
  }

  clickOutside(event) {
    if (this.element.classList.contains("hidden") || this.isOpening) return;
    const suggestionsContainer = this.getAddressSuggestionsContainer();
    if (
      !suggestionsContainer.classList.contains("hidden") &&
      (suggestionsContainer.contains(event.target) ||
        this.addressStreetTarget.contains(event.target))
    ) {
      return;
    }
    if (!suggestionsContainer.classList.contains("hidden")) {
      this.hideAddressSuggestions();
      return;
    }
    if (
      this.searchResultsTarget.contains(event.target) ||
      this.modalTarget.contains(event.target)
    ) {
      return;
    }
    this.close();
  }

  // ---------------------------
  // OPEN / CLOSE / STATE METHODS
  // ---------------------------
  open() {
    this.isOpening = true;
    try {
      // Clear search input when opening
      this.searchInputTarget.value = "";
      this.selectedCustomer = null;
      this.selectedBillingAddress = null;
      this.selectedShippingAddress = null;
      this.emptyDetailsTarget.classList.remove("hidden");
      this.customerDetailsTarget.classList.add("hidden");
      this.newCustomerFormTarget.classList.add("hidden");
      this.editCustomerFormTarget.classList.add("hidden");
      this.addressFormTarget.classList.add("hidden");
      this.modalFooterTarget.classList.remove("hidden");
      this.selectButtonTarget.classList.add("hidden");
      this.element.classList.remove("hidden");
      this.element.style.display = "flex";
      setTimeout(() => {
        try {
          this.searchInputTarget.focus();
          this.isOpening = false;
        } catch (e) {
          console.error("Error focusing input:", e);
        }
      }, 100);
    } catch (error) {
      console.error("Error in open method:", error);
      document.getElementById("customer-modal").classList.remove("hidden");
      document.getElementById("customer-modal").style.display = "flex";
      setTimeout(() => {
        this.isOpening = false;
      }, 100);
    }
  }

  close() {
    if (this.isOpening) return;
    this.hideAddressSuggestions();
    try {
      this.element.classList.add("hidden");
      this.element.style.display = "none";
    } catch (error) {
      console.error("Error in close method:", error);
      document.getElementById("customer-modal").classList.add("hidden");
      document.getElementById("customer-modal").style.display = "none";
    }
  }

  // Clear the currently selected customer and show search panel.
  clearCustomer() {
    this.selectedCustomer = null;
    this.customerDetailsTarget.classList.add("hidden");
    this.emptyDetailsTarget.classList.remove("hidden");
    this.detailsStateTarget.classList.add("hidden");
    this.searchStateTarget.classList.remove("hidden");
    // Also clear the search input.
    this.searchInputTarget.value = "";
  }

  // ---------------------------
  // CUSTOMER SEARCH & SELECTION
  // ---------------------------
  search() {
    clearTimeout(this.searchTimer);
    const query = this.searchInputTarget.value.trim();
    if (query.length < 2) {
      this.searchResultsTarget.innerHTML = `
        <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
          Type at least 2 characters to search...
        </div>
      `;
      return;
    }
    this.searchTimer = setTimeout(() => {
      this.searchResultsTarget.innerHTML = `
        <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
          <div class="animate-spin inline-block w-6 h-6 border-2 border-space-300 border-t-blue-600 rounded-full mr-2"></div>
          Searching...
        </div>
      `;
      fetch(`/api/v1/customers/search?query=${encodeURIComponent(query)}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Server responded with ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          this.renderSearchResults(data);
        })
        .catch((error) => {
          console.error("Customer search error:", error);
          this.searchResultsTarget.innerHTML = `
            <div class="p-2 text-red-500 dark:text-red-400 text-sm">
              Error loading customers. Please try again.
            </div>
          `;
        });
    }, 300);
  }

  renderSearchResults(data) {
    if (data.length === 0) {
      this.searchResultsTarget.innerHTML = `
        <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
          No customers found.
          <button type="button" data-action="customer-modal#showNewCustomerForm" class="block w-full mt-2 px-4 py-2 bg-blue-600 text-white rounded-md">
            Create New Customer
          </button>
        </div>
      `;
      return;
    }
    const resultsList = data
      .map(
        (customer) => `
      <div class="p-3 border-b border-space-200 dark:border-space-700 hover:bg-space-50 dark:hover:bg-space-800 cursor-pointer customer-item"
           data-action="click->customer-modal#selectCustomer"
           data-customer-id="${customer.id}">
        <div class="font-medium text-space-900 dark:text-space-100">${customer.name}</div>
        <div class="text-sm text-space-500 dark:text-space-400 flex items-center justify-between">
          <span>${customer.email || "No email"}</span>
          <span>${customer.phone || ""}</span>
          <span class="text-space-900 dark:text-space-100">Credit Limit: ${this.formatCurrency(customer.credit_limit)} | Available Credit: ${this.formatCurrency(customer.available_credit)}</span>
        </div>
      </div>
    `,
      )
      .join("");
    this.searchResultsTarget.innerHTML = resultsList;
    const customerItems =
      this.searchResultsTarget.querySelectorAll(".customer-item");
    customerItems.forEach((item) => {
      item.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.selectCustomer({ currentTarget: item });
      });
    });
  }

  selectCustomer(event) {
    if (event instanceof Event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const customerId = event.currentTarget.dataset.customerId;
    // Clear the search input when a customer is selected
    this.searchInputTarget.value = "";
    this.element.classList.remove("hidden");
    this.element.style.display = "flex";
    this.loadCustomerDetails(customerId);
  }

  loadCustomerDetails(customerId) {
    this.element.classList.remove("hidden");
    this.element.style.display = "flex";
    this.searchResultsTarget.innerHTML = `
      <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
        <div class="animate-spin inline-block w-6 h-6 border-2 border-space-300 border-t-blue-600 rounded-full mr-2"></div>
        Loading customer details...
      </div>
    `;
    fetch(`/api/v1/customers/${customerId}`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error(
              "Customer not found. It may have been deleted or you don't have access.",
            );
          }
          throw new Error(`Server responded with ${response.status}`);
        }
        return response.json();
      })
      .then((customer) => {
        this.selectedCustomer = customer;
        this.customerNameTarget.textContent = customer.name || "Unknown";
        this.customerEmailTarget.textContent = customer.email || "—";
        this.customerPhoneTarget.textContent = customer.phone || "—";
        this.loadAddresses(customer.addresses || []);
        this.emptyDetailsTarget.classList.add("hidden");
        this.customerDetailsTarget.classList.remove("hidden");
        this.selectButtonTarget.classList.remove("hidden");
        this.searchResultsTarget.innerHTML = "";
      })
      .catch((error) => {
        console.error("Error fetching customer details:", error);
        this.searchResultsTarget.innerHTML = `
          <div class="p-4 text-red-600 dark:text-red-400">
            <p class="font-bold">Error loading customer details:</p>
            <p>${error.message}</p>
            <div class="mt-4">
              <button type="button" data-action="customer-modal#backToSearch" class="px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-md">
                Back to Search
              </button>
            </div>
          </div>
        `;
      });
  }

  loadAddresses(addresses) {
    const billingAddresses = addresses.filter((a) => a.isBilling);
    const shippingAddresses = addresses.filter((a) => a.isShipping);

    if (!this.selectedBillingAddress && billingAddresses.length > 0) {
      this.selectedBillingAddress =
        billingAddresses.find((a) => a.isDefault) || billingAddresses[0];
    }
    if (!this.selectedShippingAddress && shippingAddresses.length > 0) {
      this.selectedShippingAddress =
        shippingAddresses.find((a) => a.isDefault) || shippingAddresses[0];
    }

    if (billingAddresses.length === 0) {
      this.billingAddressListTarget.innerHTML = `
        <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
          No billing addresses found.
        </div>
      `;
    } else {
      this.billingAddressListTarget.innerHTML = billingAddresses
        .map((address, index) => {
          const isSelected =
            this.selectedBillingAddress &&
            this.selectedBillingAddress.id === address.id;
          return `
          <div class="p-3 border border-space-200 dark:border-space-700 rounded-md mb-3 ${address.isDefault ? "border-blue-500 dark:border-blue-500" : ""} ${isSelected ? "bg-space-50 dark:bg-space-900/30" : ""}"
               data-action="click->customer-modal#selectBillingAddress"
               data-address-index="${index}"
               data-address-id="${address.id}">
            <div class="flex justify-between items-start">
              <div>
                ${address.name ? `<p class="font-medium text-space-900 dark:text-space-100">${address.name}</p>` : ""}
                <p class="text-space-700 dark:text-space-300">${address.address_1}</p>
                ${address.address_2 ? `<p class="text-space-700 dark:text-space-300">${address.address_2}</p>` : ""}
                <p class="text-space-700 dark:text-space-300">${[address.city, address.state, address.zip].filter(Boolean).join(", ")}</p>
                ${address.country && address.country !== "United States" ? `<p class="text-space-700 dark:text-space-300">${address.country}</p>` : ""}
                ${address.isDefault ? `<div class="mt-1 inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">Default</div>` : ""}
              </div>
              <div class="flex space-x-1">
                <button type="button" data-action="customer-modal#editBillingAddress" data-address-index="${index}" class="text-space-500 hover:text-space-700 dark:text-space-400 dark:hover:text-space-200">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                  </svg>
                </button>
              </div>
            </div>
            ${
              isSelected
                ? `
              <div class="mt-2 text-sm text-blue-600 dark:text-blue-400 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                </svg>
                Selected for this quote
              </div>
            `
                : ""
            }
          </div>
        `;
        })
        .join("");
    }

    if (shippingAddresses.length === 0) {
      this.shippingAddressListTarget.innerHTML = `
        <div class="text-center p-4 text-space-500 dark:text-space-400 text-sm">
          No shipping addresses found.
        </div>
      `;
    } else {
      this.shippingAddressListTarget.innerHTML = shippingAddresses
        .map((address, index) => {
          const isSelected =
            this.selectedShippingAddress &&
            this.selectedShippingAddress.id === address.id;
          return `
          <div class="p-3 border border-space-200 dark:border-space-700 rounded-md mb-3 ${address.isDefault ? "border-blue-500 dark:border-blue-500" : ""} ${isSelected ? "bg-space-50 dark:bg-space-900/30" : ""}"
               data-action="click->customer-modal#selectShippingAddress"
               data-address-index="${index}"
               data-address-id="${address.id}">
            <div class="flex justify-between items-start">
              <div>
                ${address.name ? `<p class="font-medium text-space-900 dark:text-space-100">${address.name}</p>` : ""}
                <p class="text-space-700 dark:text-space-300">${address.address_1}</p>
                ${address.address_2 ? `<p class="text-space-700 dark:text-space-300">${address.address_2}</p>` : ""}
                <p class="text-space-700 dark:text-space-300">${[address.city, address.state, address.zip].filter(Boolean).join(", ")}</p>
                ${address.country && address.country !== "United States" ? `<p class="text-space-700 dark:text-space-300">${address.country}</p>` : ""}
                ${address.isDefault ? `<div class="mt-1 inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">Default</div>` : ""}
              </div>
              <div class="flex space-x-1">
                <button type="button" data-action="customer-modal#editShippingAddress" data-address-index="${index}" class="text-space-500 hover:text-space-700 dark:text-space-400 dark:hover:text-space-200">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                  </svg>
                </button>
              </div>
            </div>
            ${
              isSelected
                ? `
              <div class="mt-2 text-sm text-blue-600 dark:text-blue-400 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                </svg>
                Selected for this quote
              </div>
            `
                : ""
            }
          </div>
        `;
        })
        .join("");
    }
    this.setupAddressEventListeners();
  }

  setupAddressEventListeners() {
    // Bind edit buttons for billing addresses.
    this.element
      .querySelectorAll('[data-action="customer-modal#editBillingAddress"]')
      .forEach((button) => {
        button.addEventListener("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.editBillingAddress({ currentTarget: button });
        });
      });
    // Bind edit buttons for shipping addresses.
    this.element
      .querySelectorAll('[data-action="customer-modal#editShippingAddress"]')
      .forEach((button) => {
        button.addEventListener("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.editShippingAddress({ currentTarget: button });
        });
      });
    // Bind address selection for billing addresses.
    this.element
      .querySelectorAll('[data-action="customer-modal#selectBillingAddress"]')
      .forEach((item) => {
        item.addEventListener("click", (e) => {
          if (!e.target.closest("button")) {
            e.preventDefault();
            e.stopPropagation();
            this.selectBillingAddress({ currentTarget: item });
          }
        });
      });
    // Bind address selection for shipping addresses.
    this.element
      .querySelectorAll('[data-action="customer-modal#selectShippingAddress"]')
      .forEach((item) => {
        item.addEventListener("click", (e) => {
          if (!e.target.closest("button")) {
            e.preventDefault();
            e.stopPropagation();
            this.selectShippingAddress({ currentTarget: item });
          }
        });
      });
  }

  selectBillingAddress(event) {
    if (event instanceof Event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const addressIndex = parseInt(event.currentTarget.dataset.addressIndex, 10);
    const addressId = event.currentTarget.dataset.addressId;
    if (
      (!addressIndex && !addressId) ||
      !this.selectedCustomer ||
      !this.selectedCustomer.addresses
    )
      return;
    if (addressId) {
      const address = this.selectedCustomer.addresses.find(
        (a) => a.id.toString() === addressId && a.isBilling,
      );
      if (address) {
        this.selectedBillingAddress = address;
        this.loadAddresses(this.selectedCustomer.addresses);
        return;
      }
    }
    const addresses = this.selectedCustomer.addresses.filter(
      (a) => a.isBilling,
    );
    if (addressIndex >= 0 && addressIndex < addresses.length) {
      this.selectedBillingAddress = addresses[addressIndex];
      this.loadAddresses(this.selectedCustomer.addresses);
    }
  }

  selectShippingAddress(event) {
    if (event instanceof Event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const addressIndex = parseInt(event.currentTarget.dataset.addressIndex, 10);
    const addressId = event.currentTarget.dataset.addressId;
    if (
      (!addressIndex && !addressId) ||
      !this.selectedCustomer ||
      !this.selectedCustomer.addresses
    )
      return;
    if (addressId) {
      const address = this.selectedCustomer.addresses.find(
        (a) => a.id.toString() === addressId && a.isShipping,
      );
      if (address) {
        this.selectedShippingAddress = address;
        this.loadAddresses(this.selectedCustomer.addresses);
        return;
      }
    }
    const addresses = this.selectedCustomer.addresses.filter(
      (a) => a.isShipping,
    );
    if (addressIndex >= 0 && addressIndex < addresses.length) {
      this.selectedShippingAddress = addresses[addressIndex];
      this.loadAddresses(this.selectedCustomer.addresses);
    }
  }

  copyBillingToShipping() {
    if (!this.selectedBillingAddress) {
      alert("Please select a billing address first");
      return;
    }
    const matchingShippingAddress = this.selectedCustomer.addresses.find(
      (a) =>
        a.isShipping &&
        a.address_1 === this.selectedBillingAddress.address_1 &&
        a.city === this.selectedBillingAddress.city &&
        a.state === this.selectedBillingAddress.state &&
        a.zip === this.selectedBillingAddress.zip,
    );
    if (matchingShippingAddress) {
      this.selectedShippingAddress = matchingShippingAddress;
    } else {
      this.showNewShippingAddressForm();
      this.addressFirstNameTarget.value =
        this.selectedBillingAddress.first_name || "";
      this.addressLastNameTarget.value =
        this.selectedBillingAddress.last_name || "";
      this.addressBusinessTarget.value =
        this.selectedBillingAddress.business || "";
      this.addressPhoneTarget.value = this.selectedBillingAddress.phone || "";
      this.addressStreetTarget.value =
        this.selectedBillingAddress.address_1 || "";
      this.addressStreet2Target.value =
        this.selectedBillingAddress.address_2 || "";
      this.addressCityTarget.value = this.selectedBillingAddress.city || "";
      this.addressStateTarget.value = this.selectedBillingAddress.state || "";
      this.addressPostalCodeTarget.value =
        this.selectedBillingAddress.zip || "";
      this.addressCountryTarget.value =
        this.selectedBillingAddress.country || "United States";
    }
    this.loadAddresses(this.selectedCustomer.addresses);
  }

  // ---------------------------
  // FORM / VIEW TOGGLING METHODS
  // ---------------------------
  showNewCustomerForm() {
    this.emptyDetailsTarget.classList.add("hidden");
    this.customerDetailsTarget.classList.add("hidden");
    this.newCustomerFormTarget.classList.remove("hidden");
    this.editCustomerFormTarget.classList.add("hidden");
    this.addressFormTarget.classList.add("hidden");
    this.modalFooterTarget.classList.add("hidden");
    this.selectButtonTarget.classList.add("hidden");
    this.customerFormTarget.reset();
  }

  showEditCustomerForm() {
    if (!this.selectedCustomer) return;
    this.editCustomerIdTarget.value = this.selectedCustomer.id;
    const editForm = this.customerEditFormTarget;
    const nameParts = this.selectedCustomer.name.split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts.slice(1).join(" ") || "";
    editForm.querySelector('select[name="customer[customer_type]"]').value =
      this.selectedCustomer.customer_type || "retail";
    editForm.querySelector('input[name="customer[first_name]"]').value =
      firstName;
    editForm.querySelector('input[name="customer[last_name]"]').value =
      lastName;
    editForm.querySelector('input[name="customer[email]"]').value =
      this.selectedCustomer.email || "";
    editForm.querySelector('input[name="customer[business_name]"]').value =
      this.selectedCustomer.business_name || "";
    editForm.querySelector('input[name="customer[contact_phone]"]').value =
      this.selectedCustomer.phone || "";
    editForm.querySelector('input[name="customer[contact_mobile]"]').value =
      this.selectedCustomer.mobile || "";
    this.emptyDetailsTarget.classList.add("hidden");
    this.customerDetailsTarget.classList.add("hidden");
    this.newCustomerFormTarget.classList.add("hidden");
    this.editCustomerFormTarget.classList.remove("hidden");
    this.addressFormTarget.classList.add("hidden");
    this.modalFooterTarget.classList.add("hidden");
  }

  showNewShippingAddressForm() {
    this.showAddressForm("shipping");
  }

  showNewBillingAddressForm() {
    this.showAddressForm("billing");
  }

  editBillingAddress(event) {
    if (event instanceof Event) {
      event.stopPropagation();
    }
    const addressIndex = parseInt(event.currentTarget.dataset.addressIndex, 10);
    if (
      isNaN(addressIndex) ||
      !this.selectedCustomer ||
      !this.selectedCustomer.addresses
    )
      return;
    const addresses = this.selectedCustomer.addresses.filter(
      (a) => a.isBilling,
    );
    if (addressIndex >= 0 && addressIndex < addresses.length) {
      this.showAddressForm("billing", addresses[addressIndex]);
    }
  }

  editShippingAddress(event) {
    if (event instanceof Event) {
      event.stopPropagation();
    }
    const addressIndex = parseInt(event.currentTarget.dataset.addressIndex, 10);
    if (
      isNaN(addressIndex) ||
      !this.selectedCustomer ||
      !this.selectedCustomer.addresses
    )
      return;
    const addresses = this.selectedCustomer.addresses.filter(
      (a) => a.isShipping,
    );
    if (addressIndex >= 0 && addressIndex < addresses.length) {
      this.showAddressForm("shipping", addresses[addressIndex]);
    }
  }

  showAddressForm(addressType, address = null) {
    if (!this.selectedCustomer) return;
    this.addressCustomerIdTarget.value = this.selectedCustomer.id;
    this.addressTypeTarget.value = addressType;
    this.addressFormContentTarget.reset();
    // Disable browser autocomplete on address fields
    this.addressStreetTarget.setAttribute("autocomplete", "off");
    if (this.hasAddressStreet2Target)
      this.addressStreet2Target.setAttribute("autocomplete", "off");
    if (this.hasAddressCityTarget)
      this.addressCityTarget.setAttribute("autocomplete", "off");
    if (this.hasAddressStateTarget)
      this.addressStateTarget.setAttribute("autocomplete", "off");
    if (this.hasAddressPostalCodeTarget)
      this.addressPostalCodeTarget.setAttribute("autocomplete", "off");
    if (address) {
      this.addressIdTarget.value = address.id;
      this.addressFirstNameTarget.value = address.name
        ? address.name.split(" ")[0]
        : "";
      this.addressLastNameTarget.value = address.name
        ? address.name.split(" ").slice(1).join(" ")
        : "";
      this.addressBusinessTarget.value = address.business || "";
      this.addressPhoneTarget.value = address.phone || "";
      this.addressStreetTarget.value = address.address_1 || "";
      this.addressStreet2Target.value = address.address_2 || "";
      this.addressCityTarget.value = address.city || "";
      this.addressStateTarget.value = address.state || "";
      this.addressPostalCodeTarget.value = address.zip || "";
      this.addressCountryTarget.value = address.country || "United States";
      this.addressDefaultTarget.checked = address.isDefault;
      const geoLatField = this.addressFormContentTarget.querySelector(
        'input[name="address[geo_lat]"]',
      );
      const geoLongField = this.addressFormContentTarget.querySelector(
        'input[name="address[geo_long]"]',
      );
      if (geoLatField && address.geo_lat) {
        geoLatField.value = address.geo_lat;
      }
      if (geoLongField && address.geo_long) {
        geoLongField.value = address.geo_long;
      }
    } else {
      this.addressIdTarget.value = "";
      if (this.selectedCustomer) {
        const nameParts = this.selectedCustomer.name.split(" ");
        this.addressFirstNameTarget.value = nameParts[0] || "";
        this.addressLastNameTarget.value = nameParts.slice(1).join(" ") || "";
        this.addressPhoneTarget.value = this.selectedCustomer.phone || "";
      }
    }
    if (window.useGoogleAutocomplete) {
      this.geocodingStatusTarget.classList.remove("hidden");
    } else {
      this.geocodingStatusTarget.classList.add("hidden");
    }
    this.emptyDetailsTarget.classList.add("hidden");
    this.customerDetailsTarget.classList.add("hidden");
    this.newCustomerFormTarget.classList.add("hidden");
    this.editCustomerFormTarget.classList.add("hidden");
    this.addressFormTarget.classList.remove("hidden");
    this.modalFooterTarget.classList.add("hidden");
  }

  backToSearch() {
    this.emptyDetailsTarget.classList.remove("hidden");
    this.customerDetailsTarget.classList.add("hidden");
    this.newCustomerFormTarget.classList.add("hidden");
    this.editCustomerFormTarget.classList.add("hidden");
    this.addressFormTarget.classList.add("hidden");
    this.modalFooterTarget.classList.remove("hidden");
    this.selectButtonTarget.classList.add("hidden");
  }

  backToCustomerDetails() {
    this.emptyDetailsTarget.classList.add("hidden");
    this.customerDetailsTarget.classList.remove("hidden");
    this.newCustomerFormTarget.classList.add("hidden");
    this.editCustomerFormTarget.classList.add("hidden");
    this.addressFormTarget.classList.add("hidden");
    this.modalFooterTarget.classList.remove("hidden");
    this.selectButtonTarget.classList.remove("hidden");
  }

  // ---------------------------
  // FORM SUBMISSION METHODS
  // ---------------------------
  submitNewCustomer(event) {
    event.preventDefault();
    const form = this.customerFormTarget;
    const formData = new FormData(form);
    form.querySelectorAll('button[type="submit"]').forEach((button) => {
      button.disabled = true;
      button.innerHTML = `
        <div class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
        Saving...
      `;
    });
    fetch("/api/v1/customers", {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((customer) => {
        this.selectedCustomer = customer;
        this.loadCustomerDetails(customer.id);
        setTimeout(() => {
          this.showNewBillingAddressForm();
        }, 500);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while saving the customer. Please try again.");
        form.querySelectorAll('button[type="submit"]').forEach((button) => {
          button.disabled = false;
          button.innerHTML = "Save Customer";
        });
      });
  }

  submitEditCustomer(event) {
    event.preventDefault();
    const form = this.customerEditFormTarget;
    const formData = new FormData(form);
    const customerId = this.editCustomerIdTarget.value;
    form.querySelectorAll('button[type="submit"]').forEach((button) => {
      button.disabled = true;
      button.innerHTML = `
        <div class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
        Saving...
      `;
    });
    fetch(`/api/v1/customers/${customerId}`, {
      method: "PATCH",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((customer) => {
        this.selectedCustomer = customer;
        this.loadCustomerDetails(customer.id);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(
          "An error occurred while updating the customer. Please try again.",
        );
        form.querySelectorAll('button[type="submit"]').forEach((button) => {
          button.disabled = false;
          button.innerHTML = "Save Changes";
        });
      });
  }

  submitAddress(event) {
    event.preventDefault();
    const form = this.addressFormContentTarget;
    const formData = new FormData(form);
    const addressId = this.addressIdTarget.value;
    const customerId = this.addressCustomerIdTarget.value;
    const addressType = this.addressTypeTarget.value;
    const submitButtons = form.querySelectorAll('button[type="submit"]');
    submitButtons.forEach((button) => {
      button.disabled = true;
      button.innerHTML = `
        <div class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
        Saving...
      `;
    });
    let url, method;
    if (addressId) {
      url = `/api/v1/addresses/${addressId}`;
      method = "PATCH";
    } else {
      url = `/api/v1/customers/${customerId}/addresses`;
      method = "POST";
    }
    const resetButtons = () => {
      submitButtons.forEach((button) => {
        button.disabled = false;
        button.innerHTML = "Save Address";
      });
    };
    fetch(url, {
      method: method,
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            return response.json().then((data) => {
              if (
                data.errors &&
                data.errors.join("").includes("already been taken")
              ) {
                resetButtons();
                if (
                  confirm(
                    "This customer already has an address of this type. Would you like to update it instead?",
                  )
                ) {
                  return this.findExistingAddressId(
                    customerId,
                    addressType,
                  ).then((existingId) => {
                    if (existingId) {
                      this.addressIdTarget.value = existingId;
                      submitButtons.forEach((button) => {
                        button.disabled = true;
                        button.innerHTML = `
                          <div class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                          Saving...
                        `;
                      });
                      return this.retryAsPatch(existingId, formData);
                    } else {
                      throw new Error(
                        "Could not find existing address to update",
                      );
                    }
                  });
                } else {
                  throw new Error(
                    "Cannot add multiple addresses of the same type",
                  );
                }
              } else {
                throw new Error(data.errors.join(", "));
              }
            });
          }
          throw new Error(`Server responded with ${response.status}`);
        }
        return response.json();
      })
      .then((address) => {
        resetButtons();
        this.loadCustomerDetails(customerId);
        this.backToCustomerDetails();
        const messageDiv = document.createElement("div");
        messageDiv.className =
          "fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded z-50";
        messageDiv.innerHTML = addressId
          ? "Address updated successfully"
          : "Address added successfully";
        document.body.appendChild(messageDiv);
        setTimeout(() => {
          messageDiv.remove();
        }, 3000);
        if (
          addressType === "billing" &&
          this.selectedCustomer.addresses.filter((a) => a.isShipping).length ===
            0
        ) {
          if (
            confirm("Would you like to use this same address for shipping?")
          ) {
            this.copyBillingToShipping();
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(`An error occurred while saving the address: ${error.message}`);
        resetButtons();
      });
  }

  selectCustomerAndClose() {
    if (!this.selectedCustomer) return;
    if (
      !this.selectedBillingAddress &&
      this.selectedCustomer.addresses.some((a) => a.isBilling)
    ) {
      const billingAddresses = this.selectedCustomer.addresses.filter(
        (a) => a.isBilling,
      );
      this.selectedBillingAddress =
        billingAddresses.find((a) => a.isDefault) || billingAddresses[0];
    }
    if (!this.selectedBillingAddress) {
      if (
        confirm(
          "This customer has no billing address. Would you like to add one now?",
        )
      ) {
        this.showNewBillingAddressForm();
        return;
      }
    }
    const customer = {
      ...this.selectedCustomer,
      selectedBillingAddress: this.selectedBillingAddress,
      selectedShippingAddress: this.selectedShippingAddress,
    };
    if (
      this.onCustomerSelected &&
      typeof this.onCustomerSelected === "function"
    ) {
      this.onCustomerSelected(customer);
    }
    this.close();
  }

  findExistingAddressId(customerId, addressType) {
    return fetch(`/api/v1/customers/${customerId}`)
      .then((response) => response.json())
      .then((customer) => {
        const addresses = customer.addresses || [];
        const existingAddress = addresses.find(
          (a) =>
            (addressType === "billing" && a.isBilling) ||
            (addressType === "shipping" && a.isShipping),
        );
        return existingAddress ? existingAddress.id : null;
      });
  }

  retryAsPatch(addressId, formData) {
    return fetch(`/api/v1/addresses/${addressId}`, {
      method: "PATCH",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Update failed with status ${response.status}`);
      }
      return response.json();
    });
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
}
