// app/javascript/controllers/builder/customer_sidebar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "customerIdField",
    "customerInfo", "emptyState", "changeCustomerButton",
    "customerName", "customerEmail", "customerPhone",
    "billingAddressDisplay", "emptyBillingAddress",
    "shippingAddressDisplay", "emptyShippingAddress",
    "billToField", "billingPhoneField", "billingEmailField",
    "billingAddress1Field", "billingAddress2Field",
    "billingCityField", "billingStateField", "billingZipField", "billingCountryField",
    "billingGeoLatField", "billingGeoLongField",
    "shipToField", "shippingPhoneField", "shippingEmailField",
    "shippingAddress1Field", "shippingAddress2Field",
    "shippingCityField", "shippingStateField", "shippingZipField", "shippingCountryField",
    "shippingGeoLatField", "shippingGeoLongField"
  ]

  connect() {
    console.log("Customer Sidebar Connected")

    // Initialize customer modal on connect
    this.initializeCustomerModal()

    // Also try again after a slight delay in case the modal is not available immediately
    setTimeout(() => {
      if (!this.customerModal) {
        this.initializeCustomerModal()
      }
    }, 500)
  }

  initializeCustomerModal() {
    // Find the customer modal controller
    const modalElement = document.getElementById("customer-modal")
    if (!modalElement) {
      console.warn("Customer modal element not found")
      return null
    }

    // Find all controllers with this element
    const controllers = this.application.controllers.filter(
      controller => controller.element === modalElement
    )

    if (controllers.length === 0) {
      console.warn("No controllers found for customer modal")
      return null
    }

    // Find the customer-modal controller specifically
    this.customerModal = controllers.find(
      controller => controller.identifier === "customer-modal"
    )

    if (this.customerModal) {
      console.log("Customer modal controller found")
      this.customerModal.onCustomerSelected = this.handleCustomerSelected.bind(this)
    } else {
      console.warn("Customer modal controller not found")

      // Try a different approach
      if (window.Stimulus && window.Stimulus.application) {
        const allControllers = window.Stimulus.application.controllers;
        const modalController = allControllers.find(c =>
          c.identifier === "customer-modal"
        );

        if (modalController) {
          console.log("Found modal controller via window.Stimulus");
          this.customerModal = modalController;
          this.customerModal.onCustomerSelected = this.handleCustomerSelected.bind(this);
        }
      }
    }
  }

  openSearchModal() {
    if (!this.customerModal) {
      this.initializeCustomerModal()
    }

    if (this.customerModal) {
      console.log("Opening customer modal")
      this.customerModal.open()
    } else {
      console.error("Cannot open customer modal - controller not found")

      // As a fallback, try to show it directly
      const modalElement = document.getElementById("customer-modal")
      if (modalElement) {
        console.log("Attempting direct modal opening")
        modalElement.classList.remove("hidden")
        modalElement.style.display = "flex"
      }
    }
  }

  openEditModal() {
    if (!this.customerModal) {
      this.initializeCustomerModal()
    }

    if (this.customerModal && this.customerIdFieldTarget.value) {
      console.log("Opening customer edit modal")
      this.customerModal.openWithCustomer(this.customerIdFieldTarget.value)
    } else {
      console.error("Cannot open customer edit modal - controller not found or no customer ID")

      // If we have a customer ID but no modal controller, try direct approach
      if (this.customerIdFieldTarget.value) {
        const modalElement = document.getElementById("customer-modal")
        if (modalElement) {
          // Get a reference to the modal controller through the DOM
          const controller = this.application.getControllerForElementAndIdentifier(
            modalElement,
            "customer-modal"
          );

          if (controller) {
            console.log("Found controller via getControllerForElementAndIdentifier");
            controller.openWithCustomer(this.customerIdFieldTarget.value);
          }
        }
      }
    }
  }

  clearCustomer() {
    console.log("Clearing customer")

    // Clear customer ID
    this.customerIdFieldTarget.value = ""

    // Hide customer info
    this.customerInfoTarget.classList.add("hidden")
    this.emptyStateTarget.classList.remove("hidden")
    this.changeCustomerButtonTarget.classList.add("hidden")

    // Clear address fields
    this.billToFieldTarget.value = ""
    this.billingPhoneFieldTarget.value = ""
    this.billingEmailFieldTarget.value = ""
    this.billingAddress1FieldTarget.value = ""
    this.billingAddress2FieldTarget.value = ""
    this.billingCityFieldTarget.value = ""
    this.billingStateFieldTarget.value = ""
    this.billingZipFieldTarget.value = ""
    this.billingCountryFieldTarget.value = "United States"
    this.billingGeoLatFieldTarget.value = ""
    this.billingGeoLongFieldTarget.value = ""

    this.shipToFieldTarget.value = ""
    this.shippingPhoneFieldTarget.value = ""
    this.shippingEmailFieldTarget.value = ""
    this.shippingAddress1FieldTarget.value = ""
    this.shippingAddress2FieldTarget.value = ""
    this.shippingCityFieldTarget.value = ""
    this.shippingStateFieldTarget.value = ""
    this.shippingZipFieldTarget.value = ""
    this.shippingCountryFieldTarget.value = "United States"
    this.shippingGeoLatFieldTarget.value = ""
    this.shippingGeoLongFieldTarget.value = ""

    // Hide address displays
    this.billingAddressDisplayTarget.classList.add("hidden")
    this.emptyBillingAddressTarget.classList.remove("hidden")
    this.shippingAddressDisplayTarget.classList.add("hidden")
    this.emptyShippingAddressTarget.classList.remove("hidden")
  }

  handleCustomerSelected(customer) {
    console.log("Customer selected", customer)

    // Set customer ID
    this.customerIdFieldTarget.value = customer.id

    // Update display
    this.customerNameTarget.textContent = customer.name
    this.customerEmailTarget.textContent = customer.email || "No email"
    this.customerPhoneTarget.textContent = customer.phone || "No phone"

    // Show customer info, hide empty state
    this.emptyStateTarget.classList.add("hidden")
    this.customerInfoTarget.classList.remove("hidden")
    this.changeCustomerButtonTarget.classList.remove("hidden")

    // Update billing address if one is selected
    if (customer.selectedBillingAddress) {
      this.updateBillingAddress(customer.selectedBillingAddress)
    } else {
      this.billingAddressDisplayTarget.classList.add("hidden")
      this.emptyBillingAddressTarget.classList.remove("hidden")

      // Clear billing address fields
      this.billToFieldTarget.value = ""
      this.billingPhoneFieldTarget.value = ""
      this.billingEmailFieldTarget.value = customer.email || ""
      this.billingAddress1FieldTarget.value = ""
      this.billingAddress2FieldTarget.value = ""
      this.billingCityFieldTarget.value = ""
      this.billingStateFieldTarget.value = ""
      this.billingZipFieldTarget.value = ""
      this.billingCountryFieldTarget.value = "United States"
      this.billingGeoLatFieldTarget.value = ""
      this.billingGeoLongFieldTarget.value = ""
    }

    // Update shipping address if one is selected
    if (customer.selectedShippingAddress) {
      this.updateShippingAddress(customer.selectedShippingAddress)
    } else {
      this.shippingAddressDisplayTarget.classList.add("hidden")
      this.emptyShippingAddressTarget.classList.remove("hidden")

      // Clear shipping address fields
      this.shipToFieldTarget.value = ""
      this.shippingPhoneFieldTarget.value = ""
      this.shippingEmailFieldTarget.value = customer.email || ""
      this.shippingAddress1FieldTarget.value = ""
      this.shippingAddress2FieldTarget.value = ""
      this.shippingCityFieldTarget.value = ""
      this.shippingStateFieldTarget.value = ""
      this.shippingZipFieldTarget.value = ""
      this.shippingCountryFieldTarget.value = "United States"
      this.shippingGeoLatFieldTarget.value = ""
      this.shippingGeoLongFieldTarget.value = ""
    }

    // Notify any parent forms that data has changed
    this.notifyFormChange()
  }

  updateBillingAddress(address) {
    console.log("Updating billing address", address)

    // Update hidden fields
    this.billToFieldTarget.value = address.name || ""
    this.billingPhoneFieldTarget.value = address.phone || ""
    this.billingEmailFieldTarget.value = address.email || this.customerEmailTarget.textContent || ""
    this.billingAddress1FieldTarget.value = address.address_1 || ""
    this.billingAddress2FieldTarget.value = address.address_2 || ""
    this.billingCityFieldTarget.value = address.city || ""
    this.billingStateFieldTarget.value = address.state || ""
    this.billingZipFieldTarget.value = address.zip || ""
    this.billingCountryFieldTarget.value = address.country || "United States"
    this.billingGeoLatFieldTarget.value = address.geo_lat || ""
    this.billingGeoLongFieldTarget.value = address.geo_long || ""

    // Update display
    this.billingAddressDisplayTarget.innerHTML = this.formatAddressHtml(address)
    this.billingAddressDisplayTarget.classList.remove("hidden")
    this.emptyBillingAddressTarget.classList.add("hidden")

    // Notify any parent forms that data has changed
    this.notifyFormChange()
  }

  updateShippingAddress(address) {
    console.log("Updating shipping address", address)

    // Update hidden fields
    this.shipToFieldTarget.value = address.name || ""
    this.shippingPhoneFieldTarget.value = address.phone || ""
    this.shippingEmailFieldTarget.value = address.email || this.customerEmailTarget.textContent || ""
    this.shippingAddress1FieldTarget.value = address.address_1 || ""
    this.shippingAddress2FieldTarget.value = address.address_2 || ""
    this.shippingCityFieldTarget.value = address.city || ""
    this.shippingStateFieldTarget.value = address.state || ""
    this.shippingZipFieldTarget.value = address.zip || ""
    this.shippingCountryFieldTarget.value = address.country || "United States"
    this.shippingGeoLatFieldTarget.value = address.geo_lat || ""
    this.shippingGeoLongFieldTarget.value = address.geo_long || ""

    // Update display
    this.shippingAddressDisplayTarget.innerHTML = this.formatAddressHtml(address)
    this.shippingAddressDisplayTarget.classList.remove("hidden")
    this.emptyShippingAddressTarget.classList.add("hidden")

    // Notify any parent forms that data has changed
    this.notifyFormChange()
  }

  formatAddressHtml(address) {
    let html = ''

    if (address.name) {
      html += `<p class="font-medium text-gray-900 dark:text-white">${address.name}</p>`
    }

    html += `<p class="text-gray-700 dark:text-space-300">${address.address_1}</p>`

    if (address.address_2) {
      html += `<p class="text-gray-700 dark:text-space-300">${address.address_2}</p>`
    }

    const cityStateZip = [address.city, address.state, address.zip].filter(Boolean).join(", ")
    html += `<p class="text-gray-700 dark:text-space-300">${cityStateZip}</p>`

    if (address.country && address.country !== "United States") {
      html += `<p class="text-gray-700 dark:text-space-300">${address.country}</p>`
    }

    return html
  }

  notifyFormChange() {
    // Find the closest form
    const form = this.element.closest('form');
    if (form) {
      // Create and dispatch a custom change event
      const event = new Event('change', { bubbles: true });
      form.dispatchEvent(event);

      // Also try to find and notify the quote-builder controller
      const quoteBuilderElement = document.querySelector('[data-controller~="quote-builder"]');
      if (quoteBuilderElement) {
        const quoteBuilderController = this.application.getControllerForElementAndIdentifier(
          quoteBuilderElement,
          "quote-builder"
        );

        if (quoteBuilderController && typeof quoteBuilderController.recalculateTotals === 'function') {
          quoteBuilderController.recalculateTotals();
        }
      }
    }
  }
}