// app/javascript/controllers/product_images_handler_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropzone", "fileInput", "previewContainer", "queuedTemplate"]
  static values = {
    maxFileSize: { type: Number, default: 5 }, // Max file size in MB
    acceptedTypes: { type: Array, default: ["image/jpeg", "image/png", "image/gif", "image/webp"] }
  }

  connect() {
    this.setupDropzone()
    console.log("Product Images Handler connected")
  }

  setupDropzone() {
    this.dropzoneTarget.addEventListener("dragover", (e) => {
      e.preventDefault()
      this.dropzoneTarget.classList.add("border-blue-500", "bg-blue-500/10")
    })

    this.dropzoneTarget.addEventListener("dragleave", (e) => {
      e.preventDefault()
      this.dropzoneTarget.classList.remove("border-blue-500", "bg-blue-500/10")
    })

    this.dropzoneTarget.addEventListener("drop", (e) => {
      e.preventDefault()
      this.dropzoneTarget.classList.remove("border-blue-500", "bg-blue-500/10")

      const files = e.dataTransfer.files
      if (files && files.length > 0) {
        // For drag and drop, we need to manually add files to the input
        this.addFilesToInput(files)
        this.createPreviews(files)
      }
    })

    // Make the entire dropzone clickable to trigger file input
    this.dropzoneTarget.addEventListener("click", () => {
      this.fileInputTarget.click()
    })
  }

  // Handle file input change
  fileInputChanged(event) {
    const files = event.target.files
    if (files && files.length > 0) {
      // For file input, the files are already attached to the input
      // We just need to create the previews
      this.createPreviews(files)
    }
  }

  // Add files to the file input
  addFilesToInput(newFiles) {
    try {
      // Get existing files from the input
      const existingFiles = Array.from(this.fileInputTarget.files || [])

      // Create a new DataTransfer object
      const dt = new DataTransfer()

      // Add existing files
      existingFiles.forEach(file => dt.items.add(file))

      // Add new files
      Array.from(newFiles).forEach(file => {
        if (this.isValidFile(file)) {
          dt.items.add(file)
        }
      })

      // Update the file input's files
      this.fileInputTarget.files = dt.files

      console.log(`Files added, total: ${this.fileInputTarget.files.length}`)
    } catch (error) {
      console.error("Error adding files to input:", error)
      this.showError("There was a problem adding the files. Try selecting them directly instead.")
    }
  }

  // Create previews for files
  createPreviews(files) {
    Array.from(files).forEach(file => {
      if (this.isValidFile(file)) {
        this.createSinglePreview(file)
      }
    })
  }

  // Check if file is valid
  isValidFile(file) {
    // Check file type
    if (!this.acceptedTypesValue.includes(file.type)) {
      this.showError(`${file.name} is not a supported image type.`)
      return false
    }

    // Check file size
    if (file.size > this.maxFileSizeValue * 1024 * 1024) {
      this.showError(`${file.name} exceeds the ${this.maxFileSizeValue}MB size limit.`)
      return false
    }

    return true
  }

  // Create preview for a single file
  createSinglePreview(file) {
    const template = this.queuedTemplateTarget.content.cloneNode(true)
    const previewContainer = template.querySelector(".queued-image-container")
    const previewImage = template.querySelector(".queued-image")
    const fileName = template.querySelector(".queued-filename")

    // Create object URL for preview
    const objectUrl = URL.createObjectURL(file)
    previewImage.src = objectUrl

    // Show truncated filename
    const nameToDisplay = file.name.length > 15
      ? file.name.substring(0, 12) + '...'
      : file.name
    fileName.textContent = nameToDisplay
    fileName.title = file.name // Full name on hover

    // Add the preview to the container
    this.previewContainerTarget.appendChild(template)

    // Revoke object URL when no longer needed
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
  }

  showError(message) {
    console.error(message)
    alert(message) // Simple fallback
  }
}