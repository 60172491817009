import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subtotal", "tax", "shipping", "discount", "total", "itemsContainer"]

  connect() {
    this.updateTotals()

    // Listen for order item removal events
    document.addEventListener('order-item:removed', this.updateTotals.bind(this))
  }

  disconnect() {
    document.removeEventListener('order-item:removed', this.updateTotals.bind(this))
  }

  updateTotals() {
    // Calculate order level totals based on all visible items
    const items = this.getVisibleItems()

    // Calculate subtotal from all items
    const subtotal = items.reduce((sum, item) => {
      const controller = this.getItemController(item)
      if (!controller) return sum

      const price = parseFloat(controller.priceTarget.value) || 0
      const quantity = parseInt(controller.quantityTarget.value) || 0
      const discountType = controller.discountTypeTarget.value
      const discountAmount = parseFloat(controller.discountAmountTarget.value) || 0

      let itemTotal = price * quantity

      // Apply item-level discount
      if (discountType === 'percentage') {
        itemTotal -= itemTotal * (discountAmount / 100)
      } else {
        itemTotal -= discountAmount
      }

      return sum + itemTotal
    }, 0)

    // Get order-level values
    const taxRate = parseFloat(this.element.dataset.taxRate || 0) / 100
    const shippingCost = parseFloat(this.hasShippingTarget ? this.shippingTarget.value : 0) || 0
    const discountType = this.hasDiscountTarget ? this.discountTarget.dataset.discountType : 'fixed'
    const discountAmount = parseFloat(this.hasDiscountTarget ? this.discountTarget.value : 0) || 0

    // Calculate tax
    const tax = subtotal * taxRate

    // Calculate order-level discount
    let orderDiscount = 0
    if (discountType === 'percentage') {
      orderDiscount = subtotal * (discountAmount / 100)
    } else {
      orderDiscount = discountAmount
    }

    // Calculate final total
    const total = subtotal + tax + shippingCost - orderDiscount

    // Update displays if targets exist
    if (this.hasSubtotalTarget) {
      this.subtotalTarget.textContent = this.formatCurrency(subtotal)
    }

    if (this.hasTaxTarget) {
      this.taxTarget.textContent = this.formatCurrency(tax)
    }

    if (this.hasTotalTarget) {
      this.totalTarget.textContent = this.formatCurrency(total)
    }

    // If we have a form with hidden total inputs, update those too
    const totalInput = this.element.querySelector('input[name*="[total]"]')
    if (totalInput) totalInput.value = total.toFixed(2)

    const subtotalInput = this.element.querySelector('input[name*="[subtotal]"]')
    if (subtotalInput) subtotalInput.value = subtotal.toFixed(2)

    const taxInput = this.element.querySelector('input[name*="[tax]"]')
    if (taxInput) taxInput.value = tax.toFixed(2)
  }

  getVisibleItems() {
    // Get all item elements that aren't hidden and don't have _destroy=true
    let items = []

    if (this.hasItemsContainerTarget) {
      items = Array.from(this.itemsContainerTarget.querySelectorAll('.order-item-wrapper')).filter(item => {
        const isHidden = item.classList.contains('hidden')
        const destroyInput = item.querySelector('input[name*="[_destroy]"]')
        const isDestroyed = destroyInput && destroyInput.value === 'true'

        return !isHidden && !isDestroyed
      })
    } else {
      // If no specific container, look for all order items in the form
      items = Array.from(this.element.querySelectorAll('.order-item-wrapper')).filter(item => {
        const isHidden = item.classList.contains('hidden')
        const destroyInput = item.querySelector('input[name*="[_destroy]"]')
        const isDestroyed = destroyInput && destroyInput.value === 'true'

        return !isHidden && !isDestroyed
      })
    }

    return items
  }

  getItemController(item) {
    // Get the Stimulus controller for an item element
    return this.application.getControllerForElementAndIdentifier(item, 'order-item')
  }

  formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  }
}