import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["orderType"]

  connect() {
    this.updateOrderItems()
  }

  // When order type changes, trigger recalculation of all order items
  typeChanged() {
    this.updateOrderItems()

    // If we have builder orders, show/hide builder cost fields
    const isBuilderOrder = this.orderTypeTarget.value === 'builder_order'
    this.toggleBuilderCostFields(isBuilderOrder)
  }

  // Update all order items to recalculate totals based on new order type
  updateOrderItems() {
    const orderItems = document.querySelectorAll('.order-item-wrapper:not(.hidden)')
    orderItems.forEach(item => {
      // Dispatch update event to recalculate totals
      item.dispatchEvent(new CustomEvent('order-item:update', {
        bubbles: true
      }))
    })

    // Also update order totals
    document.dispatchEvent(new CustomEvent('order-item:update', {
      bubbles: true
    }))
  }

  // Show/hide builder cost fields based on order type
  toggleBuilderCostFields(show) {
    const builderCostFields = document.querySelectorAll('.builder-cost-field')
    builderCostFields.forEach(field => {
      if (show) {
        field.classList.remove('hidden')
      } else {
        field.classList.add('hidden')
      }
    })
  }
}