// app/javascript/controllers/customer_notes_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "form", "content"]

  connect() {
    // Setup code when controller is connected
  }

  showAddNoteModal() {
    this.modalTarget.classList.remove("hidden")
  }

  hideModal(event) {
    // Close the modal when clicking outside or on cancel
    if (event.target === event.currentTarget || event.target.dataset.action.includes("hideModal")) {
      this.modalTarget.classList.add("hidden")
      // Reset the form
      this.contentTarget.value = ""
    }
  }

  submitNote(event) {
    event.preventDefault()

    // Basic validation
    if (!this.contentTarget.value.trim()) {
      alert("Note content is required")
      this.contentTarget.focus()
      return
    }

    // Submit the form
    this.formTarget.submit()
  }
}