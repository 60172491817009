import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = {
    activeTab: { type: Number, default: 0 }
  }

  connect() {
    // Set the first tab as active on connect
    this.activateTab(this.activeTabValue)
  }

  // Switch to the selected tab
  switchTab(event) {
    const tabIndex = parseInt(event.currentTarget.dataset.tabIndex)
    this.activateTab(tabIndex)
  }

  // Activate the tab with the given index
  activateTab(index) {
    this.activeTabValue = index

    // Update tab styling
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.add("text-white", "bg-blue-500", "border-b-2", "border-blue-500")
        tab.classList.remove("text-space-400", "border-transparent")
      } else {
        tab.classList.remove("text-white", "bg-blue-500", "border-b-2", "border-blue-500")
        tab.classList.add("text-space-400", "border-transparent", "hover:text-space-100", "hover:bg-space-800/30")
      }
    })

    // Update panel visibility
    this.panelTargets.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("hidden")
      } else {
        panel.classList.add("hidden")
      }
    })
  }
}