import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Theme toggle controller connected");

    // Initialize theme on page load
    this.applyThemeFromLocalStorage();

    // Monitor for changes to localStorage theme
    window.addEventListener('storage', (event) => {
      if (event.key === 'theme') {
        this.applyTheme(event.newValue);
      }
    });
  }

  // Apply theme from localStorage or defaults to dark
  applyThemeFromLocalStorage() {
    const theme = localStorage.getItem('theme') || 'dark';
    this.applyTheme(theme);
  }

  toggle(event) {
    event.preventDefault();
    console.log("Toggle theme clicked");

    // Determine current theme
    const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    console.log("Current theme:", currentTheme);

    // Set new theme
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    console.log("New theme:", newTheme);

    // Apply the new theme immediately
    this.applyTheme(newTheme);

    // Save preference
    this.saveThemePreference(newTheme);
  }

  applyTheme(theme) {
    console.log("Applying theme:", theme);

    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    // Force a repaint to make sure the theme change is applied
    document.body.style.display = 'none';
    document.body.offsetHeight; // Trigger a reflow
    document.body.style.display = '';

    // Update localStorage
    localStorage.setItem('theme', theme);

    // Update data attribute on body
    document.body.dataset.theme = theme;

    console.log("Theme applied:", theme, "dark class present:", document.documentElement.classList.contains('dark'));
  }

  saveThemePreference(theme) {
    // If user is logged in, save preference to server via AJAX
    const userId = document.querySelector('meta[name="current-user-id"]')?.content;
    const token = document.querySelector('meta[name="csrf-token"]')?.content;

    if (userId && token) {
      console.log("Saving theme preference to server:", theme);

      fetch('/account/update_theme', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        body: JSON.stringify({ theme: theme })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log("Theme preference saved:", data);
        })
        .catch(error => {
          console.error('Error saving theme preference:', error);
        });
    } else {
      console.log("User not logged in or CSRF token missing, only saving to localStorage");
    }
  }
}