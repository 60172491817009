import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dialog", "backdrop", "panel", "filePreview", "fileInput", "errorMessage",
    "otherMethod", "termsSection", "customTerms", "termsEndDate", "creditSection",
    "tabButton", "tabContent", "partialOptions", "amountInput", "percentageInput", "calculatedAmount",
    "partialOptionsCard", "cardAmountInput", "percentageInputCard", "calculatedAmountCard",
    "partialOptionsLink", "linkAmountInput", "percentageInputLink", "calculatedAmountLink",
    "cardElement", "cardErrors", "linkResult", "linkUrl", "error", "dialogTitle",

    // Refund-specific targets
    "refundTransactionSelector", "refundTransactionDetails", "refundFormFields",
    "refundOriginalAmount", "refundPreviouslyRefunded", "refundAvailableAmount",
    "refundPaymentMethod", "refundPaymentDate", "refundReferenceNumber",
    "refundAmountInput", "fullRefundCheckbox", "refundMethodSelect",
    "refundOtherMethod", "refundSubmitButton", "refundError"
  ]

  static values = {
    key: String,
    balanceDue: Number
  }

  connect() {
    // Store the current payment method selection
    this.previousPaymentMethod = 'cash';

    // Safely attempt to initialize file upload handling
    try {
      if (this.hasFileInputTarget && this.fileInputTarget) {
        this.initializeFileHandling()
      }
    } catch (error) {
      console.error("Error initializing file handling:", error)
    }

    // Initialize Stripe if it's available
    if (typeof Stripe !== 'undefined' && this.hasCardElementTarget) {
      this.initializeStripe()
    }

    // Initialize the end date for terms if applicable
    if (this.hasTermsEndDateTarget) {
      this.updateEndDate()
    }

    // Disable refund button initially (until transaction selected)
    if (this.hasRefundSubmitButtonTarget) {
      this.refundSubmitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      this.refundSubmitButtonTarget.disabled = true
    }

    // Hide refund form fields initially
    if (this.hasRefundFormFieldsTarget) {
      this.refundFormFieldsTarget.classList.add('hidden')
    }
  }

  // Simplified file handling that will work regardless of HTML structure
  initializeFileHandling() {
    // Add change listener to the file input
    this.fileInputTarget.addEventListener('change', (event) => {
      this.previewFiles(event.target.files)
    })
  }

  openDialog(event) {
    try {
      if (this.hasDialogTarget) {
        this.dialogTarget.classList.remove("hidden")
        document.body.classList.add("overflow-hidden")
      } else {
        // Try a direct selector as fallback
        const dialog = document.querySelector('[data-payment-dialog-target="dialog"]')
        if (dialog) {
          dialog.classList.remove("hidden")
          document.body.classList.add("overflow-hidden")
        }
      }

      // Reset to first tab when opening
      this.switchToTab('manual')

      // Update dialog title
      if (this.hasDialogTitleTarget) {
        this.dialogTitleTarget.textContent = "Record Payment"
      }
    } catch (error) {
      console.error("Error opening dialog:", error)
    }
  }

  // Method to open the refund dialog directly
  openRefundDialog(event) {
    try {
      if (this.hasDialogTarget) {
        this.dialogTarget.classList.remove("hidden")
        document.body.classList.add("overflow-hidden")
      }

      // Switch directly to refund tab
      this.switchToTab('refund')

      // Update dialog title
      if (this.hasDialogTitleTarget) {
        this.dialogTitleTarget.textContent = "Process Refund"
      }

      // If we have transaction data in the clicked element, pre-select it
      if (event.currentTarget.dataset.transactionId) {
        const transactionId = event.currentTarget.dataset.transactionId

        // Find and select the transaction in the dropdown
        if (this.hasRefundTransactionSelectorTarget) {
          this.refundTransactionSelectorTarget.value = transactionId
          // Trigger the change event to load the data
          this.loadRefundTransaction()
        }
      }
    } catch (error) {
      console.error("Error opening refund dialog:", error)
    }
  }

  closeDialog(event) {
    try {
      if (!event || (this.hasBackdropTarget && event.target === this.backdropTarget)) {
        if (this.hasDialogTarget) {
          this.dialogTarget.classList.add("hidden")
          document.body.classList.remove("overflow-hidden")
          this.resetFilePreview()
        }
      }
    } catch (error) {
      console.error("Error closing dialog:", error)
    }
  }

  closeWithButton() {
    try {
      if (this.hasDialogTarget) {
        this.dialogTarget.classList.add("hidden")
        document.body.classList.remove("overflow-hidden")
        this.resetFilePreview()
      }
    } catch (error) {
      console.error("Error closing dialog with button:", error)
    }
  }

  resetFilePreview() {
    try {
      // Clear file preview when closing dialog
      if (this.hasFilePreviewTarget) {
        this.filePreviewTarget.innerHTML = ''
      }

      // Reset the file input
      if (this.hasFileInputTarget) {
        this.fileInputTarget.value = ''
      }
    } catch (error) {
      console.error("Error resetting file preview:", error)
    }
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      this.closeDialog()
    }
  }

  preventDialogClose(event) {
    event.stopPropagation()
  }

  // Merged payment method handler
  togglePaymentMethod(event) {
    const method = event.target.value;

    // Save the current selection for potential rollback
    const previousMethod = this.previousPaymentMethod || 'cash';
    this.previousPaymentMethod = method;

    // Handle 'other' method - show/hide the other method input field
    if (this.hasOtherMethodTarget) {
      if (method === 'other') {
        this.otherMethodTarget.classList.remove('hidden');
      } else {
        this.otherMethodTarget.classList.add('hidden');
      }
    }

    // Handle net terms section
    if (this.hasTermsSectionTarget) {
      if (method === 'net_terms') {
        // Check if net terms is available (not disabled in select)
        const netTermsSelect = document.getElementById('payment_method');
        if (netTermsSelect) {
          const netTermsOption = Array.from(netTermsSelect.options).find(option => option.value === 'net_terms');
          if (netTermsOption && netTermsOption.disabled) {
            // If disabled, show a message and don't open terms section
            alert("Net Terms requires a customer to be assigned to this order.");
            // Reset selection to previous option
            event.target.value = previousMethod;
            this.previousPaymentMethod = previousMethod;
            return;
          }
        }

        // Show the terms section
        this.termsSectionTarget.classList.remove('hidden');

        // Initialize end date
        if (this.hasTermsEndDateTarget) {
          this.updateEndDate();
        }
      } else {
        // Hide the terms section for all other payment methods
        this.termsSectionTarget.classList.add('hidden');
      }
    }
  }

  // Update end date based on terms and start date
  updateEndDate() {
    if (!this.hasTermsEndDateTarget) return;

    // Get terms days
    let termsDays;
    const termsSelect = document.getElementById('terms_days');
    const customTermsInput = document.getElementById('custom_terms_days');

    if (!termsSelect) return;

    if (termsSelect.value === 'custom') {
      termsDays = customTermsInput && customTermsInput.value ? parseInt(customTermsInput.value) : 0;
    } else {
      termsDays = parseInt(termsSelect.value);
    }

    // Get start date
    const startDateInput = document.getElementById('terms_start_date');
    if (!startDateInput) return;

    const startDateString = startDateInput.value;
    if (!startDateString || isNaN(termsDays)) {
      this.termsEndDateTarget.textContent = 'Select valid terms and date';
      return;
    }

    // Calculate end date
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + termsDays);

    // Format date as YYYY-MM-DD
    const formattedDate = endDate.toISOString().split('T')[0];
    this.termsEndDateTarget.textContent = formattedDate;
  }

  // Toggle custom terms input
  toggleCustomTerms(event) {
    if (!this.hasCustomTermsTarget) return;

    const termsValue = event.target.value;

    if (termsValue === 'custom') {
      this.customTermsTarget.classList.remove('hidden');
    } else {
      this.customTermsTarget.classList.add('hidden');
      this.updateEndDate(); // Update end date when preset terms selected
    }
  }

  // Toggle account credit section
  toggleApplyCredit(event) {
    if (!this.hasCreditSectionTarget) return;

    if (event.target.checked) {
      this.creditSectionTarget.classList.remove('hidden');
    } else {
      this.creditSectionTarget.classList.add('hidden');
    }
  }

  // Payment methods tab handling
  switchTab(event) {
    const tab = event.currentTarget.dataset.tab
    this.switchToTab(tab)
  }

  // Helper to programmatically switch tabs
  switchToTab(tab) {
    // Update active tab button
    this.tabButtonTargets.forEach(button => {
      if (button.dataset.tab === tab) {
        button.classList.add('bg-blue-600', 'text-white')
        button.classList.remove('text-gray-600', 'dark:text-space-400')
      } else {
        button.classList.remove('bg-blue-600', 'text-white')
        button.classList.add('text-gray-600', 'dark:text-space-400')
      }
    })

    // Show selected tab content, hide others
    this.tabContentTargets.forEach(content => {
      if (content.dataset.tab === tab) {
        content.classList.remove('hidden')
      } else {
        content.classList.add('hidden')
      }
    })
  }

  // Payment amount toggle methods
  togglePaymentType(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsTarget) {
      if (isPartial) {
        this.partialOptionsTarget.classList.remove('hidden')
        this.updateAmountFromPercentage()
      } else {
        this.partialOptionsTarget.classList.add('hidden')
        if (this.hasAmountInputTarget) {
          this.amountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  togglePaymentTypeCard(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsCardTarget) {
      if (isPartial) {
        this.partialOptionsCardTarget.classList.remove('hidden')
        this.updateAmountFromPercentageCard()
      } else {
        this.partialOptionsCardTarget.classList.add('hidden')
        if (this.hasCardAmountInputTarget) {
          this.cardAmountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  togglePaymentTypeLink(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsLinkTarget) {
      if (isPartial) {
        this.partialOptionsLinkTarget.classList.remove('hidden')
        this.updateAmountFromPercentageLink()
      } else {
        this.partialOptionsLinkTarget.classList.add('hidden')
        if (this.hasLinkAmountInputTarget) {
          this.linkAmountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  // Percentage to amount calculation methods
  updateAmountFromPercentage() {
    if (this.hasPercentageInputTarget && this.hasAmountInputTarget && this.hasCalculatedAmountTarget) {
      const percentage = parseFloat(this.percentageInputTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.amountInputTarget.value = amount
      this.calculatedAmountTarget.textContent = `$${amount}`
    }
  }

  updateAmountFromPercentageCard() {
    if (this.hasPercentageInputCardTarget && this.hasCardAmountInputTarget && this.hasCalculatedAmountCardTarget) {
      const percentage = parseFloat(this.percentageInputCardTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.cardAmountInputTarget.value = amount
      this.calculatedAmountCardTarget.textContent = `$${amount}`
    }
  }

  updateAmountFromPercentageLink() {
    if (this.hasPercentageInputLinkTarget && this.hasLinkAmountInputTarget && this.hasCalculatedAmountLinkTarget) {
      const percentage = parseFloat(this.percentageInputLinkTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.linkAmountInputTarget.value = amount
      this.calculatedAmountLinkTarget.textContent = `$${amount}`
    }
  }

  // File preview logic
  previewFiles(files) {
    try {
      if (!this.hasFilePreviewTarget || !files) return

      // Clear the preview area
      this.filePreviewTarget.innerHTML = ''

      if (files.length === 0) return

      // Create the files list container
      const filesList = document.createElement('div')
      filesList.className = 'mt-2 space-y-2'

      Array.from(files).forEach((file, index) => {
        const fileItem = document.createElement('div')
        fileItem.className = 'flex items-center justify-between bg-white dark:bg-space-800 p-2 rounded-md border border-gray-200 dark:border-space-700'

        // File icon based on type
        let iconHtml = ''
        if (file.type.startsWith('image/')) {
          iconHtml = `<svg class="w-5 h-5 text-gray-500 dark:text-space-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>`
        } else if (file.type === 'application/pdf') {
          iconHtml = `<svg class="w-5 h-5 text-gray-500 dark:text-space-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>`
        } else {
          iconHtml = `<svg class="w-5 h-5 text-gray-500 dark:text-space-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 010 .707l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>`
        }

        // File info
        const fileSize = this.formatFileSize(file.size)
        const fileName = file.name

        // Create file info element
        const fileInfo = document.createElement('div')
        fileInfo.className = 'flex items-center space-x-2'
        fileInfo.innerHTML = `
          <div class="flex-shrink-0">${iconHtml}</div>
          <div class="text-sm">
            <p class="text-gray-800 dark:text-gray-200 font-medium truncate max-w-xs">${fileName}</p>
            <p class="text-gray-500 dark:text-space-500 text-xs">${fileSize}</p>
          </div>
        `

        // Remove button
        const removeButton = document.createElement('button')
        removeButton.type = 'button'
        removeButton.className = 'text-red-500 hover:text-red-700 dark:hover:text-red-400'
        removeButton.innerHTML = `
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        `
        removeButton.dataset.index = index
        removeButton.addEventListener('click', (e) => this.removeFile(e))

        // Append elements to file item
        fileItem.appendChild(fileInfo)
        fileItem.appendChild(removeButton)

        // Append file item to list
        filesList.appendChild(fileItem)
      })

      // Add the files list to the preview
      this.filePreviewTarget.appendChild(filesList)

      // Validate total file size
      this.validateFiles(files)
    } catch (error) {
      console.error("Error previewing files:", error)
    }
  }

  formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  removeFile(event) {
    try {
      const button = event.currentTarget
      const index = parseInt(button.dataset.index, 10)

      if (!this.hasFileInputTarget || !this.fileInputTarget.files || this.fileInputTarget.files.length === 0) return

      // Create a new DataTransfer object
      const dataTransfer = new DataTransfer()

      // Add all files except the one to remove
      Array.from(this.fileInputTarget.files).forEach((file, i) => {
        if (i !== index) {
          dataTransfer.items.add(file)
        }
      })

      // Set the new file list
      this.fileInputTarget.files = dataTransfer.files

      // Update preview
      this.previewFiles(this.fileInputTarget.files)
    } catch (error) {
      console.error("Error removing file:", error)
    }
  }

  validateFiles(files) {
    try {
      if (!this.hasErrorMessageTarget || !files) return true

      // Reset error message
      this.errorMessageTarget.textContent = ''

      // Check file size limits
      const maxFileSize = 10 * 1024 * 1024 // 10MB

      // Check each file
      const oversizedFiles = Array.from(files).filter(file => file.size > maxFileSize)

      if (oversizedFiles.length > 0) {
        const fileNames = oversizedFiles.map(f => f.name).join(', ')
        this.errorMessageTarget.textContent = `File size limit exceeded (10MB max): ${fileNames}`
        return false
      }

      return true
    } catch (error) {
      console.error("Error validating files:", error)
      return false
    }
  }

  // To be called before form submission
  validateFormBeforeSubmit(event) {
    try {
      if (this.hasFileInputTarget && this.fileInputTarget.files && this.fileInputTarget.files.length > 0) {
        const isValid = this.validateFiles(this.fileInputTarget.files)
        if (!isValid) {
          event.preventDefault()
          return false
        }
      }
      return true
    } catch (error) {
      console.error("Error validating form:", error)
      // Allow form submission on error rather than blocking
      return true
    }
  }

  // Stripe payment processing
  initializeStripe() {
    try {
      this.stripe = Stripe(this.keyValue);
      const elements = this.stripe.elements();

      // Create card element
      this.card = elements.create('card');
      this.card.mount(this.cardElementTarget);

      // Handle card validation errors
      this.card.addEventListener('change', (event) => {
        if (event.error && this.hasCardErrorsTarget) {
          this.cardErrorsTarget.textContent = event.error.message;
        } else if (this.hasCardErrorsTarget) {
          this.cardErrorsTarget.textContent = '';
        }
      });
    } catch (error) {
      console.error('Stripe initialization error:', error);
      if (this.hasErrorTarget) {
        this.errorTarget.textContent = 'Failed to initialize payment system';
      }
    }
  }

  processCard(event) {
    event.preventDefault();

    if (!this.hasCardAmountInputTarget) return;

    const amount = parseFloat(this.cardAmountInputTarget.value);
    if (isNaN(amount) || amount <= 0) {
      if (this.hasCardErrorsTarget) {
        this.cardErrorsTarget.textContent = 'Please enter a valid amount';
      }
      return;
    }

    // Disable the button to prevent multiple submissions
    event.target.disabled = true;
    event.target.textContent = 'Processing...';

    // Create payment method using card element
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        if (this.hasCardErrorsTarget) {
          this.cardErrorsTarget.textContent = result.error.message;
        }
        event.target.disabled = false;
        event.target.textContent = 'Process Payment';
      } else {
        // Process the payment server-side
        this.submitCardPayment(result.token.id, amount);
      }
    });
  }

  submitCardPayment(token, amount) {
    fetch(`/manage/${this.element.dataset.subdomain}/orders/${this.element.dataset.orderId}/payments/card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ token, amount })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Reload the page to show the updated payment
          window.location.reload();
        } else if (data.error) {
          if (this.hasCardErrorsTarget) {
            this.cardErrorsTarget.textContent = data.error;
          }
          event.target.disabled = false;
          event.target.textContent = 'Process Payment';
        }
      })
      .catch(error => {
        console.error('Payment processing error:', error);
        if (this.hasCardErrorsTarget) {
          this.cardErrorsTarget.textContent = 'Payment processing failed';
        }
        event.target.disabled = false;
        event.target.textContent = 'Process Payment';
      });
  }

  // Payment link methods
  generateLink(event) {
    event.preventDefault();

    if (!this.hasLinkAmountInputTarget || !this.hasLinkResultTarget || !this.hasLinkUrlTarget) return;

    const amount = parseFloat(this.linkAmountInputTarget.value);
    if (isNaN(amount) || amount <= 0) {
      if (this.hasErrorTarget) {
        this.errorTarget.textContent = 'Please enter a valid amount';
      }
      return;
    }

    // Disable the button to prevent multiple submissions
    event.target.disabled = true;
    event.target.textContent = 'Generating...';

    // Generate payment link server-side
    fetch(`/manage/${this.element.dataset.subdomain}/orders/${this.element.dataset.orderId}/payments/generate_link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        amount,
        expire_after: document.getElementById('expire_after').value
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.url) {
          this.linkUrlTarget.value = data.url;
          this.linkResultTarget.classList.remove('hidden');
        } else if (data.error) {
          if (this.hasErrorTarget) {
            this.errorTarget.textContent = data.error;
          }
        }
        event.target.disabled = false;
        event.target.textContent = 'Generate Link';
      })
      .catch(error => {
        console.error('Link generation error:', error);
        if (this.hasErrorTarget) {
          this.errorTarget.textContent = 'Failed to generate payment link';
        }
        event.target.disabled = false;
        event.target.textContent = 'Generate Link';
      });
  }

  copyLink() {
    if (!this.hasLinkUrlTarget) return;

    this.linkUrlTarget.select();
    document.execCommand('copy');

    // Show copy confirmation
    const button = event.target;
    const originalText = button.textContent;
    button.textContent = 'Copied!';
    setTimeout(() => {
      button.textContent = originalText;
    }, 2000);
  }

  // Form submission
  submitManual(event) {
    // This is just a placeholder for any pre-submission processing
    // The actual form submission happens normally
  }

  // Refund-specific methods

  // Load transaction details for refund
  loadRefundTransaction() {
    if (!this.hasRefundTransactionSelectorTarget || !this.hasRefundTransactionDetailsTarget) return;

    const selector = this.refundTransactionSelectorTarget;
    const selectedOption = selector.options[selector.selectedIndex];

    if (!selectedOption || !selectedOption.value) {
      // No transaction selected, hide details and form
      this.refundTransactionDetailsTarget.classList.add('hidden');

      if (this.hasRefundFormFieldsTarget) {
        this.refundFormFieldsTarget.classList.add('hidden');
      }

      if (this.hasRefundSubmitButtonTarget) {
        this.refundSubmitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed');
        this.refundSubmitButtonTarget.disabled = true;
      }

      return;
    }

    // Transaction selected, populate details
    const transactionData = selectedOption.dataset;

    // Show details section
    this.refundTransactionDetailsTarget.classList.remove('hidden');

    // Populate transaction details
    if (this.hasRefundOriginalAmountTarget) {
      this.refundOriginalAmountTarget.textContent = `${parseFloat(transactionData.amount).toFixed(2)}`;
    }

    if (this.hasRefundPreviouslyRefundedTarget) {
      this.refundPreviouslyRefundedTarget.textContent = `${parseFloat(transactionData.refundedAmount || 0).toFixed(2)}`;
    }

    if (this.hasRefundAvailableAmountTarget) {
      this.refundAvailableAmountTarget.textContent = `${parseFloat(transactionData.refundableAmount).toFixed(2)}`;
    }

    if (this.hasRefundPaymentMethodTarget) {
      this.refundPaymentMethodTarget.textContent = transactionData.paymentMethod;
    }

    if (this.hasRefundPaymentDateTarget) {
      this.refundPaymentDateTarget.textContent = transactionData.paymentDate;
    }

    if (this.hasRefundReferenceNumberTarget) {
      this.refundReferenceNumberTarget.textContent = transactionData.reference || 'N/A';
    }

    // Show form fields
    if (this.hasRefundFormFieldsTarget) {
      this.refundFormFieldsTarget.classList.remove('hidden');
    }

    // Set default refund amount to full refundable amount
    if (this.hasRefundAmountInputTarget) {
      const refundableAmount = parseFloat(transactionData.refundableAmount);
      this.refundAmountInputTarget.value = refundableAmount.toFixed(2);
      this.refundAmountInputTarget.max = refundableAmount.toFixed(2);
    }

    // Enable submit button
    if (this.hasRefundSubmitButtonTarget) {
      this.refundSubmitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed');
      this.refundSubmitButtonTarget.disabled = false;
    }
  }

  // Toggle full refund checkbox
  toggleFullRefund(event) {
    if (!this.hasRefundAmountInputTarget || !this.hasRefundTransactionSelectorTarget) return;

    const isChecked = event.target.checked;
    const selector = this.refundTransactionSelectorTarget;
    const selectedOption = selector.options[selector.selectedIndex];

    if (isChecked && selectedOption) {
      // Set amount to max refundable amount
      const refundableAmount = parseFloat(selectedOption.dataset.refundableAmount);
      this.refundAmountInputTarget.value = refundableAmount.toFixed(2);
      this.refundAmountInputTarget.disabled = true;
    } else {
      // Re-enable the amount input
      this.refundAmountInputTarget.disabled = false;
    }
  }

  // Toggle refund payment method "other" field
  toggleRefundMethod(event) {
    if (!this.hasRefundOtherMethodTarget) return;

    const method = event.target.value;

    if (method === 'other') {
      this.refundOtherMethodTarget.classList.remove('hidden');
    } else {
      this.refundOtherMethodTarget.classList.add('hidden');
    }
  }

  // Process the refund
  processRefund(event) {
    event.preventDefault();

    if (!this.hasRefundTransactionSelectorTarget || !this.hasRefundAmountInputTarget || !this.hasRefundErrorTarget) return;

    // Clear previous errors
    this.refundErrorTarget.textContent = '';

    // Get selected transaction
    const transactionId = this.refundTransactionSelectorTarget.value;
    if (!transactionId) {
      this.refundErrorTarget.textContent = 'Please select a transaction to refund';
      return;
    }

    // Validate refund amount
    const amount = parseFloat(this.refundAmountInputTarget.value);
    const maxAmount = parseFloat(this.refundAmountInputTarget.max);

    if (isNaN(amount) || amount <= 0) {
      this.refundErrorTarget.textContent = 'Please enter a valid refund amount';
      return;
    }

    if (amount > maxAmount) {
      this.refundErrorTarget.textContent = `Refund amount cannot exceed ${maxAmount.toFixed(2)}`;
      return;
    }

    // Get refund method
    const refundMethod = this.refundMethodSelectTarget.value;

    // Get reason
    const reason = document.getElementById('reason').value.trim();
    if (!reason) {
      this.refundErrorTarget.textContent = 'Please provide a reason for the refund';
      return;
    }

    // Disable the button to prevent multiple submissions
    event.target.disabled = true;
    event.target.textContent = 'Processing...';

    // Prepare data for submission
    const formData = new FormData();
    formData.append('transaction_id', transactionId);
    formData.append('amount', amount);
    formData.append('refund_payment_method', refundMethod);

    if (refundMethod === 'other') {
      formData.append('payment_method_other', document.getElementById('refund_payment_method_other').value);
    }

    formData.append('refund_reference_number', document.getElementById('refund_reference_number').value);
    formData.append('reason', reason);

    // Submit refund request
    fetch(`/manage/${this.element.dataset.subdomain}/orders/${this.element.dataset.orderId}/payments/refund`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Reload the page to show the updated payment
          window.location.reload();
        } else if (data.errors) {
          const errorMessages = Array.isArray(data.errors) ? data.errors.join(', ') : data.errors;
          this.refundErrorTarget.textContent = errorMessages;
          event.target.disabled = false;
          event.target.textContent = 'Process Refund';
        }
      })
      .catch(error => {
        console.error('Refund processing error:', error);
        this.refundErrorTarget.textContent = 'Refund processing failed';
        event.target.disabled = false;
        event.target.textContent = 'Process Refund';
      });
  }
}