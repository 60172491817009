import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    // Add entrance animation
    this.element.classList.add("animate-fade-in-right")

    // Auto-dismiss after 5 seconds (5000ms)
    this.autoDismissTimeout = setTimeout(() => {
      this.close()
    }, 5000)
  }

  disconnect() {
    // Clear timeout if component is removed
    if (this.autoDismissTimeout) {
      clearTimeout(this.autoDismissTimeout)
    }
  }

  close() {
    // Add exit animation
    this.element.classList.add("animate-fade-out")

    // Remove the element after animation completes
    setTimeout(() => {
      this.element.remove()
    }, 300)
  }
}