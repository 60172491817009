import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal", "searchInput", "results", "content", "modalTitle", "submitButton",
    "newCustomerModal", "newCustomerName", "newCustomerEmail", "newCustomerPhone", "newCustomerBusinessName", "useBusinessName",
    "billingAddress1", "billingAddress2", "billingCity", "billingState", "billingZip", "billingCountry",
    "billingBusinessName",
    "shippingAddress1", "shippingAddress2", "shippingCity", "shippingState", "shippingZip", "shippingCountry",
    "shippingBusinessName",
    "sameAsBilling", "shippingFields", "addressSelectionModal", "billingAddressSelect", "shippingAddressSelect",
    "messages"
  ]

  connect() {
    // Initialize selected customer and addresses from existing order if present
    const form = this.element.closest('form')
    if (form) {
      const customerId = form.querySelector('input[name="order[customer_id]"]')?.value
      if (customerId) {
        this.selectedCustomer = {
          id: customerId,
          name: form.querySelector('input[name="order[customer_name]"]')?.value || '',
          email: form.querySelector('input[name="order[customer_email]"]')?.value || '',
          contact_phone: form.querySelector('input[name="order[customer_phone]"]')?.value || ''
        }

        // Get business name and use_business_name flag
        this.selectedCustomer.business_name = form.querySelector('input[name="order[business_name]"]')?.value || ''
        this.selectedCustomer.use_business_name = form.querySelector('input[name="order[use_business_name]"]')?.checked || false

        this.selectedBillingAddress = {
          street_address: form.querySelector('input[name="order[billing_address_1]"]')?.value || '',
          street_address_2: form.querySelector('input[name="order[billing_address_2]"]')?.value || '',
          city: form.querySelector('input[name="order[billing_city]"]')?.value || '',
          state: form.querySelector('input[name="order[billing_state]"]')?.value || '',
          postal_code: form.querySelector('input[name="order[billing_zipcode]"]')?.value || '',
          country: form.querySelector('input[name="order[billing_country]"]')?.value || 'United States',
          phone: form.querySelector('input[name="order[billing_phone]"]')?.value || '',
          business_name: form.querySelector('input[name="order[billing_business_name]"]')?.value || ''
        }

        this.selectedShippingAddress = {
          street_address: form.querySelector('input[name="order[shipping_address_1]"]')?.value || '',
          street_address_2: form.querySelector('input[name="order[shipping_address_2]"]')?.value || '',
          city: form.querySelector('input[name="order[shipping_city]"]')?.value || '',
          state: form.querySelector('input[name="order[shipping_state]"]')?.value || '',
          postal_code: form.querySelector('input[name="order[shipping_zipcode]"]')?.value || '',
          country: form.querySelector('input[name="order[shipping_country]"]')?.value || 'United States',
          phone: form.querySelector('input[name="order[shipping_phone]"]')?.value || '',
          business_name: form.querySelector('input[name="order[shipping_business_name]"]')?.value || ''
        }
      } else {
        this.selectedCustomer = null
        this.selectedBillingAddress = null
        this.selectedShippingAddress = null
      }
    }
  }

  openSearchModal() {
    this.modalTarget.classList.remove('hidden')
    this.searchInputTarget.focus()
  }

  closeModal() {
    this.modalTarget.classList.add('hidden')
    this.resultsTarget.innerHTML = ''
    this.searchInputTarget.value = ''
  }

  openNewCustomerModal() {
    if (this.targets.has('modalTitle')) {
      this.modalTitleTarget.textContent = "Add New Customer"
    }
    if (this.targets.has('submitButton')) {
      this.submitButtonTarget.textContent = "Add Customer"
    }
    this.newCustomerModalTarget.classList.remove('hidden')
  }

  closeNewCustomerModal() {
    this.newCustomerModalTarget.classList.add('hidden')
    this.clearCustomerForm()
  }

  openAddressSelectionModal(customer) {
    // Map addresses to consistent format
    const formattedAddresses = customer.addresses.map(addr => {
      const formatted = {
        street_address: addr.address_1 || addr.street_address,
        street_address_2: addr.address_2 || addr.street_address_2,
        city: addr.city,
        state: addr.state,
        postal_code: addr.zip || addr.postal_code,
        country: addr.country || 'United States',
        phone: addr.phone || customer.contact_phone,
        name: customer.name,
        email: customer.email,
        is_default: addr.is_default,
        address_type: addr.address_type,
        business_name: addr.business_name
      }
      return formatted
    })

    // Create options with properly formatted addresses
    const addressOptions = formattedAddresses.map(addr => `
      <option value='${JSON.stringify(addr).replace(/'/g, "&apos;")}'>
        ${addr.street_address}, ${addr.city}, ${addr.state} ${addr.postal_code}
      </option>
    `).join('')

    this.billingAddressSelectTarget.innerHTML = addressOptions
    this.shippingAddressSelectTarget.innerHTML = addressOptions

    // Try to select the appropriate addresses
    const defaultBilling = formattedAddresses.find(a => a.address_type === 'billing' && a.is_default) || formattedAddresses[0]
    const defaultShipping = formattedAddresses.find(a => a.address_type === 'shipping' && a.is_default) || defaultBilling

    if (defaultBilling) {
      this.billingAddressSelectTarget.value = JSON.stringify(defaultBilling).replace(/'/g, "&apos;")
    }
    if (defaultShipping) {
      this.shippingAddressSelectTarget.value = JSON.stringify(defaultShipping).replace(/'/g, "&apos;")
    }

    // Initialize the selected addresses
    this.handleAddressSelection()

    this.addressSelectionModalTarget.classList.remove('hidden')
    this.modalTarget.classList.add('hidden')
  }

  closeAddressModal() {
    this.addressSelectionModalTarget.classList.add('hidden')
  }

  clearCustomer() {
    this.selectedCustomer = null
    this.selectedBillingAddress = null
    this.selectedShippingAddress = null
    this.updateFormFields(null)
    this.contentTarget.innerHTML = this.renderEmptyState()
  }

  handleNewCustomerForm(event) {
    event.preventDefault()

    // Keep existing ID if editing
    const customerId = this.selectedCustomer?.id || ''

    const customer = {
      id: customerId,
      name: this.newCustomerNameTarget.value,
      first_name: this.newCustomerNameTarget.value.split(' ').slice(0, -1).join(' '),
      last_name: this.newCustomerNameTarget.value.split(' ').pop(),
      email: this.newCustomerEmailTarget.value,
      contact_phone: this.newCustomerPhoneTarget.value,
      business_name: this.newCustomerBusinessNameTarget?.value || '',
      use_business_name: this.useBusinessNameTarget?.checked || false
    }

    const billingAddress = {
      street_address: this.billingAddress1Target.value,
      street_address_2: this.billingAddress2Target.value,
      city: this.billingCityTarget.value,
      state: this.billingStateTarget.value,
      postal_code: this.billingZipTarget.value,
      country: this.billingCountryTarget.value || 'United States',
      phone: this.newCustomerPhoneTarget.value,
      business_name: this.billingBusinessNameTarget?.value || '',
      // Include business field to match the model
      business: this.billingBusinessNameTarget?.value || ''
    }

    let shippingAddress = null
    if (!this.sameAsBillingTarget.checked) {
      shippingAddress = {
        street_address: this.shippingAddress1Target.value,
        street_address_2: this.shippingAddress2Target.value,
        city: this.shippingCityTarget.value,
        state: this.shippingStateTarget.value,
        postal_code: this.shippingZipTarget.value,
        country: this.shippingCountryTarget.value || 'United States',
        phone: this.newCustomerPhoneTarget.value,
        business_name: this.shippingBusinessNameTarget?.value || '',
        // Include business field to match the model
        business: this.shippingBusinessNameTarget?.value || ''
      }
    }

    this.selectedCustomer = customer
    this.selectedBillingAddress = billingAddress
    this.selectedShippingAddress = shippingAddress || billingAddress

    this.updateFormFields(customer)
    this.closeNewCustomerModal()
  }

  async search() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(async () => {
      const query = this.searchInputTarget.value.trim()
      if (query.length < 2) {
        this.resultsTarget.innerHTML = ''
        return
      }

      try {
        const response = await fetch(`/api/v1/customers/search?query=${encodeURIComponent(query)}`)
        const data = await response.json()
        this.renderResults(data)
      } catch (error) {
        console.error('Customer search failed:', error)
      }
    }, 300)
  }

  renderResults(customers) {
    const html = customers.length ? `
      <div class="divide-y divide-gray-200 dark:divide-space-800">
        ${customers.map(customer => `
          <div class="p-4 hover:bg-gray-100 dark:hover:bg-space-800 cursor-pointer"
               data-action="click->customer-search#handleCustomerSelection"
               data-customer='${JSON.stringify(customer).replace(/'/g, "&apos;")}'>
            <div class="font-bold text-gray-900 dark:text-white">${customer.name}</div>
            ${customer.business_name ? `<div class="text-sm text-gray-600 dark:text-space-400">Business: ${customer.business_name}</div>` : ''}
            <div class="text-sm text-gray-600 dark:text-space-400">${customer.email}</div>
            ${customer.addresses.length ? `
              <div class="text-sm text-gray-600 dark:text-space-400 mt-1">
                ${customer.addresses[0].address_1}, ${customer.addresses[0].city}, ${customer.addresses[0].state}
              </div>
            ` : ''}
          </div>
        `).join('')}
      </div>
    ` : `
      <div class="p-8 text-center text-gray-600 dark:text-space-400">
        No customers found
      </div>
    `

    this.resultsTarget.innerHTML = html
  }

  handleCustomerSelection(event) {
    const customer = JSON.parse(event.currentTarget.dataset.customer)
    this.selectedCustomer = customer

    // Map address fields to match expected structure
    if (customer.addresses && customer.addresses.length) {
      const mapAddress = (addr) => ({
        street_address: addr.address_1 || addr.street_address,
        street_address_2: addr.address_2 || addr.street_address_2,
        city: addr.city,
        state: addr.state,
        postal_code: addr.zip || addr.postal_code,
        country: addr.country || 'United States',
        phone: addr.phone || customer.contact_phone,
        name: customer.name,
        email: customer.email,
        business_name: addr.business_name || ''
      })

      const addresses = customer.addresses.map(mapAddress)
      const defaultBilling = addresses.find(a => a.address_type === 'billing' && a.is_default) || addresses[0]
      const defaultShipping = addresses.find(a => a.address_type === 'shipping' && a.is_default) || defaultBilling || addresses[0]

      if (customer.addresses.length > 1) {
        this.openAddressSelectionModal(customer)
      } else {
        this.selectedBillingAddress = defaultBilling
        this.selectedShippingAddress = defaultShipping
        this.updateFormFields(customer)
        this.closeModal()
      }
    } else {
      // No addresses - just set customer info
      this.selectedBillingAddress = {
        name: customer.name,
        email: customer.email,
        phone: customer.contact_phone,
        country: 'United States',
        business_name: customer.business_name || ''
      }
      this.selectedShippingAddress = {
        name: customer.name,
        email: customer.email,
        phone: customer.contact_phone,
        country: 'United States',
        business_name: customer.business_name || ''
      }
      this.updateFormFields(customer)
      this.closeModal()
    }
  }

  // Add this new method to ensure form values are synced
  ensureFormValues() {
    const form = this.element.closest('form')
    if (!form) return

    const fields = [
      'billing_address_1', 'billing_city', 'billing_state', 'billing_zipcode',
      'shipping_address_1', 'shipping_city', 'shipping_state', 'shipping_zipcode',
      'business_name', 'use_business_name',
      'billing_business_name', 'shipping_business_name'
    ]

    fields.forEach(field => {
      const input = form.querySelector(`input[name="order[${field}]"]`)
      if (input) {
        input.dispatchEvent(new Event('change', { bubbles: true }))
      }
    })
  }

  handleAddressSelection() {
    try {
      const billingValue = this.billingAddressSelectTarget.value
      const shippingValue = this.shippingAddressSelectTarget.value

      if (billingValue) {
        this.selectedBillingAddress = JSON.parse(billingValue)
      }
      if (shippingValue) {
        this.selectedShippingAddress = JSON.parse(shippingValue)
      }

      // Ensure required fields are present
      if (this.selectedBillingAddress) {
        this.selectedBillingAddress.country = this.selectedBillingAddress.country || 'United States'
      }
      if (this.selectedShippingAddress) {
        this.selectedShippingAddress.country = this.selectedShippingAddress.country || 'United States'
      }
    } catch (error) {
      console.error('Error parsing selected addresses:', error)
    }
  }

  confirmAddressSelection() {
    // Ensure we have the latest selected addresses
    this.handleAddressSelection()

    // If we don't have a shipping address, use billing
    if (!this.selectedShippingAddress && this.selectedBillingAddress) {
      this.selectedShippingAddress = { ...this.selectedBillingAddress }
    }

    this.updateFormFields(this.selectedCustomer)
    this.closeAddressModal()
    this.closeModal()
  }

  toggleShippingAddress() {
    if (this.sameAsBillingTarget.checked) {
      this.shippingFieldsTarget.classList.add('hidden')
      // Copy billing address values to shipping
      const billingFields = {
        'Address1': this.billingAddress1Target.value,
        'Address2': this.billingAddress2Target.value,
        'City': this.billingCityTarget.value,
        'State': this.billingStateTarget.value,
        'Zip': this.billingZipTarget.value,
        'Country': this.billingCountryTarget.value,
        'BusinessName': this.hasTarget('billingBusinessName') ? this.billingBusinessNameTarget.value : ''
      }

      Object.entries(billingFields).forEach(([field, value]) => {
        if (this.hasTarget(`shipping${field}`)) {
          this[`shipping${field}Target`].value = value
        }
      })
    } else {
      this.shippingFieldsTarget.classList.remove('hidden')
    }
  }

  updateFormFields(customer) {
    const form = this.element.closest('form')
    if (!form) return

    const fields = {
      'customer_id': customer?.id || '',
      'customer_name': customer?.name || '',
      'customer_email': customer?.email || '',
      'customer_phone': customer?.contact_phone || '',
      'business_name': customer?.business_name || '',
      'use_business_name': customer?.use_business_name || false,

      'billing_name': this.selectedBillingAddress?.name || customer?.name || '',
      'billing_email': this.selectedBillingAddress?.email || customer?.email || '',
      'billing_phone': this.selectedBillingAddress?.phone || customer?.contact_phone || '',
      'billing_address_1': this.selectedBillingAddress?.street_address || '',
      'billing_address_2': this.selectedBillingAddress?.street_address_2 || '',
      'billing_city': this.selectedBillingAddress?.city || '',
      'billing_state': this.selectedBillingAddress?.state || '',
      'billing_zipcode': this.selectedBillingAddress?.postal_code || '',
      'billing_country': this.selectedBillingAddress?.country || 'United States',
      'billing_business_name': this.selectedBillingAddress?.business_name ||
        this.selectedBillingAddress?.business ||
        customer?.business_name || '',

      'shipping_name': this.selectedShippingAddress?.name || customer?.name || '',
      'shipping_email': this.selectedShippingAddress?.email || customer?.email || '',
      'shipping_phone': this.selectedShippingAddress?.phone || customer?.contact_phone || '',
      'shipping_address_1': this.selectedShippingAddress?.street_address || '',
      'shipping_address_2': this.selectedShippingAddress?.street_address_2 || '',
      'shipping_city': this.selectedShippingAddress?.city || '',
      'shipping_state': this.selectedShippingAddress?.state || '',
      'shipping_zipcode': this.selectedShippingAddress?.postal_code || '',
      'shipping_country': this.selectedShippingAddress?.country || 'United States',
      'shipping_business_name': this.selectedShippingAddress?.business_name ||
        this.selectedShippingAddress?.business ||
        customer?.business_name || ''
    }

    // Set all form field values
    Object.entries(fields).forEach(([field, value]) => {
      if (field === 'use_business_name') {
        const checkbox = form.querySelector(`input[name="order[${field}]"]`)
        if (checkbox) checkbox.checked = value
      } else {
        const input = form.querySelector(`input[name="order[${field}]"]`)
        if (input) input.value = value
      }
    })

    // Update the display content
    this.contentTarget.innerHTML = customer ? this.renderCustomerInfo() : this.renderEmptyState()

    // Ensure form values are synced
    this.ensureFormValues()
  }

  renderCustomerInfo() {
    const customer = this.selectedCustomer
    if (!customer) return this.renderEmptyState()

    const billingAddress = this.selectedBillingAddress
    const shippingAddress = this.selectedShippingAddress
    const sameAddress = JSON.stringify(billingAddress) === JSON.stringify(shippingAddress)

    return `
      <div class="space-y-4">
        <div class="flex justify-between items-start">
          <div>
            ${customer.use_business_name && customer.business_name ? `
              <h3 class="font-bold text-lg text-gray-900 dark:text-white">${customer.business_name}</h3>
              <div class="text-sm text-gray-600 dark:text-space-400">Contact: ${customer.name}</div>
            ` : `
              <h3 class="font-bold text-lg text-gray-900 dark:text-white">${customer.name}</h3>
              ${customer.business_name ? `<div class="text-sm text-gray-600 dark:text-space-400">Business: ${customer.business_name}</div>` : ''}
            `}
            
            ${customer.credit_limit > 0 ?
        `<small class="text-green-500 dark:text-green-400">Credit Limit: $${parseFloat(customer.credit_limit).toLocaleString()}</small>`
        : ''}
            <div class="py-2">
              <a href="https://www.truepeoplesearch.com/results?name=${encodeURIComponent(customer.name)}&citystatezip=${encodeURIComponent(billingAddress?.postal_code || shippingAddress?.postal_code || '')}" 
                 target="_blank" 
                 class="text-blue-500 dark:text-blue-400 text-sm hover:underline">
                TPS Lookup
              </a>
            </div>
            <div class="text-gray-600 dark:text-space-400">${customer.email}</div>
            ${customer.contact_phone ? `<div class="text-gray-600 dark:text-space-400">${customer.contact_phone}</div>` : ''}
          </div>
          <div class="space-x-2">
            <button type="button" 
                    class="text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300"
                    data-action="click->customer-search#openSearchModal">
              Change
            </button>
            <button type="button" 
                    class="text-yellow-600 dark:text-yellow-400 hover:text-yellow-500 dark:hover:text-yellow-300"
                    data-action="click->customer-search#editCustomer">
              Edit
            </button>
            <button type="button" 
                    class="text-red-600 dark:text-red-400 hover:text-red-500 dark:hover:text-red-300"
                    data-action="click->customer-search#clearCustomer">
              Clear
            </button>
          </div>
        </div>

        ${billingAddress ? `
          <div class="bg-gray-100 dark:bg-space-800 p-3 rounded-lg">
            <div class="text-sm font-bold text-gray-600 dark:text-space-400 mb-1">Billing Address</div>
            <div class="text-sm text-gray-800 dark:text-gray-200">
              ${billingAddress.business_name ? `<strong>${billingAddress.business_name}</strong><br>` : ''}
              ${billingAddress.street_address}<br>
              ${billingAddress.street_address_2 ? `${billingAddress.street_address_2}<br>` : ''}
              ${billingAddress.city}, ${billingAddress.state} ${billingAddress.postal_code}
              ${billingAddress.phone ? `<br>Phone: ${billingAddress.phone}` : ''}
            </div>
          </div>
        ` : ''}

        ${shippingAddress && !sameAddress ? `
          <div class="bg-gray-100 dark:bg-space-800 p-3 rounded-lg">
            <div class="text-sm font-bold text-gray-600 dark:text-space-400 mb-1">Shipping Address</div>
            <div class="text-sm text-gray-800 dark:text-gray-200">
              ${shippingAddress.business_name ? `<strong>${shippingAddress.business_name}</strong><br>` : ''}
              ${shippingAddress.street_address}<br>
              ${shippingAddress.street_address_2 ? `${shippingAddress.street_address_2}<br>` : ''}
              ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postal_code}
              ${shippingAddress.phone ? `<br>Phone: ${shippingAddress.phone}` : ''}
            </div>
          </div>
        ` : ''}
        
        ${sameAddress ? `
          <div class="text-sm text-gray-600 dark:text-space-400 italic">
            Shipping address same as billing
          </div>
        ` : ''}
      </div>
    `
  }

  renderEmptyState() {
    return `
      <div class="text-center">
        <h3 class="text-lg font-medium mb-4 text-gray-900 dark:text-white">No Customer Selected</h3>
        <div class="flex flex-col gap-4">
          <button type="button"
                  class="w-full px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-md"
                  data-action="click->customer-search#openSearchModal">
            Search Existing Customer
          </button>
          <button type="button"
                  class="w-full px-4 py-2 bg-green-600 hover:bg-green-500 text-white rounded-md"
                  data-action="click->customer-search#openNewCustomerModal">
            Add New Customer
          </button>
        </div>
      </div>
    `
  }

  editCustomer() {
    if (!this.selectedCustomer) {
      console.warn('No customer selected for editing')
      return
    }

    this.modalTitleTarget.textContent = "Edit Customer"
    this.submitButtonTarget.textContent = "Update Customer"

    // Pre-fill the form with current customer data
    this.newCustomerNameTarget.value = this.selectedCustomer.name
    this.newCustomerEmailTarget.value = this.selectedCustomer.email
    this.newCustomerPhoneTarget.value = this.selectedCustomer.contact_phone

    // Uncomment and fix business name fields
    if (this.targets.has('newCustomerBusinessName')) {
      this.newCustomerBusinessNameTarget.value = this.selectedCustomer.business_name || ''
    }
    if (this.targets.has('useBusinessName')) {
      this.useBusinessNameTarget.checked = this.selectedCustomer.use_business_name || false
    }

    // Fill billing address if exists
    if (this.selectedBillingAddress) {
      this.billingAddress1Target.value = this.selectedBillingAddress.street_address || ''
      this.billingAddress2Target.value = this.selectedBillingAddress.street_address_2 || ''
      this.billingCityTarget.value = this.selectedBillingAddress.city || ''
      this.billingStateTarget.value = this.selectedBillingAddress.state || ''
      this.billingZipTarget.value = this.selectedBillingAddress.postal_code || ''
      this.billingCountryTarget.value = this.selectedBillingAddress.country || 'United States'

      if (this.targets.has('billingBusinessName')) {
        this.billingBusinessNameTarget.value = this.selectedBillingAddress.business_name ||
          this.selectedBillingAddress.business || ''
      }
    }

    // Fill shipping address if exists and different from billing
    if (this.selectedShippingAddress) {
      const sameAsBilling = JSON.stringify(this.selectedBillingAddress) === JSON.stringify(this.selectedShippingAddress)
      this.sameAsBillingTarget.checked = sameAsBilling
      this.shippingFieldsTarget.classList.toggle('hidden', sameAsBilling)

      if (!sameAsBilling) {
        this.shippingAddress1Target.value = this.selectedShippingAddress.street_address || ''
        this.shippingAddress2Target.value = this.selectedShippingAddress.street_address_2 || ''
        this.shippingCityTarget.value = this.selectedShippingAddress.city || ''
        this.shippingStateTarget.value = this.selectedShippingAddress.state || ''
        this.shippingZipTarget.value = this.selectedShippingAddress.postal_code || ''
        this.shippingCountryTarget.value = this.selectedShippingAddress.country || 'United States'

        if (this.targets.has('shippingBusinessName')) {
          this.shippingBusinessNameTarget.value = this.selectedShippingAddress.business_name ||
            this.selectedShippingAddress.business || ''
        }
      }
    }

    // Open the modal with pre-filled data
    this.newCustomerModalTarget.classList.remove('hidden')
  }

  clearCustomerForm() {
    const fields = [
      'newCustomerName', 'newCustomerEmail', 'newCustomerPhone', 'newCustomerBusinessName',
      'billingAddress1', 'billingAddress2', 'billingCity', 'billingState', 'billingZip', 'billingBusinessName',
      'shippingAddress1', 'shippingAddress2', 'shippingCity', 'shippingState', 'shippingZip', 'shippingBusinessName'
    ]

    fields.forEach(field => {
      if (this.targets.has(field)) {
        this[`${field}Target`].value = ''
      }
    });

    if (this.targets.has('useBusinessName')) {
      this.useBusinessNameTarget.checked = false
    }

    if (this.targets.has('billingCountry')) {
      this.billingCountryTarget.value = 'United States'
    }
    if (this.targets.has('shippingCountry')) {
      this.shippingCountryTarget.value = 'United States'
    }

    if (this.targets.has('sameAsBilling')) {
      this.sameAsBillingTarget.checked = false
    }
    if (this.targets.has('shippingFields')) {
      this.shippingFieldsTarget.classList.remove('hidden')
    }
  }
}