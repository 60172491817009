import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dialog", "backdrop", "panel", "filePreview", "fileInput", "errorMessage",
    "otherMethod", "termsSection", "customTerms", "termsEndDate", "creditSection",
    "tabButton", "tabContent", "partialOptions", "amountInput", "percentageInput", "calculatedAmount",
    "partialOptionsCard", "cardAmountInput", "percentageInputCard", "calculatedAmountCard",
    "partialOptionsLink", "linkAmountInput", "percentageInputLink", "calculatedAmountLink",
    "cardElement", "cardErrors", "linkResult", "linkUrl", "error", "dialogTitle",

    // Refund targets
    "refundTransactionSelector", "refundTransactionDetails", "refundFormFields",
    "refundOriginalAmount", "refundPreviouslyRefunded", "refundAvailableAmount",
    "refundPaymentMethod", "refundPaymentDate", "refundReferenceNumber",
    "refundAmountInput", "fullRefundCheckbox", "refundMethodSelect",
    "refundOtherMethod", "refundSubmitButton", "refundError"
  ]

  static values = {
    key: String,
    balanceDue: Number
  }

  connect() {
    console.log("Payments controller connected");

    // Store the current payment method selection
    this.previousPaymentMethod = 'cash';

    // Safely attempt to initialize file upload handling
    try {
      if (this.hasFileInputTarget && this.fileInputTarget) {
        this.initializeFileHandling()
      }
    } catch (error) {
      console.error("Error initializing file handling:", error)
    }

    // Initialize Stripe if it's available
    if (typeof Stripe !== 'undefined' && this.hasCardElementTarget) {
      this.initializeStripe()
    }

    // Initialize the end date for terms if applicable
    if (this.hasTermsEndDateTarget) {
      this.updateEndDate()
    }

    // Disable refund button initially (until transaction selected)
    if (this.hasRefundSubmitButtonTarget) {
      this.refundSubmitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      this.refundSubmitButtonTarget.disabled = true
    }

    // Hide refund form fields initially
    if (this.hasRefundFormFieldsTarget) {
      this.refundFormFieldsTarget.classList.add('hidden')
    }
  }

  // Simplified file handling that will work regardless of HTML structure
  initializeFileHandling() {
    // Add change listener to the file input
    this.fileInputTarget.addEventListener('change', (event) => {
      this.previewFiles(event.target.files)
    })
  }

  openDialog(event) {
    console.log("Opening payment dialog");
    try {
      if (this.hasDialogTarget) {
        this.dialogTarget.classList.remove("hidden")
        document.body.classList.add("overflow-hidden")
      } else {
        // Try a direct selector as fallback
        const dialog = document.querySelector('[data-payment-dialog-target="dialog"]')
        if (dialog) {
          dialog.classList.remove("hidden")
          document.body.classList.add("overflow-hidden")
        } else {
          console.error("Cannot find dialog element");
        }
      }

      // Reset to manual tab by default
      if (this.hasTabButtonTargets && this.hasTabContentTargets) {
        this.switchToTab('manual');
      }

      // Update dialog title if available
      if (this.hasDialogTitleTarget) {
        this.dialogTitleTarget.textContent = "Record Payment";
      }
    } catch (error) {
      console.error("Error opening dialog:", error)
    }
  }

  // New method for opening refund dialog
  openRefundDialog(event) {
    console.log("Opening refund dialog");
    try {
      // Find payment dialog
      let dialog;
      if (this.hasDialogTarget) {
        dialog = this.dialogTarget;
      } else {
        dialog = document.querySelector('[data-payment-dialog-target="dialog"]');
      }

      if (!dialog) {
        console.error("Cannot find payment dialog element");
        return;
      }

      // Show the dialog
      dialog.classList.remove("hidden");
      document.body.classList.add("overflow-hidden");

      // Update dialog title if available
      const dialogTitle = dialog.querySelector('[data-payment-dialog-target="dialogTitle"]');
      if (dialogTitle) {
        dialogTitle.textContent = "Process Refund";
      }

      // Get the transaction data from the button
      const transactionData = event.currentTarget.dataset;
      console.log("Transaction data:", transactionData);

      // Find tab buttons and switch to refund tab
      const refundTab = dialog.querySelector('[data-payments-target="tabButton"][data-tab="refund"]');
      if (refundTab) {
        // Trigger a click on the refund tab button
        refundTab.click();
      } else {
        console.error("Cannot find refund tab button");
      }

      // Set the transaction in the selector if it exists
      // Set the transaction in the selector if it exists
      const selector = dialog.querySelector('[data-payments-target="refundTransactionSelector"]');
      if (selector && transactionData.transactionId) {
        selector.value = transactionData.transactionId;

        // Manually trigger change event to load transaction details
        const event = new Event('change');
        selector.dispatchEvent(event);
      } else {
        console.error("Cannot find transaction selector or transaction ID");
      }
    } catch (error) {
      console.error("Error opening refund dialog:", error);
    }
  }

  // Helper to manually populate refund details
  populateRefundDetails(data, dialog) {
    try {
      // Show transaction details section
      const detailsSection = dialog.querySelector('[data-payments-target="refundTransactionDetails"]');
      if (detailsSection) {
        detailsSection.classList.remove('hidden');
      }

      // Populate details
      const originalAmount = dialog.querySelector('[data-payments-target="refundOriginalAmount"]');
      if (originalAmount) {
        originalAmount.textContent = data.amount;
      }

      const refundedAmount = dialog.querySelector('[data-payments-target="refundPreviouslyRefunded"]');
      if (refundedAmount) {
        refundedAmount.textContent = data.refundedAmount || '$0.00';
      }

      const availableAmount = dialog.querySelector('[data-payments-target="refundAvailableAmount"]');
      if (availableAmount) {
        availableAmount.textContent = data.refundableAmount;
      }

      const paymentMethod = dialog.querySelector('[data-payments-target="refundPaymentMethod"]');
      if (paymentMethod) {
        paymentMethod.textContent = data.paymentMethod;
      }

      const paymentDate = dialog.querySelector('[data-payments-target="refundPaymentDate"]');
      if (paymentDate) {
        paymentDate.textContent = data.paymentDate;
      }

      const reference = dialog.querySelector('[data-payments-target="refundReferenceNumber"]');
      if (reference) {
        reference.textContent = data.reference || 'N/A';
      }

      // Show form fields
      const formFields = dialog.querySelector('[data-payments-target="refundFormFields"]');
      if (formFields) {
        formFields.classList.remove('hidden');
      }

      // Set default refund amount to full refundable amount
      const amountInput = dialog.querySelector('[data-payments-target="refundAmountInput"]');
      if (amountInput) {
        const refundableAmount = parseFloat(data.refundableAmount.replace(/[^0-9.]/g, ''));
        amountInput.value = refundableAmount.toFixed(2);
        amountInput.max = refundableAmount.toFixed(2);
      }

      // Enable submit button
      const submitButton = dialog.querySelector('[data-payments-target="refundSubmitButton"]');
      if (submitButton) {
        submitButton.classList.remove('opacity-50', 'cursor-not-allowed');
        submitButton.disabled = false;
      }
    } catch (error) {
      console.error("Error populating refund details:", error);
    }
  }

  closeDialog(event) {
    try {
      if (!event || (this.hasBackdropTarget && event.target === this.backdropTarget)) {
        if (this.hasDialogTarget) {
          this.dialogTarget.classList.add("hidden")
          document.body.classList.remove("overflow-hidden")
          this.resetFilePreview()
        }
      }
    } catch (error) {
      console.error("Error closing dialog:", error)
    }
  }

  closeWithButton() {
    try {
      if (this.hasDialogTarget) {
        this.dialogTarget.classList.add("hidden")
        document.body.classList.remove("overflow-hidden")
        this.resetFilePreview()
      }
    } catch (error) {
      console.error("Error closing dialog with button:", error)
    }
  }

  resetFilePreview() {
    try {
      // Clear file preview when closing dialog
      if (this.hasFilePreviewTarget) {
        this.filePreviewTarget.innerHTML = ''
      }

      // Reset the file input
      if (this.hasFileInputTarget) {
        this.fileInputTarget.value = ''
      }
    } catch (error) {
      console.error("Error resetting file preview:", error)
    }
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      this.closeDialog()
    }
  }

  preventDialogClose(event) {
    event.stopPropagation()
  }

  // Helper method to programmatically switch tabs
  switchToTab(tabName) {
    try {
      // Update active tab button
      this.tabButtonTargets.forEach(button => {
        if (button.dataset.tab === tabName) {
          button.classList.add('bg-blue-600', 'text-white')
          button.classList.remove('text-gray-600', 'dark:text-space-400')
        } else {
          button.classList.remove('bg-blue-600', 'text-white')
          button.classList.add('text-gray-600', 'dark:text-space-400')
        }
      })

      // Show selected tab content, hide others
      this.tabContentTargets.forEach(content => {
        if (content.dataset.tab === tabName) {
          content.classList.remove('hidden')
        } else {
          content.classList.add('hidden')
        }
      })
    } catch (error) {
      console.error(`Error switching to tab ${tabName}:`, error);
    }
  }

  // Merged payment method handler
  togglePaymentMethod(event) {
    const method = event.target.value;

    // Save the current selection for potential rollback
    const previousMethod = this.previousPaymentMethod || 'cash';
    this.previousPaymentMethod = method;

    // Handle 'other' method - show/hide the other method input field
    if (this.hasOtherMethodTarget) {
      if (method === 'other') {
        this.otherMethodTarget.classList.remove('hidden');
      } else {
        this.otherMethodTarget.classList.add('hidden');
      }
    }

    // Handle net terms section
    if (this.hasTermsSectionTarget) {
      if (method === 'net_terms') {
        // Check if net terms is available (not disabled in select)
        const netTermsSelect = document.getElementById('payment_method');
        if (netTermsSelect) {
          const netTermsOption = Array.from(netTermsSelect.options).find(option => option.value === 'net_terms');
          if (netTermsOption && netTermsOption.disabled) {
            // If disabled, show a message and don't open terms section
            alert("Net Terms requires a customer to be assigned to this order.");
            // Reset selection to previous option
            event.target.value = previousMethod;
            this.previousPaymentMethod = previousMethod;
            return;
          }
        }

        // Show the terms section
        this.termsSectionTarget.classList.remove('hidden');

        // Initialize end date
        if (this.hasTermsEndDateTarget) {
          this.updateEndDate();
        }
      } else {
        // Hide the terms section for all other payment methods
        this.termsSectionTarget.classList.add('hidden');
      }
    }
  }

  // Update end date based on terms and start date
  updateEndDate() {
    if (!this.hasTermsEndDateTarget) return;

    // Get terms days
    let termsDays;
    const termsSelect = document.getElementById('terms_days');
    const customTermsInput = document.getElementById('custom_terms_days');

    if (!termsSelect) return;

    if (termsSelect.value === 'custom') {
      termsDays = customTermsInput && customTermsInput.value ? parseInt(customTermsInput.value) : 0;
    } else {
      termsDays = parseInt(termsSelect.value);
    }

    // Get start date
    const startDateInput = document.getElementById('terms_start_date');
    if (!startDateInput) return;

    const startDateString = startDateInput.value;
    if (!startDateString || isNaN(termsDays)) {
      this.termsEndDateTarget.textContent = 'Select valid terms and date';
      return;
    }

    // Calculate end date
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + termsDays);

    // Format date as YYYY-MM-DD
    const formattedDate = endDate.toISOString().split('T')[0];
    this.termsEndDateTarget.textContent = formattedDate;
  }

  // Toggle custom terms input
  toggleCustomTerms(event) {
    if (!this.hasCustomTermsTarget) return;

    const termsValue = event.target.value;

    if (termsValue === 'custom') {
      this.customTermsTarget.classList.remove('hidden');
    } else {
      this.customTermsTarget.classList.add('hidden');
      this.updateEndDate(); // Update end date when preset terms selected
    }
  }

  // Toggle account credit section
  toggleApplyCredit(event) {
    if (!this.hasCreditSectionTarget) return;

    if (event.target.checked) {
      this.creditSectionTarget.classList.remove('hidden');
    } else {
      this.creditSectionTarget.classList.add('hidden');
    }
  }

  // Payment methods tab handling
  switchTab(event) {
    const tab = event.currentTarget.dataset.tab
    this.switchToTab(tab);
  }

  // Payment amount toggle methods
  togglePaymentType(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsTarget) {
      if (isPartial) {
        this.partialOptionsTarget.classList.remove('hidden')
        this.updateAmountFromPercentage()
      } else {
        this.partialOptionsTarget.classList.add('hidden')
        if (this.hasAmountInputTarget) {
          this.amountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  togglePaymentTypeCard(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsCardTarget) {
      if (isPartial) {
        this.partialOptionsCardTarget.classList.remove('hidden')
        this.updateAmountFromPercentageCard()
      } else {
        this.partialOptionsCardTarget.classList.add('hidden')
        if (this.hasCardAmountInputTarget) {
          this.cardAmountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  togglePaymentTypeLink(event) {
    const isPartial = event.target.value === 'partial'
    if (this.hasPartialOptionsLinkTarget) {
      if (isPartial) {
        this.partialOptionsLinkTarget.classList.remove('hidden')
        this.updateAmountFromPercentageLink()
      } else {
        this.partialOptionsLinkTarget.classList.add('hidden')
        if (this.hasLinkAmountInputTarget) {
          this.linkAmountInputTarget.value = this.balanceDueValue
        }
      }
    }
  }

  // Percentage to amount calculation methods
  updateAmountFromPercentage() {
    if (this.hasPercentageInputTarget && this.hasAmountInputTarget && this.hasCalculatedAmountTarget) {
      const percentage = parseFloat(this.percentageInputTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.amountInputTarget.value = amount
      this.calculatedAmountTarget.textContent = `$${amount}`
    }
  }

  updateAmountFromPercentageCard() {
    if (this.hasPercentageInputCardTarget && this.hasCardAmountInputTarget && this.hasCalculatedAmountCardTarget) {
      const percentage = parseFloat(this.percentageInputCardTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.cardAmountInputTarget.value = amount
      this.calculatedAmountCardTarget.textContent = `$${amount}`
    }
  }

  updateAmountFromPercentageLink() {
    if (this.hasPercentageInputLinkTarget && this.hasLinkAmountInputTarget && this.hasCalculatedAmountLinkTarget) {
      const percentage = parseFloat(this.percentageInputLinkTarget.value) || 0
      const amount = (this.balanceDueValue * percentage / 100).toFixed(2)
      this.linkAmountInputTarget.value = amount
      this.calculatedAmountLinkTarget.textContent = `$${amount}`
    }
  }

  // The rest of your existing methods for file handling, validation, etc.
  // ...

  // Refund-specific methods

  // Load transaction details for refund
  loadRefundTransaction() {
    console.log("Loading refund transaction details");
    if (!this.hasRefundTransactionSelectorTarget || !this.hasRefundTransactionDetailsTarget) return;

    const selector = this.refundTransactionSelectorTarget;
    const selectedOption = selector.options[selector.selectedIndex];

    if (!selectedOption || !selectedOption.value) {
      // No transaction selected, hide details and form
      this.refundTransactionDetailsTarget.classList.add('hidden');

      if (this.hasRefundFormFieldsTarget) {
        this.refundFormFieldsTarget.classList.add('hidden');
      }

      if (this.hasRefundSubmitButtonTarget) {
        this.refundSubmitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed');
        this.refundSubmitButtonTarget.disabled = true;
      }

      return;
    }

    // Transaction selected, populate details
    const transactionData = selectedOption.dataset;

    // Show details section
    this.refundTransactionDetailsTarget.classList.remove('hidden');

    // Populate transaction details
    if (this.hasRefundOriginalAmountTarget) {
      this.refundOriginalAmountTarget.textContent = `$${parseFloat(transactionData.amount).toFixed(2)}`;
    }

    if (this.hasRefundPreviouslyRefundedTarget) {
      this.refundPreviouslyRefundedTarget.textContent = `$${parseFloat(transactionData.refundedAmount || 0).toFixed(2)}`;
    }

    if (this.hasRefundAvailableAmountTarget) {
      this.refundAvailableAmountTarget.textContent = `$${parseFloat(transactionData.refundableAmount).toFixed(2)}`;
    }

    if (this.hasRefundPaymentMethodTarget) {
      this.refundPaymentMethodTarget.textContent = transactionData.paymentMethod;
    }

    if (this.hasRefundPaymentDateTarget) {
      this.refundPaymentDateTarget.textContent = transactionData.paymentDate;
    }

    if (this.hasRefundReferenceNumberTarget) {
      this.refundReferenceNumberTarget.textContent = transactionData.reference || 'N/A';
    }

    // Show form fields
    if (this.hasRefundFormFieldsTarget) {
      this.refundFormFieldsTarget.classList.remove('hidden');
    }

    // Set refund amount to 0 initially and let user choose
    if (this.hasRefundAmountInputTarget) {
      const refundableAmount = parseFloat(transactionData.refundableAmount);
      this.refundAmountInputTarget.value = "0.00";
      this.refundAmountInputTarget.max = refundableAmount.toFixed(2);

      // Make sure checkbox is unchecked initially
      if (this.hasFullRefundCheckboxTarget) {
        this.fullRefundCheckboxTarget.checked = false;
        this.refundAmountInputTarget.disabled = false;
      }
    }

    // Enable submit button
    if (this.hasRefundSubmitButtonTarget) {
      this.refundSubmitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed');
      this.refundSubmitButtonTarget.disabled = false;
    }
  }

  // Toggle full refund checkbox
  toggleFullRefund(event) {
    if (!this.hasRefundAmountInputTarget || !this.hasRefundTransactionSelectorTarget) return;

    const isChecked = event.target.checked;
    const selector = this.refundTransactionSelectorTarget;
    const selectedOption = selector.options[selector.selectedIndex];

    if (isChecked && selectedOption) {
      // Set amount to max refundable amount
      const refundableAmount = parseFloat(selectedOption.dataset.refundableAmount);
      this.refundAmountInputTarget.value = refundableAmount.toFixed(2);
      this.refundAmountInputTarget.disabled = true;
    } else {
      // Re-enable the amount input but keep its value intact
      this.refundAmountInputTarget.disabled = false;
    }
  }

  // Toggle refund payment method "other" field
  toggleRefundMethod(event) {
    if (!this.hasRefundOtherMethodTarget) return;

    const method = event.target.value;

    if (method === 'other') {
      this.refundOtherMethodTarget.classList.remove('hidden');
    } else {
      this.refundOtherMethodTarget.classList.add('hidden');
    }
  }

  // Process the refund
  processRefund(event) {
    event.preventDefault();
    console.log("Processing refund");

    // Find the dialog element which contains the data attributes
    const dialog = document.querySelector('[data-payment-dialog-target="dialog"]');

    if (!dialog) {
      console.error("Could not find payment dialog");
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Configuration error: Dialog not found';
      }
      return;
    }

    // Get subdomain and order ID from the dialog data attributes
    const subdomain = dialog.dataset.subdomain;
    const orderId = dialog.dataset.orderId;

    console.log("Data attributes from dialog:", { subdomain, orderId });

    if (!subdomain || !orderId) {
      console.error("Missing subdomain or order ID", { subdomain, orderId });
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Configuration error: Missing organization or order information';
      }
      return;
    }

    // Construct the refund URL directly from the data attributes
    const refundUrl = `/ox/${subdomain}/orders/${orderId}/payments/refund`;
    console.log("Refund URL:", refundUrl);

    // Get selected transaction
    const transactionId = this.refundTransactionSelectorTarget.value;
    if (!transactionId) {
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Please select a transaction to refund';
      }
      return;
    }

    // Validate refund amount
    const amount = parseFloat(this.refundAmountInputTarget.value);
    const maxAmount = parseFloat(this.refundAmountInputTarget.max);

    if (isNaN(amount) || amount <= 0) {
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Please enter a valid refund amount';
      }
      return;
    }

    if (amount > maxAmount) {
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = `Refund amount cannot exceed ${maxAmount.toFixed(2)}`;
      }
      return;
    }

    // Get refund method
    const refundMethod = this.hasRefundMethodSelectTarget ?
      this.refundMethodSelectTarget.value : 'same';

    console.log("Refund method:", refundMethod);

    if (!refundMethod) {
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Please select a refund method';
      }
      return;
    }

    // Get reason
    const reason = document.getElementById('reason').value.trim();
    if (!reason) {
      if (this.hasRefundErrorTarget) {
        this.refundErrorTarget.textContent = 'Please provide a reason for the refund';
      }
      return;
    }

    // Disable the button to prevent multiple submissions
    event.target.disabled = true;
    event.target.textContent = 'Processing...';

    // Log all form data for debugging
    console.log("Refund form data:", {
      transaction_id: transactionId,
      amount: amount,
      refund_payment_method: refundMethod,
      payment_method: refundMethod,
      refund_reference_number: document.getElementById('refund_reference_number').value,
      reason: reason
    });

    // Get CSRF token
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    // Submit refund request with JSON format preference
    fetch(refundUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: new URLSearchParams({
        'transaction_id': transactionId,
        'amount': amount,
        'refund_payment_method': refundMethod,
        'payment_method': refundMethod,
        'refund_reference_number': document.getElementById('refund_reference_number').value || '',
        'reason': reason
      })
    })
      .then(response => {
        if (!response.ok) {
          console.error("Response not OK:", response.status, response.statusText);
          return response.text().then(text => {
            try {
              return JSON.parse(text);
            } catch (e) {
              throw new Error(`Server error: ${response.status} ${text}`);
            }
          });
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          // If not JSON, assume success and reload
          console.log("Non-JSON success response");
          window.location.reload();
          return { success: true };
        }
      })
      .then(data => {
        console.log("Refund response:", data);

        if (data.success) {
          // Success - reload the page
          window.location.reload();
        } else if (data.errors) {
          // Show error message
          const errorMessages = Array.isArray(data.errors) ? data.errors.join(', ') : data.errors;
          if (this.hasRefundErrorTarget) {
            this.refundErrorTarget.textContent = errorMessages;
          }
          event.target.disabled = false;
          event.target.textContent = 'Process Refund';
        } else {
          // Unknown response format but not an error
          window.location.reload();
        }
      })
      .catch(error => {
        console.error('Refund processing error:', error);
        if (this.hasRefundErrorTarget) {
          this.refundErrorTarget.textContent = 'Refund processing failed: ' + error.message;
        }
        event.target.disabled = false;
        event.target.textContent = 'Process Refund';
      });
  }
}