//order_item_controller
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "quantity", "price", "cost", "costDisplay", "total", "profit", "marginInfo", "stockInfo",
    "discountType", "discountAmount", "warehouseInfo", "sku", "isCustom", "lcnn", "inStock", "onHold", "profitMargin",
    "customMargin", "amountShipped", "displayQuantity", "builderCostDisplay"
  ]

  connect() {
    this.updateTotal()
    this.renderStockInfo()
    const sku = this.element.querySelector('input[name*="[sku]"]')?.value
    console.log("Order item connected with SKU:", sku)
    this.constrainInputWidths()
    this.updateDisplayQuantity()
  }

  constrainInputWidths() {
    // Add min-width to prevent inputs from stretching the container
    const inputFields = this.element.querySelectorAll('input[type="number"], select')
    inputFields.forEach(field => {
      if (!field.style.minWidth) {
        field.style.minWidth = '0'
      }
    })
  }

  incrementQuantity() {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1
    this.updateTotal()
    this.updateDisplayQuantity()
  }

  decrementQuantity() {
    const newValue = parseInt(this.quantityTarget.value) - 1
    if (newValue >= 1) {
      this.quantityTarget.value = newValue
      this.updateTotal()
      this.updateDisplayQuantity()
    }
  }

  applyMargin(e) {
    const margin = parseFloat(e.currentTarget.dataset.margin)
    this.applyMarginValue(margin)
  }

  applyCustomMargin() {
    if (this.hasCustomMarginTarget) {
      const marginValue = parseFloat(this.customMarginTarget.value)
      if (!isNaN(marginValue)) {
        this.applyMarginValue(marginValue)
      }
    }
  }

  applyMarginValue(margin) {
    const effectiveCost = this.getEffectiveCost()
    if (!effectiveCost) {
      console.error('No cost found for margin calculation')
      return
    }
    const newPrice = effectiveCost * (1 + (margin / 100))
    this.priceTarget.value = newPrice.toFixed(2)
    this.updateTotal()
  }

  setPrice(event) {
    const newPrice = parseFloat(event.currentTarget.dataset.price)
    this.costTarget.value = newPrice.toFixed(2)
    this.updateTotal()
  }

  updateDisplayQuantity() {
    if (this.hasDisplayQuantityTarget && this.hasQuantityTarget) {
      this.displayQuantityTarget.textContent = this.quantityTarget.value
    }
  }

  // Get the effective cost based on order type
  getEffectiveCost() {
    // Check if this is a builder order
    const isBuilderOrder = this.isBuilderOrder()

    // Use builder cost for builder orders, otherwise use regular cost
    if (isBuilderOrder && this.hasBuilderCostDisplayTarget) {
      return parseFloat(this.builderCostDisplayTarget.value) || 0
    } else {
      return parseFloat(this.costTarget.value) || 0
    }
  }

  // Check if we're in a builder order
  isBuilderOrder() {
    const orderTypeSelect = document.querySelector('select[name="order[order_type]"]')
    return orderTypeSelect && orderTypeSelect.value === 'builder_order'
  }

  // Update builder cost value when display field changes
  updateBuilderCost(event) {
    const builderCostValue = parseFloat(event.target.value) || 0
    const builderCostField = this.element.querySelector('input[name*="[builder_cost]"]')
    if (builderCostField) {
      builderCostField.value = builderCostValue
    }
    this.updateTotal()
  }

  // Update cost value when display field changes
  updateCost(event) {
    const costValue = parseFloat(event.target.value) || 0
    if (this.hasCostTarget) {
      this.costTarget.value = costValue
    }
    this.updateTotal()
  }

  updateTotal() {
    const quantity = parseInt(this.quantityTarget.value) || 0
    const price = parseFloat(this.priceTarget.value) || 0
    const effectiveCost = this.getEffectiveCost() // Get the appropriate cost based on order type

    if (!effectiveCost) {
      console.warn('Missing cost value:', {
        sku: this.element.querySelector('input[name*="[sku]"]')?.value,
        cost: this.costTarget?.value,
        builderCost: this.hasBuilderCostDisplayTarget ? this.builderCostDisplayTarget.value : 'N/A'
      })
    }

    // Calculate total before discount
    const subtotal = quantity * price

    // Calculate discount
    const discountAmount = parseFloat(this.discountAmountTarget.value) || 0
    const discountType = this.discountTypeTarget.value
    const discountValue = discountType === 'percentage'
      ? (subtotal * (discountAmount / 100))
      : discountAmount

    // Calculate final total and profit
    const total = subtotal - discountValue
    const totalCost = quantity * effectiveCost
    const profit = total - totalCost
    const margin = total > 0 ? ((profit / total) * 100) : 0

    // Update UI
    this.totalTarget.textContent = this.formatCurrency(total)
    this.profitTarget.innerHTML = `
      <span class="${profit >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}">
        ${this.formatCurrency(profit)} (${margin.toFixed(1)}%)
      </span>
    `

    // Make sure cost display is updated to match hidden cost if available
    if (this.hasCostDisplayTarget) {
      this.costDisplayTarget.value = this.costTarget.value
    }

    // Support for legacy targets
    this.updateLCNN()
    this.updateProfitMargin()
    this.updateDisplayQuantity()

    // Important fix: Use window-level dispatch for better event propagation
    // This ensures the event is captured by the order-totals controller
    window.dispatchEvent(new CustomEvent('order-item:update', {
      bubbles: true,
      detail: {
        subtotal,
        total,
        profit,
        cost: totalCost,
        discountValue,
        quantity
      }
    }))

    // Also dispatch at element level for backward compatibility
    this.element.dispatchEvent(new CustomEvent('order-item:update', {
      bubbles: true,
      detail: {
        subtotal,
        total,
        profit,
        cost: totalCost,
        discountValue,
        quantity
      }
    }))
  }

  updateLCNN() {
    if (this.hasLcnnTarget) {
      const effectiveCost = this.getEffectiveCost()
      this.lcnnTarget.value = this.formatter.format(effectiveCost)
    }
  }

  updateProfitMargin() {
    if (this.hasProfitMarginTarget) {
      const effectiveCost = this.getEffectiveCost()
      const price = parseFloat(this.priceTarget.value) || 0
      const profitMargin = price > 0 ? ((price - effectiveCost) / price) * 100 : 0
      this.profitMarginTarget.value = profitMargin.toFixed(2) + '%'
    }
  }

  renderStockInfo() {
    // Get stock data from the element's data attribute
    let stockData = []
    try {
      stockData = JSON.parse(this.element.dataset.orderItemStockValue || '[]')
    } catch (e) {
      console.error('Failed to parse stock data:', e)
    }

    // If we have warehouseInfo target, render the warehouse info
    if (this.hasWarehouseInfoTarget && stockData.length > 0) {
      // Filter to only show warehouses with inventory
      const warehousesWithStock = stockData.filter(warehouse => warehouse.on_hand > 0)

      if (warehousesWithStock.length === 0) {
        this.warehouseInfoTarget.innerHTML = `
          <div class="text-xs text-gray-500 dark:text-space-500 italic">No inventory available at any warehouse</div>
        `
        return
      }

      // Create HTML for each warehouse with stock
      const warehouseHtml = warehousesWithStock.map(inventory => `
        <div class="flex items-center gap-2 py-1 px-2 rounded ${inventory.available > 0 ? 'bg-green-100 dark:bg-green-900/30' : 'bg-red-100 dark:bg-red-900/30'}">
          <div class="w-2 h-2 rounded-full ${inventory.available > 0 ? 'bg-green-600 dark:bg-green-500' : 'bg-red-600 dark:bg-red-500'}"></div>
          <span class="text-xs font-medium">${inventory.warehouse.name}</span>
          <span class="text-xs ml-auto">${inventory.available} avail</span>
        </div>
      `).join('')

      this.warehouseInfoTarget.innerHTML = warehouseHtml
    }

    // If we have stockInfo target, render a summary
    if (this.hasStockInfoTarget) {
      const totalStock = stockData.reduce((sum, s) => sum + (s.available || 0), 0)
      let statusClass = 'text-red-600 dark:text-red-500'
      let statusText = 'Out of Stock'

      if (totalStock > 10) {
        statusClass = 'text-green-600 dark:text-green-500'
        statusText = 'In Stock'
      } else if (totalStock > 0) {
        statusClass = 'text-yellow-600 dark:text-yellow-500'
        statusText = 'Low Stock'
      }

      this.stockInfoTarget.innerHTML = `
        <span class="${statusClass} font-medium">${statusText}</span>
        <span class="text-gray-500 dark:text-space-400"> · ${totalStock} available</span>
      `
    }

    const stockHtml = this.createStockHoverHtml(stockData)
    if (this.hasStockInfoTarget) {
      this.stockInfoTarget.innerHTML = stockHtml
    }
  }

  createStockHoverHtml(stockData) {
    const totalStock = stockData.reduce((sum, s) => sum + (s.available || 0), 0)

    return `
      <div class="relative group">
        <div class="cursor-help">Stock: ${totalStock}</div>
        <div class="hidden group-hover:block absolute bottom-full left-0 mb-2 w-48 p-2 bg-white dark:bg-space-800 rounded shadow-lg text-sm z-10">
          ${stockData.map(s => `
            <div class="mb-1 last:mb-0">
              <div class="font-medium">${s.warehouse.name}</div>
              <div class="text-gray-600 dark:text-space-400">
                ${s.available} available
                ${s.backordered ? `<br>${s.backordered} backordered` : ''}
              </div>
            </div>
          `).join('')}
        </div>
      </div>
    `
  }

  showInventoryHistory(e) {
    e.preventDefault()
    const itemId = e.currentTarget.dataset.id
    const sku = e.currentTarget.dataset.sku || this.element.querySelector('input[name*="[sku]"]')?.value

    // If we have an ID, use it. Otherwise, use SKU or 'new'
    const identifier = itemId || sku || 'new'
    const modalId = `inventory-history-modal-${identifier}`

    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.remove('hidden')
    } else {
      console.error(`Modal not found with ID: ${modalId}`)
    }
  }

  closeInventoryHistory(e) {
    e.preventDefault()
    const itemId = this.element.querySelector('input[name*="[id]"]')?.value
    const sku = e.currentTarget.dataset.sku || this.element.querySelector('input[name*="[sku]"]')?.value

    // If we have an ID, use it. Otherwise, use SKU or 'new'
    const identifier = itemId || sku || 'new'
    const modalId = `inventory-history-modal-${identifier}`

    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.add('hidden')
    } else {
      console.error(`Modal not found with ID: ${modalId}`)
    }
  }

  remove(e) {
    e.preventDefault()
    const destroyField = this.element.querySelector('input[name*="[_destroy]"]')
    if (destroyField) {
      destroyField.value = true
      this.element.classList.add('hidden')
      this.element.dispatchEvent(new CustomEvent('item-changed', { bubbles: true }))
    }
  }

  async showProductInfo(e) {
    e.preventDefault()
    const sku = e.currentTarget.dataset.sku || this.element.querySelector('input[name*="[sku]"]')?.value

    if (!sku) {
      console.error('SKU is missing')
      this.showErrorModal('Unable to fetch product: SKU is missing')
      return
    }

    const loadingModal = this.showLoadingModal()

    try {
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      const product = data.results[0]

      if (!product) {
        throw new Error('Product not found')
      }

      // Ensure costs are present
      if (typeof product.cost !== 'number') {
        console.error('Missing cost for product:', sku)
      }

      // Filter and process add-ons
      if (product.additionalItems) {
        const quantity = parseInt(this.quantityTarget.value) || 1
        const price = parseFloat(this.priceTarget.value) || 0
        const totalPrice = price

        // Filter warranties based on total price
        const warranties = product.additionalItems
          .filter(item => item.addon_type === 'warranty')
        const filteredWarranties = this.filterWarranties(warranties, totalPrice)
        const otherItems = product.additionalItems
          .filter(item => item.addon_type !== 'warranty')

        product.additionalItems = [...filteredWarranties, ...otherItems]

        // Validate costs
        product.additionalItems.forEach(item => {
          if (typeof item.cost !== 'number') {
            console.warn(`Missing cost for addon: ${item.sku}`)
          }
        })
      }

      loadingModal.remove()
      this.showProductModal(product)
    } catch (error) {
      console.error('Failed to fetch product info:', error)
      loadingModal.remove()
      this.showErrorModal(error.message)
    }
  }

  filterWarranties(warranties, totalPrice) {
    const getWarrantyLimit = (warranty) => {
      let match = warranty.title.match(/under \$?([\d,]+)/)
      if (match) {
        return parseFloat(match[1].replace(',', ''))
      }
      match = warranty.sku.match(/PLAT\d?(\d+)/)
      if (match) {
        return parseFloat(match[1])
      }
      return Infinity
    }

    const eligibleLimit = warranties
      .map(w => getWarrantyLimit(w))
      .filter(limit => totalPrice <= limit)
      .sort((a, b) => a - b)[0]

    return warranties.filter(w => getWarrantyLimit(w) === eligibleLimit)
  }

  async showProductModal(product) {
    const modal = document.createElement('div')
    modal.setAttribute('data-product-info-modal', '')
    modal.className = 'fixed inset-0 bg-black/50 z-50 overflow-auto p-4'

    const styles = `
      .inline_sd_table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
      }
      
      .inline_sd_header {
        background-color: rgb(17 24 39);
        padding: 0.75rem 1rem;
        font-weight: 600;
        color: rgb(229 231 235);
      }
      
      .inline_sd_cell_row td {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid rgb(31 41 55);
      }
      
      .inline_sd_odd_cell {
        background-color: rgb(17 24 39 / 0.4);
      }

      @media (prefers-color-scheme: light) {
        .inline_sd_header {
          background-color: rgb(243 244 246);
          color: rgb(17 24 39);
        }
        
        .inline_sd_cell_row td {
          border-bottom: 1px solid rgb(229 231 235);
        }
        
        .inline_sd_odd_cell {
          background-color: rgb(249 250 251);
        }
      }
    `

    modal.innerHTML = `
      <style>${styles}</style>
      <div class="mx-auto w-full max-w-3xl mt-12 bg-white dark:bg-space-900 rounded-lg overflow-hidden flex flex-col">
        <div class="p-4 bg-gray-100 dark:bg-space-800 flex items-center justify-between">
          <h2 class="text-lg font-bold">Product Information</h2>
          <button type="button" class="text-gray-500 dark:text-space-400 hover:text-gray-700 dark:hover:text-space-300">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>

        <!-- Product Header Info -->
        <div class="p-4">
          <div class="flex gap-4">
            <div class="w-32 h-32 bg-gray-200 dark:bg-space-800 rounded-lg flex-shrink-0">
              ${product.image ? `
                <img src="${product.image}" class="w-full h-full object-contain rounded-lg" alt="${product.title}" />
              ` : ''}
            </div>
            <div class="flex-1 min-w-0">
              <div class="space-y-2">
                <h3 class="text-lg font-bold">${product.title}</h3>
                <div class="text-sm text-gray-600 dark:text-space-400">SKU: ${product.sku}</div>
                <div class="text-sm text-gray-600 dark:text-space-400">${product.brand}</div>
                <div class="text-sm text-gray-600 dark:text-space-400">${product.majorClassification} - ${product.minorClassification}</div>
                <div class="text-sm text-gray-600 dark:text-space-400">MSRP: ${this.formatCurrency(product.price)}</div>
              </div>
            </div>
          </div >
        </div >

      <!-- Tabs -->
      <div class="border-t border-gray-200 dark:border-space-800">
        <div class="flex border-b border-gray-200 dark:border-space-800" role="tablist">
          <button class="px-4 py-2 text-sm font-medium text-blue-600 dark:text-blue-500 border-b-2 border-blue-600 dark:border-blue-500"
            role="tab"
            aria-selected="true"
            data-tab="description">
            Description
          </button>
          <button class="px-4 py-2 text-sm font-medium text-gray-500 dark:text-space-400 hover:text-gray-700 dark:hover:text-space-300"
            role="tab"
            aria-selected="false"
            data-tab="addons">
            Add-ons
          </button>
          <button class="px-4 py-2 text-sm font-medium text-gray-500 dark:text-space-400 hover:text-gray-700 dark:hover:text-space-300"
            role="tab"
            aria-selected="false"
            data-tab="specs">
            Spec Table
          </button>
          <button class="px-4 py-2 text-sm font-medium text-gray-500 dark:text-space-400 hover:text-gray-700 dark:hover:text-space-300"
            role="tab"
            aria-selected="false"
            data-tab="related">
            Related Items
          </button>
        </div>

        <!-- Tab Content -->
        <div class="flex-1 overflow-auto max-h-[calc(100vh-400px)]">
          <!-- Description Tab -->
          <div class="p-4 tab-content" data-tab-content="description" role="tabpanel">
            ${product.description ? `
                <div class="text-sm text-gray-700 dark:text-space-300">${product.description}</div>
              ` : '<div class="text-sm text-gray-500 dark:text-space-400">No description available.</div>'}
          </div>

          <!-- Add-ons Tab -->
          <div class="p-4 tab-content hidden" data-tab-content="addons" role="tabpanel">
            ${product.additionalItems?.length ?
        this.renderAddonCategories(product.additionalItems) :
        '<div class="text-sm text-gray-500 dark:text-space-400">No add-ons available.</div>'
      }
          </div>

          <!-- Specs Tab -->
          <div class="p-4 tab-content hidden" data-tab-content="specs" role="tabpanel">
            ${this.renderSpecTable(product.spec_table)}
          </div>

          <!-- Related Items Tab -->
          <div class="p-4 tab-content hidden" data-tab-content="related" role="tabpanel">
            ${this.renderRelatedItems(product.related_items)}
          </div>
        </div>
      </div>
      </div >
      `

    // Add tab switching functionality
    modal.addEventListener('click', async (e) => {
      const tabButton = e.target.closest('[role="tab"]')
      if (tabButton) {
        const targetTab = tabButton.dataset.tab
        const tabContent = modal.querySelectorAll('[data-tab-content]')
        const tabButtons = modal.querySelectorAll('[role="tab"]')

        // Update tab buttons
        tabButtons.forEach(button => {
          button.classList.remove('text-blue-600', 'dark:text-blue-500', 'border-b-2', 'border-blue-600', 'dark:border-blue-500')
          button.classList.add('text-gray-500', 'dark:text-space-400')
          button.setAttribute('aria-selected', 'false')
        })
        tabButton.classList.add('text-blue-600', 'dark:text-blue-500', 'border-b-2', 'border-blue-600', 'dark:border-blue-500')
        tabButton.classList.remove('text-gray-500', 'dark:text-space-400')
        tabButton.setAttribute('aria-selected', 'true')

        // If clicking related items tab, fetch and render the content
        if (targetTab === 'related') {
          const relatedContent = modal.querySelector('[data-tab-content="related"]')
          relatedContent.innerHTML = `
      <div div class="animate-pulse flex justify-center items-center py-8" >
        <div class="text-sm text-gray-500 dark:text-space-400">Loading related items...</div>
            </div >
      `

          try {
            if (typeof product.related_items === 'string') {
              const skus = product.related_items.split(';').map(sku => sku.trim())
              const relatedProducts = await Promise.all(
                skus.map(async (sku) => {
                  try {
                    const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)
                    const data = await response.json()
                    return data.results[0]
                  } catch (error) {
                    console.error(`Failed to fetch related product ${sku}: `, error)
                    return null
                  }
                })
              )
              const validProducts = relatedProducts.filter(p => p !== null)
              relatedContent.innerHTML = this.renderRelatedProductsList(validProducts)
            } else {
              relatedContent.innerHTML = this.renderRelatedProductsList(product.related_items || [])
            }
          } catch (error) {
            console.error('Failed to load related items:', error)
            relatedContent.innerHTML = `
      <div div class="text-sm text-gray-500 dark:text-space-400 text-center py-8" >
        Failed to load related items
              </div >
      `
          }
        }

        // Show the selected tab content
        tabContent.forEach(content => {
          content.classList.add('hidden')
          if (content.dataset.tabContent === targetTab) {
            content.classList.remove('hidden')
          }
        })
      }

      // Handle add addon button clicks
      const addonButton = e.target.closest('.add-addon-btn')
      if (addonButton) {
        e.preventDefault()
        e.stopPropagation()
        try {
          const itemData = addonButton.dataset.item
          if (!itemData) throw new Error('No addon data found')

          await this.addAdditionalItem({
            preventDefault: () => { },
            target: addonButton
          })
        } catch (error) {
          console.error('Failed to add addon:', error)
          this.showErrorModal('Failed to add addon item')
        }
      }

      // Handle related item clicks
      if (e.target.closest('[data-action="click->order-item#handleRelatedItemClick"]')) {
        const button = e.target.closest('[data-action="click->order-item#handleRelatedItemClick"]')
        await this.handleRelatedItemClick(e, button.dataset.sku)
      }

      // Handle modal close
      if (e.target === modal || e.target.closest('button[type="button"]')) {
        modal.remove()
      }

    })

    document.body.appendChild(modal)
  }

  renderSpecTable(specTable) {
    if (!specTable || specTable.trim() === '') {
      return '<div class="text-sm text-gray-500 dark:text-space-400">No specifications available.</div>'
    }

    return `
      <div div class="overflow-auto" >
        <div class="text-sm">
          ${specTable}
        </div>
      </div >
      `
  }

  async handleRelatedItemClick(e, sku) {
    e.preventDefault()

    const loadingModal = this.showLoadingModal()

    try {
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      const product = data.results[0]

      if (!product) {
        throw new Error('Product not found')
      }

      // Add product to order (reusing logic from product search)
      this.addProductToOrder({
        productId: product.id,
        productSku: product.sku,
        productTitle: product.title,
        productPrice: product.price,
        productCost: product.cost,
        productBuilderCost: product.builder_cost,
        productImage: product.image
      })

      loadingModal.remove()
    } catch (error) {
      console.error('Failed to fetch related product:', error)
      loadingModal.remove()
      this.showErrorModal(error.message)
    }
  }

  addProductToOrder(productData) {
    if (!productData.productCost) {
      console.warn(`Selected product ${productData.productSku} has no cost information`)
    }

    const template = document.querySelector('[data-nested-form-target="template"]')
    if (!template) {
      console.error("Template not found")
      return
    }

    const content = template.content.cloneNode(true)
    const wrapper = content.querySelector('.order-item-wrapper')
    const newIndex = new Date().getTime()

    if (wrapper) {
      // Update input names with new index
      wrapper.querySelectorAll('input').forEach(input => {
        input.name = input.name.replace('NEW_RECORD', newIndex)
      })

      // Set all form fields
      const costField = wrapper.querySelector('[data-order-item-target="cost"]')
      const costDisplay = wrapper.querySelector('[data-order-item-target="costDisplay"]')
      const builderCostField = wrapper.querySelector('input[name$="[builder_cost]"]')
      const builderCostDisplay = wrapper.querySelector('[data-order-item-target="builderCostDisplay"]')
      const skuField = wrapper.querySelector('input[name$="[sku]"]')
      const infoButton = wrapper.querySelector('[data-action="order-item#showProductInfo"]')

      if (costField) costField.value = productData.productCost
      if (costDisplay) costDisplay.value = productData.productCost
      if (skuField) skuField.value = productData.productSku
      if (infoButton) infoButton.dataset.sku = productData.productSku

      // Set builder cost if it exists in product data
      if (builderCostField && productData.productBuilderCost !== undefined) {
        builderCostField.value = productData.productBuilderCost
      }
      if (builderCostDisplay && productData.productBuilderCost !== undefined) {
        builderCostDisplay.value = productData.productBuilderCost
      }

      wrapper.querySelector('input[name$="[product_variant_id]"]').value = productData.productId
      wrapper.querySelector('input[name$="[price]"]').value = productData.productPrice
      wrapper.querySelector('input[name$="[quantity]"]').value = 1

      // Set display elements
      wrapper.querySelector('.product-title').textContent = productData.productTitle
      wrapper.querySelector('.product-sku').textContent = `SKU: ${productData.productSku}`

      if (productData.productImage) {
        const imgContainer = wrapper.querySelector('.w-24')
        if (imgContainer) {
          imgContainer.innerHTML = `<img src="${productData.productImage}" class="w-full h-full object-cover rounded-lg" alt="${productData.productTitle}" />`
        }
      }

      // Add to form
      const targetContainer = document.querySelector('[data-nested-form-target="target"]')
      if (targetContainer) {
        targetContainer.appendChild(wrapper)

        // Remove empty state if exists
        document.querySelector('[data-nested-form-target="empty"]')?.remove()

        // Trigger update
        wrapper.dispatchEvent(new CustomEvent('order-item:update', {
          bubbles: true,
          detail: {
            total: productData.productPrice,
            cost: productData.productCost,
            quantity: 1
          }
        }))
      }
    }
  }

  showLoadingModal() {
    const modal = document.createElement('div')
    modal.className = 'fixed inset-0 bg-black/50 z-50 flex items-center justify-center'
    modal.innerHTML = `
      <div div class="bg-white dark:bg-space-900 rounded-lg p-6 text-center" >
        <div class="animate-spin inline-block w-8 h-8 border-4 border-gray-300 dark:border-space-400 border-t-transparent rounded-full mb-4"></div>
        <div class="text-gray-700 dark:text-space-300">Loading product information...</div>
      </div >
      `
    document.body.appendChild(modal)
    return modal
  }

  showErrorModal(message) {
    const modal = document.createElement('div')
    modal.className = 'fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4'
    modal.innerHTML = `
      <div div class="bg-white dark:bg-space-900 rounded-lg p-6 max-w-md w-full" >
        <div class="text-red-600 dark:text-red-500 font-bold mb-4">Error Loading Product</div>
        <div class="text-gray-700 dark:text-space-300 mb-4">${message}</div>
        <button class="w-full bg-blue-600 dark:bg-blue-600 hover:bg-blue-500 dark:hover:bg-blue-500 rounded px-4 py-2 text-white">Close</button>
      </div >
      `
    modal.querySelector('button').addEventListener('click', () => modal.remove())
    document.body.appendChild(modal)
  }

  async renderRelatedItems(relatedItems) {
    if (!relatedItems || (typeof relatedItems === 'string' && relatedItems.trim() === '') ||
      (Array.isArray(relatedItems) && relatedItems.length === 0)) {
      return '<div class="text-sm text-gray-500 dark:text-space-400">No related items available.</div>'
    }

    // Handle if relatedItems is a string of SKUs
    if (typeof relatedItems === 'string') {
      const skus = relatedItems.split(';').map(sku => sku.trim())
      const relatedProducts = await Promise.all(
        skus.map(async (sku) => {
          try {
            const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)
            const data = await response.json()
            return data.results[0]
          } catch (error) {
            console.error(`Failed to fetch related product ${sku}: `, error)
            return null
          }
        })
      )

      const validProducts = relatedProducts.filter(p => p !== null)

      return this.renderRelatedProductsList(validProducts)
    }

    // Handle if relatedItems is already an array of products
    if (Array.isArray(relatedItems)) {
      return this.renderRelatedProductsList(relatedItems)
    }

    return '<div class="text-sm text-gray-500 dark:text-space-400">No related items available.</div>'
  }


  renderRelatedProductsList(products) {
    if (!products || !Array.isArray(products) || products.length === 0) {
      return '<div class="text-sm text-gray-500 dark:text-space-400 text-center">No related items available.</div>'
    }

    return `
      <div div class="grid gap-3" >
        ${products.map(product => {
      if (!product) return ''

      // Safely access properties with fallbacks
      const title = product?.title || 'Unknown Product'
      const sku = product?.sku || ''
      const price = product?.price || 0
      const stockCount = product?.stock?.reduce((sum, s) => sum + (s.available || 0), 0) || 0

      return `
            <div class="flex items-center justify-between p-3 bg-gray-100 dark:bg-space-800 rounded-lg">
              <div class="flex-1">
                <div class="font-medium">${title}</div>
                <div class="text-sm text-gray-600 dark:text-space-400">SKU: ${sku}</div>
                <div class="text-sm text-gray-600 dark:text-space-400">
                  Price: ${this.formatCurrency(price)}
                  ${stockCount > 0 ? ` • Stock: ${stockCount}` : ''}
                </div>
              </div>
              <button 
                type="button"
                class="ml-4 px-3 py-1 text-sm bg-blue-600 hover:bg-blue-500 rounded text-white"
                data-action="click->order-item#handleRelatedItemClick"
                data-sku="${sku}">
                Add to Order
              </button>
            </div>
          `
    }).join('')
      }
      </div >
      `
  }

  renderAddonCategories(items) {
    // Filter to only show add-ons with inventory if possible
    const filteredItems = items.filter(item => {
      if (item.stock && item.stock.length > 0) {
        return item.stock.some(s => s.on_hand > 0)
      }
      return true // Include if no stock information
    })

    if (filteredItems.length === 0) {
      return '<div class="text-sm text-gray-500 dark:text-space-400">No add-ons available.</div>'
    }

    const categories = filteredItems.reduce((acc, item) => {
      if (!acc[item.category]) acc[item.category] = []
      acc[item.category].push(item)
      return acc
    }, {})

    return `
      <div class="space-y-6">
        ${Object.entries(categories).map(([category, items]) => `
          <div>
            <h5 class="text-sm font-medium text-gray-700 dark:text-space-300 mb-2">${category}</h5>
            <div class="grid gap-2">
              ${items.map(item => `
                <div class="flex items-start justify-between p-3 bg-gray-100 dark:bg-space-800 rounded-lg hover:bg-gray-200 dark:hover:bg-space-700">
                  <div class="flex-1 min-w-0">
                    <div class="font-medium">${item.title}</div>
                    <div class="text-sm text-gray-600 dark:text-space-400">SKU: ${item.sku}</div>
                    ${item.description ? `
                      <div class="text-sm text-gray-600 dark:text-space-400 mt-1">${item.description}</div>
                    ` : ''}
                  </div>
                  <div class="ml-4 flex flex-col items-end">
                    <div class="font-bold mb-1">${this.formatCurrency(item.price)}</div>
                    <button type="button"
                      class="add-addon-btn px-3 py-1 text-sm bg-blue-600 hover:bg-blue-500 rounded text-white"
                      data-item='${JSON.stringify({
      sku: item.sku,
      title: item.title,
      price: item.price || 0,
      cost: item.cost || 0,
      builder_cost: item.builder_cost || 0,
      addon_type: item.addon_type,
      product_variant_id: item.product_variant_id
    })}'>
                      Add to Order
                    </button>
                  </div>
                </div>
              `).join('')}
            </div>
          </div>
        `).join('')}
      </div>
    `
  }

  addAdditionalItem(e) {
    e.preventDefault()
    try {
      const itemData = e.target.dataset.item
      if (!itemData) {
        console.error('No item data found on button')
        return
      }

      const data = JSON.parse(itemData)
      const mainSku = this.element.querySelector('input[name*="[sku]"]')?.value

      if (!mainSku) {
        console.error('Cannot add addon: Parent SKU not found')
        this.showErrorModal('Unable to add addon: Parent product not found')
        return
      }

      // Try different template selectors
      const template = document.querySelector('[data-addon-form-target="template"]')
      if (!template) {
        console.error('Addon item template not found')
        this.showErrorModal('Unable to add addon: Template not found')
        return
      }

      // Get the current counter
      const counter = document.querySelectorAll('.order-item-wrapper').length

      // Clone and prepare the template
      const clone = template.content.cloneNode(true)
      const wrapper = clone.querySelector('.order-item-wrapper')

      if (!wrapper) {
        throw new Error('Invalid addon template structure')
      }

      // Update names and IDs
      wrapper.querySelectorAll('input[name*="[placeholder]"]').forEach(element => {
        const newName = element.name.replace('placeholder', counter)
        element.name = newName
      })

      // Set form field values
      const fields = {
        'product_variant_id': data.product_variant_id || '',
        'sku': data.sku,
        'cost': data.cost || 0,
        'price': data.price || 0,
        'quantity': 1,
        'is_addon': true,
        'addon_type': data.addon_type || '',
        'parent_sku': mainSku,
        'builder_cost': data.builder_cost || 0
      }

      // Apply field values
      Object.entries(fields).forEach(([field, value]) => {
        const input = wrapper.querySelector(`input[name$="[${field}]"]`)
        if (input) input.value = value
      })

      // Set display elements
      wrapper.querySelector('.product-title').textContent = data.title

      // Add to the order
      const mainItem = this.element.closest('.order-item-wrapper')
      if (mainItem) {
        mainItem.after(wrapper)

        // Initialize the Stimulus controller
        const app = window.Stimulus?.application
        if (app) {
          // Give the DOM time to update
          setTimeout(() => {
            app.controllers.forEach(controller => {
              if (wrapper.matches(controller.scope.element.getAttribute('data-controller'))) {
                controller.scope.element = wrapper
              }
            })
          }, 0)
        }

        // Update totals
        this.dispatch('order-item:update')

        // Close the modal if it exists
        const modal = document.querySelector('[data-product-info-modal]')
        if (modal) modal.remove()
      }
    } catch (error) {
      console.error('Failed to add addon:', error)
      this.showErrorModal(`Failed to add addon: ${error.message}`)
    }
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0)
  }

  get formatter() {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
}