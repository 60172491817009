import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { columns: Object };

  connect() {
    console.log(
      "report-template controller connected. Available columns:",
      this.columnsValue,
    );
    // Locate the target model select within the entire document.
    this.select = document.querySelector(
      "[name='report_template[target_model]']",
    );
    // Locate the checkboxes container within this controller's element.
    this.checkboxContainer = this.element.querySelector("#column-checkboxes");

    if (this.select) {
      this.select.addEventListener("change", () => this.updateColumns());
      this.updateColumns(); // Call on connect to initialize
    } else {
      console.warn("Target model select not found.");
    }
  }

  updateColumns() {
    const targetModel = this.select.value;
    console.log("Target model changed to:", targetModel);
    if (!targetModel) {
      this.checkboxContainer.innerHTML =
        "<p class='text-gray-500 dark:text-gray-400'>Please select a report type to see available columns.</p>";
      return;
    }

    const available = this.columnsValue[targetModel] || [];
    console.log("Available columns for", targetModel, ":", available);
    // Clear the container.
    this.checkboxContainer.innerHTML = "";
    // For each available field, create a checkbox and label.
    available.forEach((field) => {
      const label = document.createElement("label");
      label.classList.add("inline-flex", "items-center", "mb-2");

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.name = "report_template[selected_columns][]";
      checkbox.value = field.key;
      checkbox.classList.add(
        "form-checkbox",
        "text-blue-600",
        "dark:text-blue-400",
      );

      const span = document.createElement("span");
      span.classList.add("ml-2", "text-gray-700", "dark:text-gray-300");
      span.textContent = field.label;

      label.appendChild(checkbox);
      label.appendChild(span);
      this.checkboxContainer.appendChild(label);
    });
  }
}
