// app/javascript/controllers/payment_manager_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "addPaymentModal",
    "refundModal",
    "paymentLinkModal",
    "paymentLinkResultModal",
    "paymentMethodRadio",
    "methodFields",
    "referenceField",
    "amountField",
    "refundTransactionId",
    "refundAmountField",
    "maxRefundAmount",
    "cardElement",
    "paymentLinkAmountField",
    "paymentLinkResult",
    "fileInput",
    "fileList",
    "fileListContainer",
    "submitButton"
  ]

  connect() {
    console.log("Payment Manager controller connected")

    // Initialize Stripe elements
    this.setupStripe()

    // Add filter listeners for transaction history
    this.initTransactionFilters()

    // Initialize net terms custom days field toggle
    document.querySelectorAll('input[name="terms_days"]').forEach(radio => {
      radio.addEventListener('change', this.toggleCustomTermsDays.bind(this))
    })

    // Initialize store credit toggle
    const applyCreditCheckbox = document.getElementById('apply_credit')
    if (applyCreditCheckbox) {
      applyCreditCheckbox.addEventListener('change', this.toggleCreditAmount.bind(this))
    }

    // Update payment fields based on initial selection
    this.updatePaymentFields()
  }

  setupStripe() {
    // We need to delay this slightly to ensure the DOM is fully loaded
    setTimeout(() => {
      if (typeof Stripe === 'undefined') {
        console.error('Stripe.js is not loaded')
        return
      }

      // Get the Stripe key from a data attribute or environment
      const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content')

      if (!stripeKey) {
        console.error('Stripe publishable key is missing')
        return
      }

      try {
        this.stripe = Stripe(stripeKey)
        this.elements = this.stripe.elements()

        // Only create the card element if the target exists
        if (this.hasCardElementTarget) {
          this.cardElement = this.elements.create('card', {
            style: {
              base: {
                color: document.documentElement.classList.contains('dark') ? '#e2e8f0' : '#4a5568',
                fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: document.documentElement.classList.contains('dark') ? '#64748b' : '#a0aec0',
                },
                iconColor: document.documentElement.classList.contains('dark') ? '#e2e8f0' : '#4a5568',
              },
              invalid: {
                color: '#ef4444',
                iconColor: '#ef4444'
              }
            }
          })

          this.cardElement.mount(this.cardElementTarget)
          console.log('Stripe card element mounted successfully')
        }
      } catch (error) {
        console.error('Error initializing Stripe:', error)
      }
    }, 100) // Small delay to ensure DOM is ready
  }

  initTransactionFilters() {
    const filterButtons = document.querySelectorAll('.filter-btn')
    const transactionItems = document.querySelectorAll('.transaction-item')

    filterButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        // Remove active class from all buttons
        filterButtons.forEach(btn => {
          btn.classList.remove('bg-blue-100', 'dark:bg-blue-900/30', 'text-blue-700', 'dark:text-blue-400')
          btn.classList.add('bg-gray-100', 'dark:bg-space-800', 'text-space-700', 'dark:text-space-400')
        })

        // Add active class to clicked button
        button.classList.remove('bg-gray-100', 'dark:bg-space-800', 'text-space-700', 'dark:text-space-400')
        button.classList.add('bg-blue-100', 'dark:bg-blue-900/30', 'text-blue-700', 'dark:text-blue-400')

        const filterType = button.dataset.filter

        // Filter transactions
        transactionItems.forEach(item => {
          if (filterType === 'all' || item.dataset.type.includes(filterType)) {
            item.style.display = ''
          } else {
            item.style.display = 'none'
          }
        })
      })
    })
  }

  toggleCustomTermsDays(event) {
    const customField = document.querySelector('.custom-terms-days-field')
    if (event.target.value === 'custom') {
      customField.classList.remove('hidden')
    } else {
      customField.classList.add('hidden')
    }
  }

  toggleCreditAmount(event) {
    const creditAmountContainer = document.getElementById('credit-amount-container')
    if (event.target.checked) {
      creditAmountContainer.classList.remove('hidden')
    } else {
      creditAmountContainer.classList.add('hidden')
    }
  }

  // Modal management methods
  openAddPaymentModal() {
    this.addPaymentModalTarget.classList.remove('hidden')
    // Set focus on amount field
    this.amountFieldTarget.focus()
  }

  closeAddPaymentModal() {
    this.addPaymentModalTarget.classList.add('hidden')
  }

  openRefundModal(event) {
    const transactionId = event.currentTarget.dataset.transactionId
    const amount = parseFloat(event.currentTarget.dataset.amount)

    this.refundTransactionIdTarget.value = transactionId
    this.refundAmountFieldTarget.value = amount.toFixed(2)
    this.maxRefundAmountTarget.textContent = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)

    this.refundModalTarget.classList.remove('hidden')
    // Set focus on amount field
    this.refundAmountFieldTarget.focus()
  }

  closeRefundModal() {
    this.refundModalTarget.classList.add('hidden')
  }

  openPaymentLinkModal() {
    this.paymentLinkModalTarget.classList.remove('hidden')
    // Set focus on amount field
    this.paymentLinkAmountFieldTarget.focus()
  }

  closePaymentLinkModal() {
    this.paymentLinkModalTarget.classList.add('hidden')
  }

  openPaymentLinkResultModal(url) {
    this.paymentLinkResultTarget.value = url
    this.paymentLinkResultModalTarget.classList.remove('hidden')
  }

  closePaymentLinkResultModal() {
    this.paymentLinkResultModalTarget.classList.add('hidden')
  }

  // Amount management methods
  setFullAmount() {
    const balanceDue = parseFloat(this.amountFieldTarget.dataset.balanceDue)
    if (!isNaN(balanceDue)) {
      this.amountFieldTarget.value = balanceDue.toFixed(2)
    }
  }

  setFullRefund() {
    const maxAmount = this.maxRefundAmountTarget.textContent.replace(/[^0-9.-]+/g, '')
    this.refundAmountFieldTarget.value = parseFloat(maxAmount).toFixed(2)
  }

  setFullPaymentLinkAmount() {
    const balanceDue = parseFloat(this.amountFieldTarget.dataset.balanceDue)
    if (!isNaN(balanceDue)) {
      this.paymentLinkAmountFieldTarget.value = balanceDue.toFixed(2)
    }
  }

  // Payment processing methods
  changePaymentMethod() {
    this.updatePaymentFields()
  }

  updatePaymentFields() {
    const selectedMethod = this.getSelectedPaymentMethod()
    console.log('Selected payment method:', selectedMethod)

    // Hide all method fields
    this.methodFieldsTargets.forEach(field => {
      field.classList.add('hidden')
    })

    // Show the selected method fields
    const selectedFields = this.methodFieldsTargets.find(field =>
      field.dataset.method === selectedMethod
    )

    if (selectedFields) {
      selectedFields.classList.remove('hidden')
    }

    // Handle reference field visibility
    if (selectedMethod === 'credit_card') {
      if (this.hasReferenceFieldTarget) {
        this.referenceFieldTarget.classList.add('hidden')
      }

      // Re-initialize Stripe if it's a credit card payment
      if (this.hasCardElementTarget && this.cardElement) {
        // If we're switching back to credit card, make sure the element is mounted
        setTimeout(() => {
          try {
            // Check if the element is already mounted
            if (!this.cardElementTarget.querySelector('iframe')) {
              this.cardElement.mount(this.cardElementTarget)
            }
          } catch (error) {
            console.error('Error remounting Stripe element:', error)
          }
        }, 50)
      }
    } else {
      if (this.hasReferenceFieldTarget) {
        this.referenceFieldTarget.classList.remove('hidden')
      }
    }
  }

  getSelectedPaymentMethod() {
    const selectedRadio = this.paymentMethodRadioTargets.find(radio => radio.checked)
    return selectedRadio ? selectedRadio.value : 'credit_card'
  }

  updateFileList() {
    if (!this.hasFileInputTarget) return

    const files = this.fileInputTarget.files
    if (files.length > 0) {
      // Show the file list container
      this.fileListContainerTarget.classList.remove('hidden')

      // Clear the list first
      this.fileListTarget.innerHTML = ''

      // Add each file to the list
      Array.from(files).forEach(file => {
        const li = document.createElement('li')
        li.textContent = file.name
        li.className = 'text-xs text-space-600 dark:text-space-400'
        this.fileListTarget.appendChild(li)
      })
    } else {
      // Hide the list if no files
      this.fileListContainerTarget.classList.add('hidden')
    }
  }

  // Form submission handlers
  processPayment(event) {
    const paymentMethod = this.getSelectedPaymentMethod()

    // Only intercept for credit card payments
    if (paymentMethod === 'credit_card') {
      event.preventDefault()

      // Check if Stripe is properly initialized
      if (!this.stripe || !this.cardElement) {
        const errorElement = document.getElementById('card-errors')
        if (errorElement) {
          errorElement.textContent = 'Stripe is not initialized. Please refresh the page and try again.'
        }
        return
      }

      // Disable submit button
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      this.submitButtonTarget.textContent = 'Processing...'

      // Use createToken for simple charge processing
      this.stripe.createToken(this.cardElement).then(result => {
        if (result.error) {
          // Show error
          const errorElement = document.getElementById('card-errors')
          if (errorElement) {
            errorElement.textContent = result.error.message
          }

          // Re-enable button
          this.submitButtonTarget.disabled = false
          this.submitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
          this.submitButtonTarget.textContent = 'Process Payment'
        } else {
          // Add token to form and submit
          const hiddenInput = document.createElement('input')
          hiddenInput.setAttribute('type', 'hidden')
          hiddenInput.setAttribute('name', 'stripe_payment_method_id')
          hiddenInput.setAttribute('value', result.token.id)
          event.target.appendChild(hiddenInput)

          // Submit the form
          event.target.submit()
        }
      }).catch(error => {
        console.error('Stripe error:', error)
        const errorElement = document.getElementById('card-errors')
        if (errorElement) {
          errorElement.textContent = 'An error occurred while processing your card. Please try again.'
        }

        // Re-enable button
        this.submitButtonTarget.disabled = false
        this.submitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
        this.submitButtonTarget.textContent = 'Process Payment'
      })
    }
  }

  processRefund(event) {
    event.preventDefault()

    const form = event.target
    const transactionId = this.refundTransactionIdTarget.value
    const amount = parseFloat(this.refundAmountFieldTarget.value)
    const reason = form.querySelector('textarea[name="reason"]').value

    // Basic validation
    if (isNaN(amount) || amount <= 0) {
      alert('Please enter a valid refund amount greater than 0')
      return
    }

    if (!reason.trim()) {
      alert('Please provide a reason for this refund')
      return
    }

    // Set the form action dynamically
    const baseUrl = window.location.pathname
    form.setAttribute('action', `${baseUrl}/transactions/${transactionId}/refund`)

    // Submit the form
    form.submit()
  }

  generatePaymentLink(event) {
    event.preventDefault()

    const form = event.target
    const amount = parseFloat(this.paymentLinkAmountFieldTarget.value)

    // Basic validation
    if (isNaN(amount) || amount <= 0) {
      alert('Please enter a valid amount greater than 0')
      return
    }

    // Disable the submit button
    const submitButton = form.querySelector('input[type="submit"]')
    submitButton.disabled = true
    submitButton.classList.add('opacity-50', 'cursor-not-allowed')
    submitButton.value = 'Generating...'

    // Submit via fetch to get the JSON response
    const formData = new FormData(form)

    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        // Re-enable the button
        submitButton.disabled = false
        submitButton.classList.remove('opacity-50', 'cursor-not-allowed')
        submitButton.value = 'Generate Link'

        if (data.success && data.url) {
          // Close the generate modal
          this.closePaymentLinkModal()

          // Show the result modal
          this.openPaymentLinkResultModal(data.url)
        } else {
          alert(data.error || 'Failed to generate payment link')
        }
      })
      .catch(error => {
        // Re-enable the button
        submitButton.disabled = false
        submitButton.classList.remove('opacity-50', 'cursor-not-allowed')
        submitButton.value = 'Generate Link'

        console.error('Error generating payment link:', error)
        alert('An error occurred while generating the payment link')
      })
  }

  copyPaymentLink() {
    const linkInput = this.paymentLinkResultTarget
    linkInput.select()
    document.execCommand('copy')

    // Show copied feedback
    const button = event.currentTarget
    const originalText = button.textContent
    button.textContent = 'Copied!'

    setTimeout(() => {
      button.textContent = originalText
    }, 2000)
  }

  // Button action hooks
  payInFull() {
    this.openAddPaymentModal()
    this.setFullAmount()
  }

  payPartial() {
    this.openAddPaymentModal()
    // Clear the amount to encourage entering a custom amount
    this.amountFieldTarget.value = ''
    this.amountFieldTarget.focus()
  }

  initiateRefund() {
    // Show a message that user needs to select a specific transaction
    const alertDiv = document.createElement('div')
    alertDiv.className = 'fixed inset-x-0 top-4 flex items-center justify-center z-50'
    alertDiv.innerHTML = `
      <div class="bg-amber-100 border border-amber-300 text-amber-800 dark:bg-amber-900/50 dark:border-amber-800 dark:text-amber-300 px-4 py-3 rounded-md shadow-lg max-w-md">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium">Please select a specific transaction to refund</p>
          </div>
          <div class="ml-auto pl-3">
            <button type="button" class="text-amber-800 dark:text-amber-400 hover:text-amber-600 dark:hover:text-amber-300">
              <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    `

    document.body.appendChild(alertDiv)

    // Remove alert after 3 seconds
    setTimeout(() => {
      alertDiv.classList.add('opacity-0', 'transition-opacity', 'duration-500')
      setTimeout(() => {
        document.body.removeChild(alertDiv)
      }, 500)
    }, 3000)

    // Add click listener to close button
    const closeButton = alertDiv.querySelector('button')
    closeButton.addEventListener('click', () => {
      document.body.removeChild(alertDiv)
    })
  }
}