import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["price", "costDisplay", "cost", "quantity", "total", "profit",
    "discountType", "discountAmount", "customMargin"]

  connect() {
    console.log("Line item controller connected", this.element.dataset.lineItemId)
    // Get the line item ID from the data attribute
    const lineItemId = this.element.dataset.lineItemId

    // Apply custom item styling if needed
    this.applyCustomItemStyling()

    // If we have an ID, fetch stock information
    if (lineItemId) {
      this.fetchStockInfo()
    }

    // Initialize calculations
    this.updateTotal()
  }

  // Apply custom item styling based on data-is-custom attribute
  applyCustomItemStyling() {
    // Check if this is a custom item
    const isCustom = this.element.dataset.isCustom === 'true'

    if (isCustom) {
      // Show pencil icons for custom items
      this.addPencilIcons()

      // Add editable styling to title and SKU
      this.addEditableStyles()

      // Hide details button for custom items
      const detailsButton = this.element.querySelector('[data-details-button]')
      if (detailsButton) {
        detailsButton.classList.add('hidden')
        detailsButton.disabled = true
      }

      // Make title and SKU editable
      this.makeFieldsEditable()
    } else {
      // Remove pencil icons for catalog items
      this.removePencilIcons()

      // Remove editable styling
      this.removeEditableStyles()

      // Show details button for catalog items
      const detailsButton = this.element.querySelector('[data-details-button]')
      if (detailsButton) {
        detailsButton.classList.remove('hidden')
        detailsButton.disabled = false
      }
    }
  }

  // Add pencil icons to title and SKU for custom items
  addPencilIcons() {
    const titleContainer = this.element.querySelector('.product-title').parentNode
    if (titleContainer && !titleContainer.querySelector('.fa-pencil')) {
      const pencilIcon = document.createElement('i')
      pencilIcon.className = 'fas fa-pencil text-space-500 text-xs mr-2'
      titleContainer.insertBefore(pencilIcon, titleContainer.firstChild)
    }

    const skuContainer = this.element.querySelector('.product-sku')
    if (skuContainer && !skuContainer.querySelector('.fa-pencil')) {
      const span = skuContainer.querySelector('span')
      if (span) {
        const pencilIcon = document.createElement('i')
        pencilIcon.className = 'fas fa-pencil text-space-500 text-xs mr-1'
        span.insertBefore(pencilIcon, span.firstChild)
      }
    }
  }

  // Remove pencil icons for catalog items
  removePencilIcons() {
    this.element.querySelectorAll('.fa-pencil').forEach(icon => {
      icon.remove()
    })
  }

  // Add editable styling to title and SKU
  addEditableStyles() {
    const titleElement = this.element.querySelector('.product-title')
    if (titleElement) {
      titleElement.classList.add('border-b', 'border-space-200', 'dark:border-space-500')
    }

    const skuDisplay = this.element.querySelector('[data-product-sku-display]')
    if (skuDisplay) {
      skuDisplay.classList.add('border-b', 'border-space-200', 'dark:border-space-500')
    } else {
      const skuSpan = this.element.querySelector('.product-sku span')
      if (skuSpan) {
        skuSpan.classList.add('border-b', 'border-space-200', 'dark:border-space-500')
      }
    }
  }

  // Remove editable styling
  removeEditableStyles() {
    const titleElement = this.element.querySelector('.product-title')
    if (titleElement) {
      titleElement.classList.remove('border-b', 'border-space-200', 'dark:border-space-500')
    }

    const skuDisplay = this.element.querySelector('[data-product-sku-display]')
    if (skuDisplay) {
      skuDisplay.classList.remove('border-b', 'border-space-200', 'dark:border-space-500')
    } else {
      const skuSpan = this.element.querySelector('.product-sku span')
      if (skuSpan) {
        skuSpan.classList.remove('border-b', 'border-space-200', 'dark:border-space-500')
      }
    }
  }

  // Make title and SKU editable for custom items
  makeFieldsEditable() {
    // Make title editable
    const titleElement = this.element.querySelector('.product-title')
    const nameInput = this.element.querySelector('input[name$="[name]"], [data-product-name-field]')

    if (titleElement && !titleElement.hasAttribute('contenteditable')) {
      titleElement.contentEditable = 'true'
      titleElement.addEventListener('input', (e) => {
        // Update the name field and data attribute when title changes
        const newName = e.target.innerText.trim()
        if (nameInput) nameInput.value = newName
        this.element.dataset.productName = newName

        // Also update button data attributes
        const addonButton = this.element.querySelector('[data-addons-button]')
        if (addonButton) addonButton.dataset.productName = newName
      })

      titleElement.addEventListener('blur', (e) => {
        if (e.target.innerText.trim() === '') {
          e.target.innerText = 'Custom Item'
          if (nameInput) nameInput.value = 'Custom Item'
        }
      })
    }

    // Make SKU editable
    const skuDisplay = this.element.querySelector('[data-product-sku-display]')
    const skuInput = this.element.querySelector('input[name$="[sku]"], [data-product-sku-field]')

    if (skuDisplay && !skuDisplay.hasAttribute('contenteditable')) {
      skuDisplay.contentEditable = 'true'
      skuDisplay.addEventListener('input', (e) => {
        // Update the SKU field and data attribute when SKU changes
        const newSku = e.target.innerText.trim()
        if (skuInput) skuInput.value = newSku
        this.element.dataset.productSku = newSku

        // Also update button data attributes
        const addonButton = this.element.querySelector('[data-addons-button]')
        if (addonButton) addonButton.dataset.productSku = newSku

        // Update details button if it exists
        const detailsButton = this.element.querySelector('[data-details-button]')
        if (detailsButton) detailsButton.dataset.productSku = newSku
      })

      skuDisplay.addEventListener('blur', (e) => {
        if (e.target.innerText.trim() === '') {
          e.target.innerText = this.element.dataset.productSku || 'CUSTOM'
          if (skuInput) skuInput.value = this.element.dataset.productSku || 'CUSTOM'
        }
      })
    } else if (!skuDisplay) {
      // Try for alternate SKU span
      const skuSpan = this.element.querySelector('.product-sku span')
      if (skuSpan && !skuSpan.hasAttribute('contenteditable')) {
        skuSpan.contentEditable = 'true'
        skuSpan.addEventListener('input', (e) => {
          const newSku = e.target.innerText.trim()
          if (skuInput) skuInput.value = newSku
          this.element.dataset.productSku = newSku

          // Update button data attributes
          const addonButton = this.element.querySelector('[data-addons-button]')
          if (addonButton) addonButton.dataset.productSku = newSku

          // Update details button if it exists
          const detailsButton = this.element.querySelector('[data-details-button]')
          if (detailsButton) detailsButton.dataset.productSku = newSku
        })

        skuSpan.addEventListener('blur', (e) => {
          if (e.target.innerText.trim() === '') {
            e.target.innerText = this.element.dataset.productSku || 'CUSTOM'
            if (skuInput) skuInput.value = this.element.dataset.productSku || 'CUSTOM'
          }
        })
      }
    }
  }

  async fetchStockInfo() {
    // Get the SKU from the element (assumes there's an element with class 'product-sku')
    const skuElement = this.element.querySelector('.product-sku')
    if (!skuElement) return

    // Extract SKU from the text (format: "SKU: ABC123")
    const skuText = skuElement.textContent || ''
    const match = skuText.match(/SKU:\s*(.+)/)
    if (!match || !match[1]) return

    const sku = match[1].trim()

    // Skip if SKU is empty
    if (!sku) return

    try {
      // Fetch product data from API
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)
      if (!response.ok) return

      const data = await response.json()
      const product = data.results && data.results[0]

      if (product && product.stock) {
        this.renderStockInfo(product.stock)
      }
    } catch (error) {
      console.error('Error fetching stock info:', error)
    }
  }

  renderStockInfo(stockData) {
    // Find stock container
    const stockContainer = this.element.querySelector('.stock-info-container')
    if (!stockContainer) return

    // Filter out warehouses with 0 stock
    const warehousesWithStock = stockData.filter(item => item.available > 0)

    // Calculate total stock
    const totalStock = warehousesWithStock.reduce((sum, item) => sum + item.available, 0)

    // Build HTML for stock info
    let stockHtml = ''

    // Add overall status
    let statusClass = 'text-red-600 dark:text-red-400'
    let statusText = 'Out of Stock'

    if (totalStock > 10) {
      statusClass = 'text-green-600 dark:text-green-400'
      statusText = 'In Stock'
    } else if (totalStock > 0) {
      statusClass = 'text-yellow-600 dark:text-yellow-400'
      statusText = 'Low Stock'
    }

    stockHtml += `<span class="${statusClass} font-medium">${statusText}</span> · <span>${totalStock} available</span>`

    // Add per-warehouse info for hovering - only include warehouses with stock
    if (warehousesWithStock.length > 0) {
      const tooltipId = `stock-tooltip-${this.element.dataset.lineItemId || 'temp'}`

      stockHtml += `
        <div class="relative ml-2 group">
          <span class="text-blue-600 dark:text-blue-400 cursor-help underline">Details</span>
          <div id="${tooltipId}" class="hidden group-hover:block absolute bottom-full left-0 mb-2 p-2 bg-white dark:bg-space-800 rounded shadow-lg z-50 w-48 text-xs">
            <div class="font-medium mb-1 text-gray-900 dark:text-white">Warehouse availability:</div>
            ${warehousesWithStock.map(item => `
              <div class="flex justify-between mb-1">
                <span>${item.warehouse.name}</span>
                <span class="text-green-600 dark:text-green-400">${item.available}</span>
              </div>
            `).join('')}
          </div>
        </div>
      `
    }

    // Update the container
    stockContainer.innerHTML = stockHtml
  }

  // Quantity increment/decrement methods
  incrementQuantity(event) {
    event.preventDefault()
    if (this.hasQuantityTarget) {
      this.quantityTarget.value = parseInt(this.quantityTarget.value || 0) + 1
      this.updateTotal()
    }
  }

  decrementQuantity(event) {
    event.preventDefault()
    if (this.hasQuantityTarget) {
      const newValue = parseInt(this.quantityTarget.value || 0) - 1
      if (newValue >= 1) {
        this.quantityTarget.value = newValue
        this.updateTotal()
      }
    }
  }

  // Margin application methods
  applyMargin(event) {
    event.preventDefault()
    const markup = parseFloat(event.currentTarget.dataset.margin || 0)
    if (!isNaN(markup)) {
      const cost = parseFloat(this.costDisplayTarget.value || 0)
      if (cost > 0) {
        this.priceTarget.value = (cost * (1 + markup / 100)).toFixed(2)
        this.updateTotal()
      }
    }
  }

  applyCustomMargin(event) {
    event.preventDefault()
    if (this.hasCustomMarginTarget) {
      const markup = parseFloat(this.customMarginTarget.value || 0)
      if (!isNaN(markup) && markup >= 0) {
        const cost = parseFloat(this.costDisplayTarget.value || 0)
        if (cost > 0) {
          this.priceTarget.value = (cost * (1 + markup / 100)).toFixed(2)
          this.updateTotal()
        }
      }
    }
  }

  // Handle removing a line item
  remove(event) {
    event.preventDefault()

    // Find the destroy field
    const destroyField = this.element.querySelector('input[name*="[_destroy]"]')
    if (destroyField) {
      destroyField.value = 'true'
      this.element.classList.add('hidden')

      // Update parent totals
      this.element.dispatchEvent(new CustomEvent('line-item:update', {
        bubbles: true,
        detail: {
          total: 0,
          cost: 0,
          profit: 0
        }
      }))
    }
  }

  updateTotal() {
    // Get values
    const price = parseFloat(this.priceTarget.value) || 0
    const cost = parseFloat(this.costDisplayTarget.value) || 0
    const quantity = parseInt(this.quantityTarget.value) || 1

    // Handle discount
    let discountValue = 0
    const discountAmount = parseFloat(this.discountAmountTarget.value) || 0
    const discountType = this.discountTypeTarget.value

    if (discountType === 'percentage') {
      discountValue = price * (discountAmount / 100)
    } else {
      discountValue = discountAmount
    }

    const effectivePrice = Math.max(0, price - discountValue)

    // Calculate subtotal before addons
    const subtotal = effectivePrice * quantity

    // Calculate addon total
    let addonTotal = 0
    const addonDisplay = this.element.querySelector('.line-item-addons-display')
    if (addonDisplay) {
      const addonPrices = addonDisplay.querySelectorAll('.font-medium:last-child')
      addonPrices.forEach(element => {
        const priceText = element.textContent.trim()
        const addonPrice = parseFloat(priceText.replace(/[^0-9.-]+/g, '')) || 0
        addonTotal += addonPrice
      })
    }

    // Calculate total with addons
    const total = subtotal + addonTotal

    // Calculate addon cost
    let addonCost = 0
    const addonFieldsContainer = this.element.querySelector('.line-item-addon-fields')
    if (addonFieldsContainer) {
      const costInputs = addonFieldsContainer.querySelectorAll('input[name*="[cost]"]')
      const quantityInputs = addonFieldsContainer.querySelectorAll('input[name*="[quantity]"]')

      costInputs.forEach((input, index) => {
        const addonItemCost = parseFloat(input.value) || 0
        const addonQuantity = parseInt(quantityInputs[index]?.value) || 1
        addonCost += addonItemCost * addonQuantity
      })
    }

    // Calculate total cost and profit
    const itemCost = cost * quantity
    const totalCost = itemCost + addonCost
    const profit = total - totalCost
    //const margin = total > 0 ? ((profit / total) * 100) : 0
    const margin = totalCost > 0 ? ((profit / totalCost) * 100) : 0

    // Update the hidden cost field if it exists
    if (this.hasCostTarget) {
      this.costTarget.value = cost.toFixed(2)
    }

    // Update UI
    this.totalTarget.textContent = this.formatCurrency(total)

    this.profitTarget.innerHTML = `
      <span class="${profit > 0 ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'}">
        ${this.formatCurrency(profit)} (${margin.toFixed(1)}%)
      </span>
    `

    // Dispatch event for parent calculations
    this.element.dispatchEvent(new CustomEvent('line-item:update', {
      bubbles: true,
      detail: {
        total,
        cost: totalCost,
        profit,
        quantity,
        addonTotal,
        addonCost
      }
    }))
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }
}