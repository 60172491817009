// product_details_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "title", "sku", "image", "classification", "tabContent",
    "description", "inventory", "related", "specs", "files", "addButton", "priceHistory"]

  connect() {
    this.currentTab = "description"
    this.productData = null
  }

  openModal(event) {
    event.preventDefault()
    const productId = event.currentTarget.dataset.productId
    const productSku = event.currentTarget.dataset.productSku

    this.addButtonTarget.classList.remove('inline-flex')
    this.addButtonTarget.classList.add('hidden')

    if (productId) {
      this.fetchProductDetails(productId)
    } else if (productSku) {
      this.fetchProductDetailsBySku(productSku)
    } else {
      console.error('No product ID or SKU provided')
    }
  }

  closeModal(event) {
    if (event) event.preventDefault()
    this.modalTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  performTabSwitch(tab) {
    const selectedTab = tab

    if (selectedTab === this.currentTab) return

    this.element.querySelectorAll('nav a').forEach(tab => {
      if (tab.dataset.tab === selectedTab) {
        tab.classList.remove('border-transparent', 'text-gray-500', 'dark:text-space-400', 'hover:text-gray-700', 'dark:hover:text-space-200', 'hover:border-gray-300', 'dark:hover:border-space-600')
        tab.classList.add('border-blue-500', 'dark:border-blue-400', 'text-blue-600', 'dark:text-blue-400')
      } else {
        tab.classList.remove('border-blue-500', 'dark:border-blue-400', 'text-blue-600', 'dark:text-blue-400')
        tab.classList.add('border-transparent', 'text-gray-500', 'dark:text-space-400', 'hover:text-gray-700', 'dark:hover:text-space-200', 'hover:border-gray-300', 'dark:hover:border-space-600')
      }
    })

    this.tabContentTargets.forEach(content => {
      if (content.dataset.tab === selectedTab) {
        content.classList.remove('hidden')
      } else {
        content.classList.add('hidden')
      }
    })

    this.currentTab = selectedTab
  }

  switchTab(event) {
    event.preventDefault()
    this.performTabSwitch(event.currentTarget.dataset.tab)
  }

  async fetchProductDetails(productId) {
    try {
      const response = await fetch(`/api/v1/products/${productId}`)

      if (!response.ok) {
        throw new Error("Product not found")
      }

      const data = await response.json()
      this.productData = data.results && data.results[0]

      if (!this.productData) {
        throw new Error("Product data is missing")
      }

      this.populateProductDetails()

      this.modalTarget.classList.remove('hidden')
      document.body.classList.add('overflow-hidden')

    } catch (error) {
      console.error('Error fetching product details:', error)
      this.showToast('Error loading product details. Please try again.', 'error')
    }
  }

  async fetchProductDetailsBySku(sku) {
    try {
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error("Product not found")
      }

      const data = await response.json()
      this.productData = data.results && data.results[0]

      if (!this.productData) {
        throw new Error("Product data is missing")
      }

      this.populateProductDetails()

      this.modalTarget.classList.remove('hidden')
      document.body.classList.add('overflow-hidden')

    } catch (error) {
      console.error('Error fetching product details by SKU:', error)
      this.showToast('Error loading product details. Please try again.', 'error')
    }
  }

  populateProductDetails() {
    const product = this.productData

    this.titleTarget.textContent = product.title
    this.skuTarget.textContent = `SKU: ${product.sku}`
    this.imageTarget.querySelector('img').src = product.image || ''
    this.classificationTarget.textContent = product.minorClassification || ''

    this.descriptionTarget.innerHTML = product.description || '<p class="text-gray-500 dark:text-space-400">No description available</p>'

    this.populatePriceHistory()
    this.populateInventory()
    this.populateRelated()
    this.populateSpecifications()
    this.populateFiles()
  }

  populatePriceHistory() {
    if (!this.productData.priceHistory || this.productData.priceHistory.length === 0) {
      this.priceHistoryTarget.innerHTML = `
        <div class="col-span-full text-center p-8 text-gray-500 dark:text-space-400">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <p class="text-lg font-medium">No price history available</p>
        </div>
      `
      return
    }

    let priceHistoryHtml = `
      <div class="space-y-3">
        <div class="grid grid-cols-9 gap-2 text-xs font-medium text-gray-500 dark:text-space-400 border-b border-gray-200 dark:border-space-700 pb-2">
          <div>Date</div>
          <div>Price</div>
          <div>MSRP</div>
          <div>MAP</div>
          <div>LAP</div>
          <div>UMRP</div>
          <div>LCNN</div>
          <div>BUILDER</div>
          <div>Wholesale</div>
        </div>
    `

    // Sort price history by date, most recent first
    const sortedHistory = [...this.productData.priceHistory].sort((a, b) =>
      new Date(b.date) - new Date(a.date)
    )

    sortedHistory.forEach(record => {
      const date = new Date(record.date).toLocaleDateString()

      priceHistoryHtml += `
        <div class="grid grid-cols-9 gap-2 text-xs hover:bg-gray-50 dark:hover:bg-space-800 py-2 border-b border-gray-100 dark:border-space-800 last:border-b-0">
          <div>${date}</div>
          <div>
            ${record.enforce_custom ?
          this.formatCurrency(record.custom_price || 0) : this.formatCurrency(record.price || 0)
        }
          </div >
          <div>${this.formatCurrency(record.compareAtPrice || 0)}</div>
          <div>${this.formatCurrency(record.map || 0)}</div>
          <div>${this.formatCurrency(record.lap || 0)}</div>
          <div>${this.formatCurrency(record.umrp || 0)}</div>
          <div>${this.formatCurrency(record.lcnn || 0)}</div>
          <div>${this.formatCurrency(record.builder || 0)}</div>
          <div>${this.formatCurrency(record.wholesalePrice || 0)}</div>
        </div >
      `
    })

    priceHistoryHtml += '</div>'
    this.priceHistoryTarget.innerHTML = priceHistoryHtml
  }

  populateInventory() {
    if (!this.productData.stock || this.productData.stock.length === 0) {
      this.inventoryTarget.innerHTML = `
        <div class= "col-span-full text-center p-8 text-gray-500 dark:text-space-400" >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
          </svg>
          <p class="text-lg font-medium">No inventory available</p>
        </div>
      `
      return
    }

    let inventoryHtml = '<div class="space-y-4">'

    // First, render regular inventory
    inventoryHtml += '<div class="mb-4"><h3 class="text-sm font-semibold text-gray-700 dark:text-space-300 mb-2">Regular Inventory</h3>'

    let regularInventoryItems = 0
    this.productData.stock.forEach(stockItem => {
      const isAvailable = stockItem.available > 0
      const isPreferred = stockItem.is_preferred
      const hasBackorder = stockItem.backordered > 0

      // Determine status and styling
      let statusDetails = {
        class: 'bg-green-100 dark:bg-green-900/30',
        dotClass: 'bg-green-500 dark:bg-green-500',
        text: 'Available',
        priority: 1
      }

      if (!isAvailable && hasBackorder) {
        statusDetails = {
          class: 'bg-orange-100 dark:bg-orange-900/30',
          dotClass: 'bg-orange-500 dark:bg-orange-500',
          text: 'Backordered',
          priority: 3
        }
      } else if (!isAvailable) {
        statusDetails = {
          class: 'bg-red-100 dark:bg-red-900/30',
          dotClass: 'bg-red-500 dark:bg-red-500',
          text: 'Out of Stock',
          priority: 4
        }
      }

      // Only render regular inventory items
      if (!(stockItem.builder_available > 0)) {
        regularInventoryItems++
        inventoryHtml += `
          <div class= "mb-2 flex items-center border border-gray-200 dark:border-space-700 rounded-lg p-3 ${statusDetails.class} ${isPreferred ? 'border-2 border-blue-500 dark:border-blue-400' : ''}" >
            <div class="flex-1 flex items-center space-x-3">
              <div class="w-3 h-3 rounded-full ${statusDetails.dotClass}"></div>
              <div class="flex-1">
                <div class="flex items-center">
                  <span class="text-sm font-medium mr-2">${stockItem.warehouse.name}</span>
                  ${isPreferred ? '<span class="text-xs bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 px-1 py-0.5 rounded">Preferred</span>' : ''}
                </div>
                <div class="text-xs text-gray-500 dark:text-space-400 mt-1">
                  ${stockItem.warehouse.type ? `Warehouse Type: ${stockItem.warehouse.type}` : ''}
                </div>
              </div>
            </div>
            <div class="text-right">
              <div class="text-sm font-semibold">${statusDetails.text}</div>
              <div class="text-xs text-gray-500 dark:text-space-400 mt-1">
                ${isAvailable ? `Regular: ${stockItem.available}` : ''}
                ${hasBackorder ? ` | Backordered: ${stockItem.backordered}` : ''}
              </div>
            </div>
          </div>
      `
      }
    })

    // If no regular inventory items, show a message
    if (regularInventoryItems === 0) {
      inventoryHtml += `
      <div class= "text-center p-4 text-gray-500 dark:text-space-400 bg-gray-100 dark:bg-space-800 rounded-lg" >
        No regular inventory available
      <div>
      `
    }
    inventoryHtml += '</div>'

    // Now, render builder inventory
    inventoryHtml += '<div><h3 class="text-sm font-semibold text-gray-700 dark:text-space-300 mb-2">Builder Inventory</h3>'

    let builderInventoryItems = 0
    this.productData.stock.forEach(stockItem => {
      const builderAvailable = stockItem.builder_available || 0
      const builderReserved = stockItem.builder_reserved || 0
      const isPreferred = stockItem.is_preferred_builder

      // Only render builder inventory items
      if (builderAvailable > 0) {
        builderInventoryItems++
        inventoryHtml += `
          <div class= "mb-2 flex items-center border border-gray-200 dark:border-space-700 rounded-lg p-3 bg-green-100 dark:bg-green-900/30 ${isPreferred ? 'border-2 border-blue-500 dark:border-blue-400' : ''}">
            <div class="flex-1 flex items-center space-x-3">
              <div class="w-3 h-3 rounded-full bg-green-500 dark:bg-green-500"></div>
              <div class="flex-1">
                <div class="flex items-center">
                  <span class="text-sm font-medium mr-2">${stockItem.warehouse.name}</span>
                  ${isPreferred ? '<span class="text-xs bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 px-1 py-0.5 rounded">Preferred</span>' : ''}
                </div>
                <div class="text-xs text-gray-500 dark:text-space-400 mt-1">
                  ${stockItem.warehouse.type ? `Warehouse Type: ${stockItem.warehouse.type}` : ''}
                </div>
              </div>
            </div>
            <div class="text-right">
              <div class="text-sm font-semibold">Builder Stock</div>
              <div class="text-xs text-gray-500 dark:text-space-400 mt-1">
                Available: ${builderAvailable} | Reserved: ${builderReserved}
              </div>
            </div>
          </div>
      `
      }
    })

    // If no builder inventory items, show a message
    if (builderInventoryItems === 0) {
      inventoryHtml += `
      <div class= "text-center p-4 text-gray-500 dark:text-space-400 bg-gray-100 dark:bg-space-800 rounded-lg" >
        No builder inventory available
      </div>
      `
    }
    inventoryHtml += '</div>'

    inventoryHtml += '</div>'
    this.inventoryTarget.innerHTML = inventoryHtml
  }

  async populateRelated() {
    if (!this.productData.related_items) {
      this.relatedTarget.innerHTML = `
        <div class="text-center p-8 text-gray-500 dark:text-space-400">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
          <p class="text-lg font-medium">No related products available</p>
        </div>
      `
      return
    }

    const relatedItems = typeof this.productData.related_items === 'string'
      ? this.productData.related_items.split(/;\s*/)
      : this.productData.related_items

    if (relatedItems.length === 0) {
      this.relatedTarget.innerHTML = '<div class="text-center p-6 text-gray-500 dark:text-space-400">No related products available</div>'
      return
    }

    // Fetch details for all related products
    const relatedProductsDetails = await Promise.all(
      relatedItems.map(async (sku) => {
        try {
          const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

          if (!response.ok) {
            return {
              sku,
              notInSystem: true
            }
          }

          const data = await response.json()
          const product = data.results && data.results[0]

          return product ? {
            ...product,
            notInSystem: false
          } : {
            sku,
            notInSystem: true
          }
        } catch (error) {
          console.error(`Error fetching related product ${sku}:`, error)
          return {
            sku,
            notInSystem: true
          }
        }
      })
    )

    // Sort products: in-system products first, then not-in-system
    const sortedProducts = relatedProductsDetails.sort((a, b) => {
      if (a.notInSystem && !b.notInSystem) return 1
      if (!a.notInSystem && b.notInSystem) return -1
      return 0
    })

    let relatedHtml = '<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">'

    sortedProducts.forEach(product => {
      // Truncate title if too long
      const truncateTitle = (title, maxLength = 50) =>
        title && title.length > maxLength
          ? title.substring(0, maxLength) + '...'
          : title || 'Untitled Product'

      if (product.notInSystem) {
        // Product not in system
        relatedHtml += `
          <div class="flex items-center bg-white dark:bg-space-800 border border-gray-200 dark:border-space-700 rounded-lg p-4">
            <div class="w-16 h-16 flex items-center justify-center text-gray-400 dark:text-space-500 mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="flex-1">
              <div class="text-sm font-medium text-gray-700 dark:text-space-300">
                SKU: ${product.sku}
              </div>
              <div class="text-xs text-gray-500 dark:text-space-400">
                Not in the Onyx system
              </div>
            </div>
          </div>
        `
      } else {
        // Product in system
        relatedHtml += `
          <div class="flex bg-white dark:bg-space-800 border border-gray-200 dark:border-space-700 rounded-lg p-4">
            <div class="w-24 h-24 flex items-center justify-center mr-4">
              <img 
                src="${product.image || '/placeholder-image.svg'}" 
                alt="${truncateTitle(product.title)}" 
                class="max-h-full max-w-full rounded-md overflow-hidden object-contain"
              />
            </div>
            <div class="flex-1">
              <div class="text-sm font-medium mb-1 line-clamp-2">${truncateTitle(product.title)}</div>
              <div class="text-xs text-gray-500 dark:text-space-400 mb-2">
                SKU: ${product.sku}
              </div>
              <div class="text-sm font-semibold text-gray-900 dark:text-white mb-2">
                ${this.formatCurrency(product.price || 0)}
              </div>
              <div class="flex justify-end space-x-2">
                <button type="button" 
                        class="text-sm bg-blue-600 hover:bg-blue-500 text-white px-3 py-1 rounded-md"
                        data-action="click->product-details#fetchRelatedProduct"
                        data-sku="${product.sku}">
                  View Details
                </button>
                <button type="button" 
                        class="text-sm bg-green-600 hover:bg-green-500 text-white px-3 py-1 rounded-md"
                        data-action="click->product-details#addRelatedToQuote"
                        data-product-sku="${product.sku}">
                  Add Item
                </button>
              </div>
            </div>
          </div>
        `
      }
    })

    relatedHtml += '</div>'
    this.relatedTarget.innerHTML = relatedHtml
  }

  populateSpecifications() {
    if (!this.productData.spec_table) {
      this.specsTarget.innerHTML = `
        <div class="text-center p-8 text-gray-500 dark:text-space-400">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <p class="text-lg font-medium">No specifications available</p>
        </div>
      `
      return
    }

    if (this.productData.spec_table.includes('<table')) {
      let enhancedTable = this.productData.spec_table
        .replace(/inline_sd_table/g, 'w-full')
        .replace(/inline_sd_header/g, 'bg-gray-100 dark:bg-space-800 px-4 py-2 text-left text-xs font-medium text-gray-700 dark:text-space-300 uppercase tracking-wider')
        .replace(/inline_sd_even_cell/g, 'px-4 py-2 text-sm text-gray-900 dark:text-white')
        .replace(/inline_sd_odd_cell/g, 'px-4 py-2 text-sm text-gray-900 dark:text-white bg-gray-50 dark:bg-space-800/50')
        .replace(/inline_sd_row/g, '')
        .replace(/inline_sd_cell_row/g, '')

      this.specsTarget.innerHTML = enhancedTable
    } else {
      this.specsTarget.innerHTML = '<div class="text-center p-6 text-gray-500 dark:text-space-400">No specifications available</div>'
    }
  }

  populateFiles() {
    if (!this.productData.files || this.productData.files.length === 0) {
      this.filesTarget.innerHTML = `
        <div class= "col-span-full text-center p-8 text-gray-500 dark:text-space-400" >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <p class="text-lg font-medium">No files available</p>
        </div>
      `
      return
    }

    let filesHtml = ''

    this.productData.files.forEach(file => {
      const fileExtension = file.filename ? file.filename.split('.').pop().toLowerCase() : 'pdf'
      const iconClass = this.getFileIconClass(fileExtension)

      filesHtml += `
        <a href = "${file.url}" target = "_blank" class= "border border-gray-200 dark:border-space-700 rounded-lg p-4 flex items-center gap-3 hover:bg-gray-50 dark:hover:bg-space-800 transition" >
          <div class="w-8 h-8 ${iconClass} flex items-center justify-center rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          </div>
          <div class="flex-1">
            <div class="text-sm font-medium line-clamp-1">${file.title || file.filename}</div>
            <div class="text-xs text-gray-500 dark:text-space-400">${fileExtension.toUpperCase()}</div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
        </a>
      `
    })

    this.filesTarget.innerHTML = filesHtml
  }

  getFileIconClass(extension) {
    const fileTypes = {
      pdf: 'bg-red-100 dark:bg-red-900/30 text-red-600 dark:text-red-400',
      doc: 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400',
      docx: 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400',
      xls: 'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400',
      xlsx: 'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400',
      jpg: 'bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400',
      jpeg: 'bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400',
      png: 'bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400',
      gif: 'bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400',
      txt: 'bg-gray-100 dark:bg-gray-900/30 text-gray-600 dark:text-gray-400'
    }

    return fileTypes[extension] || 'bg-gray-100 dark:bg-gray-900/30 text-gray-600 dark:text-gray-400'
  }

  async fetchRelatedProduct(event) {
    event.preventDefault()
    event.stopPropagation() // Prevent event bubbling

    const sku = event.currentTarget.dataset.sku

    try {
      // Fixed the URL by removing an extra space before the SKU parameter
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error("Product not found")
      }

      const data = await response.json()
      const product = data.results && data.results[0]

      if (!product) {
        throw new Error("Product data is missing")
      }

      this.closeModal()
      setTimeout(() => {
        this.productData = product
        this.performTabSwitch("description")
        this.populateProductDetails()
        this.addButtonTarget.classList.remove('hidden')
        this.addButtonTarget.classList.add('inline-flex')
        this.modalTarget.classList.remove('hidden')
        document.body.classList.add('overflow-hidden')
      }, 300)

    } catch (error) {
      console.error('Error fetching related product details:', error)
      this.showToast('Error fetching related product details', 'error')
    }
  }

  addToQuote(event) {
    event.preventDefault()
    event.stopPropagation() // Prevent event bubbling

    if (!this.productData) {
      console.error('No product data available')
      return
    }

    const addProductEvent = new CustomEvent('product-details:add-to-quote', {
      bubbles: true,
      detail: { product: this.productData }
    })

    this.element.dispatchEvent(addProductEvent)

    this.closeModal()

    this.showToast(`${this.productData.title} added to quote`)
  }

  addRelatedToQuote(event) {
    event.preventDefault()
    event.stopPropagation() // Prevent event bubbling

    const sku = event.currentTarget.dataset.productSku // Changed from dataset.sku

    // Fetch the product details first
    fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Product not found")
        }
        return response.json()
      })
      .then(data => {
        const product = data.results && data.results[0]

        if (!product) {
          throw new Error("Product data is missing")
        }

        // Dispatch custom event to add product to quote
        const addProductEvent = new CustomEvent('product-details:add-to-quote', {
          bubbles: true,
          detail: { product: product }
        })

        this.element.dispatchEvent(addProductEvent)

        // Show toast notification
        this.showToast(`${product.title} added to quote`)
      })
      .catch(error => {
        console.error('Error adding related product to quote:', error)
        this.showToast('Error adding product to quote', 'error')
      })
  }

  showToast(message, type = 'success') {
    const toast = document.createElement('div')

    if (type === 'success') {
      toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    } else {
      toast.className = 'fixed bottom-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    }

    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }
}