import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  search(event) {
    // Simply toggle clear button visibility without submitting
    const clearButton = this.element.querySelector('button[aria-label="Clear search"]')
    if (clearButton) {
      clearButton.classList.toggle('hidden', event.target.value.trim() === '')
    }
  }

  clear(e) {
    e.preventDefault()
    this.inputTarget.value = ''
    const clearButton = this.element.querySelector('button[aria-label="Clear search"]')
    if (clearButton) {
      clearButton.classList.add('hidden')
    }
    // Do not submit the form
  }
}