// app/javascript/controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    // Setup document click handler to close dropdown when clicking outside
    this.boundClickHandler = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.boundClickHandler);

    // Add handler for escape key
    this.boundEscapeHandler = this.handleEscape.bind(this);
    document.addEventListener("keydown", this.boundEscapeHandler);

    // Add mutation observer to watch for menu visibility changes
    this.setupObserver();
  }

  disconnect() {
    // Remove event listeners when controller disconnects
    document.removeEventListener("click", this.boundClickHandler);
    document.removeEventListener("keydown", this.boundEscapeHandler);

    // Disconnect observer
    if (this.observer) {
      this.observer.disconnect();
    }

    // Remove any portal if it exists
    this.removePortal();
  }

  setupObserver() {
    // Watch for class changes to detect when the menu is shown/hidden
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          if (!this.menuTarget.classList.contains("hidden")) {
            // Menu became visible - handle positioning
            this.handleVisibilityChange();
          } else {
            // Menu was hidden - remove portal if it exists
            this.removePortal();
          }
        }
      });
    });

    this.observer.observe(this.menuTarget, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }

  toggle(event) {
    // Stop propagation so the document click handler doesn't immediately trigger
    if (event) {
      event.stopPropagation();
    }

    // Store a reference to the element that triggered the toggle
    this.toggleElement = event ? event.currentTarget : null;

    // Toggle the menu visibility
    this.menuTarget.classList.toggle("hidden");

    // Position will be handled by the observer
  }

  hide(event) {
    // Hide the menu
    this.menuTarget.classList.add("hidden");
  }

  handleClickOutside(event) {
    // If the menu is hidden, do nothing
    if (this.menuTarget.classList.contains("hidden")) {
      return;
    }

    // If the click is on the toggle element itself, do nothing
    if (
      this.toggleElement === event.target ||
      this.toggleElement?.contains(event.target)
    ) {
      return;
    }

    // If the click is inside the dropdown container, do nothing
    if (this.element.contains(event.target)) {
      return;
    }

    // If we have a portal and the click is inside the portal, do nothing
    if (this.portalEl && this.portalEl.contains(event.target)) {
      return;
    }

    // Otherwise, hide the dropdown
    this.menuTarget.classList.add("hidden");
  }

  handleEscape(event) {
    if (
      !this.menuTarget.classList.contains("hidden") &&
      event.key === "Escape"
    ) {
      this.menuTarget.classList.add("hidden");
      event.preventDefault();
    }
  }

  handleVisibilityChange() {
    // Only continue if the menu is visible
    if (this.menuTarget.classList.contains("hidden")) {
      return;
    }

    // Check for conditions when we need to create a portal
    const menuRect = this.menuTarget.getBoundingClientRect();
    const container = this.findOverflowParent(this.menuTarget);

    if (container) {
      // Create a portal since we found an overflow container that might clip the menu
      this.createPortal();
    } else {
      // Just handle smart positioning
      this.positionMenu();
    }
  }

  findOverflowParent(element) {
    // Skip the checks if the element is the root element
    if (!element || element === document.documentElement) {
      return null;
    }

    // Get the parent element
    const parent = element.parentElement;

    // If there's no parent, return null
    if (!parent) {
      return null;
    }

    // Get computed style of the parent
    const style = window.getComputedStyle(parent);

    // Check if this parent clips overflow
    if (
      style.overflow === "hidden" ||
      style.overflowX === "hidden" ||
      style.overflowY === "hidden" ||
      style.overflow === "clip" ||
      style.overflowX === "clip" ||
      style.overflowY === "clip"
    ) {
      return parent;
    }

    // Recursively check the parent's parent
    return this.findOverflowParent(parent);
  }

  createPortal() {
    // Don't create a portal if one already exists
    if (this.portalEl) {
      this.positionPortal();
      return;
    }

    // Create a container for the portal
    this.portalEl = document.createElement("div");
    this.portalEl.className = "dropdown-portal";
    document.body.appendChild(this.portalEl);

    // Clone the menu
    this.menuClone = this.menuTarget.cloneNode(true);
    this.menuClone.classList.remove("hidden");

    // Add the menu clone to the portal
    this.portalEl.appendChild(this.menuClone);

    // Hide the original menu
    this.menuTarget.style.visibility = "hidden";

    // Position the portal
    this.positionPortal();

    // Handle clicks in the portal
    this.portalEl.addEventListener("click", (e) => {
      // Find the clicked element
      const clickedEl = e.target;

      // Find all interactive elements in the portal
      const links = this.portalEl.querySelectorAll("a, button");

      // Check if the click was on an interactive element
      for (const link of links) {
        if (link === clickedEl || link.contains(clickedEl)) {
          // Find the corresponding element in the original menu
          const selector = this.getUniqueSelector(link);
          const originalLink = this.menuTarget.querySelector(selector);

          if (originalLink) {
            // Trigger a click on the original element
            originalLink.click();
          }

          // Hide the dropdown
          this.menuTarget.classList.add("hidden");
          break;
        }
      }
    });
  }

  getUniqueSelector(element) {
    // This is a simple implementation - in a real app you might want something more robust
    if (element.id) {
      return `#${element.id}`;
    }

    if (element.className) {
      const classes = element.className
        .split(" ")
        .filter((c) => c.trim() !== "");
      if (classes.length > 0) {
        return `${element.tagName.toLowerCase()}.${classes.join(".")}`;
      }
    }

    // If we can't get a better selector, use the element's text content as a matcher
    if (element.textContent.trim()) {
      return `${element.tagName.toLowerCase()}:contains("${element.textContent.trim()}")`;
    }

    return element.tagName.toLowerCase();
  }

  positionPortal() {
    if (!this.portalEl) return;

    // Get the button that triggered the dropdown
    const button = this.toggleElement || this.element.querySelector("button");
    const buttonRect = button.getBoundingClientRect();

    // Get the menu dimensions
    const menuRect = this.menuClone.getBoundingClientRect();

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate optimal position
    let left = buttonRect.right - menuRect.width;
    let top = buttonRect.bottom + 8; // Add some spacing

    // Check right edge
    if (left < 0) {
      left = buttonRect.left;
    }

    // Check left edge
    if (left + menuRect.width > viewportWidth) {
      left = viewportWidth - menuRect.width - 8; // Keep 8px from edge
    }

    // Check bottom edge
    if (top + menuRect.height > viewportHeight) {
      top = buttonRect.top - menuRect.height - 8; // Position above with spacing
    }

    // Add scroll position
    left += window.pageXOffset;
    top += window.pageYOffset;

    // Position the portal
    this.portalEl.style.position = "absolute";
    this.portalEl.style.top = `${top}px`;
    this.portalEl.style.left = `${left}px`;
    this.portalEl.style.zIndex = "9999";
  }

  positionMenu() {
    // Get button and menu rectangles
    const button = this.toggleElement || this.element.querySelector("button");
    const buttonRect = button.getBoundingClientRect();
    const menuRect = this.menuTarget.getBoundingClientRect();

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Reset position to get natural dimensions
    this.menuTarget.style.left = "auto";
    this.menuTarget.style.right = "0";
    this.menuTarget.style.top = "auto";
    this.menuTarget.style.bottom = "auto";

    // Ensure the menu has positioning that allows us to move it
    const menuStyle = window.getComputedStyle(this.menuTarget);
    if (menuStyle.position === "static") {
      this.menuTarget.style.position = "absolute";
    }

    // Check right edge
    if (buttonRect.right - menuRect.width < 0) {
      this.menuTarget.style.right = "auto";
      this.menuTarget.style.left = "0";
    }

    // Check left edge
    if (buttonRect.left + menuRect.width > viewportWidth) {
      this.menuTarget.style.left = "auto";
      this.menuTarget.style.right = "0";
    }

    // Check bottom edge
    if (buttonRect.bottom + menuRect.height > viewportHeight) {
      this.menuTarget.style.top = "auto";
      this.menuTarget.style.bottom = "100%";
      this.menuTarget.style.marginTop = "0";
      this.menuTarget.style.marginBottom = "8px";
    } else {
      this.menuTarget.style.top = "100%";
      this.menuTarget.style.bottom = "auto";
      this.menuTarget.style.marginTop = "8px";
      this.menuTarget.style.marginBottom = "0";
    }
  }

  removePortal() {
    if (!this.portalEl) return;

    // Remove the portal element
    this.portalEl.remove();
    this.portalEl = null;
    this.menuClone = null;

    // Make the original menu visible again
    if (this.menuTarget) {
      this.menuTarget.style.visibility = "";
    }
  }
}
