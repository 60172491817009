// app/javascript/controllers/inventory_level_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "warehouseName", "currentQuantity", "form"];

  connect() {
    console.log("Inventory Level Controller connected");
  }

  open(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const warehouseName = button.dataset.warehouseName;
    const normalQuantity = button.dataset.currentQuantity;
    const builderQuantity = button.dataset.builderQuantity;
    const actionUrl = button.dataset.url;

    // Save both quantities on the controller instance for later use.
    this.normalQuantity = normalQuantity;
    this.builderQuantity = builderQuantity;

    // Populate modal targets.
    if (this.warehouseNameTarget) {
      this.warehouseNameTarget.textContent = warehouseName;
    }
    // Default to Normal quantity for display.
    if (this.currentQuantityTarget) {
      this.currentQuantityTarget.textContent = normalQuantity;
    }
    if (this.formTarget && actionUrl) {
      this.formTarget.action = actionUrl;
    }

    // Open modal.
    this.modalTarget.classList.remove("hidden");
    document.body.classList.add("overflow-hidden");
  }

  close(event) {
    event.preventDefault();
    this.modalTarget.classList.add("hidden");
    document.body.classList.remove("overflow-hidden");
  }

  updateCurrentQuantity(event) {
    const selectedType = event.target.value;
    if (this.currentQuantityTarget) {
      if (selectedType === "builder") {
        this.currentQuantityTarget.textContent = this.builderQuantity;
      } else {
        this.currentQuantityTarget.textContent = this.normalQuantity;
      }
    }
  }

  clickOutside(event) {
    if (event.target === this.modalTarget) {
      this.close(event);
    }
  }
}
