// app/javascript/controllers/sidebar_toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "content", "icon", "showText", "hideText",
    // Dropdown targets
    "dropdown", "dropdownContent",
    // Summary toggle targets
    "summaryContent", "summaryIcon", "summaryShowText", "summaryHideText"
  ]

  static classes = ["hidden"]

  connect() {
    // Call initial screen size check to set proper initial state
    this.checkScreenSize()

    // Listen for screen resize events
    this.boundCheckScreenSize = this.checkScreenSize.bind(this)
    window.addEventListener('resize', this.boundCheckScreenSize)

    // Restore saved section states
    this.restoreSectionStates()

    // Ensure summary is collapsed by default
    if (this.hasSummaryContentTarget) {
      this.hideSummary()
    }

    // Dropdown functionality
    // Close dropdown when clicking outside
    this.boundClickOutside = this.clickOutside.bind(this)
    document.addEventListener('click', this.boundClickOutside)

    // Initialize timeout variable for dropdown
    this.closeTimeout = null
  }

  disconnect() {
    // Clean up event listeners with proper bound methods
    window.removeEventListener('resize', this.boundCheckScreenSize)
    document.removeEventListener('click', this.boundClickOutside)
  }

  // Sidebar toggle methods
  checkScreenSize() {
    // Check if we're on mobile (less than lg breakpoint in Tailwind, which is 1024px)
    const isMobile = window.innerWidth < 1024
    if (isMobile) {
      // On mobile, start collapsed
      this.collapse()
    } else {
      // On desktop, always expanded (handled by CSS)
      // Don't use fixed heights that could cause issues
      this.contentTarget.style.height = ""
      this.contentTarget.classList.remove("h-0")
      this.contentTarget.classList.add("h-auto")
      this.contentTarget.style.overflow = "auto"
      this.contentTarget.style.maxHeight = ""
    }
  }

  toggle() {
    if (this.isExpanded()) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    // Show the content - use more flexible approach to height
    this.contentTarget.classList.remove("h-0")
    this.contentTarget.classList.add("h-auto")

    // Instead of fixed vh value, use a more adaptive approach
    // Calculate available height minus header space
    const windowHeight = window.innerHeight
    const headerHeight = 60 // Approximate header height, adjust as needed
    const maxHeight = windowHeight - headerHeight

    this.contentTarget.style.maxHeight = `${maxHeight}px`
    this.contentTarget.style.overflow = "auto" // Make scrollable

    // Rotate the icon
    this.iconTarget.classList.add("rotate-180")

    // Update button text
    this.showTextTarget.classList.add("hidden")
    this.hideTextTarget.classList.remove("hidden")

    // Add bottom padding for mobile view - with a slight delay to let transitions complete
    setTimeout(() => {
      document.body.classList.add("pb-safe")
    }, 50)
  }

  collapse() {
    // Hide the content
    this.contentTarget.classList.add("h-0")
    this.contentTarget.classList.remove("h-auto")
    this.contentTarget.style.maxHeight = "0" // Reset max height
    this.contentTarget.style.overflow = "hidden" // Hide overflow

    // Reset the icon
    this.iconTarget.classList.remove("rotate-180")

    // Update button text
    this.showTextTarget.classList.remove("hidden")
    this.hideTextTarget.classList.add("hidden")

    // Remove bottom padding - with a slight delay to avoid layout jumps
    setTimeout(() => {
      document.body.classList.remove("pb-safe")
    }, 50)
  }

  isExpanded() {
    return !this.contentTarget.classList.contains("h-0")
  }

  // Section Toggle Methods
  toggleSection(event) {
    const sectionContent = event.currentTarget.nextElementSibling
    const sectionIcon = event.currentTarget.querySelector('[data-section-toggle-icon]')

    // Toggle hidden class on content
    sectionContent.classList.toggle('hidden')

    // Rotate icon
    sectionIcon.classList.toggle('rotate-180')

    // Persist state
    this.persistSectionState(sectionContent)

    // Recalculate scrollable area after toggle to prevent layout issues
    this.updateScrollableArea()
  }

  // Summary Toggle Methods
  toggleSummary() {
    if (this.hasSummaryContentTarget) {
      if (this.summaryContentTarget.classList.contains('hidden')) {
        this.showSummary()
      } else {
        this.hideSummary()
      }

      // Recalculate scrollable area after toggle
      this.updateScrollableArea()

      // Persist summary state
      this.persistSummaryState()
    }
  }

  showSummary() {
    if (this.hasSummaryContentTarget) {
      this.summaryContentTarget.classList.remove('hidden')

      if (this.hasSummaryIconTarget) {
        this.summaryIconTarget.classList.add('rotate-180')
      }

      if (this.hasSummaryShowTextTarget && this.hasSummaryHideTextTarget) {
        this.summaryShowTextTarget.classList.add('hidden')
        this.summaryHideTextTarget.classList.remove('hidden')
      }
    }
  }

  hideSummary() {
    if (this.hasSummaryContentTarget) {
      this.summaryContentTarget.classList.add('hidden')

      if (this.hasSummaryIconTarget) {
        this.summaryIconTarget.classList.remove('rotate-180')
      }

      if (this.hasSummaryShowTextTarget && this.hasSummaryHideTextTarget) {
        this.summaryShowTextTarget.classList.remove('hidden')
        this.summaryHideTextTarget.classList.add('hidden')
      }
    }
  }

  persistSummaryState() {
    if (this.hasSummaryContentTarget) {
      const isHidden = this.summaryContentTarget.classList.contains('hidden')
      localStorage.setItem('section-summary', isHidden ? 'collapsed' : 'expanded')
    }
  }

  // New method to ensure proper scrollable area after content changes
  updateScrollableArea() {
    // Only apply in expanded state
    if (this.isExpanded()) {
      // Delay slightly to allow DOM updates
      setTimeout(() => {
        // Briefly remove max-height to let content expand naturally
        this.contentTarget.style.maxHeight = "none"

        // Then reapply appropriate constraints
        requestAnimationFrame(() => {
          if (window.innerWidth < 1024) {
            const windowHeight = window.innerHeight
            const headerHeight = 60
            this.contentTarget.style.maxHeight = `${windowHeight - headerHeight}px`
          } else {
            // Desktop view should use natural height or container constraints
            this.contentTarget.style.maxHeight = ""
          }
        })
      }, 10)
    }
  }

  persistSectionState(sectionContent) {
    const sectionId = sectionContent.closest('[data-section-id]')?.dataset.sectionId
    if (sectionId) {
      const isHidden = sectionContent.classList.contains('hidden')
      localStorage.setItem(`section-${sectionId}`, isHidden ? 'collapsed' : 'expanded')
    }
  }

  restoreSectionStates() {
    // Restore states for all sections with IDs
    const sections = this.element.querySelectorAll('[data-section-id]')
    sections.forEach(section => {
      const sectionId = section.dataset.sectionId
      const sectionContent = section.querySelector('[data-section-content]')
      const sectionIcon = section.querySelector('[data-section-toggle-icon]')
      const storedState = localStorage.getItem(`section-${sectionId}`)

      if (storedState === 'collapsed') {
        sectionContent.classList.add('hidden')
        sectionIcon.classList.remove('rotate-180')
      } else if (storedState === 'expanded') {
        sectionContent.classList.remove('hidden')
        sectionIcon.classList.add('rotate-180')
      }
    })

    // Restore summary section state if available
    if (this.hasSummaryContentTarget) {
      const summaryState = localStorage.getItem('section-summary')
      if (summaryState === 'expanded') {
        this.showSummary()
      } else {
        // Default to collapsed or respect stored state
        this.hideSummary()
      }
    }

    // After restoring states, update scrollable area
    this.updateScrollableArea()
  }

  // Dropdown Methods
  openDropdown(event) {
    // Clear any pending close timeout
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
      this.closeTimeout = null
    }

    // Find the closest dropdown element or use the one from the event
    const dropdown = event.currentTarget.closest('[data-sidebar-toggle-target="dropdown"]') || event.currentTarget

    // Find the dropdown content within it
    if (dropdown) {
      const content = dropdown.querySelector('[data-sidebar-toggle-target="dropdownContent"]')
      if (content) {
        content.classList.remove("hidden")
      }
    }
  }

  closeDropdown() {
    // Find all dropdown contents and hide them
    if (this.hasDropdownContentTarget) {
      this.dropdownContentTargets.forEach(content => {
        content.classList.add("hidden")
      })
    } else {
      // Fallback if targets aren't registered correctly
      const contents = this.element.querySelectorAll('[data-sidebar-toggle-target="dropdownContent"]')
      contents.forEach(content => {
        content.classList.add("hidden")
      })
    }
  }

  delayedCloseDropdown() {
    // Set a timeout to close the dropdown
    this.closeTimeout = setTimeout(() => {
      this.closeDropdown()
    }, 200) // Short delay to allow for interactions
  }

  toggleDropdown(event) {
    // Find the dropdown content
    const dropdown = event.currentTarget.closest('[data-sidebar-toggle-target="dropdown"]')
    const content = dropdown.querySelector('[data-sidebar-toggle-target="dropdownContent"]')

    // Toggle the visibility of dropdown content
    if (content) {
      content.classList.toggle("hidden")

      // Update scrollable area after dropdown toggle
      this.updateScrollableArea()
    }
  }

  clickOutside(event) {
    // Don't process if we're not using dropdown functionality
    if (!this.hasDropdownTarget && !document.querySelector('[data-sidebar-toggle-target="dropdown"]')) {
      return
    }

    // Skip if the click was inside a modal
    if (event.target.closest('[data-controller="address-selector"], [data-controller="address-form"], [data-controller="customer-form"]')) {
      return
    }

    // Collect all dropdown elements
    let dropdowns = []
    if (this.hasDropdownTarget) {
      dropdowns = this.dropdownTargets
    } else {
      dropdowns = this.element.querySelectorAll('[data-sidebar-toggle-target="dropdown"]')
    }

    // Check each dropdown
    dropdowns.forEach(dropdown => {
      // Skip if the click was inside this dropdown
      if (dropdown.contains(event.target)) {
        return
      }

      // Find and hide the dropdown content
      const content = dropdown.querySelector('[data-sidebar-toggle-target="dropdownContent"]')
      if (content && !content.classList.contains("hidden")) {
        content.classList.add("hidden")

        // Update scrollable area after dropdown close
        this.updateScrollableArea()
      }
    })
  }
}