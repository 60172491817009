import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Receive scan controller connected.");
  }

  // Optionally, handle the form's AJAX success event
  // For example, listen for the 'ajax:success' event and update the result element:
  initialize() {
    this.element.addEventListener("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("receive-result").innerHTML =
        `<div class="p-4 bg-green-100 text-green-800 rounded">${data.message}</div>`;
      // Optionally clear the form
      this.element.reset();
    });

    this.element.addEventListener("ajax:error", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("receive-result").innerHTML =
        `<div class="p-4 bg-red-100 text-red-800 rounded">Error: ${data.message}</div>`;
    });
  }
}
