import { Controller } from "@hotwired/stimulus";

// Define initial sample data
const initialExample = [
  {
    name: "CAPACITY",
    rows: [
      {
        leftCell: "Unit Capacity",
        rightCell: "126 12 oz. Cans/14 Wine Bottles",
        type: "even",
      },
      {
        leftCell: "Total Capacity (cubic feet)",
        rightCell: "5.1 cu ft",
        type: "odd",
      },
    ],
  },
  {
    name: "FILTER",
    rows: [
      { leftCell: "App Filter - Dispenser", rightCell: "No", type: "even" },
      { leftCell: "App Filter - Icemaker", rightCell: "No", type: "odd" },
    ],
  },
];

export default class extends Controller {
  static targets = [
    "sectionsContainer",
    "htmlOutput",
    "preview",
    "importModal",
    "importTextarea",
  ];

  connect() {
    // Initialize sections with the initial sample data
    this.sections = JSON.parse(JSON.stringify(initialExample));
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
    this.hideImportModal();
  }

  renderSections() {
    let html = "";
    this.sections.forEach((section, sectionIndex) => {
      html += `
      <div class="mb-6 bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden" data-section-index="${sectionIndex}">
        <div class="flex items-center bg-gray-100 dark:bg-gray-700 p-4">
          <input type="text" value="${section.name}"
            data-action="input->spec-table#updateSection"
            data-section-index="${sectionIndex}"
            placeholder="Section Name"
            class="flex-grow mr-4 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" />
          <div class="flex space-x-2">
            <button data-action="click->spec-table#removeSection" data-section-index="${sectionIndex}" class="px-3 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors">
              Remove Section
            </button>
            <button data-action="click->spec-table#addRow" data-section-index="${sectionIndex}" class="px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
              Add Row
            </button>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-200 dark:bg-gray-600">
                <th class="p-3 text-left">Left Cell</th>
                <th class="p-3 text-left">Right Cell</th>
                <th class="p-3 text-left">Row Type</th>
                <th class="p-3 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              ${section.rows
                .map(
                  (row, rowIndex) => `
                <tr class="border-b" data-section-index="${sectionIndex}" data-row-index="${rowIndex}">
                  <td class="p-3">
                    <input type="text" value="${row.leftCell}"
                      data-action="input->spec-table#updateRow"
                      data-section-index="${sectionIndex}"
                      data-row-index="${rowIndex}"
                      data-field="leftCell"
                      class="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" />
                  </td>
                  <td class="p-3">
                    <input type="text" value="${row.rightCell}"
                      data-action="input->spec-table#updateRow"
                      data-section-index="${sectionIndex}"
                      data-row-index="${rowIndex}"
                      data-field="rightCell"
                      class="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" />
                  </td>
                  <td class="p-3">
                    <button data-action="click->spec-table#toggleRowType"
                      data-section-index="${sectionIndex}"
                      data-row-index="${rowIndex}"
                      class="px-3 py-1 rounded ${row.type === "even" ? "bg-blue-100 text-blue-800" : "bg-gray-100 text-gray-800"}">
                      ${row.type}
                    </button>
                  </td>
                  <td class="p-3">
                    <button data-action="click->spec-table#removeRow"
                      data-section-index="${sectionIndex}"
                      data-row-index="${rowIndex}"
                      class="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors">
                      Remove
                    </button>
                  </td>
                </tr>
              `,
                )
                .join("")}
            </tbody>
          </table>
        </div>
      </div>
      `;
    });
    this.sectionsContainerTarget.innerHTML = html;
  }

  updateSection(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    this.sections[sectionIndex].name = event.target.value;
    this.generateHTML();
    this.renderPreview();
  }

  updateRow(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    const rowIndex = event.target.dataset.rowIndex;
    const field = event.target.dataset.field;
    this.sections[sectionIndex].rows[rowIndex][field] = event.target.value;
    this.generateHTML();
    this.renderPreview();
  }

  toggleRowType(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    const rowIndex = event.target.dataset.rowIndex;
    const currentType = this.sections[sectionIndex].rows[rowIndex].type;
    this.sections[sectionIndex].rows[rowIndex].type =
      currentType === "even" ? "odd" : "even";
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  removeRow(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    const rowIndex = event.target.dataset.rowIndex;
    this.sections[sectionIndex].rows.splice(rowIndex, 1);
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  removeSection(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    this.sections.splice(sectionIndex, 1);
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  addRow(event) {
    const sectionIndex = event.target.dataset.sectionIndex;
    const newRowType =
      this.sections[sectionIndex].rows.length > 0
        ? this.sections[sectionIndex].rows[
            this.sections[sectionIndex].rows.length - 1
          ].type === "even"
          ? "odd"
          : "even"
        : "even";
    this.sections[sectionIndex].rows.push({
      leftCell: "",
      rightCell: "",
      type: newRowType,
    });
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  addSection() {
    this.sections.push({
      name: "New Section",
      rows: [{ leftCell: "", rightCell: "", type: "even" }],
    });
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  generateHTML() {
    const html = `<table class="inline_sd_table">
${this.sections
  .map(
    (
      section,
    ) => `  <tr class="inline_sd_row"><td class="inline_sd_header" colspan="2">${section.name.toUpperCase()}</td></tr>
${section.rows
  .map(
    (row) => `  <tr class="inline_sd_cell_row">
    <td class="inline_sd_${row.type}_cell">${row.leftCell}</td>
    <td class="inline_sd_${row.type}_cell">${row.rightCell}</td>
  </tr>`,
  )
  .join("\n")}`,
  )
  .join("\n")}
</table>`;
    this.htmlOutputTarget.value = html;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.htmlOutputTarget.value).then(() => {
      alert("HTML copied to clipboard!");
    });
  }

  resetToExample() {
    this.sections = JSON.parse(JSON.stringify(initialExample));
    this.renderSections();
    this.generateHTML();
    this.renderPreview();
  }

  importTemplate() {
    try {
      const parsedSections = parseHTMLTemplate(this.importTextareaTarget.value);
      this.sections = parsedSections;
      this.hideImportModal();
      this.renderSections();
      this.generateHTML();
      this.renderPreview();
    } catch (error) {
      alert(error.message);
    }
  }

  renderPreview() {
    const html = this.htmlOutputTarget.value.trim();
    if (this.hasPreviewTarget) {
      this.previewTarget.innerHTML =
        html !== ""
          ? html
          : `<p class="text-gray-500 italic text-center">Awaiting Generation</p>`;
    }
  }

  // Methods for managing the import modal
  showImportModal() {
    this.importModalTarget.classList.remove("hidden");
  }

  hideImportModal() {
    this.importModalTarget.classList.add("hidden");
  }
}

// Global helper to parse an HTML template string
function parseHTMLTemplate(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const table = doc.querySelector("table.inline_sd_table");
  if (!table) {
    throw new Error(
      'Invalid HTML template. Must contain a table with class "inline_sd_table"',
    );
  }
  const sections = [];
  let currentSection = null;
  table.querySelectorAll("tr").forEach((row) => {
    if (row.querySelector(".inline_sd_header")) {
      if (currentSection) {
        sections.push(currentSection);
      }
      const sectionName = row.querySelector("td").textContent.trim();
      currentSection = { name: sectionName, rows: [] };
    } else if (row.classList.contains("inline_sd_cell_row")) {
      const cells = row.querySelectorAll("td");
      if (cells.length === 2 && currentSection) {
        const rowType = cells[0].className.includes("even_cell")
          ? "even"
          : "odd";
        currentSection.rows.push({
          leftCell: cells[0].textContent.trim(),
          rightCell: cells[1].textContent.trim(),
          type: rowType,
        });
      }
    }
  });
  if (currentSection) {
    sections.push(currentSection);
  }
  return sections;
}
