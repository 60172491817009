// builder_product_search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "searchInput",
    "modal",
    "modalSearchInput",
    "resultsContainer",
    "selectedCount",
    "addButton"
  ]

  connect() {
    this.searchTimeout = null
    this.searchResults = []
    this.selectedProducts = []
    this.debounceTime = 300 // ms to debounce search
    this.modalJustOpened = false // Track if modal was just opened

    // Delay setting up document click handler to prevent Firefox from immediately closing the modal
    setTimeout(() => {
      // Use capture phase for the document click handler
      document.addEventListener('click', this.handleOutsideClick = this.handleOutsideClickEvent.bind(this), true)
    }, 500)
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick, true)
  }

  // Separate method to handle outside clicks with proper binding
  handleOutsideClickEvent(e) {
    // Ignore clicks when modal was just opened
    if (this.modalJustOpened) {
      return
    }

    // Check if we have a modal and if the click was outside it
    if (this.hasModalTarget &&
      this.modalTarget &&
      !this.modalTarget.classList.contains('hidden') &&
      !this.modalTarget.contains(e.target) &&
      !e.target.closest('[data-builder-product-search-modal-open]')) {

      // Check if the click was on the search input itself
      if (this.hasSearchInputTarget && e.target === this.searchInputTarget) {
        return // Don't close if clicking the search input
      }

      this.closeModal()
    }
  }

  // Open the search modal when search input is focused
  openSearch(e) {
    // Stop the event immediately to prevent Firefox issues
    e.preventDefault()
    e.stopPropagation()

    // Only proceed if we have the modal target
    if (!this.hasModalTarget) return

    // Set flag to prevent accidental closing
    this.modalJustOpened = true

    // Open the modal
    this.modalTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')

    // Move focus to the modal's search input
    if (this.hasModalSearchInputTarget) {
      this.modalSearchInputTarget.focus()

      // Copy the value from the trigger input if it exists
      if (this.hasSearchInputTarget && this.searchInputTarget.value.trim()) {
        this.modalSearchInputTarget.value = this.searchInputTarget.value.trim()
        this.search({ target: this.modalSearchInputTarget })
      }
    }

    // Reset the flag after a longer delay for Firefox
    setTimeout(() => {
      this.modalJustOpened = false
    }, 300)

    // Stop event propagation at the capture phase too
    if (e.stopImmediatePropagation) {
      e.stopImmediatePropagation()
    }
  }

  // Close the search modal
  closeModal() {
    if (this.hasModalTarget) {
      this.modalTarget.classList.add('hidden')
      document.body.classList.remove('overflow-hidden')

      // Clear the search input
      if (this.hasSearchInputTarget) {
        this.searchInputTarget.value = ''
      }

      if (this.hasModalSearchInputTarget) {
        this.modalSearchInputTarget.value = ''
      }

      // Clear results
      if (this.hasResultsContainerTarget) {
        this.resultsContainerTarget.innerHTML = this.getEmptyResultsHTML()
      }

      // Reset selected products
      this.selectedProducts = []
      this.updateSelectedCount()
    }
  }

  // Handle search input with debounce
  search(e) {
    const query = e.target.value.trim()

    // Clear current timeout
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }

    // Clear results if query is empty
    if (!query) {
      if (this.hasResultsContainerTarget) {
        this.resultsContainerTarget.innerHTML = this.getEmptyResultsHTML()
      }
      this.searchResults = []
      return
    }

    // Debounce the search to avoid too many requests
    this.searchTimeout = setTimeout(() => {
      this.performSearch(query)
    }, this.debounceTime)
  }

  // Perform the API search
  async performSearch(query) {
    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.innerHTML = this.getLoadingHTML()
    }

    try {
      const response = await fetch(`/api/v1/products/search?query=${encodeURIComponent(query)}`)

      if (!response.ok) {
        throw new Error('Search request failed')
      }

      const data = await response.json()
      this.searchResults = data.results || []

      this.renderSearchResults()
    } catch (error) {
      console.error('Error searching products:', error)

      if (this.hasResultsContainerTarget) {
        this.resultsContainerTarget.innerHTML = this.getErrorHTML()
      }
    }
  }

  // Render search results
  renderSearchResults() {
    if (!this.hasResultsContainerTarget) return

    if (this.searchResults.length === 0) {
      this.resultsContainerTarget.innerHTML = this.getNoResultsHTML()
      return
    }

    let html = `
      <div class="mb-3 flex justify-between items-center">
        <div class="text-sm text-gray-500 dark:text-space-400">
          Found ${this.searchResults.length} products
        </div>
        <div class="flex items-center gap-2">
          <label class="flex items-center text-sm text-gray-600 dark:text-space-300">
            <input type="checkbox" class="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 dark:border-space-600" 
                  data-action="change->builder-product-search#toggleSelectAll">
            <span class="ml-2">Select All</span>
          </label>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-3">
    `

    this.searchResults.forEach(product => {
      const isSelected = this.selectedProducts.some(p => p.id === product.id)
      const stockLevel = this.calculateStockLevel(product.stock)
      const stockClass = this.getStockLevelClass(stockLevel)

      html += `
        <div class="border border-gray-200 dark:border-space-700 rounded-lg overflow-hidden bg-white dark:bg-space-800 hover:shadow-md transition-shadow">
          <div class="flex items-start p-3 gap-3">
            <div class="flex-shrink-0">
              <input type="checkbox" 
                    class="h-5 w-5 text-blue-600 rounded border-gray-300 dark:border-space-600" 
                    ${isSelected ? 'checked' : ''}
                    data-action="change->builder-product-search#toggleProductSelection"
                    data-product-id="${product.id}">
            </div>
            <div class="flex-shrink-0 w-16 h-16 bg-gray-100 dark:bg-space-700 rounded-md overflow-hidden" data-builder-product-search-product-id="${product.id}">
              ${product.image ? `<img src="${product.image}" alt="${product.title}" class="w-full h-full object-cover">` : `
                <div class="flex items-center justify-center h-full text-gray-400 dark:text-space-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              `}
            </div>
            <div class="flex-1 min-w-0" data-builder-product-search-product-id="${product.id}">
              <h4 class="text-sm font-medium text-gray-900 dark:text-white truncate">${product.title}</h4>
              <p class="text-xs text-gray-500 dark:text-space-400">SKU: ${product.sku}</p>
              <div class="mt-1 flex flex-wrap items-center gap-x-3 gap-y-1">
                <span class="text-sm font-medium text-gray-900 dark:text-white">$${parseFloat(product.price).toFixed(2)}</span>
                <span class="text-xs text-gray-500 dark:text-space-400">Cost: $${parseFloat(product.cost).toFixed(2)}</span>
                <span class="text-xs ${stockClass}">Stock: ${stockLevel}</span>
              </div>
            </div>
            <div class="flex-shrink-0 flex flex-col items-end gap-2">
              <div class="flex items-center gap-1">
                <label class="text-xs text-gray-600 dark:text-space-300 sr-only">Qty:</label>
                <input type="number" 
                      class="w-16 text-sm border border-gray-300 dark:border-space-600 rounded p-1 bg-white dark:bg-space-900" 
                      min="1" 
                      value="1"
                      data-product-id="${product.id}"
                      data-builder-product-search-target="quantity${product.id}">
              </div>
              <button type="button"
                      class="text-xs bg-blue-600 hover:bg-blue-500 text-white px-2 py-1 rounded"
                      data-builder-product-search-add-button
                      data-product-id="${product.id}">
                Add
              </button>
            </div>
          </div>
        </div>
      `
    })

    html += `</div>`

    this.resultsContainerTarget.innerHTML = html

    // Add click event listeners after rendering
    this.addProductClickListeners()
  }

  // Add click listeners to product elements after rendering
  addProductClickListeners() {
    // For product images and names
    const productElements = this.element.querySelectorAll('[data-builder-product-search-product-id]')
    productElements.forEach(el => {
      const productId = el.dataset.builderProductSearchProductId
      el.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.quickAddProduct(parseInt(productId))
      })
    })

    // For add buttons
    const addButtons = this.element.querySelectorAll('[data-builder-product-search-add-button]')
    addButtons.forEach(button => {
      const productId = button.dataset.productId
      button.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.quickAddProduct(parseInt(productId))
      })
    })
  }

  // Calculate stock level based on warehouse data
  calculateStockLevel(stockData) {
    if (!stockData || !Array.isArray(stockData)) return 0
    return stockData.reduce((total, location) => total + (location.available || 0), 0)
  }

  // Get appropriate CSS class based on stock level
  getStockLevelClass(stockLevel) {
    if (stockLevel <= 0) return 'text-red-600 dark:text-red-400'
    if (stockLevel < 5) return 'text-yellow-600 dark:text-yellow-400'
    return 'text-green-600 dark:text-green-400'
  }

  // Toggle select/deselect a product
  toggleProductSelection(e) {
    e.stopPropagation() // Prevent event bubbling

    const checkbox = e.target
    const productId = parseInt(checkbox.dataset.productId)

    if (checkbox.checked) {
      // Add to selected products if not already selected
      if (!this.selectedProducts.some(p => p.id === productId)) {
        const product = this.searchResults.find(p => p.id === productId)
        if (product) {
          const quantity = parseInt(this.element.querySelector(`input[data-product-id="${productId}"][data-builder-product-search-target]`)?.value || 1)
          this.selectedProducts.push({
            ...product,
            quantity: quantity
          })
        }
      }
    } else {
      // Remove from selected products
      this.selectedProducts = this.selectedProducts.filter(p => p.id !== productId)
    }

    this.updateSelectedCount()
  }

  // Toggle select/deselect all products
  toggleSelectAll(e) {
    e.stopPropagation() // Prevent event bubbling

    const checkbox = e.target

    if (checkbox.checked) {
      // Select all products that aren't already selected
      this.searchResults.forEach(product => {
        if (!this.selectedProducts.some(p => p.id === product.id)) {
          const quantity = parseInt(this.element.querySelector(`input[data-product-id="${product.id}"][data-builder-product-search-target]`)?.value || 1)
          this.selectedProducts.push({
            ...product,
            quantity: quantity
          })
        }
      })

      // Update all checkboxes to checked
      this.element.querySelectorAll('input[type="checkbox"][data-product-id]').forEach(cb => {
        cb.checked = true
      })
    } else {
      // Deselect all products
      this.selectedProducts = []

      // Update all checkboxes to unchecked
      this.element.querySelectorAll('input[type="checkbox"][data-product-id]').forEach(cb => {
        cb.checked = false
      })
    }

    this.updateSelectedCount()
  }

  // Update selected count display
  updateSelectedCount() {
    if (this.hasSelectedCountTarget) {
      this.selectedCountTarget.textContent = this.selectedProducts.length
    }

    if (this.hasAddButtonTarget) {
      if (this.selectedProducts.length > 0) {
        this.addButtonTarget.disabled = false
        this.addButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
      } else {
        this.addButtonTarget.disabled = true
        this.addButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      }
    }
  }

  // Add a single product quickly (when clicking on the product or add button)
  quickAddProduct(productId) {
    if (typeof productId !== 'number') {
      // If called from event, extract productId from dataset
      if (productId && productId.currentTarget && productId.currentTarget.dataset) {
        productId = parseInt(productId.currentTarget.dataset.productId)
      } else {
        console.error('Invalid product ID:', productId)
        return
      }
    }

    const product = this.searchResults.find(p => p.id === productId)

    if (!product) {
      console.error('Product not found:', productId)
      return
    }

    // Get quantity from the input
    const quantityInput = this.element.querySelector(`input[data-product-id="${productId}"][data-builder-product-search-target]`)
    const quantity = parseInt(quantityInput?.value || 1)

    // Add the product to the line items
    this.addProductsToItems([{
      ...product,
      quantity: quantity
    }])

    // Close the modal
    this.closeModal()
  }

  // Add all selected products to the line items
  addSelectedProducts(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (this.selectedProducts.length === 0) return

    // Update quantities for all selected products
    this.selectedProducts.forEach(product => {
      const quantityInput = this.element.querySelector(`input[data-product-id="${product.id}"][data-builder-product-search-target]`)
      if (quantityInput) {
        product.quantity = parseInt(quantityInput.value || 1)
      }
    })

    // Add the products to the line items
    this.addProductsToItems(this.selectedProducts)

    // Close the modal
    this.closeModal()
  }

  // Add products to line items
  addProductsToItems(products) {
    console.log("Adding products to line items:", products)

    if (products.length === 0) return

    // Find template and target container
    let template = document.querySelector('[data-nested-form-target="template"]')

    // If not found, try alternative selectors
    if (!template) {
      template = document.querySelector('template[data-line-item-template]') ||
        document.querySelector('template#line-item-template') ||
        document.querySelector('template')

      console.log("Using alternative template:", template)
    }

    // Find target container
    let targetContainer = document.querySelector('[data-nested-form-target="target"]')

    // If not found, try alternative selectors
    if (!targetContainer) {
      targetContainer = document.querySelector('[data-quote-builder-target="lineItems"]') ||
        document.querySelector('[data-line-items-container]') ||
        document.querySelector('.line-items-container')

      console.log("Using alternative target container:", targetContainer)
    }

    // Check if we found the template and target container
    if (!template || !targetContainer) {
      console.error("Could not find template or target container for adding line items")
      this.showMessage("Error: Could not add products. Template or target container not found.")
      return
    }

    // Process each product
    let addedCount = 0
    products.forEach(productData => {
      try {
        const added = this.addProductToItem(productData, template, targetContainer)
        if (added) addedCount++
      } catch (error) {
        console.error(`Error adding product ${productData.sku}:`, error)
      }
    })

    // Show success message
    if (addedCount > 0) {
      this.showMessage(`Successfully added ${addedCount} product${addedCount !== 1 ? 's' : ''}`)
    } else {
      this.showMessage("No products could be added. Check console for errors.")
    }
  }

  // Add a single product to line items
  addProductToItem(productData, template, targetContainer) {
    console.log(`Adding product ${productData.sku} to items...`)

    try {
      // Clone the template content
      const content = template.content.cloneNode(true)

      // Find the wrapper element
      const wrapper = content.querySelector('.line-item-wrapper') ||
        content.querySelector('.order-item-wrapper') ||
        content.querySelector('.item-wrapper') ||
        content.firstElementChild

      if (!wrapper) {
        console.error("No wrapper element found in template")
        return false
      }

      // Generate a unique index for this item
      const newIndex = new Date().getTime() + Math.floor(Math.random() * 1000)

      // Update all input names
      wrapper.querySelectorAll('input, select, textarea').forEach(input => {
        if (input.name) {
          input.name = input.name.replace('NEW_RECORD', newIndex)
        }
      })

      // Field selectors for various template structures
      const fieldSelectors = {
        productId: [
          'input[name$="[product_id]"]',
          'input[name$="[product_variant_id]"]'
        ],
        sku: [
          'input[name$="[sku]"]'
        ],
        name: [
          'input[name$="[name]"]',
          'input[name$="[title]"]'
        ],
        cost: [
          'input[name$="[cost]"]'
        ],
        builderCost: [
          'input[name$="[builder_cost]"]'
        ],
        price: [
          'input[name$="[price]"]'
        ],
        quantity: [
          'input[name$="[quantity]"]'
        ]
      }

      // Helper function to find an element using multiple selectors
      const findElement = (selectors) => {
        for (const selector of selectors) {
          const element = wrapper.querySelector(selector)
          if (element) return element
        }
        return null
      }

      // Set field values
      const fields = {
        productId: findElement(fieldSelectors.productId),
        sku: findElement(fieldSelectors.sku),
        name: findElement(fieldSelectors.name),
        cost: findElement(fieldSelectors.cost),
        builderCost: findElement(fieldSelectors.builderCost),
        price: findElement(fieldSelectors.price),
        quantity: findElement(fieldSelectors.quantity)
      }

      // Display element selectors
      const displaySelectors = {
        costDisplay: [
          '[data-line-item-target="costDisplay"]',
          '[data-order-item-target="costDisplay"]'
        ],
        builderCostDisplay: [
          '[data-line-item-target="builderCostDisplay"]',
          '[data-order-item-target="builderCostDisplay"]'
        ],
        title: [
          '.product-title'
        ],
        sku: [
          '.product-sku'
        ],
        image: [
          '.product-image',
          '.w-24'
        ],
        stockInfo: [
          '.stock-info-container',
          '.warehouse-availability'
        ]
      }

      const displays = {
        costDisplay: findElement(displaySelectors.costDisplay),
        builderCostDisplay: findElement(displaySelectors.builderCostDisplay),
        title: findElement(displaySelectors.title),
        sku: findElement(displaySelectors.sku),
        image: findElement(displaySelectors.image),
        stockInfo: findElement(displaySelectors.stockInfo)
      }

      // Set all fields that exist
      if (fields.productId) fields.productId.value = productData.id
      if (fields.name) fields.name.value = productData.title
      if (fields.sku) fields.sku.value = productData.sku
      if (fields.cost) fields.cost.value = productData.cost
      if (fields.builderCost) fields.builderCost.value = productData.builder_cost || 0
      if (fields.price) fields.price.value = productData.price
      if (fields.quantity) fields.quantity.value = productData.quantity || 1

      // Set display elements
      if (displays.costDisplay) displays.costDisplay.textContent = productData.cost
      if (displays.builderCostDisplay) displays.builderCostDisplay.textContent = productData.builder_cost || 0
      if (displays.title) displays.title.textContent = productData.title
      if (displays.sku) displays.sku.textContent = `SKU: ${productData.sku}`

      // Set image if available
      if (displays.image && productData.image) {
        displays.image.innerHTML = `
          <img src="${productData.image}" class="w-full h-full object-cover rounded-lg" alt="${productData.title}" />
        `
      }

      // Add stock information if available
      if (displays.stockInfo && productData.stock) {
        let stockHtml = ''
        productData.stock.forEach(stockItem => {
          const available = stockItem.available || 0
          const cssClass = available > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
          stockHtml += `<span class="${cssClass} mr-2">${stockItem.warehouse.name.split(' ')[0]}: ${available}</span>`
        })
        displays.stockInfo.innerHTML = stockHtml
      }

      // Append the new item to the container
      targetContainer.appendChild(wrapper)

      // Remove empty state message if it exists
      document.querySelector('[data-nested-form-target="empty"]')?.remove()

      // Trigger calculation events
      const eventName = wrapper.classList.contains('order-item-wrapper') ? 'order-item:update' : 'line-item:update'

      wrapper.dispatchEvent(new CustomEvent(eventName, {
        bubbles: true,
        detail: {
          total: productData.price * (productData.quantity || 1),
          cost: productData.cost * (productData.quantity || 1),
          quantity: productData.quantity || 1
        }
      }))

      // Calculate margin
      if (fields.price) {
        const event = new Event('input')
        fields.price.dispatchEvent(event)
      }

      console.log(`Product ${productData.sku} added successfully`)
      return true
    } catch (error) {
      console.error("Error in addProductToItem:", error)
      return false
    }
  }

  // Display HTML for empty results
  getEmptyResultsHTML() {
    return `
      <div class="flex flex-col items-center justify-center py-8 text-gray-500 dark:text-space-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mb-3 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <p class="text-center">Type to search for products</p>
      </div>
    `
  }

  // Display HTML for loading state
  getLoadingHTML() {
    return `
      <div class="flex flex-col items-center justify-center py-8 text-gray-500 dark:text-space-400">
        <svg class="animate-spin h-8 w-8 text-blue-600 mb-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="text-center">Searching products...</p>
      </div>
    `
  }

  // Display HTML for no results
  getNoResultsHTML() {
    return `
      <div class="flex flex-col items-center justify-center py-8 text-gray-500 dark:text-space-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mb-3 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-center">No products found</p>
        <p class="text-center text-sm mt-1">Try a different search term</p>
      </div>
    `
  }

  // Display HTML for error state
  getErrorHTML() {
    return `
      <div class="flex flex-col items-center justify-center py-8 text-gray-500 dark:text-space-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mb-3 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-center">Error searching products</p>
        <p class="text-center text-sm mt-1">Please try again</p>
      </div>
    `
  }

  // Show a toast message
  showMessage(message) {
    const toast = document.createElement('div')
    toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }
}