// quote_builder_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form", "productSearch", "subtotal", "itemDiscounts", "quoteDiscount",
    "tax", "total", "profit", "margin", "emptyNotes", "notesContainer",
    "emptyFiles", "filesContainer", "fileInput", "shipping", "shippingDiscount"
  ]

  connect() {
    console.log("Quote Builder controller connected")
    console.log("Has notes container target:", this.hasNotesContainerTarget)
    console.log("Has files container target:", this.hasFilesContainerTarget)

    this.setupModalListeners()
    this.searchDebounce = null
    this.searchDelay = 300
    this.updateTotals()

    // Initialize the file input element
    this.initFileInput()

    // Initialize notes and files displays
    this.refreshNotesDisplay()
    this.refreshFilesDisplay()

    // Set up real-time update listeners for the form elements
    this.setupRealTimeUpdates()

    // Set up listeners for line item inputs
    this.setupLineItemListeners()

    // Initial calculation of totals
    this.updateTotals()

    // Initialize margin gauge if present
    this.initializeMarginGauge()
  }

  // Initialize the margin gauge with the current value
  initializeMarginGauge() {
    const marginElement = document.querySelector('[data-quote-builder-target="margin"]')
    const gaugeElement = document.getElementById('margin-gauge')

    if (marginElement && gaugeElement) {
      const margin = parseFloat(marginElement.textContent.replace('%', '').trim())
      if (!isNaN(margin)) {
        // Cap the display at 40% for visual purposes
        const gaugeWidth = Math.min(margin, 40)
        gaugeElement.style.width = `${gaugeWidth}%`

        // Set the appropriate color class
        gaugeElement.className = this.getMarginGaugeClass(margin)
      }
    }
  }

  // Get the appropriate CSS class for the margin gauge
  getMarginGaugeClass(margin) {
    if (margin >= 20) {
      return 'bg-green-500 rounded-r transition-all duration-300'
    } else if (margin >= 15) {
      return 'bg-orange-500 rounded-r transition-all duration-300'
    } else if (margin >= 10) {
      return 'bg-orange-600 rounded-r transition-all duration-300'
    } else if (margin >= 5) {
      return 'bg-red-500 rounded-r transition-all duration-300'
    } else {
      return 'bg-red-600 rounded-r transition-all duration-300'
    }
  }

  setupModalListeners() {
    document.addEventListener('product-details:add-to-quote', this.handleProductAdd.bind(this))
    document.addEventListener('related-products:add-to-quote', this.handleRelatedProductsAdd.bind(this))
    document.addEventListener('line-item-addons:updated', this.handleAddonsUpdated.bind(this))
  }

  searchProducts(event) {
    const searchTerm = event.target.value.trim()

    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce)
    }

    this.searchDebounce = setTimeout(() => {
      if (searchTerm.length >= 2) {
        this.performSearch(searchTerm)
      } else {
        this.clearSearchResults()
      }
    }, this.searchDelay)
  }

  async performSearch(searchTerm) {
    try {
      this.showSearchLoading()

      const response = await fetch(`/api/v1/products/search?q=${encodeURIComponent(searchTerm)}`)

      if (!response.ok) {
        throw new Error("Search failed")
      }

      const data = await response.json()
      const products = data.results || []

      this.displaySearchResults(products)

    } catch (error) {
      console.error('Error searching products:', error)
      this.showSearchError(error.message)
    } finally {
      this.hideSearchLoading()
    }
  }

  displaySearchResults(products) {
    const searchContainer = document.querySelector('.search-results-container')
    if (!searchContainer) return

    searchContainer.innerHTML = ''

    if (products.length === 0) {
      searchContainer.innerHTML = `
        <div class="p-4 text-center text-space-500 dark:text-space-400">
          No products found. Try a different search term.
        </div>
      `
      searchContainer.classList.remove('hidden')
      return
    }

    const resultsList = document.createElement('div')
    resultsList.className = 'max-h-96 overflow-y-auto divide-y divide-space-200 dark:divide-space-700'

    products.forEach(product => {
      const resultItem = document.createElement('div')
      resultItem.className = 'p-3 hover:bg-space-50 dark:hover:bg-space-800 cursor-pointer flex items-center gap-3'
      resultItem.dataset.action = 'click->quote-builder#addProductFromSearch'
      resultItem.dataset.productId = product.id

      const imageContainer = document.createElement('div')
      imageContainer.className = 'w-12 h-12 bg-space-100 dark:bg-space-800 rounded overflow-hidden flex-shrink-0'

      if (product.image) {
        imageContainer.innerHTML = `<img src="${product.image}" class="w-full h-full object-cover" alt="${product.title}" />`
      } else {
        imageContainer.innerHTML = `
          <div class="w-full h-full flex items-center justify-center text-space-400 dark:text-space-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
        `
      }

      const details = document.createElement('div')
      details.className = 'flex-1 min-w-0'
      details.innerHTML = `
        <div class="text-sm font-medium text-space-900 dark:text-white truncate">${product.title}</div>
        <div class="text-xs text-space-500 dark:text-space-400">SKU: ${product.sku}</div>
        <div class="flex items-center gap-4 mt-1">
          <div class="text-xs font-semibold">${this.formatCurrency(product.price)}</div>
          ${product.stock && product.stock.length > 0 ?
          `<div class="text-xs ${this.getTotalStock(product) > 0 ? 'text-green-500' : 'text-red-500'}">
              ${this.getTotalStock(product) > 0 ? `${this.getTotalStock(product)} in stock` : 'Out of stock'}
            </div>` :
          ''}
        </div>
      `

      resultItem.appendChild(imageContainer)
      resultItem.appendChild(details)

      resultsList.appendChild(resultItem)
    })

    searchContainer.appendChild(resultsList)
    searchContainer.classList.remove('hidden')
  }

  clearSearchResults() {
    const searchContainer = document.querySelector('.search-results-container')
    if (searchContainer) {
      searchContainer.innerHTML = ''
      searchContainer.classList.add('hidden')
    }
  }

  showSearchLoading() {
    const searchContainer = document.querySelector('.search-results-container')
    if (searchContainer) {
      searchContainer.innerHTML = `
        <div class="p-4 text-center">
          <div class="inline-block animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
          <div class="mt-2 text-space-500 dark:text-space-400">Searching...</div>
        </div>
      `
      searchContainer.classList.remove('hidden')
    }
  }

  hideSearchLoading() {
    // Handled by displaySearchResults or showSearchError
  }

  showSearchError(message) {
    const searchContainer = document.querySelector('.search-results-container')
    if (searchContainer) {
      searchContainer.innerHTML = `
        <div class="p-4 text-center text-red-500">
          <div>Error searching products</div>
          <div class="text-sm">${message || 'Please try again'}</div>
        </div>
      `
      searchContainer.classList.remove('hidden')
    }
  }

  addProductFromSearch(event) {
    const productId = event.currentTarget.dataset.productId

    if (!productId) return

    this.fetchProductDetails(productId)
      .then(product => {
        if (product) {
          this.addProductToQuote(product)
          this.clearSearchResults()
          if (this.hasProductSearchTarget) {
            this.productSearchTarget.value = ''
          }
        }
      })
      .catch(error => {
        console.error('Error fetching product details:', error)
        this.showToast('Failed to add product', 'error')
      })
  }

  async fetchProductDetails(productId) {
    try {
      const response = await fetch(`/api/v1/products/${productId}`)

      if (!response.ok) {
        throw new Error("Failed to fetch product details")
      }

      const data = await response.json()
      return data.results && data.results[0]

    } catch (error) {
      console.error('Error fetching product details:', error)
      throw error
    }
  }

  addCustomItem(event) {
    event.preventDefault()

    const template = document.querySelector('[data-nested-form-target="template"]')

    if (!template) {
      console.error('Line item template not found')
      return
    }

    const content = template.content.cloneNode(true)
    const newIndex = new Date().getTime()
    const uniqueId = `new_${newIndex}`

    // Update all input names with the new index
    const inputs = content.querySelectorAll('input, select, textarea')
    inputs.forEach(input => {
      if (input.name) {
        input.name = input.name.replace('NEW_RECORD', newIndex)
      }
    })

    const wrapper = content.querySelector('.line-item-wrapper')
    if (!wrapper) {
      console.error('Line item wrapper not found in template')
      return
    }

    // Set unique ID and explicitly mark as custom item
    wrapper.dataset.lineItemId = uniqueId
    wrapper.dataset.isCustom = 'true'
    wrapper.dataset.customItem = 'true'
    wrapper.dataset.productSku = `CUSTOM-${newIndex}`
    wrapper.dataset.productName = 'Custom Item'

    // Set input fields
    const productIdInput = wrapper.querySelector('input[name$="[product_id]"], [data-product-id-field]')
    if (productIdInput) productIdInput.value = '' // No product ID for custom item

    const skuInput = wrapper.querySelector('input[name$="[sku]"], [data-product-sku-field]')
    if (skuInput) skuInput.value = `CUSTOM-${newIndex}`

    const nameInput = wrapper.querySelector('input[name$="[name]"], [data-product-name-field]')
    if (nameInput) nameInput.value = 'Custom Item'

    // Set initial price and cost
    const priceInput = wrapper.querySelector('input[data-line-item-target="price"]')
    if (priceInput) priceInput.value = '0.00'

    const costInput = wrapper.querySelector('input[data-line-item-target="costDisplay"]')
    if (costInput) costInput.value = '0.00'

    const hiddenCostInput = wrapper.querySelector('input[data-line-item-target="cost"]')
    if (hiddenCostInput) hiddenCostInput.value = '0.00'

    // Update visible elements
    const titleElement = wrapper.querySelector('.product-title, [data-product-title]')
    if (titleElement) {
      // Ensure we have the pencil icon for custom items
      if (!titleElement.previousElementSibling || !titleElement.previousElementSibling.classList.contains('fa-pencil')) {
        const pencilIcon = document.createElement('i')
        pencilIcon.className = 'fas fa-pencil text-space-500 text-xs'
        titleElement.parentNode.insertBefore(pencilIcon, titleElement)
      }

      // Clear the existing text but keep the details button
      const detailsButton = titleElement.querySelector('button')
      titleElement.innerHTML = 'Custom Item'
      if (detailsButton) {
        titleElement.appendChild(detailsButton)
      }

      // Add editable styling
      titleElement.classList.add('border-b', 'border-space-200', 'dark:border-space-500')

      // Make the title editable
      titleElement.contentEditable = 'true'
      titleElement.addEventListener('input', (e) => {
        // Update the name field and data attribute when title changes
        const newName = e.target.innerText.trim()
        if (nameInput) nameInput.value = newName
        wrapper.dataset.productName = newName

        // Also update button data attribute if it exists
        const addonButton = wrapper.querySelector('[data-addons-button]')
        if (addonButton) addonButton.dataset.productName = newName
      })
      titleElement.addEventListener('blur', (e) => {
        if (e.target.innerText.trim() === '') {
          e.target.innerText = 'Custom Item'
          if (nameInput) nameInput.value = 'Custom Item'
        }
      })
    }

    // Make SKU editable
    const skuElement = wrapper.querySelector('.font-mono[data-product-sku-display]')
    if (skuElement) {
      skuElement.textContent = `CUSTOM-${newIndex}`
      // Add editable styling
      skuElement.classList.add('border-b', 'border-space-200', 'dark:border-space-500')
      skuElement.contentEditable = 'true'
      skuElement.addEventListener('input', (e) => {
        // Update the SKU field and data attribute when SKU changes
        const newSku = e.target.innerText.trim()
        if (skuInput) skuInput.value = newSku
        wrapper.dataset.productSku = newSku

        // Also update button data attribute if it exists
        const addonButton = wrapper.querySelector('[data-addons-button]')
        if (addonButton) addonButton.dataset.productSku = newSku
      })
      skuElement.addEventListener('blur', (e) => {
        if (e.target.innerText.trim() === '') {
          e.target.innerText = `CUSTOM-${newIndex}`
          if (skuInput) skuInput.value = `CUSTOM-${newIndex}`
        }
      })
    }

    const skuDisplayElement = wrapper.querySelector('.product-sku')
    if (skuDisplayElement && !skuElement) {
      // Ensure we have the pencil icon for custom SKU
      if (!skuDisplayElement.querySelector('.fa-pencil')) {
        const pencilIcon = document.createElement('i')
        pencilIcon.className = 'fas fa-pencil text-space-500 text-xs mr-1'
        skuDisplayElement.querySelector('span').insertBefore(pencilIcon, skuDisplayElement.querySelector('span').firstChild)
      }

      const skuSpan = skuDisplayElement.querySelector('span')
      if (skuSpan) {
        skuSpan.textContent = `CUSTOM-${newIndex}`
        skuSpan.contentEditable = 'true'
        skuSpan.addEventListener('input', (e) => {
          const newSku = e.target.innerText.trim()
          if (skuInput) skuInput.value = newSku
          wrapper.dataset.productSku = newSku
        })
      }
    }

    // Hide details button for custom items
    const detailsButton = wrapper.querySelector('[data-details-button]')
    if (detailsButton) {
      detailsButton.classList.add('hidden')
      detailsButton.disabled = true
      detailsButton.dataset.lineItemId = uniqueId
      detailsButton.dataset.productSku = `CUSTOM-${newIndex}`
      detailsButton.dataset.productName = 'Custom Item'
      detailsButton.dataset.customItem = 'true'
    }

    const addonsButton = wrapper.querySelector('[data-addons-button]')
    if (addonsButton) {
      addonsButton.dataset.lineItemId = uniqueId
      addonsButton.dataset.productSku = `CUSTOM-${newIndex}`
      addonsButton.dataset.productName = 'Custom Item'
      addonsButton.dataset.customItem = 'true'
    }

    // Update warehouse section
    const warehouseSection = wrapper.querySelector('[data-order-item-target="warehouseInfo"]')
    if (warehouseSection) {
      warehouseSection.innerHTML = '<div class="text-xs text-space-500 dark:text-space-500 italic">Custom item - no warehouse data</div>'
    }

    // Add the line item to the container
    const container = document.querySelector('[data-nested-form-target="target"]')
    if (container) {
      container.appendChild(content)

      const emptyState = document.querySelector('[data-nested-form-target="empty"]')
      if (emptyState) {
        emptyState.remove()
      }

      this.updateTotals()
      this.lastAddedLineItem = wrapper
      this.showToast('Custom item added')
    }
  }

  updateTotals(event) {
    const lineItems = document.querySelectorAll('.line-item-wrapper:not(.hidden)')

    let subtotal = 0
    let itemDiscounts = 0
    let totalAddons = 0
    let totalCost = 0
    let addonCost = 0
    let totalQuantity = 0

    lineItems.forEach(item => {
      const priceElement = item.querySelector('[data-line-item-target="price"]')
      const quantityElement = item.querySelector('[data-line-item-target="quantity"]')
      const discountTypeElement = item.querySelector('[data-line-item-target="discountType"]')
      const discountAmountElement = item.querySelector('[data-line-item-target="discountAmount"]')
      const costDisplayElement = item.querySelector('[data-line-item-target="costDisplay"]')
      const builderCostElement = item.querySelector('[data-line-item-target="builderCostDisplay"]')

      // Calculate base item price and quantity
      if (priceElement && quantityElement) {
        const price = parseFloat(priceElement.value) || 0
        const quantity = parseInt(quantityElement.value) || 0
        totalQuantity += quantity
        const itemTotal = price * quantity

        subtotal += itemTotal

        // Calculate cost
        let cost = 0
        if (builderCostElement && parseFloat(builderCostElement.value) > 0) {
          cost = parseFloat(builderCostElement.value)
        } else if (costDisplayElement) {
          cost = parseFloat(costDisplayElement.value) || 0
        }

        totalCost += cost * quantity

        // Calculate item discounts
        if (discountTypeElement && discountAmountElement) {
          const discountType = discountTypeElement.value
          const discountAmount = parseFloat(discountAmountElement.value) || 0

          let discountValue = 0
          if (discountType === 'percentage') {
            discountValue = itemTotal * (discountAmount / 100)
          } else {
            discountValue = discountAmount * quantity
            // Ensure fixed discount doesn't exceed the item total
            discountValue = Math.min(discountValue, itemTotal)
          }

          itemDiscounts += discountValue
        }

        // Calculate addons for this line item
        const addonPriceElements = item.querySelectorAll('.line-item-addon-fields input[name$="[price]"]')
        const addonQuantityElements = item.querySelectorAll('.line-item-addon-fields input[name$="[quantity]"]')
        const addonCostElements = item.querySelectorAll('.line-item-addon-fields input[name$="[cost]"]')

        if (addonPriceElements.length > 0) {
          addonPriceElements.forEach((addonElement, index) => {
            const addonPrice = parseFloat(addonElement.value) || 0
            const addonQuantity = addonQuantityElements[index] ?
              parseInt(addonQuantityElements[index].value) || 1 : 1

            // Add to total addons
            totalAddons += addonPrice * addonQuantity

            // Calculate addon cost
            if (addonCostElements[index]) {
              const addonItemCost = parseFloat(addonCostElements[index].value) || 0
              addonCost += addonItemCost * addonQuantity
            }
          })
        }
      }
    })

    // Add addon cost to total cost
    totalCost += addonCost

    const taxRateInput = document.querySelector('input[name="quote[tax_rate]"]')
    const discountTypeInput = document.querySelector('input[name="quote[discount_type]"]:checked')
    const discountAmountInput = document.querySelector('input[name="quote[discount_amount]"]')
    const shippingCostInput = document.querySelector('input[name="quote[shipment_cost]"]')
    const shippingDiscountTypeInput = document.querySelector('input[name="quote[shipment_discount_type]"]:checked')
    const shippingDiscountAmountInput = document.querySelector('input[name="quote[shipment_discount]"]')

    const taxRate = parseFloat(taxRateInput?.value || 0) / 100
    const discountType = discountTypeInput?.value || 'fixed'
    const discountAmount = parseFloat(discountAmountInput?.value || 0)
    const shippingCost = parseFloat(shippingCostInput?.value || 0)
    const shippingDiscountType = shippingDiscountTypeInput?.value || 'fixed'
    const shippingDiscountAmount = parseFloat(shippingDiscountAmountInput?.value || 0)

    // Calculate pre-discount amount including addons (matching the model)
    const preDiscountAmount = subtotal - itemDiscounts + totalAddons

    // Calculate quote-level discount
    let quoteDiscount = 0
    if (discountType === 'percentage') {
      quoteDiscount = preDiscountAmount * (discountAmount / 100)
    } else {
      quoteDiscount = Math.min(discountAmount, preDiscountAmount) // Can't discount more than pre-discount amount
    }

    // Calculate shipping discount (matching the model)
    let shippingDiscount = 0
    if (shippingDiscountType === 'percentage') {
      shippingDiscount = shippingCost * (shippingDiscountAmount / 100)
    } else {
      shippingDiscount = Math.min(shippingDiscountAmount, shippingCost) // Can't discount more than shipping cost
    }

    // Calculate taxable amount and tax
    const taxableAmount = preDiscountAmount - quoteDiscount
    const taxAmount = taxableAmount * taxRate

    // Calculate total (without shipping)
    const total = taxableAmount + taxAmount

    // Calculate shipping with discounts
    const shippingWithDiscounts = Math.max(0, shippingCost - shippingDiscount)

    // Calculate final total
    const finalTotal = total + shippingWithDiscounts

    // Calculate profit and markup (not margin)
    const profit = finalTotal - totalCost

    // Calculate markup percentage (profit relative to cost)
    const markup = totalCost > 0 ? (profit / totalCost) * 100 : 0
    const formattedMarkup = markup.toFixed(2)

    // Debug logging
    console.log('JS CALCULATION:', {
      subtotal,
      itemDiscounts,
      totalAddons,
      preDiscountAmount,
      quoteDiscount,
      taxableAmount,
      taxRate,
      taxAmount,
      total,
      shippingCost,
      shippingDiscount,
      shippingWithDiscounts,
      finalTotal,
      totalCost,
      addonCost,
      profit,
      markup: formattedMarkup
    });

    // Update UI display elements
    if (this.hasSubtotalTarget) {
      this.subtotalTarget.textContent = this.formatCurrency(subtotal)
    }

    if (this.hasItemDiscountsTarget) {
      this.itemDiscountsTarget.textContent = '-' + this.formatCurrency(itemDiscounts)
    }

    if (this.hasQuoteDiscountTarget) {
      this.quoteDiscountTarget.textContent = '-' + this.formatCurrency(quoteDiscount)
    }

    if (this.hasTaxTarget) {
      this.taxTarget.textContent = this.formatCurrency(taxAmount)
    }

    if (this.hasShippingTarget) {
      this.shippingTarget.textContent = this.formatCurrency(shippingCost)
    }

    if (this.hasShippingDiscountTarget) {
      this.shippingDiscountTarget.textContent = '-' + this.formatCurrency(shippingDiscount)
    }

    if (this.hasTotalTarget) {
      this.totalTarget.textContent = this.formatCurrency(finalTotal)
    }

    if (this.hasProfitTarget) {
      this.profitTarget.textContent = this.formatCurrency(profit)
      this.profitTarget.className = this.getMarkupColorClass(markup)
    }

    if (this.hasMarginTarget) {
      this.marginTarget.textContent = `${formattedMarkup}%`
      this.marginTarget.className = this.getMarkupColorClass(markup)
    }

    this.updateMarginGauge(markup)
    this.updateSidebarMargins(profit, markup)
  }

  updateMarginGauge(markup) {
    const gaugeElement = document.getElementById('margin-gauge')

    if (gaugeElement) {
      // Adjust scaling to make 20% look good and higher markups scale properly
      let gaugeWidth;
      if (markup <= 20) {
        // Linear scaling up to 20%
        gaugeWidth = markup;
      } else {
        // More aggressive scaling for markups above 20%
        gaugeWidth = 20 + (markup - 20) * 1.5;
      }

      // Cap the visual width at 40%
      gaugeWidth = Math.min(gaugeWidth, 40);

      gaugeElement.style.width = `${gaugeWidth}%`

      // Set the appropriate color class
      if (markup >= 30) {
        gaugeElement.className = 'bg-green-600 rounded-r transition-all duration-300'
      } else if (markup >= 25) {
        gaugeElement.className = 'bg-green-500 rounded-r transition-all duration-300'
      } else if (markup >= 20) {
        gaugeElement.className = 'bg-green-400 rounded-r transition-all duration-300'
      } else if (markup >= 15) {
        gaugeElement.className = 'bg-orange-500 rounded-r transition-all duration-300'
      } else if (markup >= 10) {
        gaugeElement.className = 'bg-orange-600 rounded-r transition-all duration-300'
      } else if (markup >= 5) {
        gaugeElement.className = 'bg-red-500 rounded-r transition-all duration-300'
      } else {
        gaugeElement.className = 'bg-red-600 rounded-r transition-all duration-300'
      }
    }
  }

  updateSidebarMargins(profit, markup) {
    // Format the markup to 2 decimal places
    const formattedMarkup = markup.toFixed(2)

    // Find and update all markup displays in the sidebar
    const sidebarProfitElements = document.querySelectorAll('.sidebar-profit')
    const sidebarMarginElements = document.querySelectorAll('.sidebar-margin')

    if (sidebarProfitElements.length > 0) {
      sidebarProfitElements.forEach(element => {
        element.textContent = this.formatCurrency(profit)
        element.className = `sidebar-profit font-bold ${this.getMarkupColorClass(markup)}`
      })
    }

    if (sidebarMarginElements.length > 0) {
      sidebarMarginElements.forEach(element => {
        element.textContent = `${formattedMarkup}%`
        element.className = `sidebar-margin font-bold ${this.getMarkupColorClass(markup)}`
      })
    }
  }

  getMarkupColorClass(markup) {
    if (markup >= 30) {
      return 'font-bold text-green-600 dark:text-green-400'
    } else if (markup >= 20) {
      return 'font-bold text-green-500 dark:text-green-500'
    } else if (markup >= 15) {
      return 'font-bold text-orange-500 dark:text-orange-400'
    } else if (markup >= 10) {
      return 'font-bold text-orange-600 dark:text-orange-500'
    } else if (markup >= 5) {
      return 'font-bold text-red-500 dark:text-red-400'
    } else {
      return 'font-bold text-red-600 dark:text-red-500'
    }
  }

  handleProductAdd(event) {
    const { product } = event.detail

    if (!product) {
      console.error('No product data in the event')
      return
    }

    this.addProductToQuote(product)
  }

  handleRelatedProductsAdd(event) {
    const { products } = event.detail

    if (!products || products.length === 0) {
      console.error('No products in the event')
      return
    }

    products.forEach(item => {
      this.addProductToQuote(item.product, item.quantity)
    })
  }

  handleAddonsUpdated(event) {
    const { lineItemId, addons } = event.detail

    if (!lineItemId) {
      console.error('No line item ID in the event')
      return
    }

    const lineItem = document.querySelector(`[data-line-item-id="${lineItemId}"]`)
    if (!lineItem) return

    addons.forEach(addon => {
      this.addAddonToLineItem(lineItem, addon)
    })

    this.updateTotals()
  }

  addAddonToLineItem(lineItem, addon) {
    const newIndex = new Date().getTime() + Math.floor(Math.random() * 1000)

    const lineItemIndex = this.getLineItemIndex(lineItem)

    if (!lineItemIndex) {
      console.error('Could not determine line item index')
      return
    }

    const addonFields = document.createElement('div')
    addonFields.classList.add('line-item-addon-fields', 'hidden')

    addonFields.innerHTML = `
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][name]" value="${addon.name || ''}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][sku]" value="${addon.sku || ''}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][quantity]" value="${addon.quantity || 1}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][price]" value="${addon.price || 0}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][cost]" value="${addon.cost || 0}">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][discount_amount]" value="0">
      <input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][discount_type]" value="fixed">
      ${addon.product_id ? `<input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][product_id]" value="${addon.product_id}">` : ''}
      ${addon.id ? `<input type="hidden" name="quote[line_items_attributes][${lineItemIndex}][line_item_addons_attributes][${newIndex}][id]" value="${addon.id}">` : ''}
    `

    lineItem.appendChild(addonFields)

    this.updateAddonDisplay(lineItem, lineItemIndex)
  }

  getLineItemIndex(lineItem) {
    const inputField = lineItem.querySelector('input[name*="[line_items_attributes]"]')

    if (!inputField) return null

    const match = inputField.name.match(/\[line_items_attributes\]\[([0-9]+)\]/)
    return match ? match[1] : null
  }

  updateLineItemButtons(wrapper, product) {
    const detailsButton = wrapper.querySelector('[data-details-button]')
    const addonsButton = wrapper.querySelector('[data-addons-button]')

    const lineItemId = wrapper.dataset.lineItemId;

    if (detailsButton) {
      detailsButton.dataset.lineItemId = lineItemId;
      detailsButton.dataset.productSku = product.sku;
      detailsButton.dataset.productName = product.title;
    }

    if (addonsButton) {
      addonsButton.dataset.lineItemId = lineItemId;
      addonsButton.dataset.productSku = product.sku;
      addonsButton.dataset.productName = product.title;
    }
  }

  updateAddonDisplay(lineItem, lineItemIndex) {
    let addonsDisplay = lineItem.querySelector('.line-item-addons-display')

    if (!addonsDisplay) {
      addonsDisplay = document.createElement('div')
      addonsDisplay.className = 'line-item-addons-display mt-2 pt-2 border-t border-space-200 dark:border-space-700'

      const insertAfter = lineItem.querySelector('.mt-auto')
      if (insertAfter && insertAfter.parentNode) {
        insertAfter.parentNode.insertBefore(addonsDisplay, insertAfter.nextSibling)
      } else {
        lineItem.appendChild(addonsDisplay)
      }
    }

    const addonInputs = lineItem.querySelectorAll(`input[name*="[line_items_attributes][${lineItemIndex}][line_item_addons_attributes]"]`)

    const addonGroups = {}
    addonInputs.forEach(input => {
      const match = input.name.match(/\[line_item_addons_attributes\]\[([0-9]+)\]\[([^\]]+)\]/)
      if (match) {
        const addonIndex = match[1]
        const fieldName = match[2]

        if (!addonGroups[addonIndex]) {
          addonGroups[addonIndex] = {}
        }

        addonGroups[addonIndex][fieldName] = input.value
      }
    })

    const addonData = []
    Object.values(addonGroups).forEach(addon => {
      if (addon.name && addon.sku) {
        addonData.push({
          name: addon.name,
          quantity: parseInt(addon.quantity) || 1,
          price: parseFloat(addon.price) || 0
        })
      }
    })

    if (addonData.length === 0) {
      addonsDisplay.classList.add('hidden')
      return
    }

    let addonsHtml = '<div class="text-xs font-medium text-space-700 dark:text-space-300 mb-1">Add-ons:</div>'

    addonData.forEach(addon => {
      const total = addon.quantity * addon.price

      addonsHtml += `
        <div class="flex items-center justify-between text-xs py-1">
          <div class="flex items-center gap-1">
            <span class="font-medium">${addon.name}</span>
            <span class="text-space-500 dark:text-space-400">x${addon.quantity}</span>
          </div>
          <div class="font-medium">${this.formatCurrency(total)}</div>
        </div>
      `
    })

    addonsDisplay.innerHTML = addonsHtml
    addonsDisplay.classList.remove('hidden')
  }

  addProductToQuote(product, quantity = 1) {
    const template = document.querySelector('[data-nested-form-target="template"]')

    if (!template) {
      console.error('Line item template not found')
      return
    }

    try {
      const content = template.content.cloneNode(true)

      const newIndex = new Date().getTime()

      const inputs = content.querySelectorAll('input, select, textarea')
      inputs.forEach(input => {
        if (input.name) {
          input.name = input.name.replace('NEW_RECORD', newIndex)
        }
      })

      const wrapper = content.querySelector('.line-item-wrapper')

      if (!wrapper) {
        console.error('Line item wrapper not found in template')
        return
      }

      // Generate a unique ID for the new line item
      const uniqueId = `new_${newIndex}`;
      wrapper.dataset.lineItemId = uniqueId;

      // Set all the fields for the line item
      this.setLineItemFields(wrapper, product, quantity)

      const container = document.querySelector('[data-nested-form-target="target"]')
      if (container) {
        container.appendChild(content)

        const emptyState = document.querySelector('[data-nested-form-target="empty"]')
        if (emptyState) {
          emptyState.remove()
        }
      }

      this.updateTotals()

      // Store reference to the last added line item
      this.lastAddedLineItem = wrapper;

      this.showToast(`${product.title} added to quote`)

    } catch (error) {
      console.error('Error adding product to quote:', error)
      this.showToast('Failed to add product to quote', 'error')
    }
  }

  setLineItemFields(wrapper, product, quantity = 1) {
    // Update data attributes directly on the wrapper
    wrapper.dataset.productSku = product.sku || '';
    wrapper.dataset.productName = product.title || '';

    // Set hidden input fields
    const productIdInput = wrapper.querySelector('input[name$="[product_id]"], [data-product-id-field]')
    if (productIdInput) productIdInput.value = product.id

    const skuInput = wrapper.querySelector('input[name$="[sku]"], [data-product-sku-field]')
    if (skuInput) skuInput.value = product.sku

    const nameInput = wrapper.querySelector('input[name$="[name]"], [data-product-name-field]')
    if (nameInput) nameInput.value = product.title

    // Set cost fields
    const costInput = wrapper.querySelector('input[name$="[cost]"]')
    const costDisplayInput = wrapper.querySelector('[data-line-item-target="costDisplay"]')
    if (costInput) costInput.value = product.cost
    if (costDisplayInput) costDisplayInput.value = product.cost

    // Set builder cost fields, if applicable
    const builderCostInput = wrapper.querySelector('input[name$="[builder_cost]"]')
    const builderCostDisplayInput = wrapper.querySelector('[data-line-item-target="builderCostDisplay"]')
    if (builderCostInput && product.builder_cost) {
      builderCostInput.value = product.builder_cost
    }
    if (builderCostDisplayInput && product.builder_cost) {
      builderCostDisplayInput.value = product.builder_cost
    }

    // Set price and quantity
    const priceInput = wrapper.querySelector('input[name$="[price]"], [data-line-item-target="price"]')
    if (priceInput) priceInput.value = product.price

    const quantityInput = wrapper.querySelector('input[name$="[quantity]"], [data-line-item-target="quantity"]')
    if (quantityInput) quantityInput.value = quantity

    // Set discount fields
    const discountTypeInput = wrapper.querySelector('select[name$="[discount_type]"], [data-line-item-target="discountType"]')
    const discountAmountInput = wrapper.querySelector('input[name$="[discount_amount]"], [data-line-item-target="discountAmount"]')
    if (discountTypeInput) discountTypeInput.value = 'fixed'
    if (discountAmountInput) discountAmountInput.value = '0'

    // Update visible elements
    const titleElement = wrapper.querySelector('.product-title, [data-product-title]')
    if (titleElement) titleElement.textContent = product.title

    const skuElement = wrapper.querySelector('.font-mono[data-product-sku-display]')
    if (skuElement) skuElement.textContent = product.sku

    const skuDisplayElement = wrapper.querySelector('.product-sku')
    if (skuDisplayElement && !skuElement) {
      const skuTextNode = skuDisplayElement.querySelector('span')
      if (skuTextNode) skuTextNode.textContent = product.sku
    }

    // Update image
    const imageElement = wrapper.querySelector('.product-image, [data-product-image]')
    if (imageElement && (product.image || (product.image_urls && product.image_urls.length > 0))) {
      const imageUrl = product.image || product.image_urls[0];
      imageElement.innerHTML = `<img src="${imageUrl}" class="w-full h-full object-cover" alt="${product.title}" />`
    }

    // Update buttons
    this.updateLineItemButtons(wrapper, product);

    // Set stock info
    this.setStockInfo(wrapper, product)

    // Force recalculation of totals
    const lineItemController = this.application.getControllerForElementAndIdentifier(wrapper, 'line-item');
    if (lineItemController && typeof lineItemController.updateTotal === 'function') {
      lineItemController.updateTotal();
    }
  }

  setStockInfo(wrapper, product) {
    if (!product.stock) return

    const stockContainer = wrapper.querySelector('.warehouse-availability')
    if (!stockContainer) return

    const warehousesWithStock = product.stock.filter(item => item.available > 0)

    if (warehousesWithStock.length === 0) {
      stockContainer.innerHTML = '<div class="text-xs text-space-500 dark:text-space-500 italic">No inventory available at any warehouse</div>'
      return
    }

    let stockHtml = ''
    warehousesWithStock.forEach(inventory => {
      stockHtml += `
        <div class="flex items-center gap-2 py-1 px-2 rounded bg-green-100 dark:bg-green-900/30">
          <div class="w-2 h-2 rounded-full bg-green-500 dark:bg-green-500"></div>
          <span class="text-xs font-medium">${inventory.warehouse.name}</span>
          <span class="text-xs ml-auto">${inventory.available} avail</span>
        </div>
      `
    })

    stockContainer.innerHTML = stockHtml
  }

  getTotalStock(product) {
    if (!product.stock || product.stock.length === 0) return 0

    return product.stock.reduce((sum, item) => sum + (item.available || 0), 0)
  }

  showToast(message, type = 'success') {
    const toast = document.createElement('div')

    if (type === 'success') {
      toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    } else {
      toast.className = 'fixed bottom-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    }

    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }

  // Files handling methods
  showFileUploadForm() {
    const form = document.getElementById('file-upload-form');
    if (form) {
      form.classList.remove('hidden');
    }
  }

  hideFileUploadForm() {
    const form = document.getElementById('file-upload-form');
    if (form) {
      form.classList.add('hidden');

      // Clear the file input
      const fileInput = form.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  initFileInput() {
    // Create a hidden file input element if it doesn't exist
    if (!document.getElementById('hidden-file-input')) {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.id = 'hidden-file-input'
      fileInput.multiple = true
      fileInput.className = 'hidden'
      fileInput.accept = '.pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.txt'
      fileInput.setAttribute('data-quote-builder-target', 'fileInput')
      fileInput.setAttribute('data-action', 'change->quote-builder#handleFileSelection')
      document.body.appendChild(fileInput)
    }
  }

  // Trigger the file input click
  triggerFileInput() {
    const fileInput = this.hasFileInputTarget ? this.fileInputTarget : document.getElementById('hidden-file-input')
    if (fileInput) {
      fileInput.click()
    }
  }

  // Trigger file input click and prepare for submission
  triggerFileInputAndSubmit() {
    console.log("Triggering file input for submission");
    const fileInput = document.getElementById('quote-file-input');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element not found");
    }
  }

  // Handle file selection and submit form
  handleFileSelectionAndSubmit(event) {
    console.log("File selection for submission triggered");
    const files = event.target.files;

    if (!files || files.length === 0) {
      console.log("No files selected");
      return;
    }

    console.log(`${files.length} file(s) selected, submitting form`);

    // Make sure the form has the right enctype
    const form = document.querySelector('form');
    if (form) {
      form.enctype = "multipart/form-data";

      // Show a loading message
      this.showToast(`Uploading ${files.length} file(s)...`);

      // Submit the form
      form.submit();
    } else {
      console.error("Form not found");
    }
  }

  // Remove a file and submit the form
  removeFileAndSubmit(event) {
    const fileId = event.currentTarget.dataset.fileId;

    if (!fileId) {
      console.error("No file ID found");
      return;
    }

    // Create a hidden input to mark file for deletion
    const form = document.querySelector('form');
    if (!form) {
      console.error("Form not found");
      return;
    }

    const deleteInput = document.createElement('input');
    deleteInput.type = 'hidden';
    deleteInput.name = `quote[files_attributes][${fileId}][_destroy]`;
    deleteInput.value = '1';
    form.appendChild(deleteInput);

    // Add tab parameter to return to files tab
    const tabInput = document.createElement('input');
    tabInput.type = 'hidden';
    tabInput.name = 'tab';
    tabInput.value = 'files';
    form.appendChild(tabInput);

    // Show loading message
    this.showToast('Removing file...');

    // Submit the form
    form.submit();
  }

  // Handle file selection
  handleFileSelection(event) {
    console.log("File selection triggered");
    const files = event.target.files;
    console.log("Files selected:", files ? files.length : 0);

    if (!files || files.length === 0) return;

    // Find an existing form or create a dedicated form for file uploads
    let form = document.querySelector('form');

    if (!form) {
      console.error("No form found for file upload");
      this.showToast('Error: Could not find form for file upload', 'error');
      return;
    }

    // Make sure the form has the right enctype
    form.enctype = "multipart/form-data";

    // Create a temporary visible container to show files
    let filePreviewContainer = document.querySelector('.file-previews');
    if (!filePreviewContainer) {
      filePreviewContainer = document.createElement('div');
      filePreviewContainer.className = 'file-previews space-y-3 mt-4';

      // Find a good place to add this container
      const filesTab = document.querySelector('[data-tab-id="files"]');
      if (filesTab) {
        const tabContent = filesTab.querySelector('.p-6');
        if (tabContent) {
          // Add it after the empty state
          const emptyState = tabContent.querySelector('[data-quote-builder-target="emptyFiles"]');
          if (emptyState) {
            emptyState.style.display = 'none'; // Hide the empty state
            emptyState.insertAdjacentElement('afterend', filePreviewContainer);
          } else {
            tabContent.appendChild(filePreviewContainer);
          }
        }
      }
    }

    // Add each file preview
    Array.from(files).forEach(file => {
      // Create a hidden input for the file
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.name = 'quote[files][]';
      fileInput.style.display = 'none';

      // Use DataTransfer to assign the file
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileInput.files = dataTransfer.files;

      // Add to the form
      form.appendChild(fileInput);

      // Create a visible preview item
      const filePreview = document.createElement('div');
      filePreview.className = 'flex items-center gap-3 bg-white dark:bg-space-900 p-3 rounded-md shadow-sm border border-gray-200 dark:border-space-700';

      // Get icon based on file type
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const iconClass = {
        'pdf': 'text-red-500',
        'doc': 'text-blue-500', 'docx': 'text-blue-500',
        'xls': 'text-green-500', 'xlsx': 'text-green-500',
        'jpg': 'text-purple-500', 'jpeg': 'text-purple-500', 'png': 'text-purple-500'
      }[fileExtension] || 'text-gray-500';

      filePreview.innerHTML = `
        <div class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-100 dark:bg-space-800 rounded-md">
          <svg class="w-6 h-6 ${iconClass}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
        </div>
        <div class="flex-1 min-w-0">
          <p class="text-sm font-medium text-gray-900 dark:text-white truncate">${file.name}</p>
          <p class="text-xs text-gray-500 dark:text-space-400">${(file.size / 1024).toFixed(2)} KB</p>
        </div>
        <div class="flex-shrink-0">
          <span class="text-xs px-2 py-1 rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300">
            New
          </span>
        </div>
      `;

      // Add to the preview container
      filePreviewContainer.appendChild(filePreview);
    });

    // Show a toast message
    this.showToast(`${files.length} file(s) ready to upload. Save the form to complete the upload.`);

    // Clear the input for future selections
    event.target.value = '';
  }

  // Add a file thumbnail to the display
  addFileThumbnail(file) {
    console.log("Adding file thumbnail for:", file.name);

    // Find container using DOM query if target not available
    let filesContainer = this.hasFilesContainerTarget ? this.filesContainerTarget : null;
    if (!filesContainer) {
      filesContainer = document.querySelector('[data-quote-builder-target="filesContainer"]');
    }

    if (!filesContainer) {
      console.log("Creating files container");
      this.createFilesContainer();
      // Try to find it again
      filesContainer = document.querySelector('[data-quote-builder-target="filesContainer"]');
    }

    if (!filesContainer) {
      console.log("ERROR: Files container still doesn't exist!");
      return;
    }

    // Create thumbnail element
    const thumbnail = document.createElement('div');
    thumbnail.className = 'flex items-center gap-3 bg-white dark:bg-space-900 p-3 rounded-md shadow-sm border border-gray-200 dark:border-space-700';

    // Icon based on file type
    const fileIcon = this.getFileIcon(file.name);

    thumbnail.innerHTML = `
    <div class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-100 dark:bg-space-800 rounded-md">
      ${fileIcon}
    </div>
    <div class="flex-1 min-w-0">
      <p class="text-sm font-medium text-gray-900 dark:text-white truncate">${file.name}</p>
      <p class="text-xs text-gray-500 dark:text-space-400">${this.formatFileSize(file.size)}</p>
    </div>
    <div class="flex-shrink-0">
      <span class="text-xs px-2 py-1 rounded-full bg-blue-100 dark:bg-blue-900/30 text-blue-700 dark:text-blue-300">
        New
      </span>
    </div>
  `;

    filesContainer.appendChild(thumbnail);
    console.log("Thumbnail appended to container");

    // Hide empty state
    const emptyFiles = document.querySelector('[data-quote-builder-target="emptyFiles"]');
    if (emptyFiles) {
      emptyFiles.classList.add('hidden');
      console.log("Empty files state hidden");
    }
  }

  // Create files container if it doesn't exist
  createFilesContainer() {
    const filesTab = document.querySelector('[data-tab-id="files"]');
    if (!filesTab) {
      console.log("Files tab not found!");
      return;
    }

    const container = document.createElement('div');
    container.className = 'space-y-3 mt-4';
    container.setAttribute('data-quote-builder-target', 'filesContainer');

    const tabContent = filesTab.querySelector('.p-6');
    if (tabContent) {
      tabContent.appendChild(container);
      console.log("Files container created and appended");
    } else {
      console.log("Tab content not found within files tab!");
    }
  }

  // Get an icon based on the file extension
  getFileIcon(filename) {
    const extension = filename.split('.').pop().toLowerCase()

    const iconMap = {
      'pdf': '<svg class="w-6 h-6 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>',
      'doc': '<svg class="w-6 h-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>',
      'docx': '<svg class="w-6 h-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>',
      'xls': '<svg class="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>',
      'xlsx': '<svg class="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>',
      'jpg': '<svg class="w-6 h-6 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>',
      'jpeg': '<svg class="w-6 h-6 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>',
      'png': '<svg class="w-6 h-6 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>',
      'txt': '<svg class="w-6 h-6 text-space-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>'
    }

    return iconMap[extension] || '<svg class="w-6 h-6 text-space-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>'
  }

  // Format file size in KB, MB, etc.
  formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  // Refresh the files display
  refreshFilesDisplay() {
    if (!this.hasEmptyFilesTarget) return

    // Check if there are files
    const existingFiles = document.querySelectorAll('[data-attachment-id]')
    const newFiles = document.getElementById('file-uploads-container')

    if ((existingFiles && existingFiles.length > 0) || (newFiles && newFiles.querySelector('input'))) {
      this.emptyFilesTarget.classList.add('hidden')

      // Create the files container if it doesn't exist yet
      if (!this.hasFilesContainerTarget) {
        this.createFilesContainer()
      }
    } else {
      this.emptyFilesTarget.classList.remove('hidden')
    }
  }

  // Remove file
  removeFile(event) {
    const fileId = event.currentTarget.dataset.fileId

    if (!fileId) return

    // Create a hidden input to mark file for deletion
    const form = this.hasFormTarget ? this.formTarget : document.querySelector('form')
    const deleteInput = document.createElement('input')
    deleteInput.type = 'hidden'
    deleteInput.name = `quote[files_attributes][${fileId}][_destroy]`
    deleteInput.value = '1'
    form.appendChild(deleteInput)

    // Hide the file thumbnail
    const thumbnail = event.currentTarget.closest('.file-thumbnail')
    if (thumbnail) {
      thumbnail.classList.add('hidden')
    }

    this.showToast('File will be removed when you save')
  }

  // Notes handling methods
  // Open the note modal
  openNoteModal() {
    console.log("Opening note modal");
    const modal = document.getElementById('note-modal');
    if (modal) {
      modal.classList.remove('hidden');
      // Focus the content field
      const contentField = document.getElementById('note_content');
      if (contentField) {
        contentField.focus();
      }
    } else {
      console.error("Note modal element not found in the DOM");
    }
  }

  closeNoteModal() {
    const modal = document.getElementById('note-modal');
    if (modal) {
      modal.classList.add('hidden');

      // Clear form fields
      const form = document.getElementById('modal-note-form');
      if (form) {
        const textarea = form.querySelector('textarea');
        if (textarea) {
          textarea.value = '';
        }
      }
    }
  }

  // Add a note and submit the form
  addNoteAndSubmit(event) {
    console.log("Add note and submit triggered");
    const category = document.getElementById('note-category').value;
    const content = document.getElementById('note-content').value.trim();

    if (!content) {
      this.showToast('Please enter note content', 'error');
      return;
    }

    // Create hidden fields for the note
    const form = document.querySelector('form');
    if (!form) {
      console.error("No form found for adding notes");
      return;
    }

    // Add the note index (using timestamp as unique identifier)
    const newIndex = new Date().getTime();

    // Add hidden fields for note data
    const noteFields = document.createElement('div');
    noteFields.className = 'hidden';
    noteFields.id = `note-fields-${newIndex}`;

    noteFields.innerHTML = `
    <input type="hidden" name="quote[notes_attributes][${newIndex}][category]" value="${category}">
    <input type="hidden" name="quote[notes_attributes][${newIndex}][content]" value="${content}">
    <input type="hidden" name="tab" value="notes">
  `;

    form.appendChild(noteFields);

    // Close the modal
    this.closeNoteModal();

    // Show loading message
    this.showToast('Adding note...');

    // Submit the form
    form.submit();
  }

  // Add a new note
  addNote(event) {
    console.log("Add note triggered");
    const category = document.getElementById('note-category').value;
    const content = document.getElementById('note-content').value.trim();
    console.log("Category:", category, "Content:", content);

    if (!content) {
      this.showToast('Please enter note content', 'error');
      return;
    }

    // Create a new note index
    const newIndex = new Date().getTime();

    // Create hidden fields for the note
    const form = this.hasFormTarget ? this.formTarget : document.querySelector('form');
    const noteFields = document.createElement('div');
    noteFields.className = 'hidden';
    noteFields.id = `note-fields-${newIndex}`;

    noteFields.innerHTML = `
    <input type="hidden" name="quote[notes_attributes][${newIndex}][category]" value="${category}">
    <input type="hidden" name="quote[notes_attributes][${newIndex}][content]" value="${content}">
  `;

    form.appendChild(noteFields);

    // Add the note to the display
    this.addNoteThumbnail(category, content, newIndex);

    // Close the modal
    const modal = document.getElementById('note-modal');
    if (modal) {
      modal.remove();
    }

    this.showToast('Note added');
  }

  // Add a note thumbnail to the display
  addNoteThumbnail(category, content, index) {
    console.log("Adding note thumbnail:", category, index);

    // Find container using DOM query if target not available
    let notesContainer = this.hasNotesContainerTarget ? this.notesContainerTarget : null;
    if (!notesContainer) {
      notesContainer = document.querySelector('[data-quote-builder-target="notesContainer"]');
    }

    if (!notesContainer) {
      console.log("Creating notes container");
      this.createNotesContainer();
      // Try to find it again
      notesContainer = document.querySelector('[data-quote-builder-target="notesContainer"]');
    }

    if (!notesContainer) {
      console.log("ERROR: Notes container still doesn't exist!");
      return;
    }

    // Get category color class
    const colorClass = this.getNoteCategoryColor(category);

    // Create note element
    const note = document.createElement('div');
    note.className = `${colorClass} rounded-md p-3 shadow-sm`;
    note.dataset.noteIndex = index;

    // Format the date
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }) + ' ' + now.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    });

    note.innerHTML = `
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between text-sm text-gray-600 dark:text-space-400 mb-2 gap-1">
      <span class="font-medium">Current User</span>
      <span>${category.charAt(0).toUpperCase() + category.slice(1)} | ${formattedDate}</span>
    </div>
    <div class="text-gray-900 dark:text-space-100">${this.formatNoteContent(content)}</div>
  `;

    notesContainer.appendChild(note);
    console.log("Note appended to container");

    // Hide empty state
    const emptyNotes = document.querySelector('[data-quote-builder-target="emptyNotes"]');
    if (emptyNotes) {
      emptyNotes.classList.add('hidden');
      console.log("Empty notes state hidden");
    }
  }

  // Create notes container if it doesn't exist
  createNotesContainer() {
    const notesTab = document.querySelector('[data-tab-id="notes"]');
    if (!notesTab) {
      console.log("Notes tab not found!");
      return;
    }

    const container = document.createElement('div');
    container.className = 'space-y-3 mt-4';
    container.setAttribute('data-quote-builder-target', 'notesContainer');

    const tabContent = notesTab.querySelector('.p-6');
    if (tabContent) {
      tabContent.appendChild(container);
      console.log("Notes container created and appended");
    } else {
      console.log("Tab content not found within notes tab!");
    }
  }

  // Get color class based on note category
  getNoteCategoryColor(category) {
    const colorMap = {
      'general': 'bg-blue-100 dark:bg-blue-900/30',
      'internal': 'bg-purple-100 dark:bg-purple-900/30',
      'delivery': 'bg-green-100 dark:bg-green-900/30',
      'customer': 'bg-amber-100 dark:bg-amber-900/30',
      'billing': 'bg-red-100 dark:bg-red-900/30'
    }

    return colorMap[category] || 'bg-blue-100 dark:bg-blue-900/30'
  }

  // Format note content (detect URLs, etc.)
  formatNoteContent(content) {
    // Replace URLs with links
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return content.replace(urlRegex, '<a href="$1" target="_blank" class="text-blue-600 dark:text-blue-400 hover:underline">$1</a>')
  }

  // Refresh the notes display
  refreshNotesDisplay() {
    if (!this.hasEmptyNotesTarget) return

    // Check if there are notes
    const existingNotes = document.querySelectorAll('[data-note-id]')
    const newNotes = document.querySelectorAll('[id^="note-fields-"]')

    if ((existingNotes && existingNotes.length > 0) || (newNotes && newNotes.length > 0)) {
      this.emptyNotesTarget.classList.add('hidden')

      // Create the notes container if it doesn't exist yet
      if (!this.hasNotesContainerTarget) {
        this.createNotesContainer()
      }
    } else {
      this.emptyNotesTarget.classList.remove('hidden')
    }
  }

  setupLineItemListeners() {
    // Add event listeners to line item quantity and price inputs
    const lineItems = document.querySelectorAll('.line-item-wrapper');
    lineItems.forEach(item => {
      const quantityInput = item.querySelector('[data-line-item-target="quantity"]');
      const priceInput = item.querySelector('[data-line-item-target="price"]');
      const costInput = item.querySelector('[data-line-item-target="costDisplay"]');

      if (quantityInput) {
        quantityInput.addEventListener('input', this.updateTotals.bind(this));
      }
      if (priceInput) {
        priceInput.addEventListener('input', this.updateTotals.bind(this));
      }
      if (costInput) {
        costInput.addEventListener('input', this.updateTotals.bind(this));
      }
    });
  }

  setupRealTimeUpdates() {
    // Listen for changes to tax rate
    const taxRateInput = document.querySelector('input[name="quote[tax_rate]"]')
    if (taxRateInput) {
      taxRateInput.addEventListener('input', this.updateTotals.bind(this))
    }

    // Listen for changes to discount type
    const discountTypeInputs = document.querySelectorAll('input[name="quote[discount_type]"]')
    if (discountTypeInputs.length > 0) {
      discountTypeInputs.forEach(input => {
        input.addEventListener('change', this.updateTotals.bind(this))
      })
    }

    // Listen for changes to discount amount
    const discountAmountInput = document.querySelector('input[name="quote[discount_amount]"]')
    if (discountAmountInput) {
      discountAmountInput.addEventListener('input', this.updateTotals.bind(this))
    }

    // Listen for changes in shipping inputs
    const shippingCostInput = document.querySelector('input[name="quote[shipment_cost]"]')
    if (shippingCostInput) {
      shippingCostInput.addEventListener('input', this.updateTotals.bind(this))
    }

    const shippingDiscountTypeInputs = document.querySelectorAll('input[name="quote[shipment_discount_type]"]')
    if (shippingDiscountTypeInputs.length > 0) {
      shippingDiscountTypeInputs.forEach(input => {
        input.addEventListener('change', this.updateTotals.bind(this))
      })
    }

    const shippingDiscountAmountInput = document.querySelector('input[name="quote[shipment_discount]"]')
    if (shippingDiscountAmountInput) {
      shippingDiscountAmountInput.addEventListener('input', this.updateTotals.bind(this))
    }

  }

}