// order_totals_controller.js - Simplified version
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "subtotal", "tax", "taxRate", "shipping", "total",
    "totalCost", "totalProfit", "marginPercentage", "marginIndicator",
    "discount", "shippingDiscount"
  ]

  initialize() {
    console.log("Order totals controller initializing")
    // Store initial server-rendered values
    this.initialValues = {}
  }

  connect() {
    console.log("Order totals controller connected", this.element)

    // Save initial values from server
    this.saveInitialValues()

    // Set up a single window-level event listener
    // Note: We're not going to add individual listeners to each order item
    // since those will be handled by the @window action in the HTML

    // Set up event listeners for form input changes that impact totals
    this.setupInputListeners()

    // Initial calculation
    setTimeout(() => this.updateTotals(), 200)
  }

  disconnect() {
    // Clean up any listeners we've manually added
    this.removeInputListeners()
  }

  saveInitialValues() {
    // Save all initial display values to restore if calculation fails
    this.targetNames = [
      "subtotal", "tax", "total", "totalCost", "totalProfit",
      "marginPercentage", "discount", "shippingDiscount", "shipping"
    ]

    this.targetNames.forEach(name => {
      if (this[`has${name.charAt(0).toUpperCase() + name.slice(1)}Target`]) {
        this.initialValues[name] = this[`${name}Target`].textContent
      }
    })

    if (this.hasTaxRateTarget) {
      this.initialValues.taxRate = this.taxRateTarget.textContent
    }

    console.log("Saved initial values:", this.initialValues)
  }

  setupInputListeners() {
    // Get the form containing our order
    const form = this.element.closest('form')
    if (!form) {
      console.error("No form found for order-totals controller")
      return
    }

    console.log("Setting up input listeners on form")

    // Use event delegation for efficiency
    form.addEventListener('input', this.handleFormChanges.bind(this))
    form.addEventListener('change', this.handleFormChanges.bind(this))
  }

  removeInputListeners() {
    const form = this.element.closest('form')
    if (!form) return

    form.removeEventListener('input', this.handleFormChanges.bind(this))
    form.removeEventListener('change', this.handleFormChanges.bind(this))
  }

  handleFormChanges(event) {
    // Relevant form inputs that should trigger total recalculation
    const relevantInputs = [
      'tax_rate',
      'shipping_cost',
      'discount_amount',
      'discount_type',
      'shipping_discount_amount',
      'shipping_discount_type',
      'order_type',
      'builder_cost',
      'cost',
      'price',
      'quantity'
    ]

    // Check if this is a relevant input change
    const isRelevant = relevantInputs.some(input =>
      event.target.name && event.target.name.includes(input)
    )

    if (isRelevant) {
      console.log(`Form input change detected: ${event.target.name}`)

      // Debounce updates for better performance
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => {
        this.updateTotals()
      }, 50)
    }
  }

  updateTotals() {
    console.log("============ UPDATING ORDER TOTALS ============")
    try {
      console.log("Processing totals update...")
      let subtotal = 0
      let totalCost = 0
      let lineItemDiscounts = 0
      let itemCount = 0

      // Get order type
      const orderTypeSelect = document.querySelector('select[name$="[order_type]"]')
      const isBuilder = orderTypeSelect && orderTypeSelect.value === 'builder_order'
      console.log(`Processing order totals as ${isBuilder ? 'builder order' : 'standard order'}`)

      // Calculate totals from all order items
      const orderItems = document.querySelectorAll('[data-controller~="order-item"]')
      console.log(`Found ${orderItems.length} order items to process`)

      orderItems.forEach((item, index) => {
        console.log(`Processing item ${index + 1}`)

        // Skip items that are hidden or marked for destruction
        if (item.classList.contains('hidden')) {
          console.log('Item is hidden, skipping')
          return
        }

        const destroyInput = item.querySelector('input[name*="[_destroy]"]')
        if (destroyInput && destroyInput.value === 'true') {
          console.log('Item is marked for destruction, skipping')
          return
        }

        const quantity = parseFloat(item.querySelector('[data-order-item-target="quantity"]')?.value) || 0
        const price = parseFloat(item.querySelector('[data-order-item-target="price"]')?.value) || 0

        // Get appropriate cost based on order type
        let cost = 0
        if (isBuilder) {
          const builderCostDisplay = item.querySelector('[data-order-item-target="builderCostDisplay"]')
          if (builderCostDisplay && parseFloat(builderCostDisplay.value) > 0) {
            cost = parseFloat(builderCostDisplay.value)
          } else {
            cost = parseFloat(item.querySelector('[data-order-item-target="cost"]')?.value) || 0
          }
        } else {
          cost = parseFloat(item.querySelector('[data-order-item-target="cost"]')?.value) || 0
        }

        console.log(`Item values: quantity=${quantity}, price=${price}, cost=${cost}`)

        const itemSubtotal = quantity * price

        // Calculate line item discount
        const discountType = item.querySelector('[data-order-item-target="discountType"]')?.value || 'fixed'
        const discountAmount = parseFloat(item.querySelector('[data-order-item-target="discountAmount"]')?.value || 0)
        const itemDiscount = discountType === 'percentage'
          ? itemSubtotal * (discountAmount / 100)
          : Math.min(discountAmount, itemSubtotal)

        console.log(`Item calculations: subtotal=${itemSubtotal}, discount=${itemDiscount}`)

        subtotal += itemSubtotal
        totalCost += quantity * cost
        lineItemDiscounts += itemDiscount
        itemCount++
      })

      console.log(`Processed ${itemCount} items. Totals: subtotal=${subtotal}, totalCost=${totalCost}, lineItemDiscounts=${lineItemDiscounts}`)

      // Fall back to initial values if we have no items or calculations seem incorrect
      if (itemCount === 0 && this.initialValues.subtotal) {
        console.log("No valid items found, keeping initial server-rendered values")
        return this.restoreInitialValues()
      }

      // Get order-level values from form
      const form = this.element.closest('form')
      if (!form) {
        console.error("Could not find form element")
        return this.restoreInitialValues()
      }

      const orderDiscountType = form.querySelector('select[name*="[discount_type]"]')?.value || 'fixed'
      const orderDiscountAmount = parseFloat(form.querySelector('input[name*="[discount_amount]"]')?.value || 0)

      const shipping = parseFloat(form.querySelector('input[name*="[shipping_cost]"]')?.value || 0)
      const taxRate = parseFloat(form.querySelector('input[name*="[tax_rate]"]')?.value || 0)

      const shippingDiscountType = form.querySelector('select[name*="[shipping_discount_type]"]')?.value || 'fixed'
      const shippingDiscountAmount = parseFloat(form.querySelector('input[name*="[shipping_discount_amount]"]')?.value || 0)

      console.log("Order-level values:", {
        orderDiscountType,
        orderDiscountAmount,
        shipping,
        taxRate,
        shippingDiscountType,
        shippingDiscountAmount
      })

      // Calculate discounts
      const orderDiscount = orderDiscountType === 'percentage'
        ? subtotal * (orderDiscountAmount / 100)
        : Math.min(orderDiscountAmount, subtotal)

      const shippingDiscount = shippingDiscountType === 'percentage'
        ? shipping * (shippingDiscountAmount / 100)
        : Math.min(shippingDiscountAmount, shipping)

      // Calculate final totals
      const totalDiscounts = lineItemDiscounts + orderDiscount
      const totalDiscounted = subtotal - totalDiscounts
      const tax = (totalDiscounted * taxRate) / 100
      const total = totalDiscounted + tax + Math.max(0, shipping - shippingDiscount)

      // Calculate profit metrics
      const profit = totalDiscounted - totalCost
      const marginPercentage = totalDiscounted > 0 ? ((profit / totalDiscounted) * 100) : 0

      console.log("Final calculated values:", {
        subtotal, tax, total, totalCost, profit,
        marginPercentage, orderDiscount, shippingDiscount,
        totalDiscounts, totalDiscounted
      })

      // Update display elements and hidden inputs
      this.updateDisplay({
        subtotal,
        originalSubtotal: subtotal,
        discountedSubtotal: totalDiscounted,
        tax,
        total,
        totalCost,
        profit,
        marginPercentage,
        orderDiscount: totalDiscounts,
        shippingDiscount,
        shipping,
        taxRate
      })

      // Update hidden inputs for form submission
      this.updateHiddenInputs({
        subtotal,
        tax,
        total
      })

      console.log("Order totals updated successfully")
    } catch (error) {
      console.error('Error updating totals:', error)
      this.restoreInitialValues()
    }
    console.log("============ END UPDATING ORDER TOTALS ============")
  }

  updateHiddenInputs(values) {
    // Update hidden inputs for form submission
    const form = this.element.closest('form')
    if (!form) return

    // For subtotal, we need to decide which value to use
    const subtotalToSave = values.discountedSubtotal !== undefined
      ? values.discountedSubtotal
      : values.subtotal

    const subtotalInput = form.querySelector('input[name$="[subtotal]"]')
    if (subtotalInput) subtotalInput.value = subtotalToSave.toFixed(2)

    const taxInput = form.querySelector('input[name$="[tax]"]')
    if (taxInput) taxInput.value = values.tax.toFixed(2)

    const totalInput = form.querySelector('input[name$="[total]"]')
    if (totalInput) totalInput.value = values.total.toFixed(2)

    console.log("Updated hidden inputs with values:", {
      subtotal: subtotalToSave.toFixed(2),
      tax: values.tax.toFixed(2),
      total: values.total.toFixed(2)
    })
  }

  restoreInitialValues() {
    console.log("Restoring initial values:", this.initialValues)
    // Restore server-rendered values if available
    this.targetNames.forEach(name => {
      if (this[`has${name.charAt(0).toUpperCase() + name.slice(1)}Target`] && this.initialValues[name]) {
        this[`${name}Target`].textContent = this.initialValues[name]
      }
    })

    if (this.hasTaxRateTarget && this.initialValues.taxRate) {
      this.taxRateTarget.textContent = this.initialValues.taxRate
    }

    if (this.hasMarginIndicatorTarget) {
      // Try to determine color from percentage
      const percentText = this.initialValues.marginPercentage || "0%"
      const percent = parseFloat(percentText)
      this.marginIndicatorTarget.classList.remove('bg-red-500', 'bg-green-500')
      this.marginIndicatorTarget.classList.add(percent >= 8 ? 'bg-green-500' : 'bg-red-500')
    }
  }

  updateDisplay(values) {
    console.log("Updating display with values:", values)

    // Determine which subtotal to display
    // If there are discounts applied, we want to show the original subtotal
    // This matches how most e-commerce sites display order summaries
    const displaySubtotal = values.originalSubtotal || values.subtotal

    // Create an array of updates to apply
    const updates = [
      { target: 'subtotal', value: this.formatCurrency(displaySubtotal) },
      { target: 'tax', value: this.formatCurrency(values.tax) },
      { target: 'total', value: this.formatCurrency(values.total) },
      { target: 'totalCost', value: this.formatCurrency(values.totalCost) },
      { target: 'totalProfit', value: this.formatCurrency(values.profit) },
      { target: 'marginPercentage', value: `${values.marginPercentage.toFixed(2)}%` },
      { target: 'discount', value: `-${this.formatCurrency(values.orderDiscount)}` },
      { target: 'shippingDiscount', value: `-${this.formatCurrency(values.shippingDiscount)}` },
      { target: 'shipping', value: this.formatCurrency(values.shipping) }
    ]

    // Apply each update one by one
    updates.forEach(({ target, value }) => {
      // Construct the target property name in the controller
      const targetProperty = `${target}Target`
      const hasTargetProperty = `has${target.charAt(0).toUpperCase() + target.slice(1)}Target`

      // Check if we have this target
      if (this[hasTargetProperty]) {
        // Update the DOM element
        this[targetProperty].textContent = value
      } else {
        console.warn(`Target ${target} not found for update`)
      }
    })

    // Update tax rate separately (it's a special case)
    if (this.hasTaxRateTarget) {
      this.taxRateTarget.textContent = values.taxRate
    }

    // Update margin indicator separately (it's more complex)
    if (this.hasMarginIndicatorTarget) {
      const indicator = this.marginIndicatorTarget
      indicator.classList.remove('bg-red-500', 'bg-green-500')
      indicator.classList.add(values.marginPercentage >= 8 ? 'bg-green-500' : 'bg-red-500')
    }
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }
}