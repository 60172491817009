// app/javascript/controllers/address_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal", "form", "deleteModal", "deleteForm",
    "modalTitle", "submitButtonText", "addressId",
    "business", "firstName", "lastName", "streetAddress", "streetAddress2",
    "city", "state", "postalCode", "country", "phone",
    "isDefault", "addressTypeBilling", "addressTypeShipping",
    "addressQuery", "addressSuggestions"
  ]

  connect() {
    // Initialize any required setup
    this._setupAddressAutocomplete()
  }

  _setupAddressAutocomplete() {
    // Debounce the input to prevent too many requests
    this.addressQueryTarget.addEventListener('input', this._debounce(() => {
      this.fetchAddressSuggestions()
    }, 500))
  }

  _debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  async fetchAddressSuggestions() {
    const query = this.addressQueryTarget.value.trim()

    if (query.length < 3) {
      this.addressSuggestionsTarget.innerHTML = ''
      this.addressSuggestionsTarget.classList.add('hidden')
      return
    }

    try {
      const response = await fetch(`/api/v1/addresses/geocode?query=${encodeURIComponent(query)}`)
      if (!response.ok) throw new Error('Network response was not ok')

      const data = await response.json()

      if (data.suggestions && data.suggestions.length > 0) {
        this._renderSuggestions(data.suggestions)
      } else {
        this.addressSuggestionsTarget.innerHTML = '<div class="p-2 text-sm text-gray-500">No suggestions found</div>'
      }

      this.addressSuggestionsTarget.classList.remove('hidden')
    } catch (error) {
      console.error('Error fetching address suggestions:', error)
      this.addressSuggestionsTarget.innerHTML = '<div class="p-2 text-sm text-red-500">Error loading suggestions</div>'
    }
  }

  _renderSuggestions(suggestions) {
    this.addressSuggestionsTarget.innerHTML = ''

    suggestions.forEach(suggestion => {
      const div = document.createElement('div')
      div.className = 'p-2 text-sm hover:bg-gray-100 dark:hover:bg-space-700 cursor-pointer'
      div.textContent = suggestion.text
      div.dataset.action = 'click->address-form#selectAddress'
      div.dataset.address = JSON.stringify(suggestion.address)

      this.addressSuggestionsTarget.appendChild(div)
    })
  }

  selectAddress(event) {
    const addressData = JSON.parse(event.currentTarget.dataset.address)

    // Clear existing values first
    this.clearAddressFields()

    // Populate the form with the selected address
    this.streetAddressTarget.value = addressData.street_address || ''
    this.cityTarget.value = addressData.city || ''
    this.stateTarget.value = addressData.state || ''
    this.postalCodeTarget.value = addressData.postal_code || ''
    this.countryTarget.value = addressData.country || 'United States'

    // Hide the suggestions
    this.addressSuggestionsTarget.classList.add('hidden')
    this.addressQueryTarget.value = ''
  }

  clearAddressFields() {
    this.streetAddressTarget.value = ''
    this.streetAddress2Target.value = ''
    this.cityTarget.value = ''
    this.stateTarget.value = ''
    this.postalCodeTarget.value = ''
    this.countryTarget.value = 'United States'
  }

  showAddModal() {
    // Reset form values
    this.resetForm()

    // Set modal title and button text for adding
    this.modalTitleTarget.textContent = "Add New Address"
    this.submitButtonTextTarget.textContent = "Add Address"

    // Update form action to create
    this.formTarget.action = this.formTarget.getAttribute('action').replace('/update/', '/');
    this.formTarget.method = "post"

    // Show the modal
    this.modalTarget.classList.remove("hidden")
  }

  showEditModal(event) {
    // Prevent default link behavior
    event.preventDefault()

    // Debug: Log the data attributes
    console.log("Address data from dataset:", event.currentTarget.dataset)

    // Get address data from the element's data attributes
    const addressData = event.currentTarget.dataset
    const addressId = addressData.id

    // Set form field values
    this.addressIdTarget.value = addressId || ""
    this.businessTarget.value = addressData.business || ""
    this.firstNameTarget.value = addressData.firstName || ""
    this.lastNameTarget.value = addressData.lastName || ""
    this.streetAddressTarget.value = addressData.streetAddress || ""
    this.streetAddress2Target.value = addressData.streetAddress2 || addressData['streetAddress-2'] || ""  // Note the dash format alternative
    this.cityTarget.value = addressData.city || ""
    this.stateTarget.value = addressData.state || ""
    this.postalCodeTarget.value = addressData.postalCode || ""
    this.countryTarget.value = addressData.country || "United States"
    this.phoneTarget.value = addressData.phone || ""

    // Check for boolean values
    this.isDefaultTarget.checked = addressData.isDefault === "true"

    // For radio buttons
    if (addressData.addressType === "billing") {
      this.addressTypeBillingTarget.checked = true
    } else {
      this.addressTypeShippingTarget.checked = true
    }

    // Set modal title and button text for editing
    this.modalTitleTarget.textContent = "Edit Address"
    this.submitButtonTextTarget.textContent = "Update Address"

    // Get the base URL from the form action - ensure we construct the URL correctly
    const formActionUrl = this.formTarget.getAttribute('action')

    // Make sure we're building a URL like /ox/ktapp/customers/33701/addresses/22068
    // First check if the action URL already has 'addresses' in it
    if (formActionUrl.includes('/addresses/')) {
      // We already have the path format we need
      const basePathParts = formActionUrl.split('/addresses/')
      this.formTarget.action = `${basePathParts[0]}/addresses/${addressId}`
    } else if (formActionUrl.includes('/addresses')) {
      // The URL ends with /addresses with no trailing slash
      this.formTarget.action = `${formActionUrl}/${addressId}`
    } else {
      // Need to append /addresses to the path
      this.formTarget.action = `${formActionUrl.replace(/\/$/, '')}/addresses/${addressId}`
    }

    // Set the proper HTTP method
    const methodInput = this.formTarget.querySelector('input[name="_method"]') || document.createElement('input')
    methodInput.type = 'hidden'
    methodInput.name = '_method'
    methodInput.value = 'patch'

    if (!this.formTarget.querySelector('input[name="_method"]')) {
      this.formTarget.appendChild(methodInput)
    }

    // Show the modal
    this.modalTarget.classList.remove("hidden")
  }

  showDeleteModal(event) {
    // Prevent default link behavior
    event.preventDefault()

    // Get the address ID from the data attribute
    const addressId = event.currentTarget.dataset.id

    // Update the delete form action
    const baseUrl = this.deleteFormTarget.action.replace(/\/\d+$/, '')
    this.deleteFormTarget.action = `${baseUrl}/${addressId}`

    // Show the delete confirmation modal
    this.deleteModalTarget.classList.remove("hidden")
  }

  hideModal(event) {
    // Close the modal when clicking outside or on cancel
    if (event.target === event.currentTarget ||
      (event.target.dataset && event.target.dataset.action &&
        event.target.dataset.action.includes("hideModal"))) {
      this.modalTarget.classList.add("hidden")
    }
  }

  // Also fix the hideDeleteModal method similarly
  hideDeleteModal(event) {
    // Close the delete modal when clicking outside or on cancel
    if (event.target === event.currentTarget ||
      (event.target.dataset && event.target.dataset.action &&
        event.target.dataset.action.includes("hideDeleteModal"))) {
      this.deleteModalTarget.classList.add("hidden")
    }
  }

  submitForm(event) {
    event.preventDefault()

    // Basic validation
    if (!this.validateForm()) {
      return
    }

    // Get the form element
    const form = this.formTarget

    // Create a hidden input for the method if using PATCH/PUT
    if (form.method.toLowerCase() === "patch") {
      let methodInput = form.querySelector('input[name="_method"]')
      if (!methodInput) {
        methodInput = document.createElement('input')
        methodInput.type = 'hidden'
        methodInput.name = '_method'
        form.appendChild(methodInput)
      }
      methodInput.value = 'patch'
    }

    // Submit the form
    form.submit()
  }

  validateForm() {
    // Required fields validation
    if (!this.streetAddressTarget.value.trim()) {
      alert("Street address is required")
      this.streetAddressTarget.focus()
      return false
    }

    if (!this.cityTarget.value.trim()) {
      alert("City is required")
      this.cityTarget.focus()
      return false
    }

    if (!this.stateTarget.value.trim()) {
      alert("State/Province is required")
      this.stateTarget.focus()
      return false
    }

    if (!this.postalCodeTarget.value.trim()) {
      alert("Postal/Zip code is required")
      this.postalCodeTarget.focus()
      return false
    }

    return true
  }

  resetForm() {
    // Reset form fields
    this.addressIdTarget.value = ""
    this.businessTarget.value = ""
    this.firstNameTarget.value = ""
    this.lastNameTarget.value = ""
    this.streetAddressTarget.value = ""
    this.streetAddress2Target.value = ""
    this.cityTarget.value = ""
    this.stateTarget.value = ""
    this.postalCodeTarget.value = ""
    this.countryTarget.value = "United States"
    this.phoneTarget.value = ""
    this.isDefaultTarget.checked = false
    this.addressTypeShippingTarget.checked = true

    // Clear the address query and suggestions
    this.addressQueryTarget.value = ""
    this.addressSuggestionsTarget.innerHTML = ""
    this.addressSuggestionsTarget.classList.add("hidden")
  }
}