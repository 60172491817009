// app/javascript/controllers/global_tabs_controller.js
import { Controller } from "@hotwired/stimulus"

// Configurable CSS variables for theming
const DEFAULT_ACTIVE_COLOR = '#3b82f6' // Blue-500
const DEFAULT_HOVER_COLOR = '#60a5fa' // Blue-400

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    // Check for any CSS variables that might be set
    this.activeColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--tab-active-color').trim() || DEFAULT_ACTIVE_COLOR
    this.hoverColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--tab-hover-color').trim() || DEFAULT_HOVER_COLOR

    // First check URL parameters for tab information
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get('tab');

    if (tabParam) {
      // If we have a tab parameter, activate that tab
      const matchingTab = this.tabTargets.find(tab => tab.dataset.tab === tabParam)
      if (matchingTab) {
        this.switchTab({ currentTarget: matchingTab, preventDefault: () => { } })
        return
      }
    }

    // Then check for URL hash (backwards compatibility)
    if (window.location.hash) {
      const tabId = window.location.hash.substring(1)
      const matchingTab = this.tabTargets.find(tab => tab.dataset.tab === tabId)
      if (matchingTab) {
        this.switchTab({ currentTarget: matchingTab, preventDefault: () => { } })
        return
      }
    }

    // Otherwise, ensure first tab is active
    if (this.tabTargets.length > 0 && !this.hasActiveTab()) {
      this.activateTab(this.tabTargets[0])
    }
  }

  hasActiveTab() {
    return this.tabTargets.some(tab => tab.classList.contains('border-blue-500'))
  }

  switchTab(event) {
    event.preventDefault()
    const selectedTab = event.currentTarget
    const tabId = selectedTab.dataset.tab

    // Handle both URL formats (maintain backwards compatibility)
    // 1. Update URL parameter if the tab link has an href
    if (selectedTab.href) {
      const url = new URL(selectedTab.href)

      // Keep the scroll position
      const scrollPosition = window.scrollY

      // Update URL without page reload
      window.history.pushState({}, "", url.toString())

      // Restore scroll position
      window.scrollTo(0, scrollPosition)
    } else {
      // 2. Fall back to hash-based navigation (legacy support)
      const scrollPosition = window.scrollY
      window.location.hash = tabId
      window.scrollTo(0, scrollPosition)
    }

    // Update tabs
    this.tabTargets.forEach(tab => {
      if (tab === selectedTab) {
        this.activateTab(tab)
      } else {
        this.deactivateTab(tab)
      }
    })

    // Update content
    this.contentTargets.forEach(content => {
      if (content.dataset.tabId === tabId) {
        content.classList.remove('hidden')
      } else {
        content.classList.add('hidden')
      }
    })
  }

  activateTab(tab) {
    // Get the current active and inactive classes
    const activeClassAttr = tab.getAttribute('data-active-class');
    const inactiveClassAttr = tab.getAttribute('data-inactive-class');

    // Use custom classes if provided, otherwise fall back to defaults
    const activeClasses = activeClassAttr ? activeClassAttr.split(' ') : ['border-blue-500', 'text-blue-500'];
    const inactiveClasses = inactiveClassAttr ? inactiveClassAttr.split(' ') : ['border-transparent', 'hover:text-blue-400'];

    // Remove inactive classes and add active classes
    inactiveClasses.forEach(cls => tab.classList.remove(cls));
    activeClasses.forEach(cls => tab.classList.add(cls));
  }

  deactivateTab(tab) {
    // Get the current active and inactive classes
    const activeClassAttr = tab.getAttribute('data-active-class');
    const inactiveClassAttr = tab.getAttribute('data-inactive-class');

    // Use custom classes if provided, otherwise fall back to defaults
    const activeClasses = activeClassAttr ? activeClassAttr.split(' ') : ['border-blue-500', 'text-blue-500'];
    const inactiveClasses = inactiveClassAttr ? inactiveClassAttr.split(' ') : ['border-transparent', 'hover:text-blue-400'];

    // Remove active classes and add inactive classes
    activeClasses.forEach(cls => tab.classList.remove(cls));
    inactiveClasses.forEach(cls => tab.classList.add(cls));
  }
}