import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    // Set the first tab as active by default
    if (this.tabTargets.length > 0) {
      this.activateTab(this.tabTargets[0])
    }
  }

  switch(event) {
    event.preventDefault()
    this.activateTab(event.currentTarget)
  }

  activateTab(tab) {
    // Deactivate all tabs
    this.tabTargets.forEach((t) => {
      t.classList.remove("bg-blue-500", "text-white")
      t.classList.add("bg-gray-100", "dark:bg-space-800", "text-gray-700", "dark:text-space-300")
    })

    // Activate the selected tab
    tab.classList.remove("bg-gray-100", "dark:bg-space-800", "text-gray-700", "dark:text-space-300")
    tab.classList.add("bg-blue-500", "text-white")

    // Hide all panels
    this.panelTargets.forEach((panel) => {
      panel.classList.add("hidden")
    })

    // Show the selected panel
    const panelId = tab.dataset.tabPanel
    const panel = this.panelTargets.find((p) => p.id === panelId)
    if (panel) {
      panel.classList.remove("hidden")
    }
  }
}