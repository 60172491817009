// app/javascript/controllers/credit_line_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal", "form", "creditLimitInput", "termsInput", "notesInput",
    "updateModal", "updateForm", "newLimitInput", "reasonInput",
    "suspendModal", "suspendForm", "suspendReasonInput"
  ]

  connect() {
    // Initialize any required setup
  }

  showCreateModal() {
    this.modalTarget.classList.remove("hidden")
  }

  showUpdateModal() {
    this.updateModalTarget.classList.remove("hidden")
  }

  showSuspendModal() {
    this.suspendModalTarget.classList.remove("hidden")
  }

  hideModal(event) {
    // Close the modal when clicking outside or on cancel
    if (event.target === event.currentTarget ||
      (event.target.dataset && event.target.dataset.action &&
        event.target.dataset.action.includes("hideModal"))) {
      this.modalTarget.classList.add("hidden")
      this.updateModalTarget.classList.add("hidden")
      this.suspendModalTarget.classList.add("hidden")
    }
  }

  createCreditLine(event) {
    event.preventDefault()

    // Validate inputs
    const creditLimit = parseFloat(this.creditLimitInputTarget.value)
    const terms = parseInt(this.termsInputTarget.value)

    if (isNaN(creditLimit) || creditLimit <= 0) {
      alert("Please enter a valid credit limit")
      return
    }

    if (isNaN(terms) || terms <= 0) {
      alert("Please enter valid payment terms")
      return
    }

    // Submit the form
    this.formTarget.submit()
  }

  updateCreditLimit(event) {
    event.preventDefault()

    // Validate inputs
    const newLimit = parseFloat(this.newLimitInputTarget.value)

    if (isNaN(newLimit) || newLimit <= 0) {
      alert("Please enter a valid credit limit")
      return
    }

    // Submit the form
    this.updateFormTarget.submit()
  }

  suspendCreditLine(event) {
    event.preventDefault()

    // Validate input
    if (!this.suspendReasonInputTarget.value.trim()) {
      alert("Please provide a reason for suspending the credit line")
      return
    }

    // Submit the form
    this.suspendFormTarget.submit()
  }
}