import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subtotal", "tax", "total"]

  connect() {
    console.log("Totals controller connected")
    // Initial calculation
    this.updateTotals()

    // Listen for order item changes
    document.addEventListener('order-item:update', this.updateTotals.bind(this))

    // Listen for form input changes
    const form = this.element.closest('form')
    if (form) {
      form.addEventListener('input', this.handleInputChange.bind(this))
      form.addEventListener('change', this.handleInputChange.bind(this))
    }
  }

  disconnect() {
    // Clean up event listeners
    document.removeEventListener('order-item:update', this.updateTotals.bind(this))

    const form = this.element.closest('form')
    if (form) {
      form.removeEventListener('input', this.handleInputChange.bind(this))
      form.removeEventListener('change', this.handleInputChange.bind(this))
    }
  }

  handleInputChange(event) {
    // Only update if tax rate changed
    if (event.target.name && event.target.name.includes('[tax_rate]')) {
      this.updateTotals()
    }
  }

  updateTotals() {
    let subtotal = 0
    document.querySelectorAll('[data-order-item-target="total"]').forEach(el => {
      const amount = parseFloat(el.textContent.replace(/[^0-9.-]+/g, ""))
      if (!isNaN(amount)) {
        subtotal += amount
      }
    })

    const taxRate = parseFloat(document.querySelector('input[name$="[tax_rate]"]')?.value || "0")
    const tax = subtotal * (taxRate / 100)
    const total = subtotal + tax

    if (this.hasSubtotalTarget) this.subtotalTarget.textContent = this.formatCurrency(subtotal)
    if (this.hasTaxTarget) this.taxTarget.textContent = this.formatCurrency(tax)
    if (this.hasTotalTarget) this.totalTarget.textContent = this.formatCurrency(total)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }
}