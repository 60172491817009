//builder_bulk_import_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "tableBody", "pasteArea", "validCount", "addButton", "columnHeaders"]

  connect() {
    this.validProducts = []
    this.highestRowId = 0
    this.pendingChecks = 0

    if (this.hasTableBodyTarget) {
      this.initializeRows()
    }

    // Listen for order/quote type changes
    document.addEventListener('change', this.handleTypeChange = (e) => {
      if (e.target.name && (e.target.name.includes('[quote_type]') || e.target.name.includes('[order_type]'))) {
        this.updateBuilderFieldsVisibility()
      }
    })
  }

  disconnect() {
    document.removeEventListener('change', this.handleTypeChange)
  }

  initializeRows() {
    this.tableBodyTarget.innerHTML = ''
    this.highestRowId = 0
    this.validProducts = []

    for (let i = 0; i < 3; i++) {
      this.addRow()
    }
  }

  openModal() {
    if (this.hasModalTarget) {
      this.modalTarget.classList.remove('hidden')
      document.body.classList.add('overflow-hidden')
      this.updateValidCount()

      // Update builder fields visibility when opening the modal
      this.updateBuilderFieldsVisibility()
    }
  }

  closeModal() {
    if (this.hasModalTarget) {
      this.modalTarget.classList.add('hidden')
      document.body.classList.remove('overflow-hidden')
      this.initializeRows()

      // Clear the paste area
      if (this.hasPasteAreaTarget) {
        this.pasteAreaTarget.value = ''
      }
    }
  }

  addRow() {
    // Increment highest row ID for new rows
    const rowId = ++this.highestRowId
    const isBuilderType = this.isBuilderType()

    const rowTemplate = `
      <tr data-row-id="${rowId}" class="${rowId % 2 === 0 ? 'bg-gray-50 dark:bg-space-800' : ''}">
        <td class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400">${rowId}</td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="text" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="Enter SKU" 
                data-builder-bulk-importer-row-id="${rowId}"
                data-builder-bulk-importer-field="sku"
                data-action="input->builder-bulk-importer#validateRow" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                value="1" 
                min="1"
                data-builder-bulk-importer-row-id="${rowId}"
                data-builder-bulk-importer-field="quantity"
                data-action="input->builder-bulk-importer#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full ${isBuilderType ? 'bg-white dark:bg-space-900' : 'bg-gray-100 dark:bg-space-800'} border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00" 
                step="0.01"
                data-builder-bulk-importer-row-id="${rowId}"
                data-builder-bulk-importer-field="cost"
                ${!isBuilderType ? 'readonly' : ''}
                data-action="input->builder-bulk-importer#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap builder-field ${!isBuilderType ? 'hidden' : ''}">
          <input type="number" 
                class="w-full ${isBuilderType ? 'bg-white dark:bg-space-900' : 'bg-gray-100 dark:bg-space-800'} border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00"
                step="0.01"
                data-builder-bulk-importer-row-id="${rowId}"
                data-builder-bulk-importer-field="builder_cost"
                ${!isBuilderType ? 'readonly' : ''}
                data-action="input->builder-bulk-importer#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <input type="number" 
                class="w-full bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm"
                placeholder="0.00"
                step="0.01"
                data-builder-bulk-importer-row-id="${rowId}"
                data-builder-bulk-importer-field="price"
                data-action="input->builder-bulk-importer#updateRowCalculations" />
        </td>
        <td class="px-3 py-2 whitespace-nowrap">
          <div class="flex items-center gap-1">
            <input type="number" 
                  class="w-20 bg-white dark:bg-space-900 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm text-right"
                  placeholder="%" 
                  data-builder-bulk-importer-row-id="${rowId}"
                  data-builder-bulk-importer-field="margin_percent"
                  data-action="input->builder-bulk-importer#updatePriceFromMargin" />
            <span class="text-xs text-gray-500 dark:text-space-400">$</span>
            <input type="text" 
                  class="w-20 bg-gray-100 dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded-md p-1 text-sm text-right"
                  placeholder="0.00" 
                  data-builder-bulk-importer-row-id="${rowId}"
                  data-builder-bulk-importer-field="margin_amount"
                  readonly />
          </div>
        </td>
        <td class="px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400" data-builder-bulk-importer-row-id="${rowId}" data-builder-bulk-importer-field="status">
          Waiting for SKU
        </td>
        <td class="px-3 py-2 text-sm text-gray-500 dark:text-space-400" data-builder-bulk-importer-row-id="${rowId}" data-builder-bulk-importer-field="stock">
          
        </td>
        <td class="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
          <button type="button" 
                  class="text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
                  data-action="click->builder-bulk-importer#removeRow"
                  data-builder-bulk-importer-row-id="${rowId}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </td>
      </tr>
    `
    this.tableBodyTarget.insertAdjacentHTML('beforeend', rowTemplate)
    this.updateBuilderFieldsVisibility()
  }

  updateBuilderFieldsVisibility() {
    const isBuilderType = this.isBuilderType()
    console.log(`Updating bulk importer fields for type: ${this.getTypeValue()}, isBuilder: ${isBuilderType}`)

    // Update column headers visibility
    if (this.hasColumnHeadersTarget) {
      const builderHeader = this.columnHeadersTarget.querySelector('.builder-header')
      if (builderHeader) {
        builderHeader.classList.toggle('hidden', !isBuilderType)
      }
    }

    // Show/hide builder fields
    this.element.querySelectorAll('.builder-field').forEach(field => {
      field.classList.toggle('hidden', !isBuilderType)
    })

    // Update cost field editability
    this.element.querySelectorAll('input[data-builder-bulk-importer-field="cost"]').forEach(field => {
      field.readOnly = !isBuilderType
      if (isBuilderType) {
        field.classList.remove('bg-gray-100', 'dark:bg-space-800')
        field.classList.add('bg-white', 'dark:bg-space-900')
      } else {
        field.classList.add('bg-gray-100', 'dark:bg-space-800')
        field.classList.remove('bg-white', 'dark:bg-space-900')
      }
    })

    // Update builder cost field editability
    this.element.querySelectorAll('input[data-builder-bulk-importer-field="builder_cost"]').forEach(field => {
      field.readOnly = !isBuilderType
      if (isBuilderType) {
        field.classList.remove('bg-gray-100', 'dark:bg-space-800')
        field.classList.add('bg-white', 'dark:bg-space-900')
      } else {
        field.classList.add('bg-gray-100', 'dark:bg-space-800')
        field.classList.remove('bg-white', 'dark:bg-space-900')
      }
    })
  }

  isBuilderType() {
    const typeValue = this.getTypeValue()
    return typeValue === 'builder' || typeValue === 'builder_order'
  }

  getTypeValue() {
    // First check if the modal has the data attribute
    if (this.hasModalTarget) {
      if (this.modalTarget.dataset.quoteType) {
        return this.modalTarget.dataset.quoteType
      }
      if (this.modalTarget.dataset.orderType) {
        return this.modalTarget.dataset.orderType
      }
    }

    // Then check quote type select element
    const quoteTypeSelect = document.querySelector('select[name$="[quote_type]"]')
    if (quoteTypeSelect) {
      return quoteTypeSelect.value
    }

    // Then check order type select element
    const orderTypeSelect = document.querySelector('select[name$="[order_type]"]')
    if (orderTypeSelect) {
      return orderTypeSelect.value
    }

    return ''
  }

  removeRow(e) {
    const rowId = e.currentTarget.dataset.builderBulkImporterRowId
    const row = this.tableBodyTarget.querySelector(`tr[data-row-id="${rowId}"]`)

    const index = this.validProducts.findIndex(p => p.rowId == rowId)
    if (index !== -1) {
      this.validProducts.splice(index, 1)
      this.updateValidCount()
    }

    if (row) {
      row.remove()
    }
  }

  async validateRow(e) {
    const rowId = e.currentTarget.dataset.builderBulkImporterRowId
    const skuInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="sku"]`)
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="quantity"]`)

    if (!skuInput) return

    const sku = skuInput.value.trim()
    const quantity = parseInt(quantityInput?.value) || 1

    this.validateRowDirectly(rowId, sku, quantity)
  }

  updateRowCalculations(e) {
    const rowId = e.currentTarget.dataset.builderBulkImporterRowId
    const field = e.currentTarget.dataset.builderBulkImporterField
    const isBuilderType = this.isBuilderType()

    const priceInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="price"]`)
    const costInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="cost"]`)
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="builder_cost"]`)
    const marginPercentInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="margin_percent"]`)
    const marginAmountInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="margin_amount"]`)
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="quantity"]`)

    const price = parseFloat(priceInput?.value) || 0

    // Use the appropriate cost based on type
    let effectiveCost = 0
    if (isBuilderType && builderCostInput) {
      const builderCost = parseFloat(builderCostInput.value) || 0
      if (builderCost > 0) {
        effectiveCost = builderCost
      } else {
        effectiveCost = parseFloat(costInput?.value) || 0
      }
    } else {
      effectiveCost = parseFloat(costInput?.value) || 0
    }

    if (price > 0 && effectiveCost > 0) {
      const marginAmount = price - effectiveCost
      const marginPercent = (marginAmount / price) * 100

      if (marginPercentInput && field !== "margin_percent") marginPercentInput.value = marginPercent.toFixed(2)
      if (marginAmountInput) marginAmountInput.value = marginAmount.toFixed(2)
    } else {
      if (marginPercentInput && field !== "margin_percent") marginPercentInput.value = ''
      if (marginAmountInput) marginAmountInput.value = ''
    }

    // Update product data if this is a valid product
    const index = this.validProducts.findIndex(p => p.rowId == rowId)
    if (index !== -1) {
      if (priceInput) this.validProducts[index].price = parseFloat(priceInput.value) || 0
      if (costInput) this.validProducts[index].cost = parseFloat(costInput.value) || 0
      if (builderCostInput) {
        this.validProducts[index].builder_cost = parseFloat(builderCostInput.value) || 0
      }
      if (quantityInput) this.validProducts[index].quantity = parseInt(quantityInput.value) || 1
    }
  }

  async validateRowDirectly(rowId, sku, quantity) {
    const statusCell = this.tableBodyTarget.querySelector(`td[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="status"]`)
    const stockCell = this.tableBodyTarget.querySelector(`td[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="stock"]`)
    const priceInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="price"]`)
    const costInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="cost"]`)
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="builder_cost"]`)
    const skuInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="sku"]`)

    if (!statusCell) return

    if (!sku) {
      statusCell.textContent = "Waiting for SKU"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-space-400"

      if (stockCell) stockCell.textContent = ""
      if (priceInput) priceInput.value = ""
      if (costInput) costInput.value = ""
      if (builderCostInput) builderCostInput.value = ""

      const index = this.validProducts.findIndex(p => p.rowId == rowId)
      if (index !== -1) {
        this.validProducts.splice(index, 1)
        this.updateValidCount()
      }
      return
    }

    statusCell.textContent = "Validating..."
    statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-blue-500 dark:text-blue-400"

    this.pendingChecks++
    try {
      const response = await fetch(`/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error("Product not found")
      }

      const data = await response.json()
      const product = data.results && data.results[0]

      if (!product) {
        throw new Error("Product not found")
      }

      statusCell.textContent = "✓ Can be added"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-green-600 dark:text-green-400 font-semibold"

      // Update the SKU input with the SKU from the API response
      if (skuInput && product.sku) {
        skuInput.value = product.sku
      }

      // Update stock information with tooltip
      if (stockCell && product.stock) {
        // Calculate total available stock
        const totalStock = product.stock.reduce((sum, item) => sum + (item.available || 0), 0)

        // Create tooltip content with detailed stock info
        let tooltipContent = ''
        product.stock.forEach(stockItem => {
          tooltipContent += `${stockItem.warehouse.name}: ${stockItem.available} available<br>`
        })

        // Set text color based on stock level
        const textColorClass = totalStock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'

        // Generate a unique ID for this tooltip
        const tooltipId = `stock-tooltip-${rowId}`

        // Create the HTML with just the number and tooltip
        stockCell.innerHTML = `
          <div class="flex items-center justify-center">
            <span class="font-medium text-base ${textColorClass} cursor-help" id="${tooltipId}-trigger">
              ${totalStock}
            </span>
            <div id="${tooltipId}" class="hidden absolute z-50 p-2 bg-white dark:bg-gray-800 rounded shadow-lg text-sm text-gray-800 dark:text-white border border-gray-200 dark:border-gray-700" style="width: 200px;">
              ${tooltipContent}
            </div>
          </div>
        `

        // Add script to handle tooltip positioning
        setTimeout(() => {
          const trigger = document.getElementById(`${tooltipId}-trigger`)
          const tooltip = document.getElementById(tooltipId)

          if (trigger && tooltip) {
            // Show tooltip on hover
            trigger.addEventListener('mouseenter', (e) => {
              tooltip.classList.remove('hidden')

              // Position the tooltip
              const rect = trigger.getBoundingClientRect()
              const tableRect = this.tableBodyTarget.closest('table').getBoundingClientRect()

              // Default position (below and to the right)
              let top = rect.bottom + 5
              let left = rect.left

              // Adjust if tooltip would go out of viewport
              const tooltipRect = tooltip.getBoundingClientRect()
              const tooltipWidth = tooltipRect.width || 200
              const tooltipHeight = tooltipRect.height || 100

              if (left + tooltipWidth > window.innerWidth) {
                left = rect.right - tooltipWidth
              }

              if (top + tooltipHeight > window.innerHeight) {
                top = rect.top - tooltipHeight - 5
              }

              if (left < tableRect.left) {
                left = tableRect.left + 5
              }

              // Set the position
              tooltip.style.position = 'fixed'
              tooltip.style.top = `${top}px`
              tooltip.style.left = `${left}px`
            })

            // Hide tooltip when mouse leaves
            trigger.addEventListener('mouseleave', (e) => {
              const rect = tooltip.getBoundingClientRect()
              if (
                e.clientX < rect.left ||
                e.clientX > rect.right ||
                e.clientY < rect.top ||
                e.clientY > rect.bottom
              ) {
                tooltip.classList.add('hidden')
              }
            })

            // Also hide when mouse leaves the tooltip
            tooltip.addEventListener('mouseleave', () => {
              tooltip.classList.add('hidden')
            })
          }
        }, 100)
      }

      // Set price and cost values if not already set
      if (priceInput && !priceInput.value) {
        priceInput.value = parseFloat(product.price).toFixed(2)
      }

      if (costInput && !costInput.value) {
        costInput.value = parseFloat(product.cost).toFixed(2)
      }

      if (builderCostInput && !builderCostInput.value) {
        // Use builder_cost if available, otherwise default to 0
        builderCostInput.value = parseFloat(product.builder_cost || 0).toFixed(2)
      }

      this.updateRowCalculations({
        currentTarget: {
          dataset: { builderBulkImporterRowId: rowId }
        }
      })

      const productData = {
        rowId,
        productId: product.id,
        sku: product.sku,
        title: product.title,
        price: parseFloat(priceInput?.value || product.price),
        cost: parseFloat(costInput?.value || product.cost),
        builder_cost: parseFloat(builderCostInput?.value || product.builder_cost || 0),
        image: product.image,
        quantity: parseInt(quantity) || 1,
        stock: product.stock
      }

      const existingIndex = this.validProducts.findIndex(p => p.rowId == rowId)
      if (existingIndex !== -1) {
        this.validProducts[existingIndex] = productData
      } else {
        this.validProducts.push(productData)
      }

    } catch (error) {
      statusCell.textContent = "✗ Product not found"
      statusCell.className = "px-3 py-2 whitespace-nowrap text-sm text-red-600 dark:text-red-400 font-semibold"

      if (stockCell) stockCell.textContent = ""

      const index = this.validProducts.findIndex(p => p.rowId == rowId)
      if (index !== -1) {
        this.validProducts.splice(index, 1)
      }
    } finally {
      this.pendingChecks--
      this.updateValidCount()
    }
  }

  updateValidCount() {
    if (this.hasValidCountTarget) {
      this.validCountTarget.textContent = this.validProducts.length
    }

    if (this.hasAddButtonTarget) {
      if (this.validProducts.length > 0 && this.pendingChecks === 0) {
        this.addButtonTarget.disabled = false
        this.addButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
      } else {
        this.addButtonTarget.disabled = true
        this.addButtonTarget.classList.add('opacity-50', 'cursor-not-allowed')
      }
    }
  }

  handlePaste(e) {
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')
    this.processPasteData(pastedData)
    e.preventDefault()
  }

  processPasteInput() {
    if (!this.hasPasteAreaTarget) return

    const pastedData = this.pasteAreaTarget.value
    if (!pastedData || pastedData.trim() === '') return

    // Process the pasted data
    this.processPasteData(pastedData)

    // Clear the paste area after processing
    this.pasteAreaTarget.value = ''
  }

  processPasteData(pastedData) {
    if (!pastedData || !this.hasTableBodyTarget) return

    // Parse the pasted data
    const rows = pastedData.split(/\r?\n/).filter(row => row.trim() !== '')

    if (rows.length === 0) return

    // Check if all existing rows are empty (no SKUs)
    const allRowsEmpty = Array.from(
      this.tableBodyTarget.querySelectorAll('input[data-builder-bulk-importer-field="sku"]')
    ).every(input => !input.value.trim())

    // If all rows are empty, clear the table first
    if (allRowsEmpty) {
      this.tableBodyTarget.innerHTML = ''
      this.highestRowId = 0
      this.validProducts = []
    }

    // Map of SKUs to quantities from the pasted data
    const pastedSKUs = {}

    // Process pasted data
    rows.forEach((row) => {
      // Support multiple separator formats: tab, multiple spaces, colon, semicolon
      let columns = row.split(/\t|  +|:|;/)

      if (columns.length >= 1) {
        const sku = columns[0].trim()
        const quantity = columns.length >= 2 ? parseInt(columns[1]) || 1 : 1

        if (sku) {
          // Track the SKUs and quantities
          if (pastedSKUs[sku]) {
            pastedSKUs[sku] += quantity
          } else {
            pastedSKUs[sku] = quantity
          }
        }
      }
    })

    // Check if any of the pasted SKUs already exist in the table
    const existingSkus = {}
    this.tableBodyTarget.querySelectorAll('input[data-builder-bulk-importer-field="sku"]').forEach(input => {
      const sku = input.value.trim()
      if (sku && pastedSKUs[sku]) {
        const rowId = input.dataset.builderBulkImporterRowId
        const quantityInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="quantity"]`)
        if (quantityInput) {
          // Add the pasted quantity to the existing row
          const currentQty = parseInt(quantityInput.value) || 0
          quantityInput.value = currentQty + pastedSKUs[sku]

          // Update calculations
          this.updateRowCalculations({
            currentTarget: {
              dataset: {
                builderBulkImporterRowId: rowId,
                builderBulkImporterField: 'quantity'
              }
            }
          })

          // Mark this SKU as processed
          existingSkus[sku] = true
        }
      }
    })

    // Now add any new SKUs that weren't already in the table
    for (const sku in pastedSKUs) {
      if (!existingSkus[sku]) {
        this.addRowWithData(sku, pastedSKUs[sku])
      }
    }

    // Make sure we have at least 3 rows total
    const currentRows = this.tableBodyTarget.querySelectorAll('tr').length
    if (currentRows < 3) {
      for (let i = currentRows; i < 3; i++) {
        this.addRow()
      }
    }

    // Clear the paste area after processing
    if (this.hasPasteAreaTarget) {
      this.pasteAreaTarget.value = ''
    }
  }

  addRowWithData(sku, quantity) {
    this.addRow()

    const rowId = this.highestRowId
    const skuInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="sku"]`)
    const quantityInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="quantity"]`)

    if (skuInput) skuInput.value = sku
    if (quantityInput) quantityInput.value = quantity

    this.validateRowDirectly(rowId, sku, quantity)
  }

  addToItems() {
    console.log("Adding items to quote/order...");

    if (this.validProducts.length === 0) {
      console.log("No valid products to add");
      return;
    }

    // Find template - look for different possible template selectors
    let template = document.querySelector('[data-nested-form-target="template"]');

    // If not found, try different selectors that might be used in your app
    if (!template) {
      template = document.querySelector('template[data-line-item-template]') ||
        document.querySelector('template#line-item-template') ||
        document.querySelector('template');

      console.log("Using alternative template:", template);
    }

    // Find target container - look for different possible container selectors
    let targetContainer = document.querySelector('[data-nested-form-target="target"]');

    // If not found, try different selectors
    if (!targetContainer) {
      targetContainer = document.querySelector('[data-quote-builder-target="lineItems"]') ||
        document.querySelector('[data-line-items-container]') ||
        document.querySelector('.line-items-container');

      console.log("Using alternative target container:", targetContainer);
    }

    // If still not found, log a clear error message
    if (!template) {
      console.error("ERROR: Line item template not found. Please add a template element with data-nested-form-target='template' attribute.");
      this.showMessage("Error: Template not found. Contact administrator.");
      return;
    }

    if (!targetContainer) {
      console.error("ERROR: Target container not found. Please add a container with data-nested-form-target='target' attribute.");
      this.showMessage("Error: Target container not found. Contact administrator.");
      return;
    }

    // Process each valid product
    console.log(`Adding ${this.validProducts.length} products...`);

    let addedCount = 0;
    this.validProducts.forEach(productData => {
      try {
        this.addProductToItems(productData, template, targetContainer);
        addedCount++;
      } catch (error) {
        console.error(`Error adding product ${productData.sku}:`, error);
      }
    });

    // Close the modal
    this.closeModal();

    // Show success message
    if (addedCount > 0) {
      this.showMessage(`Successfully added ${addedCount} product${addedCount !== 1 ? 's' : ''}`);
    } else {
      this.showMessage("No products could be added. Check console for errors.");
    }
  }

  addProductToItems(productData, template, targetContainer) {
    console.log(`Adding product ${productData.sku} to items...`);

    try {
      // Clone the template content
      const content = template.content.cloneNode(true);

      // Find the wrapper element
      const wrapper = content.querySelector('.line-item-wrapper') ||
        content.querySelector('.order-item-wrapper') ||
        content.querySelector('.item-wrapper') ||
        content.firstElementChild;

      if (!wrapper) {
        console.error("No wrapper element found in template");
        return false;
      }

      // Generate a unique index for this item
      const newIndex = new Date().getTime() + Math.floor(Math.random() * 1000);

      // Update all input names
      wrapper.querySelectorAll('input, select, textarea').forEach(input => {
        if (input.name) {
          input.name = input.name.replace('NEW_RECORD', newIndex);
        }
      });

      // Log the structure for debugging
      console.log("Template structure:", wrapper.innerHTML);

      // Get all possible field selectors
      const fieldSelectors = {
        productId: [
          'input[name$="[product_id]"]',
          'input[name$="[product_variant_id]"]'
        ],
        sku: [
          'input[name$="[sku]"]'
        ],
        name: [
          'input[name$="[name]"]',
          'input[name$="[title]"]'
        ],
        cost: [
          'input[name$="[cost]"]'
        ],
        builderCost: [
          'input[name$="[builder_cost]"]'
        ],
        price: [
          'input[name$="[price]"]'
        ],
        quantity: [
          'input[name$="[quantity]"]'
        ]
      };

      // Helper function to find an element using multiple selectors
      const findElement = (selectors) => {
        for (const selector of selectors) {
          const element = wrapper.querySelector(selector);
          if (element) return element;
        }
        return null;
      };

      // Set field values
      const fields = {
        productId: findElement(fieldSelectors.productId),
        sku: findElement(fieldSelectors.sku),
        name: findElement(fieldSelectors.name),
        cost: findElement(fieldSelectors.cost),
        builderCost: findElement(fieldSelectors.builderCost),
        price: findElement(fieldSelectors.price),
        quantity: findElement(fieldSelectors.quantity)
      };

      // Display element selectors
      const displaySelectors = {
        costDisplay: [
          '[data-line-item-target="costDisplay"]',
          '[data-order-item-target="costDisplay"]'
        ],
        builderCostDisplay: [
          '[data-line-item-target="builderCostDisplay"]',
          '[data-order-item-target="builderCostDisplay"]'
        ],
        title: [
          '.product-title'
        ],
        sku: [
          '.product-sku'
        ],
        image: [
          '.product-image',
          '.w-24'
        ],
        stockInfo: [
          '.stock-info-container',
          '.warehouse-availability'
        ]
      };

      const displays = {
        costDisplay: findElement(displaySelectors.costDisplay),
        builderCostDisplay: findElement(displaySelectors.builderCostDisplay),
        title: findElement(displaySelectors.title),
        sku: findElement(displaySelectors.sku),
        image: findElement(displaySelectors.image),
        stockInfo: findElement(displaySelectors.stockInfo)
      };

      // Set all fields that exist
      if (fields.productId) fields.productId.value = productData.productId;
      if (fields.name) fields.name.value = productData.title;
      if (fields.sku) fields.sku.value = productData.sku;
      if (fields.cost) fields.cost.value = productData.cost;
      if (fields.builderCost) fields.builderCost.value = productData.builder_cost || 0;
      if (fields.price) fields.price.value = productData.price;
      if (fields.quantity) fields.quantity.value = productData.quantity;

      // Set display elements
      if (displays.costDisplay) displays.costDisplay.value = productData.cost;
      if (displays.builderCostDisplay) displays.builderCostDisplay.value = productData.builder_cost || 0;
      if (displays.title) displays.title.textContent = productData.title;
      if (displays.sku) displays.sku.textContent = `SKU: ${productData.sku}`;

      // Set image if available
      if (displays.image && productData.image) {
        displays.image.innerHTML = `
          <img src="${productData.image}" class="w-full h-full object-cover rounded-lg" alt="${productData.title}" />
        `;
      }

      // Add stock information if available
      if (displays.stockInfo && productData.stock) {
        let stockHtml = '';
        productData.stock.forEach(stockItem => {
          const available = stockItem.available || 0;
          const cssClass = available > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400';
          stockHtml += `<span class="${cssClass} mr-2">${stockItem.warehouse.name.split(' ')[0]}: ${available}</span>`;
        });
        displays.stockInfo.innerHTML = stockHtml;
      }

      // Append the new item to the container
      targetContainer.appendChild(wrapper);

      // Remove empty state message if it exists
      document.querySelector('[data-nested-form-target="empty"]')?.remove();

      // Trigger calculation events
      const eventName = wrapper.classList.contains('order-item-wrapper') ? 'order-item:update' : 'line-item:update';

      wrapper.dispatchEvent(new CustomEvent(eventName, {
        bubbles: true,
        detail: {
          total: productData.price * productData.quantity,
          cost: (this.isBuilderType() && productData.builder_cost > 0 ? productData.builder_cost : productData.cost) * productData.quantity,
          quantity: productData.quantity
        }
      }));

      // Calculate margin
      if (fields.price) {
        const event = new Event('input');
        fields.price.dispatchEvent(event);
      }

      console.log(`Product ${productData.sku} added successfully`);
      return true;
    } catch (error) {
      console.error("Error in addProductToItems:", error);
      return false;
    }
  }

  updatePriceFromMargin(e) {
    const rowId = e.currentTarget.dataset.builderBulkImporterRowId
    const isBuilderType = this.isBuilderType()

    const priceInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="price"]`)
    const costInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="cost"]`)
    const builderCostInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="builder_cost"]`)
    const marginPercentInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="margin_percent"]`)
    const marginAmountInput = this.tableBodyTarget.querySelector(`input[data-builder-bulk-importer-row-id="${rowId}"][data-builder-bulk-importer-field="margin_amount"]`)

    // Get the desired margin percentage
    const marginPercent = parseFloat(marginPercentInput?.value) || 0

    // Use the appropriate cost based on type
    let effectiveCost = 0
    if (isBuilderType && builderCostInput) {
      const builderCost = parseFloat(builderCostInput.value) || 0
      if (builderCost > 0) {
        effectiveCost = builderCost
      } else {
        effectiveCost = parseFloat(costInput?.value) || 0
      }
    } else {
      effectiveCost = parseFloat(costInput?.value) || 0
    }

    // Skip if no effective cost
    if (effectiveCost <= 0) return

    // Calculate new price based on desired margin percentage
    // Formula: price = cost / (1 - margin%)
    const newPrice = effectiveCost / (1 - (marginPercent / 100))

    // Update price input
    if (priceInput) {
      priceInput.value = newPrice.toFixed(2)
    }

    // Update margin amount
    const marginAmount = newPrice - effectiveCost
    if (marginAmountInput) {
      marginAmountInput.value = marginAmount.toFixed(2)
    }

    // Update product data if this is a valid product
    const index = this.validProducts.findIndex(p => p.rowId == rowId)
    if (index !== -1) {
      if (priceInput) this.validProducts[index].price = parseFloat(priceInput.value) || 0
    }
  }

  showMessage(message) {
    const toast = document.createElement('div')
    toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }
}