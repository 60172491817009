import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "bulkActionsBtn",
    "bulkActionsDropdown",
    "selectAll",
    "orderCheckbox",
    "exportForm",
    "selectedOrderIds"
  ]

  connect() {
    this.updateBulkActionsState()
    // Add document click listener when controller connects
    document.addEventListener('click', this.outsideClick)
  }

  disconnect() {
    // Clean up event listener when controller disconnects
    document.removeEventListener('click', this.outsideClick)
  }

  // Use an arrow function to maintain 'this' context
  outsideClick = (event) => {
    // Only process if dropdown is visible and click is outside
    if (!this.bulkActionsDropdownTarget.classList.contains('hidden') &&
      !this.bulkActionsDropdownTarget.contains(event.target) &&
      !this.bulkActionsBtnTarget.contains(event.target)) {
      this.bulkActionsDropdownTarget.classList.add('hidden')
    }
  }

  toggleDropdown(event) {
    event.preventDefault()
    event.stopPropagation() // Prevent immediate closing by document click
    this.bulkActionsDropdownTarget.classList.toggle('hidden')
  }

  // Rest of your methods remain the same
  toggleSelectAll() {
    const isChecked = this.selectAllTarget.checked
    this.orderCheckboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
    })
    this.updateBulkActionsState()
  }

  checkboxChanged() {
    this.updateBulkActionsState()
    // Update "select all" checkbox state
    const allSelected = this.orderCheckboxTargets.every(cb => cb.checked)
    this.selectAllTarget.checked = allSelected && this.orderCheckboxTargets.length > 0
  }

  exportSelected(event) {
    event.preventDefault()
    const selectedOrderIds = this.orderCheckboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)

    if (selectedOrderIds.length === 0) {
      alert('Please select at least one order to export.')
      return
    }

    this.selectedOrderIdsTarget.value = selectedOrderIds.join(',')
    this.exportFormTarget.submit()
  }

  updateBulkActionsState() {
    const hasSelection = this.orderCheckboxTargets.some(checkbox => checkbox.checked)
    this.bulkActionsBtnTarget.disabled = !hasSelection
  }
}