import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal", "productName", "tabContent", "addonTypeFilter",
    "availableAddons", "currentAddons", "noCurrentAddons",
    "customForm", "customName", "customSku", "customPrice",
    "customCost", "customQuantity", "customDescription"
  ]

  connect() {
    console.log("Line item addons controller connected")
    this.currentTab = "available"
    this.lineItemId = null
    this.lineItemElement = null
    this.productData = null
    this.currentAddons = []
    this.availableAddons = []
  }

  openModal(event) {
    event.preventDefault()

    this.lineItemId = event.currentTarget.dataset.lineItemId
    this.lineItemElement = document.querySelector(`.line-item-wrapper[data-line-item-id="${this.lineItemId}"]`)

    if (!this.lineItemElement) {
      console.error('Line item element not found')
      return
    }

    const productSku = event.currentTarget.dataset.productSku
    const productName = event.currentTarget.dataset.productName
    const isCustomItem = event.currentTarget.dataset.customItem === 'true'

    if (this.hasProductNameTarget) {
      this.productNameTarget.textContent = productName || "this product"
    }

    // Load the current addons first
    this.loadCurrentAddons()

    // For custom items with no SKU or with a CUSTOM- prefix, only show custom addons tab
    if (isCustomItem || !productSku || productSku.startsWith('CUSTOM-')) {
      // Hide available addons tab button and content
      const availableTab = this.element.querySelector('nav a[data-tab="available"]')
      if (availableTab) {
        availableTab.classList.add('hidden')
      }
      const availableContent = this.element.querySelector('[data-tab="available"]')
      if (availableContent) {
        availableContent.classList.add('hidden')
      }

      // Switch to custom tab by default
      this.switchToCustomTab()
    } else {
      // Show available addons tab for regular products
      const availableTab = this.element.querySelector('nav a[data-tab="available"]')
      if (availableTab) {
        availableTab.classList.remove('hidden')
      }

      // Then fetch available addons
      this.fetchAddons(productSku)
    }

    if (this.hasModalTarget) {
      this.modalTarget.classList.remove('hidden')
      document.body.classList.add('overflow-hidden')
    }
  }

  closeModal(event) {
    if (event) event.preventDefault()

    if (this.hasModalTarget) {
      this.modalTarget.classList.add('hidden')
      document.body.classList.remove('overflow-hidden')
    }

    this.lineItemId = null
    this.lineItemElement = null
    this.productData = null
    this.currentAddons = []
    this.availableAddons = []
    this.clearCustomForm()
  }

  switchTab(event) {
    event.preventDefault()

    const selectedTab = event.currentTarget.dataset.tab
    if (!selectedTab) return

    if (selectedTab === this.currentTab) return

    // Update navigation tabs
    this.element.querySelectorAll('nav a').forEach(tab => {
      if (tab.dataset.tab === selectedTab) {
        tab.classList.remove('border-transparent', 'text-gray-500', 'dark:text-space-400', 'hover:text-gray-700', 'dark:hover:text-space-200', 'hover:border-gray-300', 'dark:hover:border-space-600')
        tab.classList.add('border-blue-500', 'dark:border-blue-400', 'text-blue-600', 'dark:text-blue-400')
      } else {
        tab.classList.remove('border-blue-500', 'dark:border-blue-400', 'text-blue-600', 'dark:text-blue-400')
        tab.classList.add('border-transparent', 'text-gray-500', 'dark:text-space-400', 'hover:text-gray-700', 'dark:hover:text-space-200', 'hover:border-gray-300', 'dark:hover:border-space-600')
      }
    })

    // Update content tabs
    if (this.hasTabContentTargets) {
      this.tabContentTargets.forEach(content => {
        if (content.dataset.tab === selectedTab) {
          content.classList.remove('hidden')
        } else {
          content.classList.add('hidden')
        }
      })
    } else {
      // If we don't have tab content targets, try to find them manually
      const tabContents = this.element.querySelectorAll('[data-tab]');
      tabContents.forEach(content => {
        if (content.dataset.tab === selectedTab) {
          content.classList.remove('hidden')
        } else if (content.tagName !== 'A') { // Don't hide the nav links
          content.classList.add('hidden')
        }
      });
    }

    this.currentTab = selectedTab
  }

  switchToCustomTab() {
    const customTabLink = this.element.querySelector('nav a[data-tab="custom"]')
    if (customTabLink) {
      customTabLink.click()
    }
  }

  async fetchAddons(productSku) {
    if (!productSku) {
      if (this.hasAvailableAddonsTarget) {
        this.availableAddonsTarget.innerHTML = this.getEmptyAddonsMessage("No product SKU provided")
      }
      return
    }

    try {
      let url = `/api/v1/products/sku/${encodeURIComponent(productSku)}`
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error("Failed to fetch product details")
      }

      const data = await response.json()
      this.productData = data.results && data.results[0]

      if (!this.productData || !this.productData.additionalItems) {
        if (this.hasAvailableAddonsTarget) {
          this.availableAddonsTarget.innerHTML = this.getEmptyAddonsMessage()
        }
        return
      }

      this.availableAddons = this.productData.additionalItems
      this.renderAvailableAddons()

    } catch (error) {
      console.error('Error fetching addons:', error)
      if (this.hasAvailableAddonsTarget) {
        this.availableAddonsTarget.innerHTML = this.getEmptyAddonsMessage("Error loading add-ons")
      }
    }
  }

  getLineItemIndex(lineItem) {
    if (!lineItem) return null;

    const inputField = lineItem.querySelector('input[name*="[line_items_attributes]"]')
    if (!inputField) return null

    const match = inputField.name.match(/\[line_items_attributes\]\[([0-9]+)\]/)
    return match ? match[1] : null
  }

  loadCurrentAddons() {
    if (!this.lineItemElement) {
      this.currentAddons = []
      this.safeRenderCurrentAddons()
      return
    }

    const lineItemIndex = this.getLineItemIndex(this.lineItemElement)
    if (!lineItemIndex) {
      console.error('Could not determine line item index')
      this.currentAddons = []
      this.safeRenderCurrentAddons()
      return
    }

    // Try to find any existing addon fields
    const addonFieldsContainer = this.lineItemElement.querySelector('.line-item-addon-fields')
    if (!addonFieldsContainer) {
      // If no addon fields container exists, check if line_item has addons rendered in the display
      const addonsDisplay = this.lineItemElement.querySelector('.line-item-addons-display')
      if (addonsDisplay) {
        // Parse the existing addons from the display (for items loaded from server)
        this.currentAddons = this.parseAddonsFromDisplay(addonsDisplay)
      } else {
        this.currentAddons = []
      }
      this.safeRenderCurrentAddons()
      return
    }

    // Get all addon inputs
    const addonInputs = addonFieldsContainer.querySelectorAll('input')
    const addonGroups = {}

    addonInputs.forEach(input => {
      const match = input.name.match(/\[line_item_addons_attributes\]\[([0-9]+)\]\[([^\]]+)\]/)
      if (match) {
        const addonIndex = match[1]
        const fieldName = match[2]

        if (!addonGroups[addonIndex]) {
          addonGroups[addonIndex] = {}
        }

        addonGroups[addonIndex][fieldName] = input.value
      }
    })

    this.currentAddons = []
    Object.entries(addonGroups).forEach(([index, addon]) => {
      if (addon.name && addon.sku && addon._destroy !== "1") {
        this.currentAddons.push({
          ...addon,
          id: addon.id || index,
          price: parseFloat(addon.price) || 0,
          cost: parseFloat(addon.cost) || 0,
          quantity: parseInt(addon.quantity) || 1,
          discount_amount: parseFloat(addon.discount_amount) || 0,
          discount_type: addon.discount_type || 'fixed'
        })
      }
    })

    this.safeRenderCurrentAddons()
  }

  // Safe wrapper around renderCurrentAddons to catch and handle errors
  safeRenderCurrentAddons() {
    try {
      this.renderCurrentAddons()
    } catch (error) {
      console.error('Error rendering current addons:', error)
      // Provide fallback behavior
      if (this.hasCurrentAddonsTarget) {
        this.currentAddonsTarget.innerHTML = `
          <div class="text-center py-4 text-gray-500">
            <p>Error displaying addons. Please try again.</p>
          </div>
        `
      }
    }
  }

  // Helper method to parse addons from the display
  parseAddonsFromDisplay(addonsDisplay) {
    if (!addonsDisplay) return [];

    const addonRows = addonsDisplay.querySelectorAll('.flex.items-center.justify-between.text-xs.py-1')
    const addons = []

    addonRows.forEach((row, index) => {
      const nameElement = row.querySelector('.font-medium')
      const quantityElement = row.querySelector('.text-gray-500.dark:text-space-400')
      const priceElement = row.querySelector('.font-medium:last-child')

      if (nameElement && quantityElement && priceElement) {
        const name = nameElement.textContent.trim()
        const quantityMatch = quantityElement.textContent.match(/x(\d+)/)
        const quantity = quantityMatch ? parseInt(quantityMatch[1]) : 1

        // Parse price from the display (removing currency symbol)
        const priceText = priceElement.textContent.trim()
        const price = parseFloat(priceText.replace(/[^0-9.-]+/g, '')) || 0

        // Create a unique SKU for existing addons
        const sku = `ADDON-${Date.now()}-${index}`

        addons.push({
          id: `existing_${index}`,
          name: name,
          sku: sku,
          quantity: quantity,
          price: price / quantity, // Calculate unit price
          cost: 0, // Default cost
          discount_amount: 0,
          discount_type: 'fixed',
          addon_type: 'existing'
        })
      }
    })

    return addons
  }

  renderCurrentAddons() {
    if (!this.hasCurrentAddonsTarget) {
      console.warn('Current addons target is missing')
      return
    }

    this.currentAddonsTarget.innerHTML = ''

    if (!this.currentAddons || this.currentAddons.length === 0) {
      // Handle the case when there are no addons
      if (this.hasNoCurrentAddonsTarget) {
        // If the target exists, unhide it
        this.noCurrentAddonsTarget.classList.remove('hidden')
      } else {
        // If the target doesn't exist, inject the empty state HTML directly
        this.currentAddonsTarget.innerHTML = `
          <div class="text-center py-8 text-gray-500 dark:text-space-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
            </svg>
            <p class="text-lg font-medium">No add-ons have been added yet</p>
            <p class="mt-2">Add some from the "Available Add-ons" tab</p>
          </div>
        `
      }
      return
    }

    // If there are addons, hide the empty state message if it exists
    if (this.hasNoCurrentAddonsTarget) {
      this.noCurrentAddonsTarget.classList.add('hidden')
    }

    // Create a custom template if the standard one doesn't exist
    const template = document.getElementById('current-addon-item-template')
    if (!template) {
      console.warn('Current addon item template not found, using fallback')
      this.renderCurrentAddonsWithoutTemplate()
      return
    }

    this.currentAddons.forEach(addon => {
      if (addon._destroy) return; // Skip destroyed addons

      const addonElement = template.content.cloneNode(true)
      const addonItem = addonElement.querySelector('.current-addon-item')
      if (!addonItem) return;

      addonItem.dataset.addonId = addon.id

      const titleElement = addonElement.querySelector('.current-addon-title')
      if (titleElement) titleElement.textContent = addon.name

      const skuElement = addonElement.querySelector('.current-addon-sku')
      if (skuElement) skuElement.textContent = `SKU: ${addon.sku}`

      // Make price editable
      const priceElement = addonElement.querySelector('.current-addon-price')
      if (priceElement) {
        const formattedPrice = this.formatCurrency(addon.price)

        // Replace with an input field
        priceElement.innerHTML = `
          <span class="text-xs text-gray-500 dark:text-space-400 font-normal">Price: </span>
          <input type="number" 
                 min="0" 
                 step="0.01" 
                 class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
                 value="${addon.price}"
                 data-original-value="${addon.price}"
                 data-addon-price-input
          />
        `

        const priceInput = priceElement.querySelector('input')
        if (priceInput) {
          priceInput.addEventListener('change', (e) => {
            const newPrice = parseFloat(e.target.value) || 0
            addon.price = newPrice
            this.updateAddonDisplay(this.lineItemElement)
            this.updateLineItemTotals()
          })
        }
      }

      // Make cost editable
      const costElement = addonElement.querySelector('.current-addon-cost')
      if (costElement) {
        costElement.innerHTML = `
          <span>Cost: </span>
          <input type="number" 
                 min="0" 
                 step="0.01" 
                 class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
                 value="${addon.cost}"
                 data-original-value="${addon.cost}"
                 data-addon-cost-input
          />
        `

        const costInput = costElement.querySelector('input')
        if (costInput) {
          costInput.addEventListener('change', (e) => {
            const newCost = parseFloat(e.target.value) || 0
            addon.cost = newCost
          })
        }
      }

      const quantityInput = addonElement.querySelector('.current-addon-quantity')
      if (quantityInput) {
        quantityInput.value = addon.quantity
        quantityInput.addEventListener('change', () => {
          addon.quantity = parseInt(quantityInput.value, 10) || 1
          this.updateAddonDisplay(this.lineItemElement)
          this.updateLineItemTotals()
        })
      }

      const typeLabel = addonElement.querySelector('.current-addon-type')
      if (typeLabel) {
        typeLabel.textContent = this.formatAddonType(addon.addon_type || 'accessory')
        this.styleAddonTypeLabel(typeLabel, addon.addon_type)
      }

      const removeButton = addonElement.querySelector('.current-addon-remove-button')
      if (removeButton) {
        removeButton.addEventListener('click', () => {
          this.removeAddonFromLineItem(addon.id)
        })
      }

      this.currentAddonsTarget.appendChild(addonElement)
    })
  }

  renderCurrentAddonsWithoutTemplate() {
    const activeAddons = this.currentAddons.filter(addon => !addon._destroy)

    if (activeAddons.length === 0) {
      this.currentAddonsTarget.innerHTML = `
        <div class="text-center py-8 text-gray-500 dark:text-space-400">
          <p>No add-ons have been added yet</p>
        </div>
      `
      return
    }

    // Create addon items directly
    activeAddons.forEach(addon => {
      const addonItem = document.createElement('div')
      addonItem.className = 'border border-gray-200 dark:border-space-700 rounded-lg p-4 bg-white dark:bg-space-900 mb-4'
      addonItem.dataset.addonId = addon.id

      addonItem.innerHTML = `
        <div class="flex items-start">
          <div class="flex-1">
            <div class="flex items-center gap-2">
              <h4 class="font-medium text-gray-900 dark:text-white">${addon.name}</h4>
              <span class="px-2 py-0.5 text-xs rounded-full bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-300">${this.formatAddonType(addon.addon_type || 'accessory')}</span>
            </div>
            <div class="text-xs text-gray-500 dark:text-space-400 mt-1">SKU: ${addon.sku}</div>
          </div>
          <div class="ml-4 flex-shrink-0 flex items-end">
            <div class="font-bold text-gray-900 dark:text-white">
              <span class="text-xs text-gray-500 dark:text-space-400 font-normal">Price: </span>
              <input type="number" 
                     min="0" 
                     step="0.01" 
                     class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
                     value="${addon.price}"
                     data-original-value="${addon.price}"
                     data-addon-price-input
              />
            </div>
            <div class="text-xs text-gray-500 dark:text-space-400">
              <span>Cost: </span>
              <input type="number" 
                     min="0" 
                     step="0.01" 
                     class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
                     value="${addon.cost}"
                     data-original-value="${addon.cost}"
                     data-addon-cost-input
              />
            </div>
          </div>
        </div>
        <div class="mt-4 flex items-center justify-between">
          <div class="flex items-center">
            <label class="text-sm text-gray-700 dark:text-space-300 mr-2">Qty:</label>
            <input type="number" min="1" value="${addon.quantity}" class="w-16 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded-md p-1.5 text-sm current-addon-quantity" />
          </div>
          <button type="button" class="ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 current-addon-remove-button">
            Remove
          </button>
        </div>
      `

      // Add event listeners
      const priceInput = addonItem.querySelector('[data-addon-price-input]')
      if (priceInput) {
        priceInput.addEventListener('change', (e) => {
          const newPrice = parseFloat(e.target.value) || 0
          addon.price = newPrice
          this.updateAddonDisplay(this.lineItemElement)
          this.updateLineItemTotals()
        })
      }

      const costInput = addonItem.querySelector('[data-addon-cost-input]')
      if (costInput) {
        costInput.addEventListener('change', (e) => {
          const newCost = parseFloat(e.target.value) || 0
          addon.cost = newCost
        })
      }

      const quantityInput = addonItem.querySelector('.current-addon-quantity')
      if (quantityInput) {
        quantityInput.addEventListener('change', (e) => {
          addon.quantity = parseInt(e.target.value, 10) || 1
          this.updateAddonDisplay(this.lineItemElement)
          this.updateLineItemTotals()
        })
      }

      const removeButton = addonItem.querySelector('.current-addon-remove-button')
      if (removeButton) {
        removeButton.addEventListener('click', () => {
          this.removeAddonFromLineItem(addon.id)
        })
      }

      this.currentAddonsTarget.appendChild(addonItem)
    })
  }

  renderAvailableAddons() {
    if (!this.hasAvailableAddonsTarget) {
      console.warn('Available addons target is missing')
      return
    }

    if (!this.availableAddons || this.availableAddons.length === 0) {
      this.availableAddonsTarget.innerHTML = this.getEmptyAddonsMessage()
      return
    }

    const filterType = this.hasAddonTypeFilterTarget ? this.addonTypeFilterTarget.value : ""

    const filteredAddons = filterType
      ? this.availableAddons.filter(addon => addon.addon_type === filterType)
      : this.availableAddons

    if (filteredAddons.length === 0) {
      this.availableAddonsTarget.innerHTML = this.getEmptyAddonsMessage(`No ${filterType} add-ons available`)
      return
    }

    this.availableAddonsTarget.innerHTML = ''

    const template = document.getElementById('addon-item-template')
    if (!template) {
      console.error('Addon item template not found')
      return
    }

    filteredAddons.forEach(addon => {
      const addonElement = template.content.cloneNode(true)
      const addonItem = addonElement.querySelector('.addon-item')
      if (!addonItem) return;

      const isAlreadyAdded = this.currentAddons.some(currentAddon =>
        currentAddon.sku === addon.sku && !currentAddon._destroy
      )

      if (isAlreadyAdded) {
        return
      }

      addonItem.dataset.addonId = addon.id
      addonItem.dataset.addonType = addon.addon_type || 'accessory'

      const titleElement = addonElement.querySelector('.addon-title')
      if (titleElement) titleElement.textContent = addon.title

      const skuElement = addonElement.querySelector('.addon-sku')
      if (skuElement) skuElement.textContent = `SKU: ${addon.sku}`

      const descElement = addonElement.querySelector('.addon-description')
      if (descElement) descElement.textContent = addon.description || 'No description available'

      const priceElement = addonElement.querySelector('.addon-price')
      if (priceElement) priceElement.textContent = this.formatCurrency(addon.price)

      const costElement = addonElement.querySelector('.addon-cost')
      if (costElement) costElement.textContent = `Cost: ${this.formatCurrency(addon.cost)}`

      const typeLabel = addonElement.querySelector('.addon-type')
      if (typeLabel) {
        typeLabel.textContent = this.formatAddonType(addon.addon_type)
        this.styleAddonTypeLabel(typeLabel, addon.addon_type)
      }

      const addButton = addonElement.querySelector('.addon-add-button')
      const quantityInput = addonElement.querySelector('.addon-quantity')

      if (addButton && quantityInput) {
        addButton.addEventListener('click', () => {
          this.addAddonToLineItem(addon, parseInt(quantityInput.value, 10) || 1)
        })
      }

      this.availableAddonsTarget.appendChild(addonElement)
    })
  }

  addAddonToLineItem(addon, quantity = 1) {
    const newAddon = {
      id: 'new_' + Date.now(),
      name: addon.title,
      sku: addon.sku,
      price: addon.price,
      cost: addon.cost,
      quantity: quantity,
      addon_type: addon.addon_type || 'accessory',
      product_id: addon.id,
      discount_amount: 0,
      discount_type: 'fixed'
    }

    this.currentAddons.push(newAddon)

    // Update the line item directly
    this.createAddonFields(this.getLineItemIndex(this.lineItemElement))
    this.updateLineItemTotals()

    // Re-render the UI
    this.renderCurrentAddons()
    this.renderAvailableAddons()

    // Switch to current addons tab
    this.switchToCurrentTab()

    this.showToast(`${addon.title} added to line item`)
  }

  addCustomAddon(event) {
    event.preventDefault()

    if (!this.validateCustomForm()) {
      return
    }

    // Generate a unique ID and timestamp for the custom addon
    const timestamp = Date.now()
    const customAddon = {
      id: `custom_${timestamp}`,
      name: this.customNameTarget.value,
      sku: this.customSkuTarget.value,
      price: parseFloat(this.customPriceTarget.value) || 0,
      cost: parseFloat(this.customCostTarget.value) || 0,
      quantity: parseInt(this.customQuantityTarget.value, 10) || 1,
      addon_type: 'custom',
      description: this.customDescriptionTarget.value,
      discount_amount: 0,
      discount_type: 'fixed',
      is_custom: true,
      created_at: timestamp
    }

    this.currentAddons.push(customAddon)

    // Update the line item directly
    this.createAddonFields(this.getLineItemIndex(this.lineItemElement))
    this.updateLineItemTotals()

    // Re-render the UI
    this.renderCurrentAddons()

    this.clearCustomForm()

    this.switchToCurrentTab()

    this.showToast(`Custom add-on "${customAddon.name}" added to line item`)
  }

  removeAddonFromLineItem(addonId) {
    const addonIndex = this.currentAddons.findIndex(addon => addon.id.toString() === addonId.toString())

    if (addonIndex === -1) return

    const removedAddon = this.currentAddons[addonIndex]

    // If the addon has a numeric ID (stored in database), mark it for destruction instead of removing
    if (removedAddon.id && !isNaN(parseInt(removedAddon.id))) {
      removedAddon._destroy = '1'
    } else {
      this.currentAddons.splice(addonIndex, 1)
    }

    // Update the line item directly
    this.createAddonFields(this.getLineItemIndex(this.lineItemElement))
    this.updateLineItemTotals()

    // Re-render the UI
    this.renderCurrentAddons()
    this.renderAvailableAddons()

    this.showToast(`${removedAddon.name} removed from line item`)
  }

  // renderCurrentAddonsWithoutTemplate() {
  //   const activeAddons = this.currentAddons.filter(addon => !addon._destroy)

  //   if (activeAddons.length === 0) {
  //     this.currentAddonsTarget.innerHTML = `
  //       <div class="text-center py-8 text-gray-500 dark:text-space-400">
  //         <p>No add-ons have been added yet</p>
  //       </div>
  //     `
  //     return
  //   }

  //   // Create addon items directly
  //   activeAddons.forEach(addon => {
  //     const addonItem = document.createElement('div')
  //     addonItem.className = 'border border-gray-200 dark:border-space-700 rounded-lg p-4 bg-white dark:bg-space-900 mb-4'
  //     addonItem.dataset.addonId = addon.id

  //     addonItem.innerHTML = `
  //       <div class="flex items-start">
  //         <div class="flex-1">
  //           <div class="flex items-center gap-2">
  //             <h4 class="font-medium text-gray-900 dark:text-white">${addon.name}</h4>
  //             <span class="px-2 py-0.5 text-xs rounded-full bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-300">${this.formatAddonType(addon.addon_type || 'accessory')}</span>
  //           </div>
  //           <div class="text-xs text-gray-500 dark:text-space-400 mt-1">SKU: ${addon.sku}</div>
  //         </div>
  //         <div class="ml-4 flex-shrink-0 flex flex-col items-end">
  //           <div class="font-bold text-gray-900 dark:text-white">
  //             <input type="number" 
  //                    min="0" 
  //                    step="0.01" 
  //                    class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
  //                    value="${addon.price}"
  //                    data-original-value="${addon.price}"
  //                    data-addon-price-input
  //             />
  //           </div>
  //           <div class="text-xs text-gray-500 dark:text-space-400">
  //             <span>Cost: </span>
  //             <input type="number" 
  //                    min="0" 
  //                    step="0.01" 
  //                    class="w-20 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded p-1 text-sm" 
  //                    value="${addon.cost}"
  //                    data-original-value="${addon.cost}"
  //                    data-addon-cost-input
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div class="mt-4 flex items-center justify-between">
  //         <div class="flex items-center">
  //           <label class="text-sm text-gray-700 dark:text-space-300 mr-2">Qty:</label>
  //           <input type="number" min="1" value="${addon.quantity}" class="w-16 bg-white dark:bg-space-800 border border-gray-300 dark:border-space-700 rounded-md p-1.5 text-sm current-addon-quantity" />
  //         </div>
  //         <button type="button" class="ml-4 inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 current-addon-remove-button">
  //           Remove
  //         </button>
  //       </div>
  //     `

  //     // Add event listeners
  //     const priceInput = addonItem.querySelector('[data-addon-price-input]')
  //     if (priceInput) {
  //       priceInput.addEventListener('change', (e) => {
  //         const newPrice = parseFloat(e.target.value) || 0
  //         addon.price = newPrice
  //         this.updateAddonDisplay(this.lineItemElement)
  //         this.updateLineItemTotals()
  //       })
  //     }

  //     const costInput = addonItem.querySelector('[data-addon-cost-input]')
  //     if (costInput) {
  //       costInput.addEventListener('change', (e) => {
  //         const newCost = parseFloat(e.target.value) || 0
  //         addon.cost = newCost
  //       })
  //     }

  //     const quantityInput = addonItem.querySelector('.current-addon-quantity')
  //     if (quantityInput) {
  //       quantityInput.addEventListener('change', (e) => {
  //         addon.quantity = parseInt(e.target.value, 10) || 1
  //         this.updateAddonDisplay(this.lineItemElement)
  //         this.updateLineItemTotals()
  //       })
  //     }

  //     const removeButton = addonItem.querySelector('.current-addon-remove-button')
  //     if (removeButton) {
  //       removeButton.addEventListener('click', () => {
  //         this.removeAddonFromLineItem(addon.id)
  //       })
  //     }

  //     this.currentAddonsTarget.appendChild(addonItem)
  //   })
  // }

  validateCustomForm() {
    if (!this.hasCustomNameTarget || !this.hasCustomSkuTarget || !this.hasCustomPriceTarget) {
      console.error('Missing custom form targets')
      return false
    }

    if (!this.customNameTarget.value) {
      this.showToast('Please enter a name for the custom add-on', 'error')
      this.customNameTarget.focus()
      return false
    }

    if (!this.customSkuTarget.value) {
      this.showToast('Please enter a SKU for the custom add-on', 'error')
      this.customSkuTarget.focus()
      return false
    }

    if (!this.customPriceTarget.value) {
      this.showToast('Please enter a price for the custom add-on', 'error')
      this.customPriceTarget.focus()
      return false
    }

    return true
  }

  clearCustomForm() {
    if (!this.hasCustomFormTarget) return;

    if (this.hasCustomNameTarget) this.customNameTarget.value = '';
    if (this.hasCustomSkuTarget) this.customSkuTarget.value = '';
    if (this.hasCustomPriceTarget) this.customPriceTarget.value = '';
    if (this.hasCustomCostTarget) this.customCostTarget.value = '';
    if (this.hasCustomQuantityTarget) this.customQuantityTarget.value = '1';
    if (this.hasCustomDescriptionTarget) this.customDescriptionTarget.value = '';
  }

  switchToCurrentTab() {
    const currentTabLink = this.element.querySelector('nav a[data-tab="current"]')
    if (currentTabLink) {
      currentTabLink.click()
    }
  }

  filterAddons(event) {
    this.renderAvailableAddons()
  }

  saveAddons(event) {
    event.preventDefault()

    if (!this.lineItemId || !this.lineItemElement) {
      console.error('No line item available')
      return
    }

    const lineItemIndex = this.getLineItemIndex(this.lineItemElement)
    if (!lineItemIndex) {
      console.error('Could not determine line item index')
      return
    }

    this.removeExistingAddonFields(lineItemIndex)
    this.createAddonFields(lineItemIndex)

    this.closeModal()

    this.updateLineItemTotals()

    this.showToast('Add-ons saved')
  }

  removeExistingAddonFields(lineItemIndex) {
    if (!this.lineItemElement) return

    const addonFields = this.lineItemElement.querySelectorAll(`input[name*="[line_items_attributes][${lineItemIndex}][line_item_addons_attributes]"]`)
    addonFields.forEach(field => {
      field.remove()
    })
  }

  createAddonFields(lineItemIndex) {
    if (!this.currentAddons || this.currentAddons.length === 0) return
    if (!this.lineItemElement) return
    if (!lineItemIndex) return

    let addonFieldsContainer = this.lineItemElement.querySelector('.line-item-addon-fields')
    if (!addonFieldsContainer) {
      addonFieldsContainer = document.createElement('div')
      addonFieldsContainer.className = 'line-item-addon-fields hidden'
      this.lineItemElement.appendChild(addonFieldsContainer)
    }

    // Clear existing fields
    addonFieldsContainer.innerHTML = ''

    // Determine form prefix based on the parent model
    const formPrefix = this.lineItemElement.querySelector('input[name*="quote[line_items_attributes]"]') ?
      'quote[line_items_attributes]' : 'order[line_items_attributes]'

    this.currentAddons.forEach((addon, index) => {
      const addonFields = document.createElement('div')
      addonFields.className = 'hidden'

      addonFields.innerHTML = `
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][name]" value="${addon.name || ''}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][sku]" value="${addon.sku || ''}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][quantity]" value="${addon.quantity || 1}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][price]" value="${addon.price || 0}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][cost]" value="${addon.cost || 0}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][discount_amount]" value="${addon.discount_amount || 0}">
        <input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][discount_type]" value="${addon.discount_type || 'fixed'}">
        ${addon.product_id ? `<input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][product_id]" value="${addon.product_id}">` : ''}
        ${addon.id && !isNaN(parseInt(addon.id)) ? `<input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][id]" value="${addon.id}">` : ''}
        ${addon._destroy ? `<input type="hidden" name="${formPrefix}[${lineItemIndex}][line_item_addons_attributes][${index}][_destroy]" value="1">` : ''}`

      addonFieldsContainer.appendChild(addonFields)
    })

    this.updateAddonDisplay(this.lineItemElement)
  }

  updateAddonDisplay(lineItem) {
    if (!lineItem) return

    let addonsDisplay = lineItem.querySelector('.line-item-addons-display')

    if (!addonsDisplay) {
      addonsDisplay = document.createElement('div')
      addonsDisplay.className = 'line-item-addons-display mt-2 pt-2 border-t border-gray-200 dark:border-space-700'

      // Find the pricing container using a more reliable attribute selector
      const parentElement = lineItem.querySelector('[data-pricing-container], .rounded-lg.p-3.flex-1.flex.flex-col')
      if (parentElement) {
        const targetElement = parentElement.querySelector('.mt-auto')
        if (targetElement) {
          parentElement.insertBefore(addonsDisplay, targetElement)
        } else {
          parentElement.appendChild(addonsDisplay)
        }
      } else {
        lineItem.appendChild(addonsDisplay)
      }
    }

    // Filter out destroyed addons
    const activeAddons = this.currentAddons.filter(addon => !addon._destroy)

    if (activeAddons.length === 0) {
      addonsDisplay.classList.add('hidden')
      return
    }

    let addonsHtml = '<div class="text-xs font-medium text-gray-700 dark:text-space-300 mb-1">Add-ons:</div>'

    activeAddons.forEach(addon => {
      const quantity = parseInt(addon.quantity) || 1
      const price = parseFloat(addon.price) || 0
      const total = quantity * price

      addonsHtml += `
        <div class="flex items-center justify-between text-xs py-1">
          <div class="flex items-center gap-1">
            <span class="font-medium">${addon.name}</span>
            <span class="text-gray-500 dark:text-space-400">x${quantity}</span>
          </div>
          <div class="font-medium">${this.formatCurrency(total)}</div>
        </div>
      `
    })

    addonsDisplay.innerHTML = addonsHtml
    addonsDisplay.classList.remove('hidden')
  }

  updateLineItemTotals() {
    if (!this.lineItemElement) return

    // Trigger the line item controller to update totals
    const priceInput = this.lineItemElement.querySelector('[data-line-item-target="price"]')
    if (priceInput) {
      const event = new Event('change')
      priceInput.dispatchEvent(event)
    }

    // If there's a quote-builder controller, update its totals too
    const quoteBuilderController = document.querySelector('[data-controller="quote-builder"]')
    if (quoteBuilderController && typeof quoteBuilderController.updateTotals === 'function') {
      quoteBuilderController.updateTotals()
    }
  }

  showToast(message, type = 'success') {
    const toast = document.createElement('div')

    if (type === 'success') {
      toast.className = 'fixed bottom-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    } else {
      toast.className = 'fixed bottom-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md shadow-lg z-50 transition-all duration-500 transform translate-y-0'
    }

    toast.style.opacity = '0'
    toast.textContent = message

    document.body.appendChild(toast)

    setTimeout(() => {
      toast.style.opacity = '1'
    }, 10)

    setTimeout(() => {
      toast.style.opacity = '0'
      setTimeout(() => {
        toast.remove()
      }, 500)
    }, 3000)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount)
  }

  formatAddonType(type) {
    if (!type) return 'Accessory'

    return type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, ' ')
  }

  styleAddonTypeLabel(label, type) {
    label.className = 'px-2 py-0.5 text-xs rounded-full'

    switch (type) {
      case 'warranty':
        label.classList.add('bg-blue-100', 'dark:bg-blue-900/30', 'text-blue-800', 'dark:text-blue-300')
        break
      case 'installation':
        label.classList.add('bg-purple-100', 'dark:bg-purple-900/30', 'text-purple-800', 'dark:text-purple-300')
        break
      case 'service':
        label.classList.add('bg-orange-100', 'dark:bg-orange-900/30', 'text-orange-800', 'dark:text-orange-300')
        break
      case 'custom':
        label.classList.add('bg-red-100', 'dark:bg-red-900/30', 'text-red-800', 'dark:text-red-300')
        break
      default:
        label.classList.add('bg-green-100', 'dark:bg-green-900/30', 'text-green-800', 'dark:text-green-300')
    }
  }

  getEmptyAddonsMessage(message = "No add-ons available") {
    return `
      <div class="col-span-full text-center p-8 text-gray-500 dark:text-space-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-space-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
        </svg>
        <p class="text-lg font-medium">${message}</p>
      </div>
    `
  }
}