import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    // Find the overlay manually
    const overlay = document.getElementById('mobile-search-overlay')

    if (overlay) {
      overlay.classList.toggle('hidden')

      // Focus on search input when opening
      if (!overlay.classList.contains('hidden')) {
        const searchInput = overlay.querySelector('input[type="text"]')
        if (searchInput) {
          searchInput.focus()
        }
      }
    }
  }

  connect() {
    // Optional: Add any setup logic
  }
}