// Application initialization
import { application } from "./application";

// Core controllers
import AnimationController from "./animation_controller";
import FlashController from "./flash_controller";
import ProgressController from "./progress_controller";
import SidebarController from "./sidebar";
import TabsController from "./tabs_controller";
import ImageUploadController from "./image_upload_controller";
import TrixController from "./trix_controller";
import ProductController from "./product_controller";
import ToggleController from "./toggle_controller";
import BrandFormController from "./brand_form_controller";
import ProductSyncController from "./product_sync_controller";
import FormTabsController from "./form_tabs_controller";
import SearchController from "./search_controller";
import SearchToggleController from "./search_toggle_controller";
import ProdcutTabsController from "./product_tabs_controller";
import ThemeToggleController from "./theme_toggle_controller";
import FilterPersistenceController from "./filter_persistence_controller";
import BulkSelectionController from "./bulk_selection_controller";
import GlobalTabsController from "./global_tabs_controller";
import SidebarToggleController from "./sidebar_toggle_controller";
import DepartmentRolesController from "./department_roles_controller";
import NotificationsController from "./notifications_controller";
import MessengerController from "./messenger_controller";
import Clipboard from "@stimulus-components/clipboard";
import ProductImagesHandlerController from "./product_images_handler_controller";

// Third party
import RailsNestedForm from "@stimulus-components/rails-nested-form";
import Dropdown from "./extended_dropdown_controller";
import Dialog from "@stimulus-components/dialog";
import CheckboxSelectAll from "@stimulus-components/checkbox-select-all";
import PlacesAutocomplete from "stimulus-places-autocomplete";

// Payment controllers
import StripeController from "./stripe_controller";
import PaymentsController from "./payments_controller";
import PaymentDialogController from "./payment_dialog_controller";

// Order and Quote Builder controllers
import OrderFormController from "./builder/order_form_controller";
import OrderItemController from "./builder/order_item_controller";
import OrderController from "./order_controller";
import OrderTotalsController from "./builder/order_totals_controller";
import ProductSearchController from "./builder/product_search_controller";
import CustomerSearchController from "./builder/customer_search_controller";
import AdditionalItemsController from "./builder/additional_items_controller";
import CustomItemController from "./builder/custom_item_controller";
import BulkAddController from "./builder/bulk_add_controller";
import OrderItemTabsController from "./builder/order_item_tabs_controller";
import OrderTypeHandlerController from "./builder/order_type_controller";
import QuoteBuilderController from "./quote_builder_controller";
import BuilderBulkImportController from "./builder/builder_bulk_import_controller";
import LineItemController from "./builder/line_item_controller";
import ProductDetailsController from "./builder/product_details_controller";

// Customer and Address controllers
import CustomerSelectorController from "./builder/customer_selector_controller";
import AddressSelectorController from "./builder/address_selector_controller";
import AddressFormController from "./builder/address_form_controller";
import CustomerFormController from "./builder/customer_form_controller";
import CustomerSidebarController from "./builder/customer_sidebar_controller";
import CustomerModalController from "./builder/customer_modal_controller";

// Purchase Order controllers
import POProductSearchController from "./po_product_search_controller";
import POOrderTotalsController from "./po_order_totals_controller";
import POTabsController from "./po_tabs_controller";

// Shipping and Inventory controllers
import ReceivingController from "./receiving_controller";
import ShippingController from "./shipping_controller";
import SerialsController from "./serials_controller";

// New modal controllers
import LineItemAddonsController from "./builder/line_item_addons_controller";
import BuilderProductSearchController from "./builder/builder_product_search_controller";
//import RelatedProductsController from "./builder/related_products_controller"

// New index controllers
import GlobalQotesController from "./global_quotes_controller";
//import GlobalOrdersController from "./global_orders_controller"

import OrderWizardController from "./order_wizard/order_wizard_controller";
import WizardPaymentsController from "./order_wizard/wizard_payments_controller";

import CreditLineController from "./credit_line_controller";
import CustomerAddressFormController from "./address_form_controller";
import CustomerNotesController from "./customer_notes_controller";
import PaymentManagerController from "./payment_manager_controller";
import SpecTableController from "./spec_table_controller";
import InventoryLevelController from "./inventory_level_controller";
import RmaController from "./rma_controller";
import ReceiveScanController from "./receieve_scan_controller";
import ReportTemplateController from "./report_template_controller";
import NotesController from "./notes_controller";

// Register core controllers
application.register("animation", AnimationController);
application.register("flash", FlashController);
application.register("progress", ProgressController);
application.register("sidebar", SidebarController);
application.register("tabs", TabsController);
application.register("image-upload", ImageUploadController);
application.register("trix", TrixController);
application.register("product", ProductController);
application.register("toggle", ToggleController);
application.register("brand-form", BrandFormController);
application.register("product-sync", ProductSyncController);
application.register("form-tabs", FormTabsController);
application.register("search", SearchController);
application.register("search-toggle", SearchToggleController);
application.register("product-tabs", ProdcutTabsController);
application.register("theme-toggle", ThemeToggleController);
application.register("filter-persistence", FilterPersistenceController);
application.register("bulk-selection", BulkSelectionController);
application.register("global-tabs", GlobalTabsController);
application.register("sidebar-toggle", SidebarToggleController);
application.register("department-roles", DepartmentRolesController);
application.register("notifications", NotificationsController);
application.register("messenger", MessengerController);
application.register("clipboard", Clipboard);
application.register("product-images-handler", ProductImagesHandlerController);

// Register third party
application.register("nested-form", RailsNestedForm);
application.register("dropdown", Dropdown);
application.register("dialog", Dialog);
application.register("checkbox-select-all", CheckboxSelectAll);
application.register("places-autocomplete", PlacesAutocomplete);

// Register payment controllers
application.register("stripe", StripeController);
application.register("payments", PaymentsController);
application.register("payment-dialog", PaymentDialogController);

// Register order and quote builder controllers
application.register("order-form", OrderFormController);
application.register("order-item", OrderItemController);
application.register("order", OrderController);
application.register("order-totals", OrderTotalsController);
application.register("product-search", ProductSearchController);
application.register("customer-search", CustomerSearchController);
application.register("additional-items", AdditionalItemsController);
application.register("custom-item", CustomItemController);
application.register("bulk-add", BulkAddController);
application.register("order-item-tabs", OrderItemTabsController);
application.register("order-type-handler", OrderTypeHandlerController);
application.register("quote-builder", QuoteBuilderController);
application.register("builder-bulk-importer", BuilderBulkImportController);
application.register("line-item", LineItemController);
application.register("product-details", ProductDetailsController);

// Register customer and address controllers
//application.register("customer-selector", CustomerSelectorController)
//application.register("address-selector", AddressSelectorController)
//application.register("address-form", AddressFormController)
//application.register("customer-form", CustomerFormController)
application.register("customer-sidebar", CustomerSidebarController);
application.register("customer-modal", CustomerModalController);

// Register purchase order controllers
application.register("po-product-search", POProductSearchController);
application.register("po-order-totals", POOrderTotalsController);
application.register("po-tabs", POTabsController);

// Register shipping and inventory controllers
application.register("receiving", ReceivingController);
application.register("shipping", ShippingController);
application.register("serials", SerialsController);

// Register new modal controllers
application.register("line-item-addons", LineItemAddonsController);
application.register("builder-product-search", BuilderProductSearchController);
//application.register("related-products", RelatedProductsController)

// Register new index controllers
application.register("global-quotes", GlobalQotesController);
//application.register("global-orders", GlobalOrdersController)

application.register("order-wizard", OrderWizardController);
application.register("wizard-payments", WizardPaymentsController);

application.register("credit-line", CreditLineController);
application.register("address-form", CustomerAddressFormController);
application.register("customer-notes", CustomerNotesController);
application.register("payment-manager", PaymentManagerController);
application.register("spec-table", SpecTableController);
application.register("inventory-level", InventoryLevelController);
application.register("rma", RmaController);
application.register("receive-scan", ReceiveScanController);
application.register("report-template", ReportTemplateController);
application.register("notes", NotesController);
